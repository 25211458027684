import styles from "./columnStore.module.scss";
import gem from "../../../assets/pages/store/gem.png";
import gemstore from "../../../assets/pages/store/gemStore.png";
import storeClose from "../../../assets/pages/store/storeClose.png";
import storeOpen from "../../../assets/pages/store/storeOpen.png";
import { Accessories } from "./Accessories";
import { FC, useState } from "react";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../../__generated__/gql-types/getClothesAndAccessories";
import { getUserScore_getUserScore } from "../../../__generated__/gql-types/getUserScore";
import { useNavigate } from "react-router-dom";
import useUpdateShowCloseStore from "../../../api/useUpdateShowCloseStore";
import { formatNumberWithSpaces } from "../utils";
interface PropsStore {
  setClothesAndAccessories: (
    e: getClothesAndAccessories_getClothesAndAccessories[] | null
  ) => void;
  clothesAndAccessories:
    | getClothesAndAccessories_getClothesAndAccessories[]
    | null;
  userScore: getUserScore_getUserScore | undefined;
  setUserScore: (e: getUserScore_getUserScore | undefined) => void;
}
const ColumnStore: FC<PropsStore> = ({
  clothesAndAccessories,
  setClothesAndAccessories,
  userScore,
  setUserScore,
}) => {
  const navigate = useNavigate();
  const updateShowClose = useUpdateShowCloseStore();
  const [tab, setTab] = useState("clothes");

  const [openStore, setOpenStore] = useState(false);

  return (
    <div className={styles.container}>
      {userScore?.level && userScore?.level < 6 ? (
        <div className={styles.containerStoreClose}>
          <div className={styles.containerImage}>
            <img src={storeClose} alt="storeClose" />
          </div>
          <div className={styles.containerInfo}>
            <div className={styles.title}>¡La tienda Luca está cerrada!</div>
            <div className={styles.description}>
              Podrás acceder a ella para comprar ropa para tu avatar, ¡cuando
              hayas alcanzado el nivel 6!
            </div>
            <div
              className={styles.completedButton}
              onClick={() => navigate("/mymissions")}
            >
              COMPLETA MÁS MISIONES
            </div>
          </div>
        </div>
      ) : openStore ||
        !userScore?.user?.avatar_state?.show_image_close_store ? (
        <>
          <div className={styles.containerHeader}>
            <div className={styles.headerPoints}>
              <div>Cristales disponibles</div>
              <div className={styles.headerStart}>
                <img src={gem} alt="gem" />
                <div>
                  {formatNumberWithSpaces(
                    userScore?.user?.avatar_state?.available_balance
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.containerTab}>
            <div
              className={tab === "clothes" ? styles.tabOn : styles.tabOff}
              onClick={() => setTab("clothes")}
            >
              Ropa
            </div>
            <div
              className={tab === "accessories" ? styles.tabOn : styles.tabOff}
              onClick={() => setTab("accessories")}
            >
              Accesorios
            </div>
            <div
              className={tab === "costume" ? styles.tabOn : styles.tabOff}
              onClick={() => setTab("costume")}
            >
              Disfraces
            </div>
          </div>
          {tab === "accessories" && (
            <div className={styles.containerAccessories}>
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                title={"Gafas"}
                setClothesAndAccessories={setClothesAndAccessories}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) =>
                      e.type === "accessories" && e.clasification === "glasses"
                  ) ?? null
                }
              />
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                title={"Sombreros"}
                setClothesAndAccessories={setClothesAndAccessories}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) =>
                      e.type === "accessories" && e.clasification === "hats"
                  ) ?? null
                }
              />
            </div>
          )}
          {tab === "clothes" && (
            <div className={styles.containerAccessories}>
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                title={"Parte de abajo"}
                setClothesAndAccessories={setClothesAndAccessories}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) => e.type === "clothes" && e.clasification === "bottom"
                  ) ?? null
                }
              />
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                setClothesAndAccessories={setClothesAndAccessories}
                title={"Parte de arriba"}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) => e.type === "clothes" && e.clasification === "top"
                  ) ?? null
                }
              />{" "}
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                setClothesAndAccessories={setClothesAndAccessories}
                title={"Trajes"}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) => e.type === "clothes" && e.clasification === "suite"
                  ) ?? null
                }
              />
            </div>
          )}
          {tab === "costume" && (
            <div className={styles.containerAccessories}>
              <Accessories
                userScore={userScore}
                setUserScore={setUserScore}
                setClothesAndAccessories={setClothesAndAccessories}
                title={"Disfraces"}
                clothesAndAccessories={
                  clothesAndAccessories?.filter(
                    (e) => e.type === "costume" && e.clasification === "costume"
                  ) ?? null
                }
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.containerStoreClose}>
          <div className={styles.containerImage}>
            <img src={storeOpen} alt="storeClose" />
          </div>
          <div className={styles.containerInfo}>
            <div className={styles.title}>
              <img src={gemstore} alt="" />
              ¡La tienda Luca está abierta!
            </div>
            <div className={styles.description}>
              Ahora puedes comprar ropa y accesorios para tu avatar, ¡y
              personalizarlo como tu quieras!
            </div>
            <div
              className={styles.completedButton}
              onClick={async () => {
                setOpenStore(true);
                await updateShowClose();
              }}
            >
              ACCEDE A LA TIENDA
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColumnStore;
