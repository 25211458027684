import React, { FC } from "react";
import styles from "./button.module.scss"

interface ButtonProps {
  type: string;
  text: string;
  disabled?: boolean;
  onClick?: any
}


const Button: FC<ButtonProps> = ({ type, text, onClick, disabled }) => {

  return (
    <button onClick={onClick} type="submit" className={styles[type]} disabled={disabled}>
      {text}
    </button>
  )
}

export default Button;