import { FC, useEffect, useState } from "react";
import { DrawerProfileMobile } from "../Mobile/Profile";
import { ActivityIndicator } from "../ActivityIndicator/ActivityIndicator";
import useAuth from "../../context/useAuth";
import home from "../../assets/icons/school_Drawer.svg";
import textBoxImage from "../../assets/images/textBoxImage.svg";
import familyIcon from "../../assets/icons/family.svg";
import msgIcon from "../../assets/icons/letterGrey.svg";
import Question from "../../assets/icons/students/Question.png";
import logOff from "../../assets/icons/students/log-off.png";
import styles from "./myProfileMobile.module.scss";
import { resolveAvatar } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { getUserScore_getUserScore } from "../../__generated__/gql-types/getUserScore";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../__generated__/gql-types/getClothesAndAccessories";
import UseUserScore from "../../api/useGetUserScore";
import UseGetClothesAndAccessories from "../../api/useGetClothesAndAccessories";
import { functionReturnImgAvatar } from "../Store/ColumnAvatar/ContainerAvatar/utils";
import start from "../../assets/icons/starBanner.png";
const MyProfileMobile: FC = () => {
  const { userToken, logout } = useAuth();
  const navigate = useNavigate();
  const getUserScore = UseUserScore();
  const getClothesAndAccessories = UseGetClothesAndAccessories();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    getClothesAndAccessories_getClothesAndAccessories[] | null
  >(null);
  const [userScore, setUserScore] = useState<getUserScore_getUserScore>();

  const onClose = () => {
    setVisibleAvatar && setVisibleAvatar(false);
  };
  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const goBack = () => {
    setVisibleAvatar(false);
  };
  
  const redirectSchoolBrisas = () => {
    logout();
    window.location.href = `https://www.colegiobrisas.com/aulavirtual`;
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getClothesAndAccessories();
      const clothesActive = response?.filter((e) => e.active);
      clothesActive && setClothesAndAccessories(clothesActive);
      const userScoreRes = await getUserScore();
      userScoreRes && setUserScore(userScoreRes);
      setIsLoading(false);
    })();
  }, [getClothesAndAccessories, getUserScore]);

  if (isLoading) {
    return <ActivityIndicator />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.pointsContainer}>
        <div className={styles.thirdAvatarContainer}>
          <div className={styles.sqContainerLeft}>
            <h3>Nivel</h3>
            <p>{userToken?.score?.level}</p>
          </div>
          <div className={styles.sqContainerMid}>
            <h3>EXPERIENCIA</h3>
            <div className={styles.numberContainerTwo}>
              <img alt="" src={start} />
              <p>{formatNumberWithSpaces(userScore?.main_score ?? 0)}</p>
            </div>
          </div>
          {isLoading && <ActivityIndicator />}
        </div>
      </div>

      <div className={styles.avatarContainer}>
        <div className={styles.textBoxSubContainer}>
          <div className={styles.textBoxSubContainerRelative}>
            <img src={textBoxImage} alt="" className={styles.textBoxImage} />
            <div className={styles.textImg}>
              ¡Hola {userToken?.first_name}!
              <p className={styles.textTwoImg}>
                ¡Wow! Tu monstruo cada día está más grande. ¡Sigue ganando
                cristales en las misiones y crecerá cada vez más!
              </p>
            </div>
          </div>
        </div>

        <div className={styles.avatarSubContainer}>
          {clothesAndAccessories?.map((clothes) => (
            <img
              src={clothes.item_img ?? ""}
              alt="avatar"
              style={{
                zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
              }}
              className={styles.clothes}
            />
          ))}
          <img
            src={
              userScore?.level && userScore?.level < 6
                ? resolveAvatar(
                    userScore?.user?.avatar_state?.name ?? "polux",
                    userScore?.level.toString() ?? "1"
                  )
                : functionReturnImgAvatar(
                    userScore?.user?.avatar_state?.name ?? ""
                  )
            }
            alt="avatar"
            className={
              userScore?.level && userScore?.level < 6
                ? styles.rotateImage // revisar
                : styles.avatarImg
            }
          />{" "}
        </div>
      </div>

      <div className={styles.accountContainer}>
        <div className={styles.account}> Mi cuenta</div>

        <div className={styles.name}>
          {userToken?.first_name} {userToken?.last_name}
        </div>

        <div className={styles.accountTextRow}>
          <img src={home} alt="" />
          <h5>{userToken?.schools && userToken?.schools[0]?.name}</h5>

          <h5 className={styles.gradeText}>
            Grupo {userToken?.groups && userToken?.groups[0]?.grade?.grade}
            {userToken?.groups && userToken?.groups[0]?.name}
          </h5>
        </div>
        <div className={styles.accountTextRow}>
          <img src={msgIcon} alt="" />
          <h5>{userToken?.email}</h5>
        </div>

        {userToken?.email_parent && (
          <div className={styles.accountTextRow}>
            <img src={familyIcon} alt="" />
            <h5 className={styles.schoolGrayText}>{userToken?.email_parent}</h5>
          </div>
        )}

        <div className={styles.containerbutton}>
          {userToken?.schools[0].name !== "Colegio Brisas" && (
            <div
              className={styles.buttonProfile}
              onClick={() => {
                navigate("/passwordchange");
              }}
            >
              Cambiar Contraseña
            </div>
          )}
        </div>
        <div className={styles.containerHelp}>
          <div className={styles.boxHelp}>
            <img src={Question} alt="question" />
            <div className={styles.contentHelpContianer}>
              <span className={styles.needHelp}>¿Necesitas ayuda?</span>
              <span className={styles.writing}>Escríbenos por WhatsApp al</span>
              <a href="https://wa.me/525541691994" className={styles.phone}>
                +52 55 4169 1994.
              </a>
            </div>
          </div>
        </div>

        <div className={styles.containerbutton}>
          <div
            className={styles.buttonProfile}
            onClick={() => {
              userToken?.schools[0].name === "Colegio Brisas"
                ? redirectSchoolBrisas()
                : logout();
            }}
          >
            <img src={logOff} alt="logg" />
            CERRAR SESIÓN
          </div>
        </div>
      </div>
      {visibleAvatar && (
        <DrawerProfileMobile
          onClose={onClose}
          visible={visibleAvatar}
          goBack={goBack}
          userDb={userToken}
        />
      )}
    </div>
  );
};

export default MyProfileMobile;
