import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useUpdateShowCloseStore = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: UPDATE_SHOW_CLOSE_STORE,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useUpdateShowCloseStore;

const UPDATE_SHOW_CLOSE_STORE = gql`
  mutation UpdateShowCloseStore {
    updateShowCloseStore {
      name
    }
  }
`;
