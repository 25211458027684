import { FC, useEffect, useState } from "react";
import scienceIcon from "../../../../../assets/icons/cienciaIcon.svg";
import mathIcon from "../../../../../assets/icons/mathIcon.svg";
import arrowBack from "../../../../../assets/icons/arrowIcon.svg";
import circleCorrect from "../../../../../assets/icons/circleCorrect.svg";
import styles from "./cardMobile.module.css";
import { TaskBanner } from "../../../../Classes/TaskBanner";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../context/useAuth";
import UseClassroomWithHomeworks from "../../../../../api/useGetClassroomWithHomeworks";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../../../__generated__/gql-types/getClassroomWithHomeworks";
import { getUserByEmail_getUserByEmail_classrooms } from "../../../../../__generated__/gql-types/getUserByEmail";

interface CardProps {
  item: getUserByEmail_getUserByEmail_classrooms;
}
const CardMobile: FC<CardProps> = ({ item }) => {
  const navigate = useNavigate();
  const getClassroomWithHomeworks = UseClassroomWithHomeworks();
  const { userToken } = useAuth();
  const showGradesOnBase100 = userToken?.schools[0].show_grades_on_base_100;
  const [ClassroomWithHomework, setClassroomWithHomework] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks | null>(null);

  const handleClickCard = (e?: boolean) => {
    navigate(`/myclasses/${item.id}`, {
      state: { item, openDrawer: e ?? false },
    });
  };
  useEffect(() => {
    const fetchHomework = async () => {
      const studentsClassroomHomeworks = await getClassroomWithHomeworks({
        classroomId: item.id,
      });
      setClassroomWithHomework(studentsClassroomHomeworks);
    };
    fetchHomework();
  }, [getClassroomWithHomeworks, item.id]);

  return (
    <div className={styles.container}>
      <div
        className={styles.headerContainer}
        onClick={() => {
          handleClickCard();
        }}
      >
        {item?.program?.subject?.name === "Español" && (
          <img src={scienceIcon} alt="" className={styles.headerImg} />
        )}
        {item?.program?.subject?.name === "Ciencias" && (
          <img src={scienceIcon} alt="" className={styles.headerImg} />
        )}
        {item?.program?.subject?.name === "Matemáticas" && (
          <img src={mathIcon} alt="" className={styles.headerImg} />
        )}

        <div className={styles.headerTextContainer}>
          <div className={styles.titleHeader}>{item?.name}</div>
          <div className={styles.subInfoHeader}>
            {item?.group?.users?.length ?? 0} Alumnos - {""}
            {
              ClassroomWithHomework?.homeworks?.filter(
                (a) => a && a.deleted_at === null
              ).length
            }
            {""} Tareas
          </div>
        </div>
        <img src={arrowBack} alt="" className={styles.arrowBack} />
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.notesContainer}>
          <div className={styles.box}>
            <div className={styles.textBox}>Calificación promedio</div>
            <div className={styles.notasContainer}>
              <div className={styles.notas}>
                {ClassroomWithHomework?.total_average && showGradesOnBase100 ? (
                  <div className={styles.numberContainer}>
                    <img src={circleCorrect} alt="" />

                    {Number(ClassroomWithHomework?.total_average) * 10}
                  </div>
                ) : Number(ClassroomWithHomework?.total_average) ? (
                  Number(ClassroomWithHomework?.total_average)
                ) : (
                  "--"
                )}
              </div>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.textBox}>Participación tareas</div>
            <div className={styles.notas}>
              {ClassroomWithHomework?.total_participation
                ? `${ClassroomWithHomework?.total_participation}%`
                : "0%"}
            </div>
          </div>
        </div>
        <TaskBanner
          handleClickCard={handleClickCard}
          homeworks={
            ClassroomWithHomework?.homeworks?.filter(
              (a) => a && a.deleted_at === null
            ) ?? []
          }
        />
      </div>
    </div>
  );
};

export default CardMobile;
