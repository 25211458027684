import React, { FC, useState } from "react";
import styles from "./accessories.module.scss";
import accesesories from "../../../../assets/pages/store/accesesoriesTest.png";
import gemBox from "../../../../assets/pages/store/gemBox.png";
import {
  getClothesAndAccessories_getClothesAndAccessories as PropsClothes,
  getClothesAndAccessories_getClothesAndAccessories,
} from "../../../../__generated__/gql-types/getClothesAndAccessories";
import { ModalAccessory } from "./ModalAccessory";
import useUpdateStatusItem from "../../../../api/useUpdateItem";
import UseGetClothesAndAccessories from "../../../../api/useGetClothesAndAccessories";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import useRemoveItemSelected from "../../../../api/useRemoveItemSelected";

interface PropsClothesAndAccessories {
  setClothesAndAccessories: (
    e: getClothesAndAccessories_getClothesAndAccessories[] | null
  ) => void;
  clothesAndAccessories: PropsClothes[] | null;
  title: string;
  setUserScore: (e: getUserScore_getUserScore | undefined) => void;
  userScore: getUserScore_getUserScore | undefined;
}

const Accessories: FC<PropsClothesAndAccessories> = ({
  title,
  clothesAndAccessories,
  setClothesAndAccessories,
  setUserScore,
  userScore,
}) => {
  const [isChanging, setIsChanging] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [clothes, setClothes] = useState<PropsClothes | null>(null);
  const updateItem = useUpdateStatusItem();
  const removeItem = useRemoveItemSelected();
  const getClothesAndAccessories = UseGetClothesAndAccessories();
  const functionUpdateItem = async (id: string) => {
    await updateItem({ itemId: id });
    const response = await getClothesAndAccessories();
    setClothesAndAccessories(response);
  };

  const onCloseModalAccessory = () => {
    setOpenModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.tite}>{title}</div>
      <div className={styles.cuadBox}>
        {clothesAndAccessories?.map((clothes) => {
          if (!clothes.purchased) {
            return (
              <div
                className={styles.boxWithOutAccesories}
                onClick={() => {
                  setClothes(clothes);
                  setOpenModal(true);
                }}
              >
                <img
                  src={clothes.thumbnail_img ?? accesesories}
                  alt="accesesories"
                  className={styles.accesesoriesImg}
                />
                <div className={styles.containerGem}>
                  <img src={gemBox} alt="gem" />
                  <div className={styles.points}>{clothes.price}</div>
                </div>
              </div>
            );
          }
          if (clothes.purchased && clothes.active) {
            return (
              <div
                className={styles.boxSelected}
                onClick={async () => {
                  setIsChanging(true);
                  clothes.id &&
                    !isChanging &&
                    (await functionUpdateItem(clothes.id?.toString()));
                  setIsChanging(false);
                }}
              >
                <img
                  className={styles.thubnailImg}
                  src={clothes.thumbnail_img ?? accesesories}
                  alt="accesesories"
                />
              </div>
            );
          } else {
            return (
              <div
                className={styles.boxBought}
                onClick={async () => {
                  setIsChanging(true);
                  clothes.id &&
                    !isChanging &&
                    (await functionUpdateItem(clothes.id?.toString()));
                  setIsChanging(false);
                }}
              >
                <img
                  className={styles.thubnailImg}
                  src={clothes.thumbnail_img ?? accesesories}
                  alt="accesesories"
                />
              </div>
            );
          }
        })}
      </div>
      <ModalAccessory
        clothes={clothes}
        setUserScore={setUserScore}
        openModal={openModal}
        userScore={userScore}
        onCloseModalAccessory={onCloseModalAccessory}
        setClothesAndAccessories={setClothesAndAccessories}
      ></ModalAccessory>
    </div>
  );
};

export default Accessories;
