import React, { FC, useState } from "react";
import styles from "./footerRedaction.module.scss";
import { ModalRedaction } from "./ModalRedaction";
import { useNavigate } from "react-router-dom";
import useHandleStudentResponse from "../../../../../api/useHandleStudentResponse";
import { getResponseByUserAndRedactionHomework_getResponseByUserAndRedactionHomework } from "../../../../../__generated__/gql-types/getResponseByUserAndRedactionHomework";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
import {
  NotificationPositive,
  notificationNegative,
} from "../../../../Shared/Notifacation/NotificationLuca";

interface PropsRedaction {
  redactionText: string;
  responseByUserAndRedactionHomework: getResponseByUserAndRedactionHomework_getResponseByUserAndRedactionHomework | null;
  lesson: getLessonById_getLessonById | null;
  setResult: (e: boolean) => void;
  userToken: getUserByEmail_getUserByEmail | null;
  homeworkId: string | null;
}
const FooterRedaction: FC<PropsRedaction> = ({
  redactionText,
  responseByUserAndRedactionHomework,
  lesson,
  userToken,
  homeworkId,
  setResult,
}) => {
  const navigate = useNavigate();
  const handleStudentResponse = useHandleStudentResponse();
  const [open, setOpen] = useState(false);

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleSendOrSave = async (isPartialRedaction: boolean) => {
    try {
      const responseId = Number(lesson?.lesson_content?.redaction?.id) ?? null;
      const userId = Number(userToken?.id) ?? null;
      const homework_id = Number(homeworkId) ?? null;
      if (
        responseByUserAndRedactionHomework &&
        responseByUserAndRedactionHomework?.id
      ) {
        await handleStudentResponse({
          responseId: responseByUserAndRedactionHomework.id,
          responsesInput: {
            homework_id: homework_id,
            partial_redaction_save: isPartialRedaction,
            redaction_id: responseId,
            user_id: userId,
            redaction_response: redactionText,
          },
        });
      } else {
        await handleStudentResponse({
          responsesInput: {
            homework_id: homework_id,
            partial_redaction_save: isPartialRedaction,
            redaction_id: responseId,
            user_id: userId,
            redaction_response: redactionText,
          },
        });
      }
      isPartialRedaction
        ? NotificationPositive("Tu respuesta fue guardada correctamente")
        : setResult(true);
    } catch (error) {
      notificationNegative(
        "Ocurrió un error al enviar o guardar tu respuesta, por favor reintentá más tarde."
      );
      console.log(error);
    }
  };

  return (
    <div className={styles.footer}>
      <ModalRedaction
        open={open}
        onCloseModal={onCloseModal}
        handleSendOrSave={handleSendOrSave}
      />
      <div
        className={styles.saveFooter}
        onClick={async () => {
          await handleSendOrSave(true);
          navigate(-1);
        }}
      >
        Guardar y Salir
      </div>
      <div
        className={styles.sendFooter}
        onClick={() => {
          setOpen(true);
        }}
      >
        Enviar
      </div>
    </div>
  );
};

export default FooterRedaction;
