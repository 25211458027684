import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles.css";
import { GraphQlProvider } from "./components";
import { AuthProvider } from "./context/useAuth";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import "antd/dist/reset.css";
import { UserProvider } from "./UserProvider/UserProvider";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider locale={es_ES}>
      <AuthProvider>
        <GraphQlProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </GraphQlProvider>
      </AuthProvider>
    </ConfigProvider>
  </Provider>
);

// reportWebVitals(); Reportes comentados
