import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalDelete.module.scss";

interface PropsModalDelete {
  modalDelete: boolean;
  onCloseModalDelete: () => void;
  deleteHomeworkModal: () => Promise<void>;
}
const ModalDelete: FC<PropsModalDelete> = ({
  modalDelete,
  onCloseModalDelete,
  deleteHomeworkModal,
}) => {
  return (
    <Modal
      open={modalDelete}
      onCancel={onCloseModalDelete}
      closable={true}
      width={"444px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          ¿Estás seguro de que quieres eliminar esta tarea asignada??
        </div>

        <div className={styles.footerDetail}>
          <button
            className={`${styles.taskButtonDelete} ${styles.footerButton}`}
            onClick={() => {
              onCloseModalDelete();
            }}
          >
            Cancelar
          </button>

          <button
            className={`${styles.taskButtonMision} ${styles.footerButton}`}
            onClick={async () => {
              await deleteHomeworkModal();
            }}
          >
            Eliminar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
