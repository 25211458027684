import React from "react";
import styles from "./activityIndicator.module.css";
import { Spin } from "antd";

export const ActivityIndicator = () => {
  return (
    <div className={styles.spinner}>
      <Spin size="large" />
    </div>
  );
};
