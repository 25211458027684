import React, { FC } from "react";
import styles from "./mainConversation.module.scss";
import leftArrow from "../../../../../assets/icons/arrows/leftArrow.png";
import rightArrow from "../../../../../assets/icons/arrows/rightArrow.png";
import { getHomeworkById_getHomeworkById_students_grades } from "../../../../../__generated__/gql-types/getHomeworkById";
import MessageList from "../../../../Students/Missions/NewQuizz/Conversartion/ChatConversation/MessageList/MessageList";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import { GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection } from "../../../../../__generated__/gql-types/GetChatLogForTeacherCorrection";
import { Loader } from "../../../../Commons";
interface PropsConversation {
  individualLesson: getLessonById_getLessonById | null;
  chatLog: GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection | null;
  alumns: getHomeworkById_getHomeworkById_students_grades[];
  setCurrentIndex: (e: number) => void;
  currentIndex: number;
  isLoading: boolean;
}

const MainConversation: FC<PropsConversation> = ({
  chatLog,
  alumns,
  currentIndex,
  setCurrentIndex,
  isLoading,
  individualLesson,
}) => {
  const handleNext = () => {
    if (currentIndex < alumns.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(alumns.length - 1);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.containerUsers}>
        <div
          className={styles.arrow}
          onClick={() => {
            handlePrev();
          }}
        >
          <img src={leftArrow} alt="left" />
        </div>
        <div className={styles.nameUser}>
          {alumns && alumns[currentIndex] && alumns[currentIndex].student_name}
        </div>
        <div
          className={styles.arrow}
          onClick={() => {
            handleNext();
          }}
        >
          <img src={rightArrow} alt="right" />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.containerInfo}>
          {individualLesson && chatLog?.chat_log && (
            <MessageList
              isTyping={false}
              readOnly={true}
              individualLesson={individualLesson}
              messages={chatLog.chat_log}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MainConversation;
