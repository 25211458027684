import React, { ReactNode, createContext, useContext, useRef } from "react";

interface AudioContextProps {
  currentAudio: HTMLAudioElement | null;
  setCurrentAudio: (audio: HTMLAudioElement | null) => void;
}

interface AudioProviderProps {
  children: ReactNode;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const setCurrentAudio = (audio: HTMLAudioElement | null) => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    audioRef.current = audio;
    audio && audio.play();
  };

  return (
    <AudioContext.Provider
      value={{ currentAudio: audioRef.current, setCurrentAudio }}
    >
      {children}
    </AudioContext.Provider>
  );
};
