import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getHomeworkByIdForStudent,
  getHomeworkByIdForStudentVariables,
} from "../__generated__/gql-types/getHomeworkByIdForStudent";

const UseGetHomeworkByIdForStudent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getHomeworkByIdForStudentVariables) => {
      const result: ApolloQueryResult<getHomeworkByIdForStudent> =
        await client.query({
          query: GET_HOMEWORK_BY_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getHomeworkByIdForStudent;
    },
    [client]
  );
};

export default UseGetHomeworkByIdForStudent;

const GET_HOMEWORK_BY_ID = gql`
  query getHomeworkByIdForStudent($homeworkId: ID!) {
    getHomeworkByIdForStudent(homeworkId: $homeworkId) {
      id
      description
      title
      status
      start_date
      end_date
      group_id
      finished
      result
      studentEvidenceEvent
      cta_button
      redaction_correction
      redaction_review_by_student
      lessons {
        id
        title
        description
        lesson_type
        subject_id
        lesson_content {
          id
          aiconversations {
            id
            title
            publish
            level_cerf
            character_for_role_play
            card_image
            cover_image
            avatar_image
            section_1 {
              question
              order
            }
            section_2 {
              question
              order
            }
            created_at
            updated_at
            deleted_at
          }
          literacy {
            id
            cover
            title
            content {
              type
              image
              text
              order
            }
          }
          redaction {
            id
          }
          quizzes {
            questions {
              id
              question
              image
              image_aspect
              type_id
              order
              relationQuestion {
                id
                block1 {
                  image
                  text
                  coincidence
                  url
                }
                block2 {
                  image
                  text
                  coincidence
                  url
                }
                question_id
                created_at
                updated_at
                deleted_at
              }
              dAndDQuestion {
                id
                blockItems {
                  image
                  text
                  coincidence
                  url
                }
                blockContainer {
                  image
                  text
                  coincidence
                  totalItems
                  url
                }
                question_id
                created_at
                updated_at
                deleted_at
              }
              url
              created_at
              updated_at
              deleted_at
              answers {
                id
                answer
                image
                image_aspect
                correct
                order
                question_id
                url
              }
            }
          }
          videos {
            title
            url
            imageUrl
            file_id
          }
        }
      }
      updated_at
      needs_file_evidence
      teacher_file_url
      individualHomework
      tries
      studentTries
      utc_start_date
      utc_end_date
      utc_filter_date
      participation
      average
      post_homework_result
    }
  }
`;
