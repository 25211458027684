import React, { FC, useState } from "react";
import styles from "./chatConversation.module.scss";
import { PropsChat } from "../utils";
import { Report } from "./Report";
import MessageList from "./MessageList/MessageList";
import defaultImage from "../../../../../../assets/pages/quiz/dall.png";
import { InputChat } from "./InputChat";

const ChatConversation: FC<PropsChat> = ({
  messages,
  individualLesson,
  isTyping,
  isFinished,
  handleSendAlumn,
}) => {
  const [isReport, setIsReport] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.containerImg}>
        <img
          src={
            individualLesson.lesson_content.aiconversations?.cover_image ??
            defaultImage
          }
          alt="img"
          className={styles.imgStyles}
        />
      </div>
      <div className={styles.containerChatBox}>
        <MessageList
          individualLesson={individualLesson}
          autoPlay={true}
          isTyping={isTyping}
          messages={messages}
        />
        {isReport ? (
          <Report />
        ) : (
          <InputChat
            handleSendAlumn={handleSendAlumn}
            isFinished={isFinished}
            isTyping={isTyping}
          />
        )}
      </div>
    </div>
  );
};

export default ChatConversation;
