import { useState, useEffect } from "react";

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		setIsMobile(window.innerWidth < 768); // Cambia el valor 768 según tus necesidades
	};

	useEffect(() => {
		// Llama a la función inicialmente para establecer el estado inicial
		handleResize();

		// Agrega un event listener para escuchar cambios en el tamaño de la pantalla
		window.addEventListener("resize", handleResize);

		// Limpia el event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []); // El array vacío [] asegura que este efecto solo se ejecute una vez

	return isMobile;
};

export default useIsMobile;
