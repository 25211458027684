import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getClassroomsByTeacher,
  getClassroomsByTeacherVariables,
} from "../__generated__/gql-types/getClassroomsByTeacher";

const UseClassroomsByTeacher = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getClassroomsByTeacherVariables) => {
      const result: ApolloQueryResult<getClassroomsByTeacher> =
        await client.query({
          query: GET_CLASSES,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClassroomsByTeacher;
    },
    [client]
  );
};

export default UseClassroomsByTeacher;

const GET_CLASSES = gql`
  query getClassroomsByTeacher(
    $getClassroomsByTeacherIdInput: GetClassroomsByTeacherIdInput
  ) {
    getClassroomsByTeacher(
      getClassroomsByTeacherIdInput: $getClassroomsByTeacherIdInput
    ) {
      id
      name
      school {
        id
        name
      }
      group {
        id
        name
        users {
          id
          first_name
          last_name
          email
          avatar
          resolved_homeworks
          score {
            math_average
            science_average
            spanish_average
          }
        }
      }
      classroom_lessons {
        id
        start_date
        classroom {
          name
        }
        classroom_id
        group {
          name
        }
        lesson {
          id
        }
        created_at
        updated_at
        deleted_at
        type
        title
      }

      teachers {
        id
        first_name
        last_name
        email
      }
      homeworks {
        id
        title
        description
        status
        tries
        start_date
        end_date
        deleted_at
        created_at
        utc_start_date
        utc_end_date
        utc_filter_date
      }
      program {
        subject {
          id
          name
          code
        }
        grade {
          name
          grade
        }
      }
    }
  }
`;
