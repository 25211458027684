import { FC, Key, useState } from "react";
import { Box } from "./Box";
import { Dustbin } from "./Dustbin";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import { BoxState, DustbinState } from "./interfaces";
import styles from "./matchingText.module.scss";
import { synthesizeLanguageText } from "../NewQuizz/utils";

export interface MatchingTextProps {
  droppedBoxNames: any;
  itemsMatched: any;
  dustbins?: DustbinState[];
  boxes: BoxState[];
  checkButtonPressed: boolean;
  handleDrop: (
    index: number,
    item: {
      name: string;
      type: any;
    }
  ) => void;
  content: any;
  subject_id: number | null;
}

const MatchingText: FC<MatchingTextProps> = ({
  droppedBoxNames,
  dustbins,
  boxes,
  checkButtonPressed,
  itemsMatched,
  handleDrop,
  content,
  subject_id,
}) => {
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);

  const handleVoice = async () => {
    setIsSoundActive(true);
    subject_id && (await synthesizeLanguageText(content.question, subject_id));
    setIsSoundActive(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {!isSoundActive && (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt=""
          />
        )}
        {isSoundActive && (
          <img className={styles.imgSound} src={soundNegative} alt="" />
        )}
        {content.question}
      </div>
      <div className={styles.containerBox}>
        <div className={styles.containerMatching}>
          {boxes.map(
            ({ name, type, match }: any, index: Key | null | undefined) => (
              <Box
                name={name}
                type={type}
                key={index}
                droppedBoxNames={droppedBoxNames}
                id={type}
              />
            )
          )}
        </div>
        <div className={styles.containerMatching}>
          {dustbins &&
            dustbins.map(
              (
                { accepts, lastDroppedItem, name, img, type }: any,
                index: any
              ) => (
                <Dustbin
                  accept={accepts}
                  lastDroppedItem={lastDroppedItem}
                  onDrop={(item) => {
                    handleDrop(index, item);
                  }}
                  key={index}
                  name={name}
                  img={img}
                  itemsMatched={itemsMatched}
                  onCheckPressed={checkButtonPressed}
                  dustbinType={type}
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default MatchingText;
