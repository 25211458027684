import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherAssignClassroomLessonActionEventVariables } from "../__generated__/gql-types/teacherAssignClassroomLessonActionEvent";

const useTeacherAssignClassroomLessonActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherAssignClassroomLessonActionEventVariables) => {
      return await client.mutate({
        mutation: TEACHER_ASSIGN_CLASSROOMLESSON_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherAssignClassroomLessonActionEvent;

const TEACHER_ASSIGN_CLASSROOMLESSON_ACTION_EVENT = gql`
  mutation teacherAssignClassroomLessonActionEvent(
    $contentId: Int
    $startDate: String
  ) {
    teacherAssignClassroomLessonActionEvent(
      contentId: $contentId
      start_date: $startDate
    )
  }
`;
