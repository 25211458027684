import { useEffect, useState } from "react";
import styles from "./bannerClasification.module.scss";
import moon from "../../../../assets/images/students/banner/moonBanner.svg";
import medalFirst from "../../../../assets/icons/clasification/medalFirst.svg";
import medalThired from "../../../../assets/icons/clasification/medalThired.svg";
import medalSecond from "../../../../assets/icons/clasification/medalSecond.svg";
import rocket from "../../../../assets/images/students/banner/rocketBanner.svg";
import { functionReturnImgAvatar } from "../../ColumnAvatar/ContainerAvatar/utils";
import {
  getInfoTournament_getInfoTournament,
  getInfoTournament_getInfoTournament_userInfo,
} from "../../../../__generated__/gql-types/getInfoTournament";
import { getMonthName, resolveAvatar } from "../../../../utils/functions";
import useAuth from "../../../../context/useAuth";
import useIsMobile from "../../../../hooks/useIsMobile";

interface PropsBanner {
  infoTournament: getInfoTournament_getInfoTournament | null;
  dateLeader: string;
  daysLeft: number;
  setUserModal: (e: getInfoTournament_getInfoTournament_userInfo) => void;
  setOpenModal: (e: boolean) => void;
}

const BannerClasification = ({
  infoTournament,
  dateLeader,
  daysLeft,
  setOpenModal,
  setUserModal,
}: PropsBanner) => {
  const { userToken } = useAuth();
  const [dia, setDia] = useState(0);
  const isMobile = useIsMobile();
  const [diaMore, setDiaMore] = useState(0);
  const [finishMonth, setFinishMonth] = useState<Date>();
  const firstAlumn =
    infoTournament && infoTournament?.userInfo?.find((e) => e.position === 0);
  const secondAlumn =
    infoTournament && infoTournament?.userInfo?.find((e) => e.position === 1);
  const thirdAlumn =
    infoTournament && infoTournament?.userInfo?.find((e) => e.position === 2);

  useEffect(() => {
    if (dateLeader) {
      const day = dateLeader.split("-")[2];
      const month = dateLeader.split("-")[1];
      const year = dateLeader.split("-")[0];
      setDia(new Date(`${month}-${day}-${year}`).getDate());
      const dateLeaderBecome = new Date(`${month}-${day}-${year}`);
      dateLeaderBecome.setDate(dateLeaderBecome.getDate() + 14);
      setDiaMore(dateLeaderBecome.getDate());
      setFinishMonth(dateLeaderBecome);
    }
  }, [dateLeader]);

  return (
    <div className={isMobile ? styles.containerMobile : styles.container}>
      {infoTournament && (
        <>
          <div className={styles.containerDate}>
            <div className={styles.badgeDate}>
              {dia} al {diaMore} de{" "}
              {getMonthName(
                new Date(Number(finishMonth ?? "")).getMonth()
              ).toLowerCase()}{" "}
              | quedan {daysLeft} días
            </div>
          </div>
          <div className={styles.containerCard}>
            <div
              className={styles.cardAvatar}
              onClick={() => {
                firstAlumn && setUserModal(firstAlumn);
                setOpenModal(true);
              }}
              style={
                userToken?.id === firstAlumn?.id
                  ? { background: "#FFC95C" }
                  : {}
              }
            >
              <div className={styles.medalContainer}>
                <img src={medalFirst} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {firstAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    firstAlumn?.level && firstAlumn?.level < 6
                      ? resolveAvatar(
                          firstAlumn?.avatar_name ?? "polux",
                          firstAlumn?.level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(firstAlumn?.avatar_name ?? "")
                  }
                  alt="avatar"
                  className={
                    firstAlumn?.level && firstAlumn?.level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {firstAlumn && firstAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {firstAlumn && firstAlumn.score} puntos
                </div>
              </div>
            </div>
            <div
              className={styles.cardAvatar}
              onClick={() => {
                secondAlumn && setUserModal(secondAlumn);
                setOpenModal(true);
              }}
              style={
                userToken?.id === secondAlumn?.id
                  ? { background: "#FFC95C" }
                  : {}
              }
            >
              <div className={styles.medalContainer}>
                <img src={medalSecond} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {secondAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    secondAlumn?.level && secondAlumn?.level < 6
                      ? resolveAvatar(
                          secondAlumn?.avatar_name ?? "polux",
                          secondAlumn?.level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(secondAlumn?.avatar_name ?? "")
                  }
                  alt="avatar"
                  className={
                    secondAlumn?.level && secondAlumn?.level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {secondAlumn && secondAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {secondAlumn && secondAlumn.score} puntos
                </div>
              </div>
            </div>
            <div
              className={styles.cardAvatar}
              onClick={() => {
                thirdAlumn && setUserModal(thirdAlumn);
                setOpenModal(true);
              }}
              style={
                userToken?.id === thirdAlumn?.id
                  ? { background: "#FFC95C" }
                  : {}
              }
            >
              <div className={styles.medalContainer}>
                <img src={medalThired} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {thirdAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    thirdAlumn?.level && thirdAlumn?.level < 6
                      ? resolveAvatar(
                          thirdAlumn?.avatar_name ?? "polux",
                          thirdAlumn?.level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(thirdAlumn?.avatar_name ?? "")
                  }
                  alt="avatar"
                  className={
                    thirdAlumn?.level && thirdAlumn?.level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {thirdAlumn && thirdAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {thirdAlumn && thirdAlumn.score} puntos
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!isMobile && (
        <>
          <img
            alt="space"
            src={rocket}
            className={styles.imageRocket}
            width={"90%"}
          />
          <img alt="space" src={moon} className={styles.imageMoon} />
        </>
      )}
    </div>
  );
};

export default BannerClasification;
