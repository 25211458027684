import styles from "./condiciones.module.scss";

const Condiciones = () => {
  return (
    <div className={styles.bodyDrawer}>
      <h3>
        <strong>
          Propietario de la página web, la oferta y el enlace de los Términos
        </strong>
      </h3>

      <p>
        Esta página web es propiedad y está operado por
        <strong>Luca Learning Systems S.L.U.</strong> Estos Términos
        establecen los términos y condiciones bajo los cuales puedes usar
        nuestra página web y servicios ofrecidos por nosotros. Esta página web
        ofrece a los visitantes contenido audiovisual para el apoyo escolar de
        primaria y secundaria. Al acceder o usar la página web de nuestro
        servicio, usted aprueba que haya leído, entendido y aceptado estar
        sujeto a estos Términos:
      </p>
      <h4>
        <strong>
          ¿Quién puede usar tu página web; ¿Cuáles son los requisitos para
          tener una cuenta?
        </strong>
      </h4>
      <p>
        Para usar nuestra página web y / o recibir nuestros servicios, debes
        tener al menos 6 años de edad, y poseer la autoridad legal, el derecho
        y la libertad para participar en estos Términos como un acuerdo
        vinculante. No tienes permitido utilizar esta página web y / o recibir
        servicios si hacerlo está prohibido en tu país o en virtud de
        cualquier ley o regulación aplicable a tu caso.
      </p>
      <h4>
        <strong>Términos comerciales ofrecidos a los clientes</strong>
      </h4>

      <p>
        Al adquirir una licencia, aceptas que: (i) eres responsable de leer el
        listado completo del servicio antes de comprometerte a adquirirla:
        (ii) celebras un contrato legalmente vinculante para adquirir un
        servicio cuando te comprometes y completas el proceso de onboarding..
      </p>

      <h4>
        <strong>
          Condiciones particulares de contratación y uso de Luca Piloto
        </strong>
      </h4>

      <p>
        Mediante el siguiente acuerdo{" "}
        <strong>Luca Learning Systems S.L.U.</strong> pone a disposición de
        las escuelas el sistema Luca, una licencia de prueba de libre acceso
        GRATUITO al sistema.
      </p>
      <p>
        Son de aplicación las presentes Condiciones generales y mediante el
        mismo el usuario accede y/o está de acuerdo con las siguientes
        cláusulas o requisitos particulares de dicha licencia de prueba:
      </p>
      <h4>
        <strong>Términos comerciales ofrecidos a los clientes</strong>
      </h4>
      <ul>
        <li>
          Servicio disponible hasta finalización del ciclo escolar 2022-2023
          en México.
        </li>
        <li>
          Aceptación de recibir correos electrónicos, elementos publicitarios
          en entrada y pie de nuestra plataforma para la obtención de un
          correcto feedback de uso de la plataforma.
        </li>
      </ul>

      <p>
        Estas limitaciones tienen como objetivo la correcta evaluación de la
        plataforma por nuestros clientes.
      </p>
      <p>
        Estas condiciones podrán verse alteradas en cualquier momento que
        pueda poner en riesgo la integridad y objetivos de dicha licencia de
        prueba de la plataforma.
      </p>
      <h4>
        <strong>Retención del derecho a cambiar de oferta</strong>
      </h4>

      <p>
        Podemos, sin aviso previo, cambiar los servicios; dejar de
        proporcionar los servicios o cualquier característica de los servicios
        que ofrecemos; o crear límites para los servicios. Podemos suspender
        de manera permanente o temporal el acceso a los servicios sin previo
        aviso ni responsabilidad por cualquier motivo, o sin ningún motivo.
      </p>
      <h4>
        <strong>Garantías y responsabilidad por servicios y productos</strong>
      </h4>

      <p>
        Cuando recibimos un reclamo de garantía válido de un servicio que
        vendemos, repararemos el defecto relevante. Si no podemos reparar o
        reemplazar el servicio dentro de un tiempo razonable, el cliente
        tendrá derecho a un reembolso.
      </p>

      <h4>
        <strong>
          Posesión de propiedad intelectual, derechos de autor y logos.
        </strong>
      </h4>
      <p>
        El Servicio y todos los materiales incluidos o transferidos,
        incluyendo, sin limitación, software, imágenes, texto, gráficos,
        logotipos, patentes, marcas registradas, marcas de servicio, derechos
        de autor, fotografías, audio, videos, música y todos los Derechos de
        Propiedad Intelectual relacionados con ellos, son la propiedad
        exclusiva de <strong>Luca Learning Systems S.L.U.</strong> salvo que
        se indique explícitamente en este documento, no se considerará que
        nada en estos Términos crea una licencia en o bajo ninguno de dichos
        Derechos de Propiedad Intelectual, y tú aceptas no vender, licenciar,
        alquilar, modificar, distribuir, copiar, reproducir, transmitir,
        exhibir públicamente, realizar públicamente, publicar, adaptar, editar
        o crear trabajos derivados de los mismos.
      </p>

      <h4>
        <strong>Derecho a suspender o cancelar la cuenta de usuario</strong>
      </h4>
      <p>
        Podemos terminar o suspender de manera permanente o temporal tu acceso
        al servicio sin previo aviso y responsabilidad por cualquier razón,
        incluso si nuestra sola determinación tu violas alguna disposición de
        estos Términos o cualquier ley o regulación aplicable. Puedes
        descontinuar el uso y solicitar cancelar tu cuenta y / o cualquier
        servicio en cualquier momento. Sin perjuicio de lo contrario en lo que
        antecede, con respecto a las suscripciones renovadas automáticamente a
        los servicios pagados, dichas suscripciones se suspenderán sólo al
        vencimiento del período correspondiente por el que ya has realizado el
        pago.
      </p>

      <h4>
        <strong>Indemnización</strong>
      </h4>
      <p>
        Usted acuerda indemnizar y eximir de responsabilidad a{" "}
        <strong>Luca Learning Systems S.L.U</strong>. de cualquier demanda,
        pérdida, responsabilidad, reclamación o gasto (incluidos los
        honorarios de abogados) que terceros realicen en tu contra como
        consecuencia de, o derivado de, o en relación con tu uso de la página
        web o cualquiera de los servicios ofrecidos en la página web
      </p>

      <h4>
        <strong>Limitación de responsabilidad</strong>
      </h4>
      <p>
        En la máxima medida permitida por la ley aplicable, en ningún caso
        <strong>Luca Learning Systems S.L.U.</strong> será responsable por
        daños indirectos, punitivos, incidentales, especiales, consecuentes o
        ejemplares, incluidos, entre otros, daños por pérdida de beneficios,
        buena voluntad, uso, datos. u otras pérdidas intangibles, que surjan
        de o estén relacionadas con el uso o la imposibilidad de utilizar el
        servicio.
      </p>
      <p>
        En la máxima medida permitida por la ley aplicable,
        <strong>Luca Learning Systems S.L.U.</strong>
        no asume responsabilidad alguna por (i) errores, errores o
        inexactitudes de contenido; (ii) lesiones personales o daños a la
        propiedad, de cualquier naturaleza que sean, como resultado de tu
        acceso o uso de nuestro servicio; y (iii) cualquier acceso no
        autorizado o uso de nuestros servidores seguros y / o toda la
        información personal almacenada en los mismos.
      </p>

      <h4>
        <strong>Derecho a cambiar y modificar los Términos</strong>
      </h4>
      <p>
        Nos reservamos el derecho de modificar estos términos de vez en cuando
        a nuestra entera discreción. Por lo tanto, debes revisar estas páginas
        periódicamente. Cuando cambiemos los Términos de una manera material,
        te notificaremos que se han realizado cambios importantes en los
        Términos. El uso continuado de la página web o nuestro servicio
        después de dicho cambio constituye tu aceptación de los nuevos
        Términos. Si no aceptas alguno de estos términos o cualquier versión
        futura de los Términos, no uses o accedas (o continúes accediendo) a
        la página web o al servicio.
      </p>

      <h4>
        <strong>Emails de promociones y contenido</strong>
      </h4>
      <p>
        Acepta recibir nuestros mensajes y materiales de promoción, por correo
        postal, correo electrónico o cualquier otro formulario de contacto que
        nos proporciones (incluido tu número de teléfono para llamadas o
        mensajes de texto). Si no deseas recibir dichos materiales o avisos de
        promociones, simplemente avísanos en cualquier momento.
      </p>
      <h4>
        <strong>Preferencia de ley y resolución de disputas</strong>
      </h4>
      <p>
        Estos Términos, los derechos y recursos provistos aquí, y todos y cada
        uno de los reclamos y disputas relacionados con este y / o los
        servicios, se regirán, interpretarán y aplicarán en todos los aspectos
        única y exclusivamente de conformidad con las leyes sustantivas
        internas de España, sin respeto a sus principios de conflicto de
        leyes. Todos los reclamos y disputas se presentarán y usted acepta que
        sean decididos exclusivamente por un tribunal de jurisdicción
        competente ubicada en Madrid. La aplicación de la Convención de
        Contratos de las Naciones Unidas para la Venta Internacional de Bienes
        queda expresamente excluida.
      </p>
      <h4>
        <strong>Atención al cliente e información de contacto</strong>
      </h4>
      <p>
        Ofrecemos ayuda y soporte a todos nuestros Clientes. Si tienes alguna
        pregunta, comentario, consejos, por favor, escríbenos. Encontrarás
        nuestra dirección en nuestra Política de Privacidad.
      </p>
      <h4>
        <strong>Comunidad Luca: Foros Públicos</strong>
      </h4>
      <p>
        Nuestra plataforma ofrece blogs, comunidades y foros de asistencia
        técnica accesibles públicamente. Tenga en cuenta que cualquier
        Información Personal que usted proporcione en cualquiera de dichas
        áreas la pueden leer, recopilar y utilizar otras personas que tengan
        acceso a ella. Para solicitar la supresión de su Información Personal
        de nuestros blogs, comunidades o foros, no dude en ponerse en contacto
        con nosotros en la siguiente dirección: luca@lucaedu.com. En algunos
        casos, es posible que no podamos suprimir su Información Personal de
        esas áreas. Por ejemplo, si utiliza una aplicación de un tercero para
        publicar un comentario (p. ej., la aplicación de plugin social de
        Facebook) mientras está conectado a su perfil relacionado con dicho
        tercero, debe iniciar sesión en dicha aplicación o ponerse en contacto
        con su proveedor si desea suprimir Información Personal que haya
        publicado en esa plataforma.
      </p>
      <p>
        En cualquier caso, le recomendamos no publicar ninguna Información
        Personal (por ningún medio) que no desee dar a conocer.
      </p>
      <p>
        Hemos establecido medidas de seguridad adecuadas para proteger su
        Información Personal. Usted entiende y reconoce que, incluso después
        de que usted o nosotros hayamos procedido a su eliminación, copias de
        su Información Personal pueden permanecer visibles en páginas en caché
        y archivadas. Para mayor claridad, le recomendamos no subir ni
        publicar ninguna información que no desee hacer pública.
      </p>
      <p>
        Evite publicar alguna información personal en alguna de las áreas
        públicas de nuestros Servicios o en su propio sitio web si no desea
        que esté disponible públicamente.
      </p>
    </div>
  );
};

export default Condiciones;
