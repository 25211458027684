import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getCoursesBySubjectIdProgram2017,
  getCoursesBySubjectIdProgram2017Variables,
} from "../__generated__/gql-types/getCoursesBySubjectIdProgram2017";

const UseGetCoursesBySubjectIdProgram2017 = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdProgram2017Variables) => {
      const result: ApolloQueryResult<getCoursesBySubjectIdProgram2017> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID_V2,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySubjectIdProgram2017;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectIdProgram2017;

const GET_COURSES_BY_SUBJECT_ID_V2 = gql`
  query getCoursesBySubjectIdProgram2017(
    $getCoursesBySubjectIdInputProgram2017: GetCoursesBySubjectIdInputProgram2017
  ) {
    getCoursesBySubjectIdProgram2017(
      GetCoursesBySubjectIdInputProgram2017: $getCoursesBySubjectIdInputProgram2017
    ) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      program_2017
      description
      initial_evaluation
      sep_book_pages
      sep_book_pages_2017
      threemester
      threemester_2017
      week
      week_2017
      count
    }
  }
`;
