import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  GetChatLogForTeacherCorrection,
  GetChatLogForTeacherCorrectionVariables,
} from "../__generated__/gql-types/GetChatLogForTeacherCorrection";

const UseGetChatLogForTeacherCorrection = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: GetChatLogForTeacherCorrectionVariables) => {
      const result: ApolloQueryResult<GetChatLogForTeacherCorrection> =
        await client.query({
          query: GET_CHATLOG_TEACHER_CORRECTION,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getChatLogForTeacherCorrection;
    },
    [client]
  );
};

export default UseGetChatLogForTeacherCorrection;

const GET_CHATLOG_TEACHER_CORRECTION = gql`
  query GetChatLogForTeacherCorrection($userId: Int, $homeworkId: Int) {
    getChatLogForTeacherCorrection(user_id: $userId, homework_id: $homeworkId) {
      chat_log {
        message
        sender
        messageUser
        notUnderstood
        errorUser
      }
      user_id
    }
  }
`;
