import { FC, useState, useEffect } from "react";
import { Card } from "../Card";
import { Progress } from "antd";
import Slider from "react-slick";
import styles from "./collapsible.module.scss";
import blueDiamond from "../../../../assets/icons/students/blueDiamond.svg";
import {
  getCoursesBySubjectId_getCoursesBySujectId,
  getCoursesBySubjectId_getCoursesBySujectId_lessons,
} from "../../../../__generated__/gql-types/getCoursesBySubjectId";
import { DrawerLessonStudent } from "../../ExtraPoints/Lessons";
import { CollapsibleMobile } from "../../../Mobile/ExtraPoints/Collapsible";

import mathMedalM from "../../../../assets/icons/students/medals/mathMedals/mathMedal-M.svg";
import noMathMedalM from "../../../../assets/icons/students/medals/mathMedals/noMathMedal-M.svg";
import spanishMedalM from "../../../../assets/icons/students/medals/spanishMedals/spanishMedal-M.svg";
import noSpanishMedalM from "../../../../assets/icons/students/medals/spanishMedals/noSpanishMedal-M.svg";
import scienceMedalM from "../../../../assets/icons/students/medals/scienceMedals/scienceMedal-M.svg";
import noScienceMedalM from "../../../../assets/icons/students/medals/scienceMedals/noScienceMedal-M.svg";
import useIsMobile from "../../../../hooks/useIsMobile";

interface CollapsibleProps {
  indexItem: number;
  item: getCoursesBySubjectId_getCoursesBySujectId;
}

const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
};

const Collapsible: FC<CollapsibleProps> = ({ indexItem, item }) => {
  const [isActive, setIsActive] = useState<string>("");

  const [visibleLesson, setVisibleLesson] = useState(false);
  const isMobile = useIsMobile();
  const [lesson, setLesson] =
    useState<getCoursesBySubjectId_getCoursesBySujectId_lessons>();

  const showDrawerLesson = (
    lesson: getCoursesBySubjectId_getCoursesBySujectId_lessons
  ) => {
    setLesson(lesson);
    setVisibleLesson(true);
  };

  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const points = () => {
    let sum = 0;
    const arr = item.lessons?.map((lesson) => lesson.result);
    arr?.forEach((num) => (sum += num ?? 0));
    return sum;
  };

  // Abre los cursos que se encuentren en progreso
  useEffect(() => {
    let contLessons = 0;
    let contLessonsFinish = 0;
    item?.lessons?.forEach((lesson) => {
      if (!lesson.deleted_at) {
        contLessons++;
      }
      if (lesson.finished) {
        contLessonsFinish++;
      }
    });
    if (0 < contLessonsFinish && contLessonsFinish < contLessons) {
      setIsActive(indexItem.toString());
    }
  }, []);

  const subjectMedal = () => {
    if (item.subject_id === 1) {
      if (!item.lessons?.filter((lesson) => lesson.finished).length)
        return noMathMedalM;
      if (item.lessons?.filter((lesson) => lesson.finished).length)
        return mathMedalM;
    }
    if (item.subject_id === 2) {
      if (!item.lessons?.filter((lesson) => lesson.finished).length)
        return noSpanishMedalM;
      if (item.lessons?.filter((lesson) => lesson.finished).length)
        return spanishMedalM;
    }
    if (item.subject_id === 4) {
      if (!item.lessons?.filter((lesson) => lesson.finished).length)
        return noScienceMedalM;
      if (item.lessons?.filter((lesson) => lesson.finished).length)
        return scienceMedalM;
    }
  };

  return isMobile ? (
    <CollapsibleMobile item={item}></CollapsibleMobile>
  ) : (
    <div className={styles.container} id={item.id}>
      <div className={styles.headerContainer}>
        <div className={styles.topContainer}>
          <img src={subjectMedal()} alt="medals" />
          <div className={styles.title}>
            <div className={styles.headerTitleContainer}>
              <div className={styles.headerTitle}>
                <h1>{item?.title}</h1>
                <span>
                  <img src={blueDiamond} alt="blueDiamond" />
                  {points()}/
                  {(item?.lessons?.filter((lesson) => !lesson.deleted_at)
                    .length ?? 0) * 15}
                </span>
              </div>
              {isActive === indexItem.toString() ? (
                <div
                  onClick={() => setIsActive("")}
                  className={styles.showContent}
                >
                  Ver menos
                </div>
              ) : (
                <div
                  onClick={() => setIsActive(indexItem.toString())}
                  className={styles.showContent}
                >
                  Ver detalle
                </div>
              )}
            </div>
            <h2>
              {item.lessons?.filter((lesson) => lesson.finished).length}/
              {item?.lessons?.filter((lesson) => !lesson.deleted_at).length}{" "}
              Misiones
            </h2>
          </div>
        </div>
        <Progress
          percent={
            ((item.lessons?.filter((lesson) => lesson.finished).length ?? 0) /
              (item?.lessons?.length ?? 0)) *
            100
          }
          showInfo={false}
          trailColor={"#CBCEDE"}
          strokeColor={"#3843D0"}
        />

        <div
          className={styles.sliderContainer}
          style={{ height: isActive ? "auto" : 0 }}
        >
          {isActive === indexItem.toString() &&
            (item.lessons &&
            item.lessons?.filter((lesson) => !lesson.deleted_at).length > 3 ? (
              <Slider {...settings}>
                {item?.lessons
                  ?.filter((lesson) => !lesson.deleted_at)
                  .map((element: any, indexCard: number) => {
                    return (
                      <Card
                        element={element}
                        showDrawerLesson={showDrawerLesson}
                      />
                    );
                  })}
              </Slider>
            ) : (
              <div className={styles.sliderContentContainer}>
                {item?.lessons
                  ?.filter((lesson) => !lesson.deleted_at)
                  .map((element: any, indexCard: number) => {
                    return (
                      <div key={indexCard}>
                        <Card
                          element={element}
                          showDrawerLesson={showDrawerLesson}
                        />
                      </div>
                    );
                  })}
              </div>
            ))}
        </div>
      </div>
      {lesson && visibleLesson && (
        <DrawerLessonStudent
          origin="extra_points"
          data={lesson}
          onCloseLesson={onCloseLesson}
          visibleLesson={visibleLesson}
        ></DrawerLessonStudent>
      )}
    </div>
  );
};

export default Collapsible;
