import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteTeacherProjectsVariables } from "../__generated__/gql-types/deleteTeacherProjects";

const UseDeleteTeacherProjects = () => {
  const client = useApolloClient();

  return useCallback(
    async (id: deleteTeacherProjectsVariables) => {
      return await client.mutate({
        mutation: DELETE_TEACHER_PROJECTS,
        fetchPolicy: "no-cache",
        variables: id,
      });
    },
    [client]
  );
};
export default UseDeleteTeacherProjects;

const DELETE_TEACHER_PROJECTS = gql`
  mutation deleteTeacherProjects($teacherProjectId: Int) {
    deleteTeacherProjects(teacherProject_id: $teacherProjectId) {
      title
    }
  }
`;
