import { FC, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import logo from "../../assets/icons/logo_blue.svg";
import useAuth from "../../context/useAuth";
import styles from "./login.module.scss";
import { LoginFormSchema } from "./LoginForm.types";
import { useAppSelector } from "../../redux/hooks";
import { emailOrUsername } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";
import eyesSelect from "../../assets/images/eye-regular.svg";
import eyesDeselect from "../../assets/images/eye-slash-regular.svg";
import useIsMobile from "../../hooks/useIsMobile";
import { getActualVersion } from "../../utils/version";

const LoginComponent: FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [url, setUrl] = useState<string>();
  const [versions, setVersions] = useState<{
    backend: string;
    front: string;
  } | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const RememberedEmailOrUsername = useAppSelector(emailOrUsername);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const fetchVersion = async () => {
    try {
      const getActualVersionResult = await getActualVersion();
      setVersions(getActualVersionResult);
    } catch (error) {
      console.log("fetch version error: ", error);
    }
  };

  const handleSubmit = async (values: any) => {
    login(values.emailOrUsername, values.password, values.remember);
  };

  useEffect(() => {
    fetchVersion();
    setUrl(window.location.href);
  }, []);

  return (
    <div className={!isMobile ? styles.container : styles.mobileContainer}>
      {!isMobile ? (
        <img src={logo} alt="" className={styles.logo} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="" />
        </div>
      )}
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>¡Bienvenido a Luca!</h3>
        {!isMobile &&
          (url?.includes("localhost") ||
            url?.includes("app") ||
            url?.includes("frontend") ||
            url?.includes("gavi")) && (
            <h3 className={styles.subTitle}>
              Introduce tu usuario y tu contraseña.
            </h3>
          )}
        {isMobile &&
          (url?.includes("localhost") ||
            url?.includes("app") ||
            url?.includes("frontend")) && (
            <h3 className={styles.subTitle}>
              Introduce tu usuario y tu contraseña.
            </h3>
          )}
      </div>

      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            emailOrUsername: RememberedEmailOrUsername ?? "",
            password: "",
            remember: true,
          }}
          onSubmit={handleSubmit}
          validationSchema={LoginFormSchema}
        >
          <Form
            style={!isMobile ? undefined : { width: "100%" }}
            placeholder=""
          >
            <h3 className={styles.formText}>Usuario *</h3>
            <Field
              id="emailOrUsername"
              name="emailOrUsername"
              className={styles.formFields}
              placeholder={
                url?.includes("gavi") ? "Escribe tu usuario de Gavi" : ""
              }
            />

            <ErrorMessage
              name="emailOrUsername"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.formText}>Contraseña *</h3>

            <div className={styles.containerPassword}>
              <Field
                id="password"
                name="password"
                className={styles.formFields}
                type={showPassword ? "text" : "password"}
                placeholder={
                  url?.includes("gavi") ? "Escribe tu usuario de Gavi" : ""
                }
              />
              <div
                className={styles.containerEye}
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <img
                    src={eyesSelect}
                    alt="mostrar"
                    className={styles.eyeSelect}
                  />
                ) : (
                  <img
                    src={eyesDeselect}
                    alt="mostrar"
                    className={styles.eyeSelect}
                    style={{ width: "22px" }}
                  />
                )}
              </div>
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className={styles.error}
            />

            <h3
              className={styles.forgotPasswordText}
              onClick={() => {
                navigate("/recoveryPassword");
              }}
            >
              ¿Olvidaste tu contraseña?
            </h3>

            <button type="submit" className={styles.submitButton}>
              Entrar a Luca
            </button>

            <div className={styles.centerContainerCheck}>
              <Field
                type="checkbox"
                className={styles.checkboxFormik}
                name="remember"
              />{" "}
              <div style={{ marginLeft: 20 }}>Mantener la sesión iniciada</div>
            </div>
          </Form>
        </Formik>
      </div>
      <div
        className={
          !isMobile ? styles.clientContainer : styles.mobileClientContainer
        }
      >
        <div className={styles.helpContainer}>
          <h3 className={styles.helpTitle}>¿Necesitas ayuda?</h3>
          <h3 className={styles.helpSubTitle}>
            Mándanos un correo a{" "}
            <a
              className={styles.helpSubTitleBlue}
              href="mailto:soporte@lucaedu.com"
            >
              soporte@lucaedu.com
            </a>
            o escríbenos por Whatsapp al
            <a
              className={styles.helpSubTitleBlue}
              href="https://wa.me/525541691994"
            >
              +52 55 4169 1994
            </a>
            .
          </h3>
        </div>
      </div>
      {isMobile ? (
        <div
          style={{
            position: "relative",
            top: 20,
          }}
        >
          <h3 style={{ opacity: 0.5, fontWeight: 700 }}>
            Local: {versions?.front} <br /> Global: {versions?.backend}
          </h3>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            left: 40,
            bottom: 40,
          }}
        >
          <h3 style={{ opacity: 0.5, fontWeight: 700 }}>
            Local: {versions?.front} <br /> Global: {versions?.backend}
          </h3>
        </div>
      )}
    </div>
  );
};

export default LoginComponent;
