export const mathProblemGenerator = (grade: number) => {
    let generators = [];
    // const minimum = min(grade);
    const maximum = SubsAndAddMax(grade);
    for (let index = 0; index < 25; index++) {
      let add = getRandomIntAddProblem(1, maximum);
      let subst = getRandomIntSubstractProblem(1, maximum);
      let div = getRandomIntDivisionProblem(grade);
      let mult = getRandomIntMultiplicationProblem(grade);
      if (grade === 1) {
        generators.push(add, subst);
      } else if (grade === 2) {
        generators.push(add, subst, mult);
      } else if (grade > 2) {
        generators.push(add, subst, mult, div);
      }
    }
    return generators;
  };
  
  const min = (grade: number) => {
    return grade === 4 ? 1000 : grade === 5 || grade === 6 ? 10000 : 0;
  };
  
  const SubsAndAddMax = (grade: number) => {
    return grade === 1 || grade === 2
      ? 99
      : grade === 3
      ? 999
      : grade === 4
      ? 9999
      : grade === 5 || grade === 6
      ? 99999
      : 0;
  };
  
  function getRandomIntAddProblem(min: number, max: number) {
    let num1 = Math.floor(Math.random() * (max - min + 1)) + min;
    let num2 = Math.floor(Math.random() * (max - min + 1)) + min;
    while (num1 + num2 > max) {
      num1 = Math.floor(Math.random() * (max - min + 1)) + min;
      num2 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return {
      problem: `${num1} + ${num2}`,
      answer: num1 + num2,
    };
  }
  function getRandomIntSubstractProblem(min: number, max: number) {
    let num1 = Math.floor(Math.random() * (max - min + 1)) + min;
    let num2 = Math.floor(Math.random() * (max - min + 1)) + min;
    while (num1 - num2 > max || num1 - num2 < min) {
      num1 = Math.floor(Math.random() * (max - min + 1)) + min;
      num2 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return {
      problem: `${num1} - ${num2}`,
      answer: num1 - num2,
    };
  }
  function getRandomIntDivisionProblem(grade: number) {
    if (grade === 3) {
      const randomDivision3rdGrade = () => {
        let num1 = Math.floor(Math.random() * 90) + 10;
        let num2 = Math.floor(Math.random() * 90) + 10;
        if (num1 % num2 !== 0) {
          while (num1 % num2 !== 0) {
            num1 = Math.floor(Math.random() * 90) + 10;
            num2 = Math.floor(Math.random() * 90) + 10;
          }
        }
        return {
          problem: `${num1} / ${num2}`,
          answer: num1 / num2,
        };
      };
      return randomDivision3rdGrade();
    } else if (grade === 4) {
      const randomDivision4thGrade = () => {
        const randomProblem = Math.floor(Math.random() * 3) + 1;
  
        if (randomProblem === 1) {
          let num1 = Math.floor(Math.random() * 90) + 10;
          let num2 = Math.floor(Math.random() * 90) + 10;
          if (num1 % num2 !== 0) {
            while (num1 % num2 !== 0) {
              num1 = Math.floor(Math.random() * 90) + 10;
              num2 = Math.floor(Math.random() * 90) + 10;
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: num1 / num2,
          };
        } else if (randomProblem === 2) {
          let num1 = Math.floor(Math.random() * 900) + 100;
          let num2 = Math.floor(Math.random() * 9) + 1;
          if (num1 % num2 !== 0) {
            while (num1 % num2 !== 0) {
              num1 = Math.floor(Math.random() * 900) + 100;
              num2 = Math.floor(Math.random() * 9) + 1;
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: num1 / num2,
          };
        } else {
          let num1 = Math.floor(Math.random() * 900) + 100;
          let num2 = Math.floor(Math.random() * 90) + 10;
          if (num1 % num2 !== 0) {
            while (num1 % num2 !== 0) {
              num1 = Math.floor(Math.random() * 900) + 100;
              num2 = Math.floor(Math.random() * 90) + 10;
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: num1 / num2,
          };
        }
      };
      return randomDivision4thGrade();
    } else if (grade === 5) {
      const randomDivision5thGrade = () => {
        const randomProblem = Math.floor(Math.random() * 2) + 1;
        if (randomProblem === 1) {
          let num1 = Math.floor(Math.random() * 900) + 100;
          let num2 = Math.floor(Math.random() * 90) + 10;
          if (num1 % num2 !== 0) {
            while (num1 % num2 !== 0) {
              num1 = Math.floor(Math.random() * 900) + 100;
              num2 = Math.floor(Math.random() * 90) + 10;
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: num1 / num2,
          };
        } else {
          let num1 = Math.floor(Math.random() * 999);
          let num2 = Math.floor(Math.random() * 999);
          if (num1 % num2 === 0) {
            while (num1 % num2 === 0) {
              num1 = Math.floor(Math.random() * 999);
              num2 = Math.floor(Math.random() * 999);
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: Number((num1 / num2).toFixed(2)),
          };
        }
      };
      return randomDivision5thGrade();
    } else {
      const randomDivision6thgGrade = () => {
        const randomProblem = Math.floor(Math.random() * 2) + 1;
        if (randomProblem === 1) {
          let num1 = Math.floor(Math.random() * 999);
          let num2 = Math.floor(Math.random() * 999);
          if (num1 % num2 === 0) {
            while (num1 % num2 === 0) {
              num1 = Math.floor(Math.random() * 999);
              num2 = Math.floor(Math.random() * 999);
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: Number((num1 / num2).toFixed(2)),
          };
        } else {
          let num1 = Number((Math.random() * 999).toFixed(2));
          let num2 = Math.floor(Math.random() * 999);
          if (num1 % num2 === 0) {
            while (num1 % num2 === 0) {
              num1 = Math.floor(Math.random() * 999);
              num2 = Math.floor(Math.random() * 999);
            }
          }
          return {
            problem: `${num1} / ${num2}`,
            answer: Number((num1 / num2).toFixed(2)),
          };
        }
      };
      return randomDivision6thgGrade();
    }
  }
  
  function getRandomIntMultiplicationProblem(grade: number) {
    if (grade === 2) {
      const randomMultiplication2ndGrade = () => {
        const num1 = Math.floor(Math.random() * 10) + 1;
        const num2 = Math.floor(Math.random() * 10) + 1;
        return {
          problem: `${num1} * ${num2}`,
          answer: num1 * num2,
        };
      };
      return randomMultiplication2ndGrade();
    } else if (grade === 3) {
      const randomMultiplication3rdGrade = () => {
        let num1 = Math.floor(Math.random() * 90) + 10;
        let num2 = Math.floor(Math.random() * 90) + 10;
        while (num1 * num2 < 100 || num1 * num2 >= 1000) {
          num1 = Math.floor(Math.random() * 90) + 10;
          num2 = Math.floor(Math.random() * 90) + 10;
        }
        return {
          problem: `${num1} * ${num2}`,
          answer: num1 * num2,
        };
      };
      return randomMultiplication3rdGrade();
    } else if (grade === 4) {
      const randomMultiplication4thGrade = () => {
        const num1 = Math.floor(Math.random() * 890) + 10;
        const num2 = Math.floor(Math.random() * 890) + 10;
  
        return {
          problem: `${num1} * ${num2}`,
          answer: num1 * num2,
        };
      };
      return randomMultiplication4thGrade();
    } else if (grade === 5) {
      const randomMultiplication5thGrade = () => {
        const randomProblem = Math.floor(Math.random() * 2) + 1;
  
        if (randomProblem === 1) {
          const num1 = Math.floor(Math.random() * 900) + 100;
          const num2 = Math.floor(Math.random() * 900) + 100;
          return { problem: `${num1} * ${num2}`, answer: num1 * num2 };
        } else {
          const num1 = Math.floor(Math.random() * 9) + 1;
          let num2 = Number((Math.random() * 90 + 10).toFixed(1));
          return {
            problem: `${num1} * ${num2}`,
            answer: Number((num1 * num2).toFixed(1)),
          };
        }
      };
      return randomMultiplication5thGrade();
    } else {
      const randomMultiplication6thGrade = () => {
        const randomProblem = Math.floor(Math.random() * 3) + 1;
  
        if (randomProblem === 1) {
          const num1 = Math.floor(Math.random() * 900) + 100;
          const num2 = Math.floor(Math.random() * 900) + 100;
          return { problem: `${num1} * ${num2}`, answer: num1 * num2 };
        } else if (randomProblem === 2) {
          const num1 = Math.floor(Math.random() * 9) + 1;
          let num2 = Number((Math.random() * 90 + 10).toFixed(1));
          return {
            problem: `${num1} * ${num2}`,
            answer: Number((num1 * num2).toFixed(1)),
          };
        } else {
          const num1 = Math.floor(Math.random() * 9) + 1;
          let num2 = Number((Math.random() * 90 + 10).toFixed(2));
          return {
            problem: `${num1} * ${num2}`,
            answer: Number((num1 * num2).toFixed(2)),
          };
        }
      };
      return randomMultiplication6thGrade();
    }
  }
  