import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./asteroidsHeader.module.scss";
import { Countdown } from "../Countdown";

import mira from "../../../../assets/icons/asteroids_mira.svg";
import asteroid from "../../../../assets/icons/students/challenges/asteroid.svg";
import asteroids from "../../../../assets/icons/students/challenges/asteroids/asteroids_group.svg";
import clock from "../../../../assets/icons/asteroids_clock.png";
import close from "../../../../assets/icons/students/closeMobile.svg";
import mathFlag from "../../../../assets/icons/students/flags/asteroids_ship_icon.svg";
import instructions from "../../../../assets/icons/students/instructions.svg";
import explotion from "../../../../assets/icons/students/challenges/asteroids/explosion.gif";

import diamond from "../../../../assets/icons/students/diamonds/mathDiamonds/mathDiamond-S.svg";
import { AsteroidsFooter } from "../AsteroidsFooter";

interface AsteroidsHeaderProps {
  handleInstructions: () => void;
  handleInput: (e: string) => void;
  counter: number;
  input: string;
  start: boolean;
  setInput: (e: string) => void;
  setStart: (e: boolean) => void;
  setPoints: (e: number) => void;
  points: number;
  questions: any;
  handleDelete:()=>void;
}

const AsteroidsHeader: FC<AsteroidsHeaderProps> = ({
  handleInstructions,
  handleInput,
  handleDelete,
  input,
  counter,
  setStart,
  start,
  setInput,
  points,
  setPoints,
  questions,
}) => {
  const navigate = useNavigate();
  const [blink, setBlink] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [index, setIndex] = useState(0);
  const [asteroidTop, setAsteroidTop] = useState(16.1);
  const [asteroidLeft, setAsteroidLeft] = useState(47.9);
  const [asteroidWidth, setAsteroidWidth] = useState(4.3);
  useEffect(() => {
    if (counter === 0) setBlink(false);
    if (counter < 10 && counter > 0) return setBlink(true);
  }, [counter]);

  useEffect(() => {
    setTimeout(() => {
      if (correct) {
        setCorrect(false);
      }
    }, 1000);
  }, [correct]);

  const handleResult = () => {
    if (questions.length - 1 === index) {
      setCorrect(true);
      return;
    }
    if (questions[index].answer.toString() === input) {
      setCorrect(true);
      setPoints(points + 1);
      setIndex(index + 1);
      setInput("");
    }
    if (questions[index].answer.toString() !== input) {
      setAsteroidWidth(asteroidWidth + 8);
      setAsteroidLeft(asteroidLeft - 5);
      setAsteroidTop(asteroidTop - 8);
      setIndex(index + 1);
      setInput("");
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerTop}>
          <div className={styles.leftItems}>
            <img src={mathFlag} alt="mathFlag" />
            <h1>Desafío asteroide</h1>
          </div>
          <div className={styles.rightItems}>
            <button
              onClick={() => {
                handleInstructions();
              }}
            >
              <img src={instructions} alt="instructions" /> Instrucciones
            </button>
            <img
              src={close}
              alt="close-icon"
              style={{ marginLeft: "16px", cursor: "pointer" }}
              onClick={() => navigate("/extrapoints")}
            />
          </div>
        </div>

        <div className={styles.header}>
          <div className={styles.pointsContainer}>
            <img src={diamond} alt="diamond" /> <h1>{points}</h1>
          </div>
          <div className={styles.boardContainer}>
            <h1> {(questions[index].problem).replace("*", 'x')} = </h1>
            <div
              className={styles.result}
              // type="number"
              // onChange={(e) => console.log(e)}
            >
              <h1>{input}</h1>
            </div>
          </div>
          <div className={styles.timeContainer}>
            <img src={clock} alt="diamond" />{" "}
            <h1>
              {" "}
              00:{counter < 10 && "0"}
              {counter}
            </h1>
          </div>
        </div>

        <div className={styles.content}>
          <img src={asteroids} alt="asteroids" className={styles.asteroids} />
          {!correct ? (
            <img
              src={asteroid}
              alt="asteroid"
              className={styles.singleAsteroid}
              style={{
                width: `${asteroidWidth}%`,
                left: `${setAsteroidLeft}vw`,
                top: `${asteroidTop}vh`,
              }}
            />
          ) : (
            <img
              src={explotion}
              alt="explotion"
              className={styles.animatedAsteroid}
            />
          )}
          <img src={mira} alt="mira" className={styles.mira} />
          <img
            src={asteroid}
            alt="asteroid"
            className={styles.singleAsteroid0}
          />
          <img
            src={asteroid}
            alt="asteroid"
            className={styles.singleAsteroid1}
          />
          <img
            src={asteroid}
            alt="asteroid"
            className={styles.singleAsteroid2}
          />
          <img
            src={asteroid}
            alt="asteroid"
            className={styles.singleAsteroid3}
          />
        </div>

        <AsteroidsFooter
          handleInput={handleInput}
          handleResult={handleResult}
          handleDelete={handleDelete}
        />
      </div>
      {!start && (
        <div className={styles.countdownContainer}>
          <h1>¡Comienza la aventura!</h1>
          <Countdown setStart={setStart} />
        </div>
      )}
      {blink && <div className={styles.blinker}></div>}
    </>
  );
};
export default AsteroidsHeader;
