import React from "react";
import { Input } from "antd";
import styles from "./drawerPassword.module.scss";

interface DrawerProfileProps {
  handleOffFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  password: {
    error: boolean;
    passwordOld: string;
    passwordNew1: string;
    passwordNew2: string;
  };
  validError: {
    error: boolean;
    errorText: string;
  };
}
const DrawerPassword = ({
  password,
  validError,
  handleChange,
  handleSubmit,
  handleOffFocus,
}: DrawerProfileProps) => {
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div className={styles.bodyDrawer}>
          <h1> Establece tu contraseña</h1>
          <h4>
            Asegúrate que sea diferente a la anterior y puedas recordarla
            facilmente.
          </h4>

          <label className={styles.passwordLabel}>Contraseña actual</label>
          <Input.Password
            name="passwordOld"
            value={password.passwordOld}
            onChange={handleChange}
            placeholder="Por seguridad, introduce tu contraseña actual."
            className={styles.passwordInput}
            style={{ borderRadius: "11px" }}
          />

          <label className={styles.passwordLabel}> Nueva contraseña</label>
          <Input.Password
            name="passwordNew1"
            value={password.passwordNew1}
            onChange={handleChange}
            placeholder="Escribe tu nueva contraseña."
            className={styles.passwordInput}
            style={{ borderRadius: "11px" }}
            onBlur={handleOffFocus}
          />

          <label className={styles.passwordLabel}> Confirmar contraseña</label>
          <Input.Password
            name="passwordNew2"
            value={password.passwordNew2}
            onChange={handleChange}
            placeholder="Escribe tu nueva contraseña"
            className={styles.passwordInput}
            style={{ borderRadius: "11px" }}
            onBlur={handleOffFocus}
          />
          <p style={{ color: "red" }}>{validError.errorText}</p>
        </div>
      </form>
    </div>
  );
};

export default DrawerPassword;
