import { PropsBanner } from "../utils";
import styles from "./tableClasification.module.scss";
import arrowUp from "../../../../assets/icons/clasification/arrowUp.svg";
import arrowDown from "../../../../assets/icons/clasification/arrowDown.svg";
import equal from "../../../../assets/icons/clasification/equal.svg";
import { resolveAvatar, resolveColorAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";

const TableClasification = ({ tablaScore }: PropsBanner) => {
  return (
    <div className={styles.cardsContainer}>
      {tablaScore &&
        tablaScore?.scores?.map((student: any) => {
          if (
            student &&
            student.position !== 1 &&
            student.position !== 2 &&
            student.position !== 3
          ) {
            let backgroundColor = resolveColorAvatar(
              student.student_avatar ?? "polux"
            );
            return (
              <div className={styles.cardContainer} key={student.position}>
                <div className={styles.numberContainer}>
                  {student.state === "equal" && <img src={equal} alt="" />}
                  {student.state === "up" && <img src={arrowUp} alt="" />}
                  {student.state === "down" && <img src={arrowDown} alt="" />}
                  <div> {student.position}</div>
                </div>
                <div className={styles.textContainer}>
                  <div className={styles.avatarContainer}>
                    <div
                      className={styles.studentAvatar}
                      style={{ background: `${backgroundColor}` }}
                    >
                      {student?.shopActions?.map((clothes: any) => (
                        <img
                          src={clothes.item_img ?? ""}
                          alt="avatar"
                          style={{
                            zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                          }}
                          className={styles.clothes}
                        />
                      ))}
                      <img
                        src={
                          student?.student_level && student?.student_level < 6
                            ? resolveAvatar(
                                student?.student_avatar ?? "polux",
                                student?.student_level.toString() ?? "1"
                              )
                            : functionReturnImgAvatar(
                                student?.student_avatar ?? ""
                              )
                        }
                        alt="avatar"
                        className={
                          student?.student_level && student?.student_level < 6
                            ? styles.avatarImgwithOutLevel // revisar
                            : styles.avatarImg
                        }
                      />
                    </div>
                    <div className={styles.textName}>{student.name} </div>
                  </div>
                  <div className={styles.textPoint}>
                    {student.score ?? 0}{" "}
                    <span className={styles.textPointHidden}> puntos</span>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default TableClasification;
