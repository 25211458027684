import React, { FC } from "react";
import styles from "./matching.module.scss";
import arrowGreen from "../../../../../assets/icons/arrows/arrow-green.svg";
import {
  getResponsesByHomework_getResponsesByHomework,
  getResponsesByHomework_getResponsesByHomework_dAndDQuestion_blockContainer,
  getResponsesByHomework_getResponsesByHomework_dAndDQuestion_blockItems,
} from "../../../../../__generated__/gql-types/getResponsesByHomework";

interface PropsMatching {
  index: number;
  question: getResponsesByHomework_getResponsesByHomework;
  subject_id?: number | null;
}

const MatchingTextQuiz: FC<PropsMatching> = ({
  index,
  question,
  subject_id,
}) => {
  const blockContainer = question.dAndDQuestion![0].blockContainer?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );
  const blockItems = question.dAndDQuestion![0].blockItems?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );

  interface RenderContentProps {
    image: string | null;
    text: string | null;
    coincidence: number | null;
    totalItems: number | null;
    url: string | null;
    isWhite: boolean;
    percent: number;
    correct: boolean;
  }

  const calculatePercents = (
    question: getResponsesByHomework_getResponsesByHomework,
    blockContainer:
      | getResponsesByHomework_getResponsesByHomework_dAndDQuestion_blockContainer[]
      | undefined,
    blockItems:
      | getResponsesByHomework_getResponsesByHomework_dAndDQuestion_blockItems[]
      | undefined
  ) => {
    let renderContent: RenderContentProps[][] = [];
    if (blockContainer && blockItems) {
      renderContent = Array.from(
        { length: blockContainer.length + 1 },
        () => []
      );

      // agregamos el primer elemento nulo y los blockItems
      renderContent[0].push({
        image: "",
        text: "",
        coincidence: 0,
        totalItems: 0,
        url: "",
        isWhite: true,
        percent: -1,
        correct: false,
      });

      // agreglo los elementos a la primera fila
      blockContainer.forEach((elem) => {
        renderContent[0].push({
          coincidence: elem.coincidence,
          image: elem.image,
          text: elem.text,
          isWhite: false,
          percent: -1,
          totalItems: 0,
          url: elem.url,
          correct: false,
        });
      });

      // agregar a los demás array's el contenedor como primer elemento
      blockItems
        .sort((a, b) => a!.coincidence! - b!.coincidence!)
        .forEach((elem, index) => {
          renderContent[index + 1].push({
            coincidence: elem.coincidence,
            image: elem.image,
            isWhite: false,
            percent: -1,
            text: elem.text,
            totalItems: 0,
            url: "",
            correct: false,
          });
        });

      question.student_relation_DaD_responses?.forEach((item) => {
        renderContent[item?.second!][item?.first!] = {
          coincidence: -1,
          image: "",
          isWhite: false,
          percent: item?.percent!,
          text: "",
          totalItems: 0,
          url: "",
          correct: item?.second === item?.first,
        };
      });
    }

    return renderContent;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {question.question
            ? question.question
            : subject_id === 16
            ? "Relate the concepts by dragging the card into place."
            : "Relaciona los conceptos arrastrando la ficha a su lugar."}
        </div>
        {calculatePercents(question, blockContainer, blockItems).map((fila) => {
          return (
            <div className={styles.containerBlock}>
              <div className={styles.firstColumn}>
                {fila.map((elem) => {
                  return elem.isWhite ? (
                    <div className={styles.containerImageButtonWhite}>
                      <div className={styles.containerbuttonContent}></div>
                    </div>
                  ) : elem.image ? (
                    <div className={styles.containerImageButton}>
                      <div className={styles.containerbuttonContentImage}>
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "85%",
                            background: "#dadbe7",
                            borderRadius: 15,
                          }}
                          src={elem.url!}
                        ></img>
                      </div>
                    </div>
                  ) : !elem.isWhite && elem.percent < 0 ? (
                    <div className={styles.containerImageButton}>
                      <div className={styles.containerbuttonContent}>
                        {elem.text}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.containerImageButton}>
                      {elem.correct ? (
                        <>
                          {elem.percent >= 0 && elem.percent <= 29 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectBad
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 30 && elem.percent <= 59 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectMid
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 60 && elem.percent <= 100 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectGood
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {elem.percent >= 0 && elem.percent <= 29 && (
                            <div
                              className={styles.containerbuttonContentWorngBad}
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 30 && elem.percent <= 59 && (
                            <div
                              className={styles.containerbuttonContentWorngMid}
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 60 && elem.percent <= 100 && (
                            <div
                              className={styles.containerbuttonContentWorngGood}
                            >
                              {elem.percent}%
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchingTextQuiz;
