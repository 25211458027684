import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { handleStudentResponseVariables } from "../__generated__/gql-types/handleStudentResponse";

const useHandleStudentResponse = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: handleStudentResponseVariables) => {
      return await client.mutate({
        mutation: HANDLE_STUDENT_RESPONSE,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useHandleStudentResponse;

const HANDLE_STUDENT_RESPONSE = gql`
  mutation handleStudentResponse(
    $responsesInput: ResponsesInput
    $responseId: ID
  ) {
    handleStudentResponse(
      responsesInput: $responsesInput
      response_id: $responseId
    )
  }
`;
