import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteClassroomLessonVariables } from "../__generated__/gql-types/deleteClassroomLesson";

const UseDeleteClassRoomLesson = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteClassroomLessonVariables) => {
      return await client.mutate({
        mutation: DELETE_CLASSROOM_LESSON,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseDeleteClassRoomLesson;

const DELETE_CLASSROOM_LESSON = gql`
  mutation deleteClassroomLesson($classroomLessonId: ID!) {
    deleteClassroomLesson(classroomLessonId: $classroomLessonId) {
      id
      title
    }
  }
`;
