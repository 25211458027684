import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateClassroomLessonVariables } from "../__generated__/gql-types/updateClassroomLesson";

const useUpdateClassroomLesson = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateClassroomLessonVariables) => {
      return await client.mutate({
        mutation: UPDATE_CLASSROOMLESSON,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdateClassroomLesson;

const UPDATE_CLASSROOMLESSON = gql`
  mutation updateClassroomLesson(
    $classroomLessonId: ID!
    $classroomLessonInput: ClassroomLessonInput
  ) {
    updateClassroomLesson(
      classroomLessonId: $classroomLessonId
      classroomLessonInput: $classroomLessonInput
    ) {
      title
    }
  }
`;
