import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLessonsBySubjectIdVariables,
  getLessonsBySubjectId,
} from "../__generated__/gql-types/getLessonsBySubjectId";

const UseGetLessonsBySubjectId = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getLessonsBySubjectIdVariables) => {
      const result: ApolloQueryResult<getLessonsBySubjectId> =
        await client.query({
          query: GET_LESSONS_BY_SUBJECT_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonsBySubject;
    },
    [client]
  );
};

export default UseGetLessonsBySubjectId;

const GET_LESSONS_BY_SUBJECT_ID = gql`
  query getLessonsBySubjectId(
    $getLessonsBySubjectInput: GetLessonsBySubjectInput
  ) {
    getLessonsBySubject(getLessonsBySubjectInput: $getLessonsBySubjectInput) {
      id
      title
      description
      week
      sep_name
      sep_book_pages
      lesson_type
      learnings
      subject {
        id
      }
      lesson_content {
        pdf
        slide
        quizzes {
          url
          filename
        }
        videos {
          id
          url
          title
          file_id
          metadata
          imageUrl
          order
          quizzes {
            quiz {
              id
              url
              title
              filename
              order
              questions {
                answers {
                  id
                  answer
                  correct
                  order
                  image
                  url
                }
                question
                id
                image
                url
              }
            }
            time
            time_out
          }
        }
      }
      grade {
        id
        name
        grade
      }

      themes {
        id
        name
      }
      axes {
        id
        name
      }
    }
  }
`;
