import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import { getClassroomLessons } from "../__generated__/gql-types/getClassroomLessons";

const UseGetClassroomLessons = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClassroomLessons> = await client.query({
      query: GET_CLASSROOM_LESSONS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClassroomLessons;
  }, [client]);
};

export default UseGetClassroomLessons;

const GET_CLASSROOM_LESSONS = gql`
  query getClassroomLessons {
    getClassroomLessons {
      id
      start_date
      classroom_id
      title
      lesson {
      id
    }
    }
  }
`;
