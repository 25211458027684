/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from "react";
import styles from "./layoutReading.module.scss";
import { DrawerLessonNew } from "../../Lessons/Drawer";
import { useLocation, useSearchParams } from "react-router-dom";
import { getLiteracyLessonsSearch_getLiteracyLessonsSearch as PropsLiteracy } from "../../../__generated__/gql-types/getLiteracyLessonsSearch";
import { DrawerLiteracy } from "./DrawerLiteracy";
import useAuth from "../../../context/useAuth";

import { CardLiteracy } from "./CardLiteracy";

interface PropsLayoutReading {
  setLessonId: (e: string) => void;
  lessonId: string;
  literacys: PropsLiteracy[] | null;
}
const LayoutReading: FC<PropsLayoutReading> = ({
  lessonId,
  setLessonId,
  literacys,
}) => {
  const { userToken } = useAuth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const lessonIdParams = queryParams.get("lessonId");
  const inputParams = queryParams.get("search");
  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );

  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
  };
  return (
    <div className={styles.container}>
      {literacys?.length
        ? literacys?.map((literacy) => (
            <CardLiteracy
              literacy={literacy}
              setLessonId={setLessonId}
              setVisible={setVisible}
            />
          ))
        : inputParams && (
            <div className={styles.containerWithoutCourses}>
              <div className={styles.textWithoutInfo}>
                Tu búsqueda no arroja resultados entre las lecturas de nuestra
                biblioteca. ¡Inténtalo de nuevo con una búsqueda diferente!
              </div>
            </div>
          )}
      {userToken?.role === "teacher"
        ? lessonId &&
          visible && (
            <DrawerLessonNew
              onClose={onClose}
              visible={visible}
              lessonViewSelected={"dosification"}
              back={"lessons"}
              individualLesson={null}
              lessonId={lessonId}
            />
          )
        : visible &&
          lessonId && (
            <DrawerLiteracy
              visibleLesson={visible}
              onCloseLesson={onClose}
              id={lessonId}
            />
          )}
    </div>
  );
};

export default LayoutReading;
