import React, { useEffect, useState } from "react";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";

import styles from "./newFill.module.scss";
import { synthesizeLanguageText } from "../NewQuizz/utils";
import { ModalImg } from "../NewQuestions/ModalImg";

interface PropsNewFill {
  setTemporalInputFill: (e: string) => void;
  temporalInputFill: string;
  stateNext: boolean;
  alertFalse: boolean;
  alertTrue: boolean;
  content: any;
  subject_id: number | null;
}
const NewFill = ({
  setTemporalInputFill,
  temporalInputFill,
  stateNext,
  alertFalse,
  alertTrue,
  content,
  subject_id,
}: PropsNewFill) => {
  const [partes, setPartes] = useState<string[]>([]);
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setPartes(content.question.split("__"));
  }, [content]);

  const handleVoice = async () => {
    const questionToSynth =
      content.question && content.question.replace(/__/g, " ");
    setIsSoundActive(true);
    subject_id &&
      questionToSynth &&
      (await synthesizeLanguageText(questionToSynth, subject_id));
    setIsSoundActive(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.titleQuiz}>
        {!isSoundActive && (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt=""
          />
        )}
        {isSoundActive && (
          <img className={styles.imgSound} src={soundNegative} alt="" />
        )}
        {stateNext ? (
          <>
            {alertFalse && (
              <div className={styles.textContainer}>
                <p>
                  {partes[0]}
                  <span
                    style={{
                      display: "inline-block",
                      color: "#ef8986",
                    }}
                  >
                    {content.answers[0].answer}
                  </span>
                  {partes[1]}
                </p>
              </div>
            )}
            {alertTrue &&
              content.question.replace("__", content.answers[0].answer)}
          </>
        ) : (
          <div className={styles.textContainer}>
            <p>
              {partes[0]}
              <span
                style={{
                  borderBottom: "2px solid black",
                  width: "100px",
                  display: "inline-block",
                }}
              ></span>{" "}
              {partes[1]}
            </p>
          </div>
        )}
      </div>
      {content.url && (
        <img
          className={styles.imgQuiz}
          alt="img"
          src={content.url ?? ""}
          onClick={() => {
            setOpen(true);
          }}
        />
      )}
      <input
        className={
          alertTrue
            ? styles.inputQuizTrue
            : alertFalse
            ? styles.inputQuizFalse
            : styles.inputQuizNormal
        }
        disabled={stateNext}
        value={temporalInputFill}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={(e) => {
          setTemporalInputFill(e.currentTarget.value);
        }}
      />
      <ModalImg
        open={open}
        setOpen={setOpen}
        urlImg={content.url ?? ""}
      ></ModalImg>
    </div>
  );
};

export default NewFill;
