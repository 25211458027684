import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherAssignHomeworkNEMActionEventVariables } from "../__generated__/gql-types/teacherAssignHomeworkNEMActionEvent";

const useTeacherAssignHomeworkNEMActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherAssignHomeworkNEMActionEventVariables) => {
      return await client.mutate({
        mutation: TEACHER_ASSIGN_HOMEWORK_NEM_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherAssignHomeworkNEMActionEvent;

const TEACHER_ASSIGN_HOMEWORK_NEM_ACTION_EVENT = gql`
  mutation teacherAssignHomeworkNEMActionEvent(
    $contentId: Int
    $startDate: String
    $reasign: Boolean
  ) {
    teacherAssignHomeworkNEMActionEvent(
      contentId: $contentId
      start_date: $startDate
      reasign: $reasign
    )
  }
`;
