import React, { FC } from "react"
import styles from "./filterBubble.module.scss"

interface FilterBubbleProps {
  sepBookPage?: string;
  selectedItem?: string;
  sepBookPagesList?: string[];
  setPageNumber?: (e: number) => void;
  setSelectedItem?: (value: string) => void;
  itemList?: string[];
  type: string
}


const FilterBubbles: FC<FilterBubbleProps> = ({ type, setSelectedItem, setPageNumber, selectedItem, itemList }) => {

  const handleClick = (item: any) => {
    setPageNumber && setPageNumber(0);
    setSelectedItem && setSelectedItem(item);
  }

  return (
    <div className={styles.bubblesContainer}>
      {itemList?.map((item: any, i) => {
        return <button
          className={
            selectedItem === item
              ? styles.bubbleSelected
              : styles.bubble
          }
          key={i}
          onClick={() => handleClick(item)}
        >
          {type === "week" && "Semana"} {item}
        </button>
      })}
    </div>
  )
}

export default FilterBubbles