import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherInteractWithContentVariables } from "../__generated__/gql-types/teacherInteractWithContent";

const useTeacherInteractWithContent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherInteractWithContentVariables) => {
      return await client.mutate({
        mutation: TEACHER_INTERACT_CONTENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherInteractWithContent;

const TEACHER_INTERACT_CONTENT = gql`
  mutation teacherInteractWithContent($contentId: Int, $contentType: String) {
    teacherInteractWithContent(contentId: $contentId, contentType: $contentType)
  }
`;
