import { FC, lazy, Suspense } from "react";

const English = lazy(() =>
  import("../../components").then((module) => {
    return { default: module.English };
  })
);

const LessonsPage: FC = () => {
  return (
    <Suspense fallback={<h2 style={{ color: "black" }}>Loading....</h2>}>
      <English />
    </Suspense>
  );
};

export default LessonsPage;
