import { createContext, ReactNode, useContext } from "react";
import { getUserByEmail_getUserByEmail } from "../__generated__/gql-types/getUserByEmail";
import jwt from "jwt-decode";
import axios from "axios";
import { apiLuca } from "../utils/constants";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  outToken,
  PropsState,
  setEmailOrUsername,
  setErrorToken,
  setisAuthToken,
  setisLoading,
  setToken,
  setUserToken,
} from "../redux/slices/tokens";
import { Tokens } from "../redux/store/store";
import { notificationNegative } from "../components/Shared/Notifacation/NotificationLuca";

interface AuthContextType extends PropsState {
  login: (emailOrUsername: string, password: string, remember: boolean) => void;
  logout: () => void;
  passwordlessLogin: (code: string, remember: boolean) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(Tokens);

  const setTokenInRedux = (
    loginResponse: { success: boolean; token: string; message: string } | null,
    remember: boolean
  ) => {
    if (loginResponse && loginResponse.success && loginResponse.token) {
      dispatch(setisAuthToken(true));
      dispatch(setErrorToken(""));
      dispatch(setToken(loginResponse.token));
      try {
        const user = jwt(loginResponse.token) as getUserByEmail_getUserByEmail;
        dispatch(setUserToken(user));
        remember && localStorage.setItem("token", loginResponse.token);
      } catch (error) {
        console.error("Error al procesar el token:", error);
      }

      dispatch(setisLoading(false));
    }
  };

  const login = async (
    emailOrUsername: string,
    password: string,
    remember: boolean
  ) => {
    dispatch(setisLoading(true));
    dispatch(setEmailOrUsername(emailOrUsername));
    try {
      // const manageVersionResult = await manageVersion();

      // if (!manageVersionResult.idem) {
      //   dispatch(setisLoading(false));
      //   dispatch(setErrorToken("error"));
      //   notificationVersionNotMatch();
      // } else {
      const { data } = await axios.post(`${apiLuca}/login`, {
        emailOrUsername,
        password,
      });

      if (data && data.success) {
        setTokenInRedux(data, remember);
      } else {
        dispatch(setisLoading(false));
        dispatch(setErrorToken("error"));
        notificationNegative();
      }
      // }
    } catch (error) {
      localStorage.setItem("token", "");
      dispatch(setisLoading(false));
      dispatch(setErrorToken("Usuario o contraseña incorrectos!!"));
      notificationNegative();
    }
    dispatch(setisLoading(false));
  };

  const passwordlessLogin = async (code: string, remember: boolean) => {
    dispatch(setisLoading(true));
    try {
      const { data } = await axios.get(`${apiLuca}/pllogin?code=${code}`);

      if (data && data.success) {
        const user = jwt(data.token) as getUserByEmail_getUserByEmail;
        user.email && dispatch(setEmailOrUsername(user.email));
        if (user.role === "teacher") {
          logout();
        } else {
          setTokenInRedux(data, remember);
        }
      } else {
        dispatch(setisLoading(false));
        dispatch(setErrorToken("error"));
        notificationNegative();
      }
    } catch (error) {
      localStorage.setItem("token", "");
      dispatch(setisLoading(false));
      dispatch(setErrorToken("Usuario o contraseña incorrectos!!"));
      notificationNegative();
    }
    dispatch(setisLoading(false));
  };

  const logout = async () => {
    try {
      dispatch(setisLoading(true));
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      localStorage.setItem("token", "");
      dispatch(outToken());
      dispatch(setisAuthToken(false));
      dispatch(setErrorToken(""));
      dispatch(setUserToken(null));
      dispatch(setisLoading(false));
    } catch (error) {
      console.log("useAuth logout", error);
    }
  };

  const state = {
    ...tokens,
    login,
    logout,
    passwordlessLogin,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  return useContext(AuthContext);
}
