import React, { FC, useState } from "react";
import styles from "./bannerList.module.scss";
import inCourse from "../../../../assets/pages/classes/inCourse.png";
import programed from "../../../../assets/pages/classes/programed.png";
import { getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks } from "../../../../__generated__/gql-types/getClassroomWithHomeworksByDashboard";
import { lessonTypeIconResolve } from "../../../SingleClass/TaskTable/utils";
import { functionReturnImg } from "./utils";
import { DetailsHomeworkDrawer } from "../../../SingleClass/DetailsHomeworkDrawer";
import { toLocalDate } from "../../../../utils/functions";

interface PropsBannerList {
  homeworks:
    | (getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks | null)[]
    | null;
}
const BannerList: FC<PropsBannerList> = ({ homeworks }) => {
  const [visible, setVisible] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.containerPanel}>
        <div className={styles.headerList}>
          <div className={styles.firstColumn}>
            <div className={styles.subject}> {""}</div>
            <div className={styles.grade}>Grado</div>
            <div className={styles.class}>Clase</div>
            <div className={styles.type}>Tipo</div>
            <div className={styles.task}>Tarea</div>
          </div>
          <div className={styles.secondColumn}>
            <div className={styles.status}>Estado</div>
            <div className={styles.completed}>Completada</div>
            <div className={styles.averege}>Promedio</div>
            <div className={styles.finished}>Fin</div>
          </div>
        </div>
      </div>
      <div className={styles.containerPanel}>
        {homeworks?.map((homework, index) => {
          const finalDate = toLocalDate(homework?.end_date)
            ?.replace(/-\d{4}/, "")
            .replace(/(\d{2}:\d{2}).*/, "$1");
          return (
            <div
              className={styles.bodyList}
              key={index}
              onClick={() => {
                homework?.id && setTaskId(homework?.id);
                setVisible(true);
              }}
            >
              <div className={styles.firstColumn}>
                <div className={styles.subject}>
                  <img
                    src={functionReturnImg(homework?.subject_id ?? null)}
                    alt="default"
                  />
                </div>
                <div className={styles.grade}>{homework?.grade}°</div>
                <div className={styles.class}>{homework?.label}</div>
                <div className={styles.type}>
                  <img
                    src={
                      homework?.lesson_type
                        ? lessonTypeIconResolve(homework.lesson_type)
                        : ""
                    }
                    className={styles.typeImage}
                    alt="type"
                  />
                </div>
                <div className={styles.task}>{homework?.title}</div>
              </div>
              <div className={styles.secondColumn}>
                <div className={styles.status}>
                  <img
                    src={homework?.status === "inCourse" ? inCourse : programed}
                    alt="inCourse"
                  />
                </div>
                <div className={styles.completed}>
                  {homework?.participation}%
                </div>
                <div className={styles.averege}>
                  {homework?.average && homework?.average.toFixed(1)}
                </div>
                <div className={styles.finished}>{finalDate}</div>
              </div>
            </div>
          );
        })}
      </div>
      {visible && (
        <DetailsHomeworkDrawer
          back="calendar"
          taskId={taskId}
          singleTask={null}
          onCloseTask={onClose}
          visibleTask={visible}
        />
      )}
    </div>
  );
};

export default BannerList;
