import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherClickSeeVideoLessonVariables } from "../__generated__/gql-types/teacherClickSeeVideoLesson";

const useTeacherClickSeeVideoLesson = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherClickSeeVideoLessonVariables) => {
      return await client.mutate({
        mutation: TEACHER_CLICK_SEE_VIDEO_LESSON,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherClickSeeVideoLesson;

const TEACHER_CLICK_SEE_VIDEO_LESSON = gql`
  mutation teacherClickSeeVideoLesson($contentId: Int) {
    teacherClickSeeVideoLesson(contentId: $contentId)
  }
`;
