import React, { useEffect, useState } from "react";
import styles from "./newQuestions.module.scss";
import Latex from "react-latex";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import { getLessonById_getLessonById_lesson_content_quizzes_questions_answers } from "../../../../__generated__/gql-types/getLessonById";
import { ModalImg } from "./ModalImg";
import { synthesizeLanguageText } from "../NewQuizz/utils";

interface PropsNewQuestions {
  content: any;
  stateNext: boolean;
  answers: getLessonById_getLessonById_lesson_content_quizzes_questions_answers[];
  setAnswer: (
    e: getLessonById_getLessonById_lesson_content_quizzes_questions_answers[]
  ) => void;
  activeIndex: number[];
  setActiveIndex: (e: number[]) => void;
  putFocusFooter?: () => void;
  subject_id: number | null;
}
const NewQuestions = ({
  activeIndex,
  setActiveIndex,
  putFocusFooter,
  stateNext,
  answers,
  setAnswer,
  content,
  subject_id,
}: PropsNewQuestions) => {
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const styleSelector = (index: number) => {
    return activeIndex.includes(index);
  };

  const styleNextStateValidation = (id: string) => {
    const newStyles = content.answers.find(
      (e: any) => e.id == id && e.correct === 1
    );
    return activeIndex.includes(Number(id))
      ? newStyles
        ? styles.answerTrue
        : styles.answerFalse
      : newStyles
      ? styles.answerTrue
      : styles.afterRespQuizz;
  };
  const handleVoice = async () => {
    setIsSoundActive(true);
    subject_id && (await synthesizeLanguageText(content.question, subject_id));
    setIsSoundActive(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.fontQuestion}>
        {!isSoundActive && (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt="default"
          />
        )}
        {isSoundActive && (
          <img className={styles.imgSound} src={soundNegative} alt="default" />
        )}
        {content.question && <Latex>{content.question}</Latex>}
      </div>

      {content.url && (
        <img
          className={styles.imgQuestions}
          src={content.url ?? ""}
          alt="img"
          onClick={() => {
            setOpen(true);
          }}
        />
      )}
      {content.paragraph && (
        <div className={styles.containerParagrah}>
          <div className={styles.textParagrah}>{content.paragraph}</div>
        </div>
      )}

      <div className={styles.containerQuizz}>
        {content?.answers?.map((individualAnswer: any, index: number) => {
          return (
            <div key={index} className={styles.containerAnswer}>
              <div
                className={
                  stateNext
                    ? styleNextStateValidation(individualAnswer?.id)
                    : styleSelector(Number(individualAnswer?.id))
                    ? styles.seleccionado
                    : styles.afterRespQuizz
                }
                onClick={() => {
                  if (!stateNext) {
                    if (activeIndex.includes(Number(individualAnswer?.id))) {
                      const itemRemover = activeIndex.filter((e) => {
                        return e !== Number(individualAnswer?.id);
                      });

                      const answerRemover = answers.filter((e) => {
                        return e?.id !== individualAnswer?.id;
                      });
                      setAnswer(answerRemover);
                      setActiveIndex(itemRemover);
                    } else {
                      setActiveIndex([
                        ...activeIndex,
                        Number(individualAnswer?.id),
                      ]);
                      setAnswer([...answers, individualAnswer]);
                    }
                    putFocusFooter && putFocusFooter();
                  }
                }}
              >
                {individualAnswer?.answer ? (
                  <div className={styles.textQuiz}>
                    <Latex>{individualAnswer.answer}</Latex>
                  </div>
                ) : (
                  <img
                    src={individualAnswer?.url ?? ""}
                    alt="default"
                    width={"90px"}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ModalImg
        open={open}
        setOpen={setOpen}
        urlImg={content.url ?? ""}
      ></ModalImg>
    </div>
  );
};

export default NewQuestions;
