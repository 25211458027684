import React, { FC, useState } from "react";
import styles from "./headerRedaction.module.scss";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import gema from "../../../../../assets/icons/gemaMatematica.png";
import instructionsImg from "../../../../../assets/icons/students/instructions.svg";
import closeRedaction from "../../../../../assets/icons/others/closeRedaction.png";
import download from "../../../../../assets/pages/quiz/download.png";
import { DrawerLessonStudent } from "../../../ExtraPoints/Lessons";
import { ModalConfirmation } from "../../../../Shared/ModalConfirmation";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getHomeworkById_getHomeworkById } from "../../../../../__generated__/gql-types/getHomeworkById";
import { toLocalDate } from "../../../../../utils/functions";
import moment from "moment";
import { DrawerHomework } from "../../DrawerHomeworkStudent";
import { useLocation, useNavigate } from "react-router-dom";
interface PropsHeaderRedaction {
  lesson: getLessonById_getLessonById | null;
  homework: getHomeworkById_getHomeworkById | null;
}
const HeaderRedaction: FC<PropsHeaderRedaction> = ({ lesson, homework }) => {
  const location = useLocation();
  let navigate = useNavigate();
  const isMobile = useIsMobile();
  const [seeInstructions, setSeeInstructions] = useState(false);
  const [open, setOpen] = useState(false);
  const [visibleLesson, setVisibleLesson] = useState(false);

  const handleInstructions = () => {
    setSeeInstructions(!seeInstructions);
  };
  const onCloseModalConfirmation = () => {
    setOpen(false);
  };

  const handleRedirection = () => {
    if (location?.state?.from) {
      return navigate(-1);
    } else {
      return navigate("/mymissions");
    }
  };
  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  return (
    <>
      {isMobile ? (
        <div className={styles.container}>
          <div className={styles.firstRow}>
            <div className={styles.titleLesson}> {lesson?.title}</div>
            <div
              className={styles.containerClose}
              onClick={() => {
                setOpen(true);
              }}
            >
              <img src={closeRedaction} alt="Asdas"></img>
            </div>
          </div>
          <div className={styles.secondRow}>
            <div className={styles.containerGema}>
              <img src={gema} alt="" />
              <span className={styles.contGem}>100</span>
            </div>
            {homework && (
              <div className={styles.contdown}>
                {`Finaliza   ${moment(
                  toLocalDate(homework?.end_date)?.slice(0, 5),
                  "DD-MM"
                ).format("D MMM")}`}
              </div>
            )}
            <div className={styles.instructions} onClick={handleInstructions}>
              <img src={instructionsImg} alt="asdas" /> Detalles
            </div>
          </div>
          {homework?.needs_file_evidence && (
            <div
              className={styles.evidenceBoton}
              onClick={() => {
                setVisibleLesson(true);
              }}
            >
              <div> ADJUNTAR EVIDENCIA</div>
              <img src={download} alt="download" />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.headerRedaction}>
          <div className={styles.firstColumn}>
            <div className={styles.titleLesson}> {lesson?.title}</div>
            <div className={styles.containerGema}>
              <img src={gema} alt="" />
              <span className={styles.contGem}>100</span>
            </div>
          </div>
          <div className={styles.secondColumn}>
            {homework?.needs_file_evidence ? (
              <div
                className={styles.evidenceBoton}
                onClick={() => {
                  setVisibleLesson(true);
                }}
              >
                <div> ADJUNTAR EVIDENCIA</div>
                <img src={download} alt="download" />
              </div>
            ) : (
              homework && (
                <div className={styles.contdown}>
                  {`Finaliza   ${moment(
                    toLocalDate(homework?.end_date)?.slice(0, 5),
                    "DD-MM"
                  ).format("D MMM")}`}
                </div>
              )
            )}
            <div className={styles.instructions} onClick={handleInstructions}>
              <img src={instructionsImg} alt="asdas" /> Detalles
            </div>
            <div
              className={styles.containerClose}
              onClick={() => {
                setOpen(true);
              }}
            >
              <img src={closeRedaction} alt="Asdas"></img>
            </div>
          </div>
        </div>
      )}
      <ModalConfirmation
        open={open}
        onCloseModalConfirmation={onCloseModalConfirmation}
        handleRedirection={handleRedirection}
      />
      {seeInstructions && (
        <DrawerLessonStudent
          origin="extra_points"
          estilos={"estilosIntruc"}
          data={lesson}
          onCloseLesson={handleInstructions}
          visibleLesson={seeInstructions}
        />
      )}
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={homework?.id}
          finishedLesson={true}
        />
      )}
    </>
  );
};

export default HeaderRedaction;
