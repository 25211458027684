import styles from "./noSchool.module.css";
import Astronaut from "../../../assets/images/astronaut.png";
import Luca from "../../../assets/icons/Luca.png";

const NoSchool = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={Luca} alt="asd" />
      </div>

      <div className={styles.center}>
        <div className={styles.imgCenter}>
          <img src={Astronaut} width={"350px"} alt="error img" />
        </div>
        <div className={styles.title}>Parece que aún no estás registrado</div>
        <div className={styles.parrafo}>
          Si eres usuario de la Guía Santillana, puede que no hayas completado
          tu registro en Gavi y tu cuenta aún no esté activa.
        </div>
        <div className={styles.bloqueCenter}>
          <div className={styles.tituloBloque}>¿Necesitas ayuda?</div>
          <div className={styles.parrafoBloque}>
            Mándanos un correo a
            <a href="mailto:soporte@lucaedu.com"> soporte@lucaedu.com</a> o
            escríbenos por Whatsapp al
            <a href="https://wa.me/525541691994"> +52 55 4169 1994.</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSchool;
