import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherResponseRedactionCorrectionVariables } from "../__generated__/gql-types/teacherResponseRedactionCorrection";

const useTeacherResponseRedactionCorrection = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherResponseRedactionCorrectionVariables) => {
      return await client.mutate({
        mutation: TEACHER_NAVIGATE_SIDER,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherResponseRedactionCorrection;

const TEACHER_NAVIGATE_SIDER = gql`
  mutation teacherResponseRedactionCorrection(
    $responseId: ID!
    $redactionResponseCorrectionInput: redactionResponseCorrectionInput
  ) {
    teacherResponseRedactionCorrection(
      responseId: $responseId
      redactionResponseCorrectionInput: $redactionResponseCorrectionInput
    ) {
      id
      rubrics_calification {
        order
        calification
      }
      teacher_correction
      redaction_comments
      redaction_response
      redaction_teacher_response_correction
      note
    }
  }
`;
