import { FC } from "react";
import { Tabs } from "antd";
import { Searchbar } from "../../../../Commons";
import styles from "./tabs.module.scss"
import settingsIcon from "../../../../../assets/icons/settingsIcon.svg";

const { TabPane } = Tabs;

const tabItems = [
  {
    itemName: "Tareas",
    id: "1",
  },
  {
    itemName: "Alumnos",
    id: "2",
  },
  {
    itemName: "Contenido Recomendado",
    id: "3",
  },
];

interface TabProps {
  setSearchInput?: (e: string) => void;
  searchInput?: string;
  setSelectedItem: (e: string) => void;
  setFilterActive: (e: boolean) => void;
}

const TabsComponent: FC<TabProps> = ({ setSearchInput, searchInput, setSelectedItem, setFilterActive }) => {


  const handleChangeItem = (key: string) => {
    if (key === "1") return setSelectedItem("tareas")
    if (key === "2") return setSelectedItem("alumnos")
    if (key === "3") return setSelectedItem("recomendado")
  };

  return (
    <Tabs style={{ margin: "0 16px" }} onChange={handleChangeItem}>
      {tabItems?.map((item) => {
        return (
          <TabPane
            tab={item?.itemName}
            key={item?.id}

          >
            <div style={{ marginBottom: "16px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Searchbar
                setSearchInput={setSearchInput}
                searchInput={searchInput}
              />
              <span className={styles.itemFilter} onClick={() => setFilterActive(true)}>
                <img src={settingsIcon} alt="" style={{ marginRight: "10px" }} />
                Filtros
              </span>
            </div>
          </TabPane>
        );
      })}
    </Tabs>
  )
}

export default TabsComponent;