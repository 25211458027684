import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getClassroomsByStudentIdV2 } from "../__generated__/gql-types/getClassroomsByStudentIdV2";

const UseClassroomsByStudentIdV2 = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClassroomsByStudentIdV2> =
      await client.query({
        query: GET_CLASSROOMS_BY_STUDENT_ID_V2,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClassroomsByStudentIdV2;
  }, [client]);
};

export default UseClassroomsByStudentIdV2;

const GET_CLASSROOMS_BY_STUDENT_ID_V2 = gql`
  query getClassroomsByStudentIdV2 {
    getClassroomsByStudentIdV2 {
      id
      name
      program_id
      group {
        id
        name
      }
      group_id
      teachers {
        id
        first_name
      }
      homeworks {
        id
        description
      }
      school_id
      created_at
      updated_at
      deleted_at
      program {
        id
        title
        grade {
          grade
        }
        subject {
          id
          name
        }
      }
    }
  }
`;
