interface EnvironmentType {
  apiGraphQLURL: string;
  urlImages: string;
  urlVideos: string;
  backendUrl: string;
  awsRegion: string;
  awsKey: string;
  awsSecretKey: string;
  awsBucket: string;
  awsBucketImages: string;
  awsBucketVideos: string;
}

const resolveBackendUrl = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    let backEndUrl = "https://luca2-backend.prod-k8s.lucaedu.com";
    return backEndUrl;
  } else if (
    `${process.env.REACT_APP_NODE_ENV}` === "development" ||
    `${process.env.REACT_APP_NODE_ENV}` === "dev"
  ) {
    let backEndUrlDev = "https://luca2-backend.dev-k8s.lucaedu.com";
    return backEndUrlDev;
  } else {
    let backEndUrlLocal = "http://localhost:4000";
    return backEndUrlLocal;
  }
};

const resolveBucketFilesByEnv = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_IMAGES}` ||
      "https://assets.app.lucaedu.com/";
    return urlBucketImages;
  } else {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_IMAGES}` ||
      "https://assets.dev-k8s.lucaedu.com/";
    return urlBucketImages;
  }
};

const resolveBucketVideosByEnv = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_VIDEOS}` || "https://vod.app.lucaedu.com/";
    return urlBucketImages;
  } else {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_VIDEOS}` ||
      "https://vod.dev-k8s.lucaedu.com/";
    return urlBucketImages;
  }
};

export const resolveEnv = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    return "prod";
  } else {
    return "dev";
  }
};

export const environment: EnvironmentType = {
  apiGraphQLURL: `${process.env.REACT_APP_API_GRAPHQL_URL}` || "",
  urlImages: `${process.env.REACT_APP_URL_IMAGES}` || "",
  urlVideos: `${process.env.REACT_APP_URL_VIDEOS}` || "",
  backendUrl: resolveBackendUrl(),
  awsRegion: `${process.env.REACT_APP_AWS_REGION}`,
  awsKey: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
  awsSecretKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
  awsBucket: `${process.env.REACT_APP_AWS_BUCKET_VIDEOS}`,
  awsBucketImages: resolveBucketFilesByEnv(),
  awsBucketVideos: resolveBucketVideosByEnv(),
};
