import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateUserAvatarVariables } from "../__generated__/gql-types/updateUserAvatar";

const useUpdateUserAvatar = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateUserAvatarVariables) => {
      return await client.mutate({
        mutation: UPDATE_USERAVATAR,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdateUserAvatar;

const UPDATE_USERAVATAR = gql`
  mutation updateUserAvatar($userInput: UserInput) {
    userSelectedAvatar(userInput: $userInput) {
      succes
    }
  }
`;


