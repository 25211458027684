import { FC, useState, useEffect } from "react";
import { Feedback } from "./Feedback";
import styles from "./asteroidsChallenge.module.scss";
import { AsteroidsHeader } from "./Header";
import { InstructionsModal } from "./InstructionsModal";
import { MainAsteroidsPage } from "./MainAsteroidsPage";
import { useLocation } from "react-router-dom";
import problems from "./questions.json"
interface AsteroidsChallengeProps {
  lesson?: any;
}

const AsteroidsChallenge: FC<AsteroidsChallengeProps> = () => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [counter, setCounter] = useState(60);
  const [finished, setFinished] = useState(false);
  const [input, setInput] = useState<string>("");
  const [step2, setStep2] = useState(false);
  const [start, setStart] = useState(false);
  const [points, setPoints] = useState(0);
  function handleInstructions() {
    setShowInstructions(!showInstructions);
  }

  const handleInput = (e: string) => {
    setInput(input.concat(e));
  };
  const handleDelete = () => {
    setInput(input.substring(0, input.length - 1))
  }
  let location = useLocation();

  useEffect(() => {
    if (counter === 0) return setFinished(true);
    if (counter !== 0 && start && !showInstructions) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      let contador = counter;
      setCounter(contador);
    }
  }, [counter, start, showInstructions]);

  return !finished ? (
    <>
      {step2 ? (
        <div className={styles.container}>
          <AsteroidsHeader
            handleInstructions={handleInstructions}
            handleInput={handleInput}
            handleDelete={handleDelete}
            counter={counter}
            input={input}
            questions={location.state ?? problems}
            setInput={setInput}
            setStart={setStart}
            start={start}
            setPoints={setPoints}
            points={points}
          />
        </div>
      ) : (
        <MainAsteroidsPage
          setStep2={setStep2}
          setShowInstructions={setShowInstructions}
        />
      )}

      {showInstructions && (
        <InstructionsModal
          visible={showInstructions}
          handleInstructions={handleInstructions}
          setStep2={setStep2}
          step2={step2}
        />
      )}
    </>
  ) : (
    <Feedback points={points} />
  );
};

export default AsteroidsChallenge;
