import correo from "../../../assets/icons/correo.svg";
import { Field, Form, Formik } from "formik";
import { SignupSchema } from "./RecoveryForm.types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./recoveryPassword.module.scss";
import axios from "axios";
import { apiLuca } from "../../../utils/constants";
import { notificationNegative } from "../../Shared/Notifacation/NotificationLuca";
interface Values {
  email: string;
}

const RecoveryPassword = () => {
  const [resultCall, setResultCall] = useState<boolean>();
  const navigate = useNavigate();
  const handleSubmit = async (values: Values) => {
    try {
      await axios.post(`${apiLuca}/forgotPassword`, {
        email: values.email,
      });
      setResultCall(true);
    } catch (error) {
      notificationNegative(
        "¡Ups! el usuario no existe. Por favor, vuelve a intentarlo."
      );
    }
  };

  return resultCall ? (
    <>
      <img src={correo} alt="" />
      <div className={styles.titleHeader}>Revisa tu correo electrónico</div>
      <div className={styles.subTitleHeader}>Te hemos enviado un email.</div>
      <div className={styles.containerLabel}>
        <button
          type="submit"
          className={styles.botonReturn}
          onClick={() => {
            return navigate("/");
          }}
        >
          Volver a la página de inicio
        </button>
      </div>
    </>
  ) : (
    <>
      <div className={styles.titleHeader}>Recuperar contraseña</div>
      <div className={styles.subTitleHeader}>
        Ingresa el correo con el que entras a Luca y te enviaremos un email para
        cambiar tu contraseña
      </div>
      <div className={styles.containerTitleInput}>
        <div className={styles.containerLabel}>
          <div className={styles.titleInput}>Email</div>
        </div>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
          validationSchema={SignupSchema}
        >
          {({ errors, touched }) => (
            <Form className={styles.formSpaces} placeholder="">
              <Field
                className={styles.formFields}
                id="email"
                name="email"
                placeholder="john@acme.com"
                type="email"
              />

              {errors.email && touched.email ? <div>{errors.email}</div> : null}

              <button type="submit" className={styles.botonForm}>
                Enviar correo
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RecoveryPassword;
