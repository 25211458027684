import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateHomeworkVariables } from "../__generated__/gql-types/updateHomework";

const useUpdateHomework = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateHomeworkVariables) => {
      return await client.mutate({
        mutation: UPDATE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdateHomework;

const UPDATE_HOMEWORK = gql`
  mutation updateHomework($homeworkId: ID!, $homeworkInput: HomeworkInput) {
    updateHomework(homeworkId: $homeworkId, homeworkInput: $homeworkInput) {
      title
    }
  }
`;
