import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState = {
  file: {},
};

export const uploadedFileSlice = createSlice({
  name: "uploadedFile",
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<File>) => ({
      ...state,
      file: action.payload,
    }),
  },
});
export const {
    setFile,
  } = uploadedFileSlice.actions;
export default uploadedFileSlice.reducer;
export const selectUploadedFile = (state: RootState) => state;
