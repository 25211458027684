import styles from "./collapsibleMobile.module.css";

import medalLenguaMobileLoss from "../../../../assets/icons/medalLenguaMobileLoss.svg";
import medalLenguaMobile from "../../../../assets/icons/medalLenguaMobile.svg";

import medalMatematicasMobileLoss from "../../../../assets/icons/medalMatematicasMobileLoss.svg";
import medalMatematicasMobile from "../../../../assets/icons/medalMatematicasMobile.svg";

import medalCienciasMobile from "../../../../assets/icons/medalCienciasMobile.svg";
import medalCienciasMobileLoss from "../../../../assets/icons/medalCienciasMobileLoss.svg";

import CircleDiamondMatematicas from "../../../../assets/icons/CircleDiamond.svg";
import CircleDiamondEspañol from "../../../../assets/icons/CircleDiamondEspañol.svg";
import CircleDiamondCiencias from "../../../../assets/icons/CircleDiamondCiencias.svg";
import { Progress } from "antd";
import { getCoursesBySubjectId_getCoursesBySujectId } from "../../../../__generated__/gql-types/getCoursesBySubjectId";
import { useState } from "react";

interface PropsCollapsible {
  item: getCoursesBySubjectId_getCoursesBySujectId;
}
const CollapsibleMobile = ({ item }: PropsCollapsible) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const goBack = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.container} onClick={showDrawer}>
        <div className={styles.medal}>
          {item.subject_id === 1 && (
            <img
              src={
                (item.lessons?.filter((lesson) => lesson.finished).length ??
                  0) /
                  (item?.lessons?.length ?? 0) ===
                1
                  ? medalMatematicasMobile
                  : medalMatematicasMobileLoss
              }
              alt=""
            />
          )}
          {item.subject_id === 2 && (
            <img
              src={
                (item.lessons?.filter((lesson) => lesson.finished).length ??
                  0) /
                  (item?.lessons?.length ?? 0) ===
                1
                  ? medalLenguaMobile
                  : medalLenguaMobileLoss
              }
              alt=""
            />
          )}
          {item.subject_id === 4 && (
            <img
              src={
                (item.lessons?.filter((lesson) => lesson.finished).length ??
                  0) /
                  (item?.lessons?.length ?? 0) ===
                1
                  ? medalCienciasMobile
                  : medalCienciasMobileLoss
              }
              alt=""
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.containerText}>
            <div className={styles.title}> {item?.title}</div>

            <div className={styles.plus}>Ver detalle</div>
          </div>

          <div className={styles.containerProgress}>
            <Progress
              percent={
                ((item.lessons?.filter((lesson) => lesson.finished).length ??
                  0) /
                  (item?.lessons?.length ?? 0)) *
                100
              }
              showInfo={false}
              trailColor={"#CBCEDE"}
              strokeColor={"#3843D0"}
            />

            <div className={styles.diamond}>
              {item.subject_id === 1 && (
                <img src={CircleDiamondMatematicas} alt="" />
              )}
              {item.subject_id === 2 && (
                <img src={CircleDiamondEspañol} alt="" />
              )}
              {item.subject_id === 4 && (
                <img src={CircleDiamondCiencias} alt="" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapsibleMobile;
