import { FC } from "react";
import styles from "./card.module.scss";
import defaultImage from "../../../assets/images/defaultImage.svg";
import thumbnailClase from "../../../assets/images/thumbnail/thumbnailGuion.png";
import thumbnailCiencias from "../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../assets/images/thumbnail/thumbnailEspañol.png";
import pizza from "../../../assets/dataMock/Pizza_portada.png";
import videoLight from "../../../assets/icons/Video_light.svg";
import evaluationIcon from "../../../assets/icons/evaluationIcon.svg";
import guionIcon from "../../../assets/icons/guionIcon.svg";
import useAuth from "../../../context/useAuth";
import { getLessonsBySubjectId_getLessonsBySubject } from "../../../__generated__/gql-types/getLessonsBySubjectId";
import { getLessonsSearch_getLessonsSearch } from "../../../__generated__/gql-types/getLessonsSearch";
interface CardProps {
  setLessonId?: (id: string) => void;
  showDrawer: () => void;
  item:
    | getLessonsBySubjectId_getLessonsBySubject
    | getLessonsSearch_getLessonsSearch;
  setIndividualLesson?: (item: any) => void;
}

const Card: FC<CardProps> = ({
  item,
  showDrawer,
  setLessonId,
  setIndividualLesson,
}) => {
  const functionReturnImg = () => {
    if (item.lesson_type === "classroomguide") {
      return thumbnailClase;
    }
    if (item.lesson_type === "evaluation") {
      if (item.subject?.id === "1") {
        return thumbnailMate;
      }
      if (item.subject?.id === "2") {
        return thumbnailCiencias;
      }
      if (item.subject?.id === "4") {
        return thumbnailEspañol;
      }
    }
  };
  const { userToken } = useAuth();
  const showSepPages = userToken?.schools[0].show_sep_pages;
  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        setIndividualLesson && setIndividualLesson(item);
        setLessonId && setLessonId(item?.id);
        showDrawer();
      }}
    >
      <div className={styles.videoQuizContainer}>
        <img
          src={
            item.lesson_type === "videolesson"
              ? videoLight
              : item.lesson_type === "classroomguide"
              ? guionIcon
              : item.lesson_type === "evaluation"
              ? evaluationIcon
              : videoLight
          }
        />
        {item.lesson_type === "videolesson"
          ? "Video Lección"
          : item.lesson_type === "classroomguide"
          ? "Orientación didáctica"
          : item.lesson_type === "evaluation"
          ? "Evaluación"
          : item.lesson_type === "slides"
          ? "Diapositivas"
          : item.lesson_type === "book_pages"
          ? "Libro"
          : "Video Lección"}
      </div>

      {
        <img
          src={
            Number(item?.id) === 1812
              ? pizza
              : functionReturnImg()
              ? functionReturnImg()
              : item.lesson_content?.videos?.length &&
                item.lesson_content?.videos[0]?.imageUrl
              ? item.lesson_content?.videos[0].imageUrl
              : defaultImage
          }
          alt="default"
          style={{ width: "100%" }}
        />
      }

      <div className={styles.cardContent}>
        <div className={styles.titleCard}>{item?.title}</div>

        <div>
          <h3>{item?.sep_name}</h3>

          {showSepPages &&
            !!item?.sep_book_pages?.length &&
            item?.sep_book_pages.replace(/\s/g, "") !==
              "Evaluacióndiagnóstica" && (
              <div className={styles.containerPages}>
                <div className={styles.pages}>
                  {(item?.lesson_type === "videolesson" ||
                    item?.lesson_type === "classroomguide" ||
                    item?.lesson_type === "manipulative_dynamics" ||
                    item?.lesson_type === "slides") &&
                    "Didáctica"}
                  {(item?.lesson_type === "book_pages" ||
                    item?.lesson_type === "evaluation" ||
                    item?.lesson_type === "redaction" ||
                    item?.lesson_type === "literacy") &&
                    "Práctica"}
                </div>
                <div className={styles.trim}>
                  <div className={styles.bubble}>{item.grade?.grade}°</div>
                  <div className={styles.bubble}>Semana {item?.week}</div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Card;
