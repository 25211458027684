import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherNavigateCalifications = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_NAVIGATE_CALIFICATIONS,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherNavigateCalifications;

const TEACHER_NAVIGATE_CALIFICATIONS = gql`
  mutation teacherNavigateCalifications {
    teacherNavigateCalifications
  }
`;
