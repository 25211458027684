import { getClassroomsByTeacher_getClassroomsByTeacher } from "../__generated__/gql-types/getClassroomsByTeacher";

const TeacherClassroooms = (() => {
  let teacherClassrooms:
    | getClassroomsByTeacher_getClassroomsByTeacher[]
    | null;

  let getTeacherClassrooms = () => {
    return teacherClassrooms; // Or pull this from cookie/localStorage
  };

  let setTeacherClassrooms = (
    teacherClassroomsResponse:
      | getClassroomsByTeacher_getClassroomsByTeacher[]
      | null
  ) => {
    teacherClassrooms = teacherClassroomsResponse;

    // Also set this in cookie/localStorage
  };

  return {
    getTeacherClassrooms: getTeacherClassrooms,

    setTeacherClassrooms: setTeacherClassrooms,
  };
})();

export default TeacherClassroooms;
