import React, { FC } from "react";
import styles from "./feedback.module.scss";

import crossRed from "../../../../../assets/icons/crossRed.svg";
import check from "../../../../../assets/icons/check-svgrepo-com.svg";
import checkOranje from "../../../../../assets/icons/checkOranje.svg";

interface FeedbackProps {
  alertTrue: boolean;
  alertFalse: boolean;
  alertMid?: boolean;
}

const Feedback: FC<FeedbackProps> = ({ alertTrue, alertFalse, alertMid }) => {
  return (
    <div className={styles.containerMessege}>
      {alertTrue && (
        <div className={styles.containerMessegeBackground}>
          <div className={styles.imgMessage}>
            <img alt={check} src={check} width={"23px"} />
          </div>
          <div className={styles.fontFeedback}>
            ¡Genial! Ya casi lo tienes, ¡continua así!
          </div>
        </div>
      )}
      {alertFalse && (
        <div className={styles.containerMessegeBackground}>
          <div className={styles.imgMessage}>
            <img alt={check} src={crossRed} width={"22px"} />
          </div>
          <div className={styles.fontFeedback}>
            ¡No te rindas! Continúa sumando puntos, !tu puedes!
          </div>
        </div>
      )}
      {alertMid && (
        <div className={styles.containerMessegeBackground}>
          <div className={styles.imgMessage}>
            <img alt={check} src={checkOranje} width={"23px"} />
          </div>
          <div className={styles.fontFeedback}>
            ¡Casi lo consigues! Obtienes la mitad de los puntos, ¡ánimo!
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
