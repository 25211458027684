import React from "react";
import { BackgroundLogin } from "../../components/Login/BackgroundLogin";
import { ChangePassword } from "../../components/Login/ChangePassword";

const ChangePasswordPage = () => {
  return (
    <BackgroundLogin>
      <ChangePassword></ChangePassword>
    </BackgroundLogin>
  );
};

export default ChangePasswordPage;
