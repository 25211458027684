import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { removeItemSelectedVariables } from "../__generated__/gql-types/removeItemSelected";

const useRemoveItemSelected = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: removeItemSelectedVariables) => {
      return await client.mutate({
        mutation: REMOVE_ITEM_SELECTED,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useRemoveItemSelected;

const REMOVE_ITEM_SELECTED = gql`
  mutation removeItemSelected($itemId: ID!) {
    removeItemSelected(item_id: $itemId)
  }
`;
