import { FC, useEffect, useState } from "react";
import styles from "./videoLessonsTable.module.scss";
import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import { getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users } from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import { DrawerLessonNew } from "../../Lessons/Drawer";
import { useLocation } from "react-router-dom";
import UseGetLessonsById from "../../../api/useGetLessonsById";
import { toLocalDate } from "../../../utils/functions";

interface IVideoLessonsProps {
  classroomStudents:
    | getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users[]
    | undefined;

  isStudentsTableLoading: boolean;
}

const VideoLessonsTable: FC<IVideoLessonsProps> = ({
  classroomStudents,
  isStudentsTableLoading,
}) => {
  const getLessonById = UseGetLessonsById();
  const location = useLocation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [individualLesson, setIndividualLesson] = useState({} as any);
  const [visibleDetailLesson, setVisibleDetailLesson] = useState(
    location.state?.openLessonDrawer ?? false
  );
  const [taskEndDate, setTaskEndDate] = useState<any>(null);
  const onCloseDetailLesson = () => {
    setVisibleDetailLesson(false);
  };

  const handleOpenDetailLesson = async (
    id: string,
    lesson_id: string,
    lessonEmdDAte: any
  ) => {
    try {
      setTaskId(id);
      setTaskEndDate(lessonEmdDAte);
      const lesson = await getLessonById({ lessonId: lesson_id });
      setIndividualLesson(lesson);
      setVisibleDetailLesson(true);
    } catch (error) {
      console.error(error);
    }
  };
  const getFormattedDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  const convertDateFormat = (dateString: string) => {
    let parts: string[] = [];
    if (
      dateString !== null &&
      dateString !== undefined &&
      dateString.length > 0
    ) {
      parts = dateString?.split(" ");
    } else {
      parts = getFormattedDate().split(" ");
    }
    const datePart = parts[0];
    const timePart = parts[1];

    const [day, month, year] = datePart?.split("/");
    const convertedDateString = `${month}/${day}/${year} ${timePart}`;

    return convertedDateString;
  };

  const getDateStatus = (dateString: string) => {
    const finalDateString = dateString?.replace(/-/g, "/");
    const formattedDate = convertDateFormat(finalDateString);
    const currentDate = new Date();
    const targetDate = new Date(formattedDate);

    // Set time to 00:00:00 for both dates to compare only the dates
    currentDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    if (targetDate?.getTime() === currentDate?.getTime()) {
      return "Hoy";
    }

    const startOfWeek = new Date(currentDate);
    startOfWeek?.setDate(currentDate?.getDate() - currentDate?.getDay() + 1);
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate?.getDate() + (7 - currentDate?.getDay()));
    if (targetDate < currentDate) {
      return "Finalizada";
    }
    if (targetDate >= startOfWeek && targetDate <= endOfWeek) {
      return "Esta Semana";
    }

    // If none of the above conditions match, return null or any default value
    else return "Planeada";
  };

  const classroomLessonsData = classroomStudents
    ?.filter((student: any) => {
      return student?.lesson !== null;
    })
    .map((student: any) => {
      if (student?.start_date === null) {
        return {
          id: student?.id,
          title: student?.title,
          start_date: getFormattedDate(),
          status_date: getDateStatus(getFormattedDate()),
          lesson_id: student?.lesson?.id,
        };
      } else {
        return {
          id: student?.id,
          title: student?.title,
          start_date: student?.start_date,
          status_date: getDateStatus(student?.start_date),
          lesson_id: student?.lesson?.id,
        };
      }
    });

  const finishedClassroomLessonsData = classroomLessonsData?.filter(
    (lesson) => lesson?.status_date === "Finalizada"
  );

  const otherClassroomLessonsData = classroomLessonsData
    ?.filter((lesson) => lesson?.status_date !== "Finalizada")
    .sort((a, b) => {
      return new Date(convertDateFormat(a?.start_date)).getTime() >
        new Date(convertDateFormat(b?.start_date)).getTime()
        ? 1
        : new Date(convertDateFormat(a?.start_date)).getTime() <
          new Date(convertDateFormat(b?.start_date)).getTime()
        ? -1
        : 0;
    });

  const columns: ColumnsType<any> = [
    {
      title: "Estado",
      dataIndex: "id",
      key: "id",
      width: 140,
      sortDirections: ["descend", "ascend", "descend"],
      render: (_, student) => {
        return (
          <>
            {student?.start_date !== null &&
              student?.start_date.length > 0 &&
              getDateStatus(student?.start_date) === "Hoy" && (
                <div className={styles.statusToday}>
                  <h3> Hoy </h3>
                </div>
              )}
            {student?.start_date !== null &&
              student?.start_date.length > 0 &&
              getDateStatus(student?.start_date) === "Esta Semana" && (
                <div className={styles.statusThisWeek}>
                  <h3> Esta Semana </h3>
                </div>
              )}
            {student?.start_date !== null &&
              student?.start_date.length > 0 &&
              getDateStatus(student?.start_date) === "Finalizada" && (
                <div className={styles.statusFinished}>
                  <h3> Finalizada </h3>
                </div>
              )}
            {student?.start_date !== null &&
              student?.start_date.length > 0 &&
              getDateStatus(student?.start_date) === "Planeada" && (
                <div className={styles.statusPlanned}>
                  <h3> Planeada </h3>
                </div>
              )}
          </>
        );
      },
    },
    {
      title: "Videolección",
      dataIndex: "title",
      key: "title",
      width: 900,
      render: (_, student) => {
        return (
          <div className={styles.descriptionContainer}>
            <p>{student?.title}</p>
          </div>
        );
      },
    },
    {
      title: "Fecha",
      dataIndex: "start_date",
      key: "start_date",
      width: 190,
      render: (_: any, record) => {
        return (
          <div className={styles.descriptionContainer}>
            <p
              style={{
                color: "#333333",
                fontSize: "14px",
                lineHeight: "140%",
                fontFamily: "Poppins-Medium",
              }}
            >
              {toLocalDate(record?.start_date)}
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {isStudentsTableLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <div style={{ paddingBottom: "20px" }}>
            {otherClassroomLessonsData?.length !== undefined &&
              otherClassroomLessonsData.length > 0 && (
                <Table
                  dataSource={otherClassroomLessonsData}
                  pagination={false}
                  columns={columns}
                  rowClassName={styles.cursorPoint}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleOpenDetailLesson(
                          record?.id,
                          record?.lesson_id,
                          record.status_date
                        );
                      },
                    };
                  }}
                />
              )}
          </div>
          <div>
            {finishedClassroomLessonsData?.length !== undefined &&
              finishedClassroomLessonsData.length > 0 && (
                <Table
                  size="middle"
                  dataSource={finishedClassroomLessonsData}
                  pagination={false}
                  columns={columns}
                  rowClassName={styles.cursorPoint}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleOpenDetailLesson(
                          record?.id,
                          record?.lesson_id,
                          record.status_date
                        );
                      },
                    };
                  }}
                />
              )}
          </div>
          <DrawerLessonNew
            onClose={onCloseDetailLesson}
            visible={visibleDetailLesson}
            taskId={taskId ?? ""}
            back={"calendar"}
            individualLesson={individualLesson}
            videoLessonStatusDate={taskEndDate}
          />
        </>
      )}
    </div>
  );
};

export default VideoLessonsTable;
