import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getHomeworkForLessonResolveDeepLink,
  getHomeworkForLessonResolveDeepLinkVariables,
} from "../__generated__/gql-types/getHomeworkForLessonResolveDeepLink";

const UseGetHomeworkForLessonResolveDeepLink = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getHomeworkForLessonResolveDeepLinkVariables) => {
      const result: ApolloQueryResult<getHomeworkForLessonResolveDeepLink> =
        await client.query({
          query: GET_HOMEWORK_FOR_LESSON_RESOLVE_DEEP_LINK,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getHomeworkForLessonResolveDeepLink;
    },
    [client]
  );
};

export default UseGetHomeworkForLessonResolveDeepLink;

const GET_HOMEWORK_FOR_LESSON_RESOLVE_DEEP_LINK = gql`
  query getHomeworkForLessonResolveDeepLink($homeworkId: ID!) {
    getHomeworkForLessonResolveDeepLink(homeworkId: $homeworkId) {
      msg
      post_homework
      success
    }
  }
`;
