import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherNavigateLessonSearch = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_NAVIGATE_LESSON_SEARCH,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherNavigateLessonSearch;

const TEACHER_NAVIGATE_LESSON_SEARCH = gql`
  mutation teacherNavigateLessonSearch {
    teacherNavigateLessonSearch
  }
`;
