import React, { FC } from "react";
import styles from "./columnAvatarMobile.module.scss";
import { Progress } from "antd";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../../../__generated__/gql-types/getClothesAndAccessories";
import { calculateLevel, resolveAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../ColumnAvatar/ContainerAvatar/utils";
import start from "../../../../assets/pages/store/start.png";
import baseMobile from "../../../../assets/pages/store/storeMobile/baseMobile.png";
import useAuth from "../../../../context/useAuth";

import gold from "../../../../assets/pages/store/copa2.png";
import silver from "../../../../assets/pages/store/laPlataa.png";
import bronze from "../../../../assets/pages/store/bronze.png";

interface PropsAvatarMobile {
  userScore: getUserScore_getUserScore | undefined;
  clothesAndAccessories: PropsClothes[] | null;
}

const ColumnAvatarMobile: FC<PropsAvatarMobile> = ({
  userScore,
  clothesAndAccessories,
}) => {
  const { userToken } = useAuth();
  const clothesActive = clothesAndAccessories?.filter((e) => e.active);

  const contCup = (positionCup: number) => {
    return userScore?.user?.avatar_state?.trophies?.filter(
      (e) => e.position === positionCup
    ).length;
  };

  return (
    <div className={styles.columnAvatarMobile}>
      <div className={styles.headerAvatar}>
        <div className={styles.secondRowPj}>
          <div className={styles.circleProgress}>{userScore?.level}</div>
          <div className={styles.progress}>
            <div className={styles.textLevel}>NIVEL {userScore?.level}</div>
            <Progress
              percent={
                userScore?.main_score
                  ? calculateLevel(userScore?.main_score)
                  : 0
              }
              showInfo={false}
              className={"myProgressStoreMobile"}
              strokeColor={"#ffba31"}
            />
          </div>
          <div className={styles.circleProgress}>
            {userScore?.level && userScore?.level + 1}
          </div>
        </div>
        <div className={styles.containerPoints}>
          <div className={styles.containerChampionship}>
            <div className={styles.containerCopas}>
              <div className={styles.numberFirst}>{contCup(1)}</div>
              <div className={styles.numberSecond}>{contCup(2)}</div>
              <div className={styles.numberThired}>{contCup(3)}</div>
              <img src={gold} alt="bg" />
              <img src={silver} alt="bg" />
              <img src={bronze} alt="bg" />
            </div>
          </div>
          <div className={styles.containerStart}>
            <img src={start} alt="start" />
            <div>{userScore?.main_score}</div>
          </div>
        </div>
      </div>
      <div className={styles.containerAvatar}>
        <img src={baseMobile} alt="base" />
        <img
          src={
            userScore?.level && userScore?.level < 6
              ? resolveAvatar(
                  userScore?.user?.avatar_state?.name ?? "polux",
                  userScore?.level.toString() ?? "1"
                )
              : functionReturnImgAvatar(userToken?.avatar ?? "")
          }
          alt="base"
          className={
            userScore?.level && userScore?.level < 6
              ? styles.avatarImgwithOutLevel // revisar
              : styles.avatarImg
          }
        />
        {clothesActive?.map((clothes) => (
          <img
            src={clothes.item_img ?? ""}
            alt="avatar"
            style={{ zIndex: clothes.z_index ?? 0 }}
            className={styles.clothes}
          />
        ))}
      </div>{" "}
    </div>
  );
};

export default ColumnAvatarMobile;
