import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherClickSeeAsStudentVideoLessonVariables } from "../__generated__/gql-types/teacherClickSeeAsStudentVideoLesson";

const useTeacherClickSeeAsStudentVideoLesson = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherClickSeeAsStudentVideoLessonVariables) => {
      return await client.mutate({
        mutation: TEACHER_CLICK_SEE_AS_STUDENT_VIDEO_LESSON,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherClickSeeAsStudentVideoLesson;

const TEACHER_CLICK_SEE_AS_STUDENT_VIDEO_LESSON = gql`
  mutation teacherClickSeeAsStudentVideoLesson($contentId: Int) {
    teacherClickSeeAsStudentVideoLesson(contentId: $contentId)
  }
`;
