import { FC } from "react";
import { toLocalDate } from "../../../../../../utils/functions";
import styles from "./homeworkCard.module.scss";
// Icons
import conversation from "../../../../../../assets/icons/tasktable/conversation.png";
import evaluation from "../../../../../../assets/icons/tasktable/evaluation.png";
import listening from "../../../../../../assets/icons/tasktable/listening.png";
import reading from "../../../../../../assets/icons/tasktable/reading.png";
import redaction from "../../../../../../assets/icons/tasktable/redaction.png";
import videolesson from "../../../../../../assets/icons/tasktable/videolesson.png";
import inCourse from "../../../../../../assets/icons/tasktable/enCurso.png";
import programmed from "../../../../../../assets/icons/tasktable/programada.png";
import finished from "../../../../../../assets/icons/tasktable/finalizada.png";

import { getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks } from "../../../../../../__generated__/gql-types/getClassroomWithHomeworks";

interface HomeworkCardProps {
  homework: getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks;
}
const lessonTypeIconResolve = (lessontype: string | null) => {
  if (lessontype === "redaction") return redaction;
  if (lessontype === "evaluation") return evaluation;
  if (lessontype === "listening") return listening;
  if (lessontype === "reading") return reading;
  if (lessontype === "conversation") return conversation;
  return videolesson;
};
const dateFix = (date: string | null) => {
  return toLocalDate(date)
    ?.replace(/-\d{4}/, "")
    .replace(/(\d{2}:\d{2}).*/, "$1");
};

const HomeworkCard: FC<HomeworkCardProps> = ({ homework }) => {
  const lessonType = homework?.lessons && homework?.lessons[0].lesson_type;
  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        {homework.status === "programed" && (
          <div className={styles.status}>
            <img src={programmed} alt="" />
          </div>
        )}

        {homework.status === "inCourse" && (
          <div className={styles.status}>
            <img src={inCourse} alt="" />
          </div>
        )}

        {homework.status === "finished" && (
          <div className={styles.status}>
            <img src={finished} alt="" />
          </div>
        )}
        <h2>{`${dateFix(homework.start_date)} - ${dateFix(
          homework.end_date
        )}`}</h2>
      </div>
      <div className={styles.titleContainer}>
        <img src={lessonTypeIconResolve(lessonType)} alt="" />
        <h1>{homework.title}</h1>
      </div>
      <div className={styles.lastRow}>
        <h2>
          COMPLETADA{" "}
          <span style={{ color: "#6A6F8A" }}>{homework.participation}%</span>
        </h2>
        <h2>
          PROMEDIO{" "}
          <span>{homework.average ? homework.average?.toFixed(1) : 0}</span>
        </h2>
      </div>
    </div>
  );
};

export default HomeworkCard;
