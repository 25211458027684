import { useEffect, useState } from "react";
import styles from "./pointsSingleClass.module.scss";
import { getClassroomById_getClassroomById } from "../../__generated__/gql-types/getClassroomById";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../Layout/Header";
import UseGetClassroomById from "../../api/useGetClassroomById";
import { ClasificationPointsSingleClass } from "./ClasificationPointsSingleClass";
import { PointerPointsSingleClass } from "./PointerPointsSingleClass";
import { Skeleton } from "antd";

const PointsSingleClass = () => {
  const getClassroomById = UseGetClassroomById();
  let { id, tab } = useParams();
  const navigate = useNavigate();
  const [tabState, setTabState] = useState("pointer");
  const [isLoading, setIsLoading] = useState(false);
  const [classRomm, setClassRoom] =
    useState<getClassroomById_getClassroomById | null>(null);

  // changing params
  useEffect(() => {
    const nuevaUrl = `/points/${id}/${tabState}`;
    navigate(nuevaUrl);
  }, [tabState, navigate, id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (id) {
          const responseClassroom = await getClassroomById({
            classroomId: id,
          });
          setClassRoom(responseClassroom);
        }
      } catch (error) {}
      setIsLoading(false);
    })();
  }, [id, getClassroomById, setClassRoom]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.spinner}>
          <Skeleton
            active
            paragraph={{ rows: 1 }}
            style={{ margin: "24px 0" }}
          />
          <Skeleton
            active
            paragraph={{ rows: 5 }}
            style={{ margin: "24px 0" }}
          />
          <Skeleton
            active
            paragraph={{ rows: 7 }}
            style={{ margin: "24px 0" }}
          />
        </div>
      ) : (
        <>
          <Header
            title={`${classRomm?.program?.grade?.grade}° ${classRomm?.group?.name}`}
            headerWithOutMobile={true}
            back={"back_points"}
          />
          <div className={styles.botonContainer}>
            <button
              className={
                tabState === "pointer"
                  ? styles.botonSelected
                  : styles.botonDeselected
              }
              onClick={() => {
                setTabState("pointer");
              }}
            >
              Puntos
            </button>
            <button
              className={
                tabState === "clasification"
                  ? styles.botonSelected
                  : styles.botonDeselected
              }
              onClick={() => {
                setTabState("clasification");
              }}
            >
              Clasificación
            </button>
          </div>
          {tab === "pointer" ? (
            <PointerPointsSingleClass classRomm={classRomm} />
          ) : (
            <ClasificationPointsSingleClass classRomm={classRomm} />
          )}
        </>
      )}
    </div>
  );
};

export default PointsSingleClass;
