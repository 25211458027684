import React, { useEffect, useState } from "react";
import styles from "./points.module.scss";
import { Skeleton } from "antd";
import { BannerPoints } from "./BannerPoints";
import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import { getClassroomsByTeacher_getClassroomsByTeacher } from "../../__generated__/gql-types/getClassroomsByTeacher";
import { CardPoints } from "./CardPoints";
import { useNavigate } from "react-router-dom";

const Points = () => {
	const getClassroomsByTeacher = UseClassroomsByTeacher();
	const navigate = useNavigate();
	const [classRooms, setClassRooms] =
		useState<getClassroomsByTeacher_getClassroomsByTeacher[]>();

	useEffect(() => {
		(async () => {
			const classRoomRes = await getClassroomsByTeacher({
				getClassroomsByTeacherIdInput: {
					searchArgs: null,
				},
			});
			if (classRoomRes) {
				for (let i = classRoomRes.length - 1; i >= 0; i--) {
					for (let j = i - 1; j >= 0; j--) {
						if (
							classRoomRes[i] &&
							classRoomRes[j] &&
							classRoomRes[i].program?.grade?.grade ===
								classRoomRes[j].program?.grade?.grade &&
							classRoomRes[i].group?.name === classRoomRes[j].group?.name
						) {
							classRoomRes.splice(j, 1);
						}
					}
				}
			}
			if (classRoomRes?.length === 1) {
				return navigate(`/points/${classRoomRes[0].id}`, {
					state: { classRoomsIndividual: classRoomRes[0] },
				});
			}
			classRoomRes && setClassRooms(classRoomRes);
		})();
	}, [getClassroomsByTeacher, navigate]);

	return (
		<div className={styles.container}>
			{!classRooms && (
				<div className={styles.spinner}>
					<Skeleton
						active
						paragraph={{ rows: 1 }}
						style={{ margin: "24px 0" }}
					/>
					<Skeleton
						active
						paragraph={{ rows: 5 }}
						style={{ margin: "24px 0" }}
					/>
					<Skeleton
						active
						paragraph={{ rows: 7 }}
						style={{ margin: "24px 0" }}
					/>
				</div>
			)}

			{classRooms && (
				<>
					<BannerPoints />
					<div className={styles.cardsContainer}>
						{classRooms.map((classRoom) => {
							return (
								<CardPoints
									classRoomsIndividual={classRoom}
									key={classRoom.id}
								/>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
};

export default Points;
