import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getClassroomByIdVariables,
  getClassroomById,
} from "../__generated__/gql-types/getClassroomById";

const UseGetClassroomById = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getClassroomByIdVariables) => {
      const result: ApolloQueryResult<getClassroomById> = await client.query({
        query: GET_CLASSROOM_BY_ID,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClassroomById;
    },
    [client]
  );
};

export default UseGetClassroomById;

const GET_CLASSROOM_BY_ID = gql`
  query getClassroomById($classroomId: ID!) {
    getClassroomById(classroomId: $classroomId) {
      id
      name
      program {
        subject {
          name
          id
        }
        grade {
          name
          grade
        }
      }
      homeworks {
        id
        users {
          id
        }
        individualHomework
        title
        description
        status
        result
        post_homework_result
        finished
        start_date
        studentTries
        tries
        end_date
        studentEvidenceEvent
        deleted_at
        needs_file_evidence
        teacher_file_url
        lessons {
          finished
          result
          lesson_type
          subject {
            id
          }
          subject_id
          course_id
          id
          title
          description
          cover
          week
          sep_name
          sep_book_pages
          lesson_content {
            id
            quizzes {
              id
              url
              title
              filename
              order
              questions {
                answers {
                  id
                  answer
                  correct
                  order
                  image
                  url
                }
                question
                id
                image
                url
              }
            }
            videos {
              id
              url
              title
              file_id
              metadata
              imageUrl
              order
              quizzes {
                quiz {
                  id
                  url
                  title
                  filename
                  order
                  questions {
                    answers {
                      id
                      answer
                      correct
                      order
                      image
                      url
                    }
                    question
                    id
                    image
                    url
                  }
                }
                time
                time_out
              }
            }
          }
          grade {
            id
            name
            grade
          }

          themes {
            id
            name
          }
          axes {
            id
            name
          }
        }
      }
      group {
        id
        name
        users {
          id
          first_name
          last_name
          email
          avatar
          shopActions {
            updated_at
            deleted_at
            created_at
            z_index
            clothesAndAccesories_id
            avatar_id
            active
            item_img
          }
          score {
            level
          }
        }
      }
    }
  }
`;
