import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./linkWrong.module.scss";
import { useNavigate } from "react-router-dom";

interface PropsModalLinkWrong {
  open: boolean;
}
const ModalLinkWrong: FC<PropsModalLinkWrong> = ({ open }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      closable={false}
      width={"500px"}
      footer={""}
      maskClosable={false}
      centered
    >
      <div className={styles.container}>
        <div className={styles.containerTitle}>¡Enlace equivocado!</div>
        <div className={styles.containerSubTitle}>
          Parece que el enlace que te han compartido no es correcto, regresa a
          la página de tus misiones para ver tus tareas pendientes.
        </div>
        <button
          className={styles.taskButtonMision}
          onClick={() => {
            navigate("/");
          }}
        >
          VER MIS MISIONES
        </button>
      </div>
    </Modal>
  );
};

export default ModalLinkWrong;
