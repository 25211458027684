import { useEffect, useState } from "react";
import styles from "./singleCourse.module.scss";

// Components
import { Loader } from "../../../Commons";
import { Header } from "../../../Layout/Header";

// Api && Types
import { HeaderMobile } from "../../../Mobile/Headers/HeaderMobile";
import useAuth from "../../../../context/useAuth";
import { Tabs } from "./BannerCards/Tabs";
import BannerCardsNew from "./BannerCards/BannerCardsNew";
import flagMoonIcon from "../../../../assets/icons/flagMoonIcon.svg";
import rocket from "../../../../assets/images/students/banner/rocketBannerSingleCourse.svg";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useParams } from "react-router-dom";
import UseClassroomsByStudentId from "../../../../api/useGetClassroomsByStudentId";

import UseUserScore from "../../../../api/useGetUserScore";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import { encontrarValorMayor } from "../utils";
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 as PropsClassRooms } from "./../../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom as PropsHomeworks } from "./../../../../__generated__/gql-types/getHomeworksByClassroom";

interface SingleCourseProps {
  indiCourse: PropsClassRooms | null;
  homeworks: (PropsHomeworks | null)[];
  setOpenCourse: (e: boolean) => void;
}
const SingleCourse = ({
  indiCourse,
  setOpenCourse,
  homeworks,
}: SingleCourseProps) => {
  console.log({ homeworks });
  console.log({ indiCourse });
  const getUserScore = UseUserScore();
  const getClassroomsByStudentId = UseClassroomsByStudentId();
  const isMobile = useIsMobile();
  const { id } = useParams();
  const { userToken } = useAuth();
  const [contReviewStudent, setContReviewStudent] = useState(0);
  const [userScore, setUserScore] =
    useState<getUserScore_getUserScore | null>();
  const [completedTasks, setCompletedTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const [needReviewTasks, setNeedReviewTasks] = useState<
    PropsHomeworks[] | null
  >(null);
  const [itemFiltered, setItemFiltered] = useState<PropsHomeworks[] | null>(
    null
  );
  const [redactionTasks, setRedactionTasks] = useState<PropsHomeworks[] | null>(
    null
  );

  const functionPromedio = () => {
    if (indiCourse?.program?.subject?.id === "1" && userScore?.math_average) {
      return userScore?.math_average;
    }
    if (
      indiCourse?.program?.subject?.id === "2" &&
      userScore?.spanish_average
    ) {
      return userScore?.spanish_average;
    }
    if (
      indiCourse?.program?.subject?.id === "4" &&
      userScore?.science_average
    ) {
      return userScore?.science_average;
    }
    if (
      indiCourse?.program?.subject?.id === "16" &&
      userToken?.score?.english_average //BUGARESIO  //BUGGATTI
    ) {
      return userToken?.score?.english_average;
    }
    return 0;
  };

  const filteredHomeworks = homeworks.filter(
    (homework) => homework?.classroom?.id === indiCourse?.id
  );
  console.log({filteredHomeworks})

  useEffect(() => {
    (async () => {
      if (indiCourse) {
        try {
          let cont = 0;
          let misionNeed: PropsHomeworks[] = [];
          let misionCompleted: PropsHomeworks[] = [];
          let misionPen: PropsHomeworks[] = [];
          let misionRedactionFinished: PropsHomeworks[] = [];
          filteredHomeworks?.forEach((homework) => {
            const maxResult = encontrarValorMayor(
              homework?.result ?? 0,
              homework?.post_homework_result ?? 0
            );
            if (
              homework?.users?.length === 0 ||
              homework?.users?.find(
                (user) => Number(user.id) === Number(userToken?.id)
              )
            ) {
              if (
                homework?.id &&
                homework?.lessons &&
                homework?.lessons[0]?.lesson_type === "redaction" &&
                !homework?.redaction_review_by_student &&
                homework?.redaction_correction
              ) {
                cont++;
              }
              if (
                homework?.lessons &&
                homework.lessons[0].lesson_type === "redaction" &&
                homework.cta_button !== "TERMINAR" &&
                homework.cta_button !== "TERMINAREVIDENCIA" &&
                (homework?.finished || homework.cta_button === "NO ENTREGADA")
              ) {
                misionRedactionFinished.push(homework);
              }

              if (
                homework.cta_button === "TERMINAR" ||
                homework.cta_button === "TERMINAREVIDENCIA" ||
                (homework.status === "inCourse" &&
                  homework.finished !== true &&
                  (!homework?.tries ||
                    (homework?.tries &&
                      homework?.tries - Number(homework?.studentTries) > 0)))
              ) {
                misionPen.push(homework);
              }

              if (maxResult && maxResult >= 6 && homework?.finished) {
                misionCompleted.push(homework);
              }
              if (
                (!maxResult || maxResult < 6) &&
                (homework?.status === "finished" || homework?.finished)
              ) {
                misionNeed.push(homework);
              }
            }
          });
          setContReviewStudent(cont);
          setRedactionTasks(misionRedactionFinished);
          setCompletedTasks(misionCompleted);
          setNeedReviewTasks(misionNeed);
          setItemFiltered(misionPen);
        } catch (error) {
          // setOpenModalError(true);
        }
      }
    })();
  }, [getClassroomsByStudentId, id, userToken, indiCourse]);

  useEffect(() => {
    (async () => {
      try {
        const userScoreRes = await getUserScore();
        setUserScore(userScoreRes);
      } catch (error) {
        // setOpenModalError(true);
      }
    })();
  }, [getUserScore]);

  return (
    <div className={styles.container}>
      {!isMobile ? (
        <Header
          setOpenClass={setOpenCourse}
          openCourse={true}
          title={indiCourse?.name ?? ""}
          back={"mymissions"}
        />
      ) : (
        <HeaderMobile
          title={indiCourse?.name ?? ""}
          openCourse={true}
          setOpenClass={setOpenCourse}
        />
      )}
      {!indiCourse ? (
        <div className={styles.spinner}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.bannerBlue}>
            <img
              alt="space"
              src={rocket}
              className={styles.imageRocket}
              width={"100%"}
            />
            <div className={styles.containerQuads}>
              <div className={styles.quad}>
                <div className={styles.promedio}>Promedio</div>
                <span className={styles.points}>
                  {functionPromedio() ? (functionPromedio() * 10) / 10 : "--"}/
                  <span className={styles.smallPoints}>10</span>
                </span>
              </div>

              <div className={styles.secondQuad}>
                <img
                  src={flagMoonIcon}
                  alt=""
                  className={styles.imgSecondQuad}
                />
                <div className={styles.containerTextSecondQuad}>
                  <div className={styles.contMisions}>
                    {completedTasks?.length} Misiones
                  </div>
                  <div className={styles.textSecondQuad}>
                    Superadas con éxito{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BannerCardsNew itemFiltered={itemFiltered} />
          <Tabs
            contReviewStudent={contReviewStudent}
            redactionTasks={redactionTasks}
            completedTasks={completedTasks}
            needReviewTasks={needReviewTasks}
          />
        </>
      )}
    </div>
  );
};

export default SingleCourse;
