import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  GetTeacherStarRankByTeacherId,
  GetTeacherStarRankByTeacherIdVariables,
} from "../__generated__/gql-types/GetTeacherStarRankByTeacherId";

const UseGetStartRankByTeacher = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: GetTeacherStarRankByTeacherIdVariables) => {
      const result: ApolloQueryResult<GetTeacherStarRankByTeacherId> =
        await client.query({
          query: GET_START_RANK_BY_TEACHER_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getTeacherStarRankByTeacherId;
    },
    [client]
  );
};

export default UseGetStartRankByTeacher;

const GET_START_RANK_BY_TEACHER_ID = gql`
  query GetTeacherStarRankByTeacherId($getTeacherStarRankByTeacherIdId: ID) {
    getTeacherStarRankByTeacherId(id: $getTeacherStarRankByTeacherIdId) {
      user_id
      updated_at
      total_stars
      streak_of_weeks
      start_date
      stars
      id
      end_date
      deleted_at
      created_at
    }
  }
`;
