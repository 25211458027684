import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalFreemiun.module.scss";
import lucaBot from "./../../../assets/images/lucaBot.png";
interface PropsModalFreemiun {
  open: boolean;
  onClose?: () => void;
}

const ModalFreemiun: FC<PropsModalFreemiun> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      closable={onClose ? true : false}
      width={"400px"}
      maskStyle={{ width: "calc(100% - 245px)", left: "auto" }}
      footer={false}
      centered
      className={"freemiunModal"}
      wrapProps={{
        style: { width: "calc(100% - 245px)", left: "auto" }, // Puedes agregar estilos personalizados
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.containerPro}> PRO</div>
          <div className={styles.title}>Luca Premium</div>
          <img src={lucaBot} alt="" />
          <div className={styles.subTitle}>
            Contenido y Funcionalidades solo disponible para Colegios con Cuenta
            Premium. Pide a tu colegio nos contacte
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFreemiun;
