import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useCloseFinishTorunament = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: UPDATE_CLOSE_FINISH,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};

export default useCloseFinishTorunament;

const UPDATE_CLOSE_FINISH = gql`
  mutation closeFinishTournament {
    closeFinishTournamentModal {
      name
      id
    }
  }
`;
