import React from "react";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any
): React.CSSProperties => ({
  userSelect: "none",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  alignSelf:"center",

  height: "85px",
  border: "none",
  borderBottom: " 5px solid #ffa11d",
  borderRadius: "15px",
  background: "#ffc95c",
  minWidth: "110px",
  minHeight: "60px",
  padding: 20,
  margin: `0 0 8px 0`,
  fontFamily: "Poppins-Medium",
  fontWeight: "500",
  fontSize: "24px",
  color: "#333333",
  //  background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
 
});

interface DraggableTokenProps {
  id: string;
  content: string;
  index: number;
}

export const DraggableToken: React.FC<DraggableTokenProps> = (props) => {
  return (
    <div >
      
      <Draggable
        draggableId={props.id}
        index={props.index}
        // isDragDisabled={false}
      >
        {(provided: any, snapshot: any) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {props.content}
          </div>
        )}
      </Draggable>
    </div>
  );
};
