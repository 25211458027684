import React, { FC } from "react";
import styles from "./tabQuiz.module.scss";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import { Questions } from "./Questions";
import { Relate } from "./Relate";
import { Fill } from "./Fill";
import { FraseDrag } from "./FraseDrag";
import { MatchingTextQuiz } from "./MatchingTextQuiz";
interface PropsQuiz {
  individualLesson: getLessonById_getLessonById | null;
}
const TabQuiz: FC<PropsQuiz> = ({ individualLesson }) => {
  const isMobile = useIsMobile();
  return (
    <div className={styles.paddingContainer}>
      {isMobile ? (
        <div className={styles.mobileQuiz}>
          Accede a la plataforma web para ver las preguntas del Quiz y sus
          respuestas correctas en detalle.
        </div>
      ) : (
        individualLesson?.lesson_content?.quizzes?.map((quiz) => {
          return quiz?.questions?.map((question, index) => {
            if (question.type_id === 2 || question.type_id === 1) {
              return (
                <div className={styles.questionsContainer}>
                  <Questions
                    question={question}
                    index={index}
                    subject_id={individualLesson.subject_id}
                  />
                </div>
              );
            }
            if (question.type_id === 6) {
              return (
                <div className={styles.questionsContainer}>
                  <Relate
                    question={question}
                    index={index}
                    subject_id={individualLesson.subject_id}
                  />
                </div>
              );
            }
            if (question.type_id === 4) {
              return (
                <div className={styles.questionsContainer}>
                  <Fill
                    question={question}
                    index={index}
                    subject_id={individualLesson.subject_id}
                  />
                </div>
              );
            }
            if (question.type_id === 7) {
              return (
                <div className={styles.questionsContainer}>
                  <FraseDrag
                    question={question}
                    index={index}
                    subject_id={individualLesson.subject_id}
                  />
                </div>
              );
            }
            if (question.type_id === 5) {
              return (
                <div className={styles.questionsContainer}>
                  <MatchingTextQuiz
                    question={question}
                    index={index}
                    subject_id={individualLesson.subject_id}
                  />
                </div>
              );
            }
          });
        })
      )}
    </div>
  );
};

export default TabQuiz;
