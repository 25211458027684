import { FC, useState } from "react";
import { Collapse } from "antd";
import styles from "./activefilters.module.scss";

// Icons
import gradeIcon from "../../../assets/icons/filters/grade.svg";
import gradeIconSelected from "../../../assets/icons/filters/grade-selected.svg";
import paginaIcon from "../../../assets/icons/filters/pagina.svg";
import paginaSelected from "../../../assets/icons/filters/pagina-selected.svg";
import dosificacionIcon from "../../../assets/icons/filters/dosificacion.svg";
import closeBubbleIcon from "../../../assets/icons/filters/close-bubble.svg";
import dosificacionSelected from "../../../assets/icons/filters/dosificacion-selected.svg";
import useAuth from "../../../context/useAuth";
const { Panel } = Collapse;
interface ActiveFiltersProps {
  setGrade: (e: number) => void;
  grade?: number;
  setBlock: (e: number) => void;
  block?: number;
  setSelectedWeek: (value: string) => void;
  weekList?: string[];
  selectedWeek?: string;
  setSepBookPage: (value: string) => void;
  sepBookPagesList?: string[];
  sepBookPage?: string;
  type?: string;
}

const ActiveFilters: FC<ActiveFiltersProps> = ({
  type,
  setSepBookPage,
  sepBookPagesList,
  sepBookPage,
  weekList,
  selectedWeek,
  setSelectedWeek,
  setGrade,
  grade,
  setBlock,
}) => {
  const { userToken } = useAuth();
  const [panelKey, setPanelKey] = useState<string | string[]>("1");
  const handleCleanAll = () => {
    setSepBookPage("");
    setSelectedWeek("");
    setGrade(0);
    setBlock(0);
  };

  const showSepPages = userToken?.schools[0].show_sep_pages;
  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterContent}>
        <div className={styles.filtersAppliedContainer}>
          <div>Filtros aplicados</div>

          <div className={styles.deleteAll} onClick={handleCleanAll}>
            Borrar todo
          </div>
        </div>
        <div className={styles.bubblesContainer} style={{ marginTop: "3%" }}>
          {grade ? (
            <button
              className={styles.bubbleSelected}
              onClick={() => {
                setGrade(0);
              }}
            >
              {grade}º grado
              <img src={closeBubbleIcon} alt="" />{" "}
            </button>
          ) : null}
          {selectedWeek ? (
            <button
              className={styles.bubbleSelected}
              onClick={() => {
                setSelectedWeek("");
              }}
            >
              {" "}
              Sem. {selectedWeek} <img src={closeBubbleIcon} alt="" />{" "}
            </button>
          ) : null}
          {sepBookPage ? (
            <button
              className={styles.bubbleSelected}
              onClick={() => {
                setSepBookPage("");
              }}
            >
              {" "}
              Pág. {sepBookPage} <img src={closeBubbleIcon} alt="" />{" "}
            </button>
          ) : null}
        </div>
      </div>
      <Collapse
        ghost
        activeKey={panelKey}
        expandIconPosition={"end"}
        style={{ width: "100%" }}
        onChange={(key: string | string[]) => {
          setPanelKey(key.length > 0 ? key[key.length - 1] : key);
        }}
      >
        {type !== "recommended" && (
          <Panel
            key="1"
            header={
              <div className={styles.filterTitle}>
                {panelKey.includes("1") ? (
                  <>
                    <img src={gradeIconSelected} alt="grade icon selected" />
                    <h1 className={styles.titleSelected}>Grado </h1>
                  </>
                ) : (
                  <>
                    <img src={gradeIcon} alt="grade icon" />
                    <h1>Grado </h1>
                  </>
                )}
              </div>
            }
          >
            <div className={styles.bubblesContainer}>
              {userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                <button
                  className={
                    grade === 4 ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    setGrade(4);
                  }}
                >
                  {" "}
                  4º grado
                </button>
              )}

              {userToken?.schools[0].name !== "Colegio Iluminación" && (
                <button
                  className={
                    grade === 5 ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    setGrade(5);
                  }}
                >
                  {" "}
                  5º grado
                </button>
              )}
              {userToken?.schools[0].name !== "Colegio Iluminación" &&
                userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                  <button
                    className={
                      grade === 6 ? styles.bubbleSelected : styles.bubble
                    }
                    onClick={() => {
                      setGrade(6);
                    }}
                  >
                    {" "}
                    6º grado
                  </button>
                )}
            </div>
          </Panel>
        )}
        {showSepPages && (
          <Panel
            key="3"
            header={
              <div className={styles.filterTitle}>
                {panelKey.includes("3") ? (
                  <>
                    <img
                      src={paginaSelected}
                      alt="Páginas del libro selected "
                    />
                    <h1 className={styles.titleSelected}>Páginas del libro </h1>
                  </>
                ) : (
                  <>
                    <img src={paginaIcon} alt="Páginas del libro " />
                    <h1>Páginas del libro </h1>
                  </>
                )}
              </div>
            }
          >
            <div className={styles.bubblesContainer}>
              {sepBookPagesList?.map((page, i) => {
                return (
                  <button
                    className={
                      sepBookPage === page
                        ? styles.bubbleSelected
                        : styles.bubble
                    }
                    key={i}
                    onClick={() => {
                      setSepBookPage(page);
                    }}
                  >
                    {" "}
                    {page}
                  </button>
                );
              })}
            </div>
          </Panel>
        )}
        <Panel
          key="4"
          header={
            <div className={styles.filterTitle}>
              {panelKey.includes("4") ? (
                <>
                  <img
                    src={dosificacionSelected}
                    alt="dosificación icon selected"
                  />
                  <h1 className={styles.titleSelected}>Dosificación </h1>
                </>
              ) : (
                <>
                  <img src={dosificacionIcon} alt="dosificación icon" />
                  <h1> Dosificación </h1>
                </>
              )}
            </div>
          }
        >
          <div className={styles.bubblesContainer}>
            {weekList?.map((week: any, i) => {
              return (
                <button
                  className={
                    selectedWeek === week
                      ? styles.bubbleSelected
                      : styles.bubble
                  }
                  key={i}
                  onClick={() => {
                    setSelectedWeek(week);
                  }}
                >
                  Semana {week}
                </button>
              );
            })}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ActiveFilters;
