import React from "react";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { getLessonById_getLessonById_lesson_content_quizzes_questions_answers } from "../../../../__generated__/gql-types/getLessonById";
import { PropsColumns } from "./newUtils";
import styles from "./newFraseDrag.module.scss";

interface TaskProps {
  alertTrue: boolean;
  alertFalse: boolean;
  alertMid: boolean;
  columnId: number;
  task: getLessonById_getLessonById_lesson_content_quizzes_questions_answers;
  index: number;
  columns: PropsColumns[];
}

export const Task: React.FC<TaskProps> = ({
  task,
  index,
  alertFalse,
  alertTrue,
  alertMid,
  columnId,
  columns,
}) => {
  const answerCorretOrFalse1 =
    task.correct && task.order === 0 && task.id === columns[0].taskIds[0]
      ? true
      : false;
  const answerCorretOrFalse2 =
    task.correct && task.order === 1 && task.id === columns[1].taskIds[0]
      ? true
      : false;

  const functionReturnBackground2 = () => {
    return (alertTrue || alertFalse || alertMid) &&
      (columnId === 1 || columnId === 2)
      ? task.correct
        ? answerCorretOrFalse1 || answerCorretOrFalse2
          ? "#a1d76f"
          : "#ff8283"
        : "#ff8283"
      : !(alertTrue || alertFalse || alertMid)
      ? "#ffc95c"
      : task.correct
      ? "#a1d76f"
      : "#DADBE7";
  };
  const functionReturnBackground1 = () => {
    return (alertTrue || alertFalse || alertMid) && columnId === 1
      ? task.correct
        ? "#a1d76f"
        : "#ff8283"
      : !(alertTrue || alertFalse || alertMid)
      ? "#ffc95c"
      : task.correct
      ? "#a1d76f"
      : "#DADBE7";
  };
  const getItemStyle = (
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => ({
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: " 110px",
    border: " none",
    borderBottom: columns[2]
      ? functionReturnBackground2()
      : functionReturnBackground1(),
    borderRadius: "15px",
    background: columns[2]
      ? functionReturnBackground2()
      : functionReturnBackground1(),
    minHeight: "60px",
    padding: 8,
    margin: `0 0 8px 0`,

    ...draggableStyle,
  });
  return (
    <Draggable
      draggableId={task.id}
      index={index}
      isDragDisabled={alertFalse || alertTrue || alertMid}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getItemStyle(provided.draggableProps.style)}
          className={styles.fontResponsive}
        >
          {task.answer}
        </div>
      )}
    </Draggable>
  );
};
