import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { studentReviewHomeworkVariables } from "../__generated__/gql-types/studentReviewHomework";

const UseStudentReviewHomework = () => {
  const client = useApolloClient();

  return useCallback(
    async (homeworkInput: studentReviewHomeworkVariables) => {
      return await client.mutate({
        mutation: CREATE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: homeworkInput,
      });
    },
    [client]
  );
};
export default UseStudentReviewHomework;

const CREATE_HOMEWORK = gql`
  mutation studentReviewHomework($homeworkId: ID!) {
    studentReviewHomework(homeworkId: $homeworkId) {
      redaction_review_by_student
    }
  }
`;
