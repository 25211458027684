import styles from "./evidence.module.scss";
import attach from "../../../../../../../../assets/icons/dock.svg";
import cross from "../../../../../../../../assets/icons/close.svg";

interface PropsEvidence {
  file: File | null;
  setFile: (e: File | null) => void;
}
const Evidence = ({ file, setFile }: PropsEvidence) => {
  return file && file.name?.length > 0 ? (
    <div className={styles.container}>
      <div className={styles.containerFile}>
        <div className={styles.firstColumnContainer}>
          <img src={attach} alt="" />
        </div>
        <div className={styles.secondColumnContainer}>
          <div className={styles.containerText}>
            <div className={styles.titleFile}>
              {file?.name ? file.name : ""}
            </div>
            <div className={styles.extensionFile}>
              {file?.type ? file.type : ""}
            </div>
          </div>
          <div
            className={styles.crossInstructions}
            onClick={() => setFile(null)}
          >
            <img src={cross} alt="cross" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Evidence;
