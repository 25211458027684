import React, { FC, useState } from "react";
import { Button, Drawer, Form, Input, Modal, Space } from "antd";

import styles from "./studentsAccountModal.module.scss";
import {
  NotificationPositive,
  notificationNegative,
} from "../../Shared/Notifacation/NotificationLuca";
import cross from "../../../assets/images/cross.svg";
import UseAddStudentByTeacher from "../../../api/useAddStudentByTeacher";
import UseGetClassroomById from "../../../api/useGetClassroomById";
import { useLocation } from "react-router-dom";
import UseUpdateStudentByTeacher from "../../../api/useUpdateStudentBtTeacher";
import UseDeleteStudentByTeacher from "../../../api/useDeleteStudent";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import useIsMobile from "../../../hooks/useIsMobile";
interface IStudentsAccountModalProps {
  visible: boolean;
  setAddStudentModal: (value: boolean) => void;
  classRoom: getClassroomWithHomeworks_getClassroomWithHomeworks | null;
  student?: any;
  setStudents: (value: any) => void;
  setIsStudentsTableLoading: (value: boolean) => void;
}

const StudentsAccountModal: FC<IStudentsAccountModalProps> = ({
  visible,
  setAddStudentModal,
  classRoom,
  student,
  setStudents,
  setIsStudentsTableLoading,
}) => {
  const locatione = useLocation();

  const registerStudent = UseAddStudentByTeacher();
  const updateStudent = UseUpdateStudentByTeacher();
  const deleteStudent = UseDeleteStudentByTeacher();
  const getClassroomById = UseGetClassroomById();
  const isMobile = useIsMobile();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [deleteStudentPressed, setDeleteStudentPressed] = useState(false);
  const [studentComparePassword, setStudentComparePassword] = useState("");
  const [studentData, setStudentData] = useState({
    first_name: student && student[0]?.first_name ? student[0].first_name : "",
    last_name: student && student[0]?.last_name ? student[0].last_name : "",
    email: student && student[0]?.email ? student[0].email : "",
    password: "",
    role: "student",
    groups: [
      {
        id: classRoom?.classroom?.group?.id,
      },
    ],
    schools: classRoom?.classroom?.school?.id
      ? [
          {
            id: classRoom?.classroom.school?.id,
          },
        ]
      : undefined,
    teacher_password: "",
    student_id: student ? parseInt(student ? student[0]?.id : "") : null,
  } as any);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  //handle delete student
  const handleOk = () => {
    setDeleteStudentPressed(true);
    setIsPasswordModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsPasswordModalVisible(false);
  };

  const handleSubmit = async () => {
    //TODO: separar en dos funciones: registrar y actualizar (no vale meter student_id en los param de createUser)
    try {
      //si no viene el student, creamos uno nuevo
      if (student === undefined) {
        const studentRegistered = await registerStudent(studentData);
        if (studentRegistered && studentRegistered.data !== null) {
          setIsStudentsTableLoading(true);
          NotificationPositive(
            "¡Estupendo! El alumno ha sido registrado con éxito."
          );
          setIsStudentsTableLoading(true);
        } else {
          notificationNegative(
            "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."
          );
        }
        const classroomId = locatione.pathname.split("/")[2].toString();
        setIsStudentsTableLoading(true);
        const newStudentsList = await getClassroomById({
          classroomId: classroomId,
        });
        newStudentsList &&
          setStudents(
            newStudentsList?.group?.users?.map((user: any) => {
              if (user.avatar === null) {
                user.avatar = "spica";
              }
              return user;
            })
          );
        setAddStudentModal(false);
        setIsStudentsTableLoading(false);
        return;
      } else if (student !== undefined && !deleteStudentPressed) {
        if (studentData.password.length === 0) delete studentData.password;
        const studentUpdated = await updateStudent(studentData);
        studentUpdated.data
          ? NotificationPositive(
              "¡Estupendo! El alumno ha sido actualizado con éxito."
            )
          : notificationNegative(
              "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."
            );
        const classroomId = locatione.pathname.split("/")[2].toString();
        const newStudentsList = await getClassroomById({
          classroomId: classroomId,
        });
        newStudentsList &&
          setStudents(
            newStudentsList?.group?.users?.map((user: any) => {
              if (user.avatar === null) {
                user.avatar = "spica";
              }
              return user;
            })
          );
        setAddStudentModal(false);
        //eliminamos student
      } else if (deleteStudentPressed) {
        if (studentData?.password?.length === 0) {
          delete studentData.password;
        }
        const studentUpdated = await deleteStudent(studentData);
        studentUpdated.data
          ? NotificationPositive(
              "¡Estupendo! El alumno ha sido eliminado con éxito."
            )
          : notificationNegative(
              "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."
            );
        const classroomId = locatione.pathname.split("/")[2].toString();
        const newStudentsList = await getClassroomById({
          classroomId: classroomId,
        });
        newStudentsList &&
          setStudents(
            newStudentsList?.group?.users?.map((user: any) => {
              if (user.avatar === null) {
                user.avatar = "spica";
              }
              return user;
            })
          );
        setAddStudentModal(false);
      }
    } catch (error: any) {
      notificationNegative(
        `¡Ups! ${error.toString().slice(19, error.toString().length)}`
      );
    }
  };
  return (
    <Drawer
      height={isMobile ? "500px" : ""}
      placement={isMobile ? "bottom" : "right"}
      open={visible}
      closable={false}
      bodyStyle={{ background: "#F5F5F9" }}
      title={
        <div
          style={{
            height: "130px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2>{classRoom?.classroom?.name && classRoom.classroom.name}</h2>
          <p>{classRoom?.classroom?.school?.name}</p>
        </div>
      }
      extra={
        <Space>
          <img
            src={cross}
            alt=""
            className={styles.crossImg}
            onClick={() => {
              setAddStudentModal(false);
            }}
          />
        </Space>
      }
      headerStyle={{
        background: "#fff",
        width: "100%",
        height: "100%",
      }}
      footer={
        <div className={styles.footer}>
          <Button
            className={styles.taskButton}
            style={{
              backgroundColor: "rgb(255, 188, 65)",
              borderRadius: "11px",
              width: "160px",
              textAlign: "center",
            }}
            onClick={() => {
              if (
                studentData.password.length > 0 &&
                studentComparePassword === studentData.password
              ) {
                setIsPasswordModalVisible(true);
              } else if (studentData.password.length === 0) {
                setIsPasswordModalVisible(true);
              } else {
                notificationNegative(
                  "¡Ups! Las contraseñas no coinciden. Por favor, vuelve a intentarlo."
                );
              }
            }}
          >
            {student ? "Guardar Cambios" : "Añadir Alumno"}
          </Button>

          {student && (
            <Button
              className={styles.taskButton}
              style={{
                borderColor: "rgb(255, 188, 65)",
                borderWidth: "2px",
                borderRadius: "11px",
                width: "160px",
                marginRight: "10px",
              }}
              onClick={() => setIsModalVisible(true)}
            >
              Eliminar Alumno
            </Button>
          )}
        </div>
      }
    >
      <Form
        layout="vertical"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 1000, padding: isMobile ? 12 : 0 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Nombre de Alumno" name="nombre de alumno">
          <Input
            placeholder={
              student?.length && student[0].first_name
                ? student[0].first_name
                : "Nombre"
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentData({
                  ...studentData,
                  first_name: e.target.value,
                });
              } else {
                setStudentData({
                  ...studentData,
                  first_name:
                    student && student[0]?.first_name
                      ? student[0].first_name
                      : "",
                });
              }
            }}
            className={styles.input}
            defaultValue={
              student && student[0]?.first_name ? student[0].first_name : ""
            }
          />
        </Form.Item>

        <Form.Item label="Apellido" name="apellido">
          <Input
            placeholder={
              student?.length && student[0].last_name
                ? student[0].last_name
                : "Apellido" //hacer logica de extraer apellido
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentData({ ...studentData, last_name: e.target.value });
              } else {
                setStudentData({
                  ...studentData,
                  last_name:
                    student && student[0]?.last_name
                      ? student[0].last_name
                      : "",
                });
              }
            }}
            className={styles.input}
            defaultValue={
              student && student[0]?.last_name ? student[0].last_name : ""
            }
          />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input
            placeholder={
              student?.length && student[0].email ? student[0].email : "Email"
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentData({ ...studentData, email: e.target.value });
              } else {
                setStudentData({
                  ...studentData,
                  email: student && student[0]?.email ? student[0].email : "",
                });
              }
            }}
            className={styles.input}
            defaultValue={student && student[0]?.email ? student[0].email : ""}
          />
        </Form.Item>
        <Form.Item label="Contraseña del Alumno" name="password">
          <Input.Password
            placeholder={
              student?.length && student[0].first_name?.length
                ? "******"
                : "Contraseña"
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentData({ ...studentData, password: e.target.value });
              } else {
                setStudentData({
                  ...studentData,
                  password:
                    student && student[0]?.password ? student[0].password : "",
                });
              }
            }}
            className={styles.input}
          />
        </Form.Item>
        <Form.Item
          label="Repite la contraseña del Alumno"
          name="student_compare_password"
        >
          <Input.Password
            placeholder={
              student?.length && student[0].first_name?.length
                ? "******"
                : "Contraseña"
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentComparePassword(e.target.value);
              }
            }}
            className={styles.input}
          />
        </Form.Item>
      </Form>
      <Modal
        title="Eliminar alumno"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: "20px" }}
        cancelButtonProps={{
          shape: "round",
        }}
        okButtonProps={{
          shape: "round",
        }}
      >
        <p>¿Estas seguro que deseas eliminar el alumno?</p>
      </Modal>
      <Modal
        title="Ingresa tu contraseña de profesor para confirmar los cambios"
        visible={isPasswordModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        style={{ borderRadius: "20px" }}
        cancelButtonProps={{
          shape: "round",
        }}
        okButtonProps={{
          shape: "round",
        }}
      >
        <Form.Item name="teacher_password">
          <Input.Password
            placeholder={"******"}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setStudentData({
                  ...studentData,
                  teacher_password: e.target.value,
                });
              }
            }}
            className={styles.teacherPasswordInput}
          />
        </Form.Item>
      </Modal>
    </Drawer>
  );
};

export default StudentsAccountModal;
