import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getClassroomWithHomeworksByDashboard } from "../__generated__/gql-types/getClassroomWithHomeworksByDashboard";

const UseClassroomWithHomeworksByDashboard = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClassroomWithHomeworksByDashboard> =
      await client.query({
        query: GET_CLASSES_BY_DASHBOARD,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClassroomWithHomeworksByDashboard;
  }, [client]);
};

export default UseClassroomWithHomeworksByDashboard;

const GET_CLASSES_BY_DASHBOARD = gql`
  query getClassroomWithHomeworksByDashboard {
    getClassroomWithHomeworksByDashboard {
      homeworksInCourse
      homeworks {
        participation
        lesson_type
        label
        grade
        dashboard_visible
        average
        title
        id
        subject_id
        end_date
        start_date
        status
      }
    }
  }
`;
