import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState = {
  status: "",
  startDate: "",
  endDate: "",
};

export const homeworkFilterSlice = createSlice({
  name: "homeworkFilterParams",
  initialState,
  reducers: {
    setHomeworkFilterParams: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
    }),

    getHomeworkFilterParams: () =>{return initialState},
  },
});
export const { setHomeworkFilterParams,getHomeworkFilterParams } = homeworkFilterSlice.actions;
export default homeworkFilterSlice.reducer;
export const homeworkFilterParams = (state: any) => state;
