import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherEditSlidesVariables } from "../__generated__/gql-types/teacherEditSlides";

const useTeacherEditSlides = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherEditSlidesVariables) => {
      return await client.mutate({
        mutation: TEACHER_EDIT_SLIDES,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherEditSlides;

const TEACHER_EDIT_SLIDES = gql`
  mutation teacherEditSlides($contentId: Int) {
    teacherEditSlides(contentId: $contentId)
  }
`;
