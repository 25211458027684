import { Modal } from "antd";
import React, { FC, useState } from "react";
import styles from "./modalRedaction.module.scss";

interface PropsModalConfirmation {
  open: boolean;
  onCloseModal: () => void;
  handleSendOrSave: (isPartialRedaction: boolean) => Promise<void>;
}

const ModalRedaction: FC<PropsModalConfirmation> = ({
  open,
  onCloseModal,
  handleSendOrSave,
}) => {
  const [isAllow, setisAllow] = useState(true);
  return (
    <Modal
      open={open}
      onCancel={onCloseModal}
      closable={false}
      width={"500px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        <div className={styles.containerText}>
          <div className={styles.containerTitle}>
            ¿Seguro que quieres enviar la redacción?
          </div>

          <div className={styles.containerSubTitle}>
            Cuando la envíes, ya no podrás modificarla y se entregará a tu
            maestro para que la revise.
          </div>
        </div>

        <div className={styles.footerDetail}>
          <button className={styles.taskButtonDelete} onClick={onCloseModal}>
            CANCELAR
          </button>

          <button
            className={styles.taskButtonMision}
            onClick={async () => {
              setisAllow(false);
              isAllow && (await handleSendOrSave(false));
            }}
          >
            ENVIAR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRedaction;
