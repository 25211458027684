import * as Yup from "yup";

export const initialValuesObj: any = {
  nameTeacher: "",
  emailOrUsername: "",
  password: "",
  remember: true,
  phoneTeacher: "",
  cityTeacher: "",
  countryTeacher: "",
};

export const LoginFormSchema = Yup.object().shape({
  emailOrUsername: Yup.string().required("El usuario es requerido."),
  nameTeacher: Yup.string().required("El nombre es requerido."),
  // cityTeacher: Yup.string().required("El nombre es requerido."),
  // countryTeacher: Yup.string().required("El nombre es requerido."),
  // gradeTeacher: Yup.string().required("El Grado es requerido."),
  schoolTeacher: Yup.string().required("La escuela es requerida."),
  phoneTeacher: Yup.string()
    .required("El telefono es requerido.")
    .length(10, "La longitud debe ser de 10 numero "),
  password: Yup.string()
    .required("La password es requerida.")
    .min(6, "La longitud mínima de la contraseña es 6."),
});
