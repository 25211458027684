import React, { FC } from "react";
import { Modal } from "antd";
import styles from "./modalAccessory.module.scss";
import glass from "../../../../../assets/pages/store/glassModal.png";
import gemModal from "../../../../../assets/pages/store/gemModal.png";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../../../../__generated__/gql-types/getClothesAndAccessories";
import UseBuyItemFromStore from "../../../../../api/useBuyItemFromStore";
import UseGetClothesAndAccessories from "../../../../../api/useGetClothesAndAccessories";
import { getUserScore_getUserScore } from "../../../../../__generated__/gql-types/getUserScore";
import UseUserScore from "../../../../../api/useGetUserScore";
import useIsMobile from "../../../../../hooks/useIsMobile";

interface PropsModalAccessory {
  openModal: boolean;
  onCloseModalAccessory: () => void;
  clothes: getClothesAndAccessories_getClothesAndAccessories | null;
  setClothesAndAccessories: (
    e: getClothesAndAccessories_getClothesAndAccessories[] | null
  ) => void;
  setUserScore: (e: getUserScore_getUserScore | undefined) => void;
  userScore: getUserScore_getUserScore | undefined;
}
const ModalAccessory: FC<PropsModalAccessory> = ({
  openModal,
  onCloseModalAccessory,
  clothes,
  setClothesAndAccessories,
  setUserScore,
  userScore,
}) => {
  const isMobile = useIsMobile();
  const buyItemFunction = UseBuyItemFromStore();
  const getClothesAndAccessories = UseGetClothesAndAccessories();
  const getUserScore = UseUserScore();
  const allowBuy =
    clothes?.price &&
    userScore?.user?.avatar_state?.available_balance &&
    userScore.user?.avatar_state?.available_balance - clothes?.price >= 0
      ? true
      : false;

  const handleBuyItem = async () => {
    try {
      if (clothes?.id) {
        await buyItemFunction({
          itemId: clothes?.id.toString(),
        });
        const response = await getClothesAndAccessories();
        setClothesAndAccessories(response);
        const userScoreRes = await getUserScore();
        userScoreRes && setUserScore(userScoreRes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={openModal}
      onCancel={onCloseModalAccessory}
      closable={false}
      width={isMobile ? "100%" : "649px"}
      footer={""}
      className={styles.container}
      centered
    >
      <div className={styles.content}>
        <div className={styles.title}> {clothes?.name}</div>
        <img
          src={clothes?.thumbnail_img ?? glass}
          alt="glass"
          className={styles.glassImg}
        />
        <div
          className={
            allowBuy ? styles.containerGem : styles.containerGemNotAllow
          }
        >
          <div className={styles.gemText}>
            {allowBuy ? "Usarás" : "¡No tienes suficientes cristales!"}{" "}
          </div>
          <div className={styles.gemText}>
            {clothes?.price}
            <img src={gemModal} alt="gen" />
          </div>
        </div>
        <div className={styles.contanerButton}>
          <div className={styles.buttonBack} onClick={onCloseModalAccessory}>
            VOLVER
          </div>
          <div
            className={allowBuy ? styles.buttonBuy : styles.buttonNotAllow}
            onClick={() => {
              if (allowBuy) {
                handleBuyItem();
                onCloseModalAccessory();
              }
            }}
          >
            COMPRAR
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAccessory;
