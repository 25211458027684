import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalConexion.module.scss";

interface PropsModalConexion {
  isOnline: boolean;
}

const ModalConexion: FC<PropsModalConexion> = ({ isOnline }) => {
  return (
    <Modal
      open={isOnline}
      closable={false}
      width={"500px"}
      footer={""}
      centered
      className={"modalConexion"}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {/* <img src={notConexion} alt="default" /> */}
          <div className={styles.iconWifi}>❗</div>
          <div className={styles.title}>Conexión inestable a internet</div>
          <div className={styles.subtitle}>
            Por favor, resuelve tu conexión a internet para poder continuar
            utilizando Luca.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConexion;
