import React, { FC, useEffect, useState } from "react";
import { getHomeworkById_getHomeworkById } from "../../../../../__generated__/gql-types/getHomeworkById";
import { Drawer, Skeleton } from "antd";
import { HeaderStudentResponse } from "./HeaderStudentResponse";
import { MainStudentResponse } from "./MainStudentResponse";
import useIsMobile from "../../../../../hooks/useIsMobile";
import UseGetResponseByHomeworkAndUserV2 from "../../../../../api/useGetResponseByUserAndHomeworkV2";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsDrawerConversation {
  setVisibleModalStudentResponse: React.Dispatch<React.SetStateAction<boolean>>;
  visibleModalStudentResponse: boolean;
  task: getHomeworkById_getHomeworkById | null;
  userId: number | null;
}

const DrawerStudentResponse: FC<PropsDrawerConversation> = ({
  setVisibleModalStudentResponse,
  task,
  userId,
  visibleModalStudentResponse,
}) => {
  const isMobile = useIsMobile();
  const getResponseByHomeworkAndUserV2 = UseGetResponseByHomeworkAndUserV2();
  const onClose = () => {
    setVisibleModalStudentResponse(false);
  };
  const [responseByHomeworkAndUser, setResponseByHomeworkAndUser] = useState<
    getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getResponseByHomeworkAndUserV2({
          homeworkId: Number(task?.id),
          userId: userId,
        });
        setResponseByHomeworkAndUser(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Drawer
      width={920}
      placement={isMobile ? "bottom" : "right"}
      height={isMobile ? "500px" : ""}
      onClose={onClose}
      open={visibleModalStudentResponse}
      closable={false}
      title={
        !isLoading ? (
          <HeaderStudentResponse
            task={task}
            onCloseDrawer={onClose}
            userId={userId}
          />
        ) : (
          <Skeleton paragraph={{ rows: 1 }} />
        )
      }
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <MainStudentResponse
          responseByHomeworkAndUser={responseByHomeworkAndUser}
        />
      )}
    </Drawer>
  );
};

export default DrawerStudentResponse;
