import React, { FC } from "react";
import styles from "./titleLessonNew.module.scss";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { Tag } from "antd";
import thumbnailClase from "../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailDynamics from "../../../../assets/images/thumbnail/thumbnailDynamics.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import thumbnailListening from "../../../../assets/images/thumbnail/thumbnailAudio.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/thumbnailBookPages.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import examenSpain from "../../../../assets/images/thumbnail/coverExamSpain.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import listeningExam from "../../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../../assets/images/thumbnail/writing_exam.png";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";

interface PropsTitleLessonNew {
  individualLesson: getLessonById_getLessonById | null;
  onClose: () => void;
}
const TitleLessonNew: FC<PropsTitleLessonNew> = ({
  individualLesson,
  onClose,
}) => {
  return (
    <div className={styles.task}>
      <div className={styles.firstTaskColumn}>
        <div className={styles.pictureContainer}>
          <img
            src={
              individualLesson &&
              individualLesson.lesson_content?.videos?.length &&
              individualLesson.lesson_content?.videos[0]?.imageUrl
                ? individualLesson.lesson_content?.videos[0].imageUrl
                : individualLesson?.lesson_type === "literacy" &&
                  individualLesson?.lesson_content?.literacy?.cover
                ? individualLesson?.lesson_content?.literacy?.cover
                : individualLesson?.lesson_type === "classroomguide"
                ? thumbnailClase
                : individualLesson?.lesson_type === "manipulative_dynamics"
                ? thumbnailDynamics
                : individualLesson?.lesson_type === "audio"
                ? thumbnailListening
                : individualLesson?.lesson_type === "book_pages"
                ? thumbnailBookPages
                : individualLesson?.lesson_type === "slides"
                ? thumbnailDiapositivas
                : individualLesson?.lesson_type === "evaluation" &&
                  individualLesson.subject_id === 1
                ? thumbnailMate
                : individualLesson?.lesson_type === "evaluation" &&
                  individualLesson.subject_id === 4
                ? thumbnailCiencias
                : individualLesson?.lesson_type === "evaluation" &&
                  individualLesson.subject_id === 2
                ? thumbnailEspañol
                : individualLesson?.lesson_type === "redaction" &&
                  individualLesson.subject_id === 1
                ? ensayoMatematica
                : individualLesson?.lesson_type === "redaction" &&
                  individualLesson.subject_id === 4
                ? ensayoCiencias
                : individualLesson?.lesson_type === "redaction" &&
                  individualLesson.subject_id === 2
                ? ensayoEspañol
                : individualLesson?.lesson_type === "conversation" &&
                  individualLesson.lesson_content.aiconversations?.card_image
                ? individualLesson.lesson_content.aiconversations?.card_image
                : individualLesson?.lesson_type === "audio_exam"
                ? listeningExam
                : individualLesson?.lesson_type === "writing_exam"
                ? individualLesson.subject?.id === "16"
                  ? writingExam
                  : examenSpain
                : defaultImage
            }
            alt="lesson"
          />
        </div>
      </div>
      <div className={styles.secondTaskColumn}>
        <div>
          <Tag color="white" className={styles.tagsGrade}>
            {individualLesson?.grade?.grade}
          </Tag>
          <Tag color="white" className={styles.tagsGrade}>
            Semana {individualLesson?.week}
          </Tag>
        </div>
        <div>
          <h3 className={styles.titleTask}>{individualLesson?.title ?? ""}</h3>
          <h3 className={styles.descriptionLesson}>
            {individualLesson?.description}
          </h3>
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img
          src={closeIcon}
          alt=""
          className={styles.crossImg}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default TitleLessonNew;
