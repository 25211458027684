import React, { FC } from "react";
import { Modal } from "antd";
import styles from "./modalPosition.module.scss";
import arrowUp from "../../../../assets/icons/clasification/arrowUp.svg";
import arrowDown from "../../../../assets/icons/clasification/arrowDown.svg";
import equal from "../../../../assets/icons/clasification/equal.svg";
import baseAvatar from "../../../../assets/pages/store/store.png";
import start from "../../../../assets/pages/store/startPosition.png";
import useIsMobile from "../../../../hooks/useIsMobile";
import { resolveAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../ColumnAvatar/ContainerAvatar/utils";
import { getInfoTournament_getInfoTournament_userInfo } from "../../../../__generated__/gql-types/getInfoTournament";
import { formatNumberWithSpaces } from "../../utils";

interface PropsModalPosition {
  openModal: boolean;
  onCloseModalPosition: () => void;
  userModal: getInfoTournament_getInfoTournament_userInfo | undefined;
}
const ModalPosition: FC<PropsModalPosition> = ({
  openModal,
  onCloseModalPosition,
  userModal,
}) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      open={openModal}
      onCancel={onCloseModalPosition}
      closable={true}
      width={isMobile ? "100%" : "649px"}
      footer={""}
      className={`${styles.container} modalPosition`}
      centered
    >
      <div className={styles.content}>
        {userModal?.shopActions?.map((clothes) => (
          <img
            src={clothes.item_img ?? ""}
            alt="avatar"
            style={{ zIndex: clothes.z_index ?? 0 }}
            className={styles.clothes}
          />
        ))}
        <img
          src={
            userModal?.level && userModal?.level < 6
              ? resolveAvatar(
                  userModal?.avatar_name ?? "polux",
                  userModal?.level.toString() ?? "1"
                )
              : functionReturnImgAvatar(userModal?.avatar_name ?? "")
          }
          alt="avatar"
          className={
            userModal?.level && userModal?.level < 6
              ? styles.avatarImgwithOutLevel // revisar
              : styles.avatarImg
          }
        />
        <img src={baseAvatar} alt="base" className={styles.imageBase} />
        <div className={styles.containerInfo}>
          <div className={styles.name}> {userModal?.name}</div>
          <div className={styles.containerLevel}>
            <div className={styles.level}>Nivel {userModal?.level}</div>
            <div className={styles.containerStart}>
              <img src={start} alt="start" />
              <div>{formatNumberWithSpaces(userModal?.main_score)}</div>
            </div>
          </div>

          <div className={styles.containerPosition}>
            {userModal?.movement === "equal" && <img src={equal} alt="" />}
            {userModal?.movement === "top" && <img src={arrowUp} alt="" />}
            {userModal?.movement === "bottom" && <img src={arrowDown} alt="" />}
            <div>{userModal?.position ? userModal?.position + 1 : 1}°</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPosition;
