import { notification } from "antd";
import React from "react";
import styles from "./notification.module.scss";
import "antd/dist/reset.css";
import crossNotification from "../../../assets/icons/crossNotification.svg";
import closeNotification from "../../../assets/icons/closeNotification.svg";
import checkNotification from "../../../assets/icons/checkNotification.svg";

export const notificationNegative = (text?: string) => {
  notification.open({
    closeIcon: <img src={closeNotification} alt="" />,
    message: (
      <div className={styles.containerNotification}>
        <div>
          <img src={crossNotification} alt="" />
        </div>
        <div className={styles.textNotification}>
          {text ?? "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."}
        </div>
        <div className={styles.lineNotification}></div>
      </div>
    ),
    placement: "bottomLeft",
    style: {
      marginTop: "30px",
      width: "100%",
      background: "#E33B57",
      boxShadow: "0px 10px 34px rgba(166, 167, 168, 0.14)",
      borderRadius: "11px",
      padding: " 12px 24px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      color: "white",
    },
    duration: 3,
  });
};

export const notificationVersionNotMatch = (text?: string) => {
  notification.open({
    closeIcon: <img src={closeNotification} alt="" />,
    message: (
      <div className={styles.containerNotification}>
        <div>
          <img src={crossNotification} alt="" />
        </div>
        <div className={styles.textNotification}>
          {text ??
            "Parece que no tienes la última versión de Luca. Actualiza la plataforma con: Ctrl + ⬆︎Shift + R (Windows) o ⌘Cmd + ⬆︎Shift + R (Mac)."}
        </div>
        <div className={styles.lineNotification}></div>
      </div>
    ),
    placement: "bottomLeft",
    style: {
      marginTop: "30px",
      background: "#E33B57",
      boxShadow: "0px 10px 34px rgba(166, 167, 168, 0.14)",
      borderRadius: "11px",
      padding: " 12px 24px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      color: "white",
      width: "100%",
    },
    duration: 3,
  });
};

export const NotificationPositive = (text: string) => {
  notification.open({
    closeIcon: <img src={closeNotification} alt="" />,
    message: (
      <div className={styles.containerNotification}>
        <div>
          <img src={checkNotification} alt="" />
        </div>
        <div className={styles.textNotification}>{text}</div>
        <div className={styles.lineNotification}></div>
      </div>
    ),
    placement: "bottomLeft",
    style: {
      marginTop: "30px",
      background: "#059281",
      boxShadow: "0px 10px 34px rgba(166, 167, 168, 0.14)",
      borderRadius: "11px",
      padding: " 12px 24px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      color: "white",
      width: "100%",
    },
    duration: 3,
  });
};
