import React, { FC } from "react";
import styles from "./matching.module.scss";
import arrowGreen from "../../../../../../../assets/icons/arrows/arrowGreen.svg";
import arrowRed from "../../../../../../../assets/icons/arrows/arrowRed.svg";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsMatching {
  index: number;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
  subject_id?: number | null;
}

const MatchingTextQuiz: FC<PropsMatching> = ({
  index,
  question,
  subject_id,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {question.question
            ? question.question
            : subject_id === 16
            ? "Relate the concepts by dragging the card into place."
            : "Relaciona los conceptos arrastrando la ficha a su lugar."}
        </div>
        <div className={styles.containerBlock}>
          <div className={styles.firstColumn}>
            {question.processedBlocks?.map((e) => {
              const ok =
                e.blockContainer?.coincidence === e.blockItem?.coincidence;
              return (
                <div className={styles.containerImageButton}>
                  <div className={styles.containerbuttonContent}>
                    {e.blockContainer?.url &&
                    e.blockContainer?.url &&
                    e.blockContainer?.url?.length > 0 ? (
                      <img
                        style={{ maxWidth: "90%", maxHeight: "85%" }}
                        src={e.blockContainer?.url}
                      ></img>
                    ) : (
                      `${e.blockContainer?.text}`
                    )}
                  </div>
                  <img
                    src={ok ? arrowGreen : arrowRed}
                    style={{ width: "20px" }}
                    className={styles.postionImage}
                    alt="arrow"
                  ></img>
                </div>
              );
            })}
          </div>
          <div className={styles.secondColumn}>
            {question.processedBlocks?.map((e) => {
              return (
                <div className={styles.containerbuttonContent}>
                  {e.blockItem?.text}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchingTextQuiz;
