import { FC, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {
  Login,
  Program2017,
  Classes,
  MyProfileMobile,
  NoSchool,
  RecoveryPasswordPage,
  ChangePassword,
  Help,
  CalendarPage,
  Points,
  SearcherPage,
  FormTeacherPage,
  English,
  Reading,
  NewProjectPage,
  PasswordlessLogin,
  Program2425,
  Store,
  LiteracyPage,
} from "./../pages";
import {
  Missions,
  ExtraPoints,
  SelectAvatar,
  AsteroidsChallenge,
} from "./../pages/Students";
import { MobilePasswordChanger } from "../components/Mobile/Shared/MobilePasswordChanger";
import { SingleClass } from "../components/SingleClass";
import { MajorToMinor } from "../components/Students/Missions/MajorToMinor";
import { NewQuizz } from "../components/Students/Missions/NewQuizz";
import { PointsSingleClass } from "../pages/PointsSingleClass";
import Nem from "../components/Nem/Nem";
// import useUserDb from "../UserProvider/UserProvider";
import { getUserByEmail_getUserByEmail } from "../__generated__/gql-types/getUserByEmail";

interface LucaRoutesProps {
  userDb?: getUserByEmail_getUserByEmail | null;
}

const LucaRoutes: FC<LucaRoutesProps> = ({ userDb }) => {
  // const { userDb } = useUserDb();
  const url = window.location.href;
  const query = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !query.includes("recoveryPassword") &&
      !query.includes("changePassword") &&
      !query.includes("registro-profesores")
    ) {
      if (!userDb?.schools || userDb?.schools.length <= 0) {
        if (!userDb) {
          return navigate("/", { replace: true });
        }
        return navigate("/noSchool", { replace: true });
      }
    }
  }, [navigate, userDb, query]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/qr" element={<PasswordlessLogin />} />
      <Route path="/recoveryPassword" element={<RecoveryPasswordPage />} />
      <Route path="/changePassword/:id/:token" element={<ChangePassword />} />
      <Route path="/noSchool" element={<NoSchool></NoSchool>} />
      <Route path="/registro-profesores" element={<FormTeacherPage />} />

      <Route path="/myprofile" element={<MyProfileMobile />} />
      <Route path="/passwordchange" element={<MobilePasswordChanger />} />

      {(userDb?.role === "teacher" || userDb?.role === "admin") &&
        (url?.includes("localhost") ||
          url?.includes("app") ||
          url?.includes("frontend")) && (
          <>
            <Route path="/myclasses" element={<Classes />} />
            <Route path="/points" element={<Points />} />
            <Route path="/points/:id/:tab?" element={<PointsSingleClass />} />
            <Route path="/myclasses/:id" element={<SingleClass />} />

            <Route path="/searcher" element={<SearcherPage />} />
            <Route path="/newProject" element={<NewProjectPage />} />
            <Route
              path="/2425/:parametro1?/:parametro2?/:lessonIdParams?"
              element={<Program2425 />}
            />
            <Route
              path="/nem/:parametro1?/:parametro2?/:lessonIdParams?"
              element={<Nem />}
            />
            <Route
              path="/2017/:parametro1?/:parametro2?/:lessonIdParams?"
              element={<Program2017 />}
            />
            <Route
              path="/reading/:parametro1?/:parametro2?/:lessonIdParams?"
              element={<Reading />}
            />
            <Route path="/literacy" element={<LiteracyPage />} />
            <Route
              path="/english/:parametro1?/:parametro2?/:lessonIdParams?"
              element={<English />}
            />
            <Route path="/task/:id" element={<NewQuizz />} />
            <Route path="/help" element={<Help />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="*" element={<Navigate to="/myclasses" replace />} />
          </>
        )}

      {userDb?.role === "student" && (
        <>
          <Route path="/reading" element={<LiteracyPage />} />
          <Route path="/newQuizz" element={<NewQuizz />} />
          <Route path="/majorToMinor" element={<MajorToMinor />} />
          <Route path="/avatar" element={<SelectAvatar userDb={userDb} />} />
          <Route path="/mymissions" element={<Missions />} />
          <Route path="/task/:id" element={<NewQuizz />} />
          <Route
            path="/extrapoints"
            element={<ExtraPoints userDb={userDb} />}
          />
          <Route path="/literacy" element={<LiteracyPage />} />
          <Route path="/asteroidschallenge" element={<AsteroidsChallenge />} />
          <Route
            path="/nem/:parametro1?/:parametro2?/:lessonIdParams?"
            element={<Nem />}
          />
          <Route
            path="/2017/:parametro1?/:parametro2?/:lessonIdParams?"
            element={<Program2017 />}
          />
          <Route
            path="/2425/:parametro1?/:parametro2?/:lessonIdParams?"
            element={<Program2425 />}
          />
          <Route path="/store" element={<Store />} />
          <Route path="*" element={<Navigate to="/mymissions" replace />} />
        </>
      )}
    </Routes>
  );
};

export default LucaRoutes;
