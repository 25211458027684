import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserInput } from "../__generated__/gql-types/globalTypes";

const UseUpdateStudentByTeacher = () => {
  const client = useApolloClient();

  return useCallback(
    async (userInput: UserInput) => {
      return await client.mutate({
        mutation: UPDATE_STUDENT_BY_TEACHER,
        fetchPolicy: "no-cache",
        variables: { userInput },
      });
    },
    [client]
  );
};
export default UseUpdateStudentByTeacher;

const UPDATE_STUDENT_BY_TEACHER = gql`
  mutation updateStudentByTeacher($userInput: UserInput!) {
    updateStudentByTeacher(userInput: $userInput) {
      id
      first_name
      last_name
      email
    }
  }
`;
