import bookSubject from "../../../../assets/icons/book_subject.svg";
import calculatorSubject from "../../../../assets/icons/calculator_subject.svg";
import scienceSubject from "../../../../assets/icons/science_subject.svg";

export const functionReturnImg = (subjectId: number | null) => {
  if (subjectId === 1) {
    return calculatorSubject;
  }
  if (subjectId === 2) {
    return bookSubject;
  }
  if (subjectId === 4) {
    return scienceSubject;
  }
  if (subjectId === 16) {
    return scienceSubject;
  }
  return calculatorSubject;
};
