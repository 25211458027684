import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./messageList.module.scss";
import { PropsMessage } from "../../utils";
import { getLessonById_getLessonById } from "../../../../../../../__generated__/gql-types/getLessonById";

import ContainerAlumn from "./ContainerAlumn/ContainerAlumn";
import { GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log } from "../../../../../../../__generated__/gql-types/GetChatLogForTeacherCorrection";
import { ContainerTeacher } from "./ContainerTeacher";
import { AudioProvider } from "./AudioContextProvider";

interface PropsMessageList {
  messages:
    | PropsMessage[]
    | GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log[];
  isTyping: boolean;
  readOnly?: boolean;
  individualLesson: getLessonById_getLessonById;
  autoPlay?: boolean;
}
const MessageList: FC<PropsMessageList> = ({
  messages,
  individualLesson,
  isTyping,
  readOnly,
  autoPlay,
}) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div && !readOnly) {
      div.scrollTop = div.scrollHeight;
    }
  }, [messages, readOnly]);
  return (
    <div className={styles.container} ref={scrollableDivRef}>
      <AudioProvider>
        <div className={styles.containerMessages}>
          {messages.map((message, index) =>
            message.sender === "LUCA" ? (
              <ContainerTeacher
                key={index}
                message={message}
                messages={messages}
                autoPlay={autoPlay}
                individualLesson={individualLesson}
              ></ContainerTeacher>
            ) : (
              <ContainerAlumn key={index} message={message} />
            )
          )}
        </div>
      </AudioProvider>
      <div className={styles.containerLoading}>
        {isTyping && (
          <>
            <div className={styles.loader}></div>{" "}
            {
              individualLesson.lesson_content.aiconversations
                ?.character_for_role_play
            }{" "}
            is thinking
          </>
        )}
      </div>
    </div>
  );
};

export default MessageList;
