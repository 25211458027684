import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createTeacherProjectVariables } from "../__generated__/gql-types/createTeacherProject";

const UseCreateTeacherProject = () => {
  const client = useApolloClient();

  return useCallback(
    async (projectInput: createTeacherProjectVariables) => {
      return await client.mutate({
        mutation: CREATE_TEACHER_PROJECT,
        fetchPolicy: "no-cache",
        variables: projectInput,
      });
    },
    [client]
  );
};

export default UseCreateTeacherProject;

const CREATE_TEACHER_PROJECT = gql`
  mutation createTeacherProject($teacherProjectInput: teacherProjectInput) {
    createTeacherProject(teacherProjectInput: $teacherProjectInput) {
      title
    }
  }
`;
