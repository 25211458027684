import { FC } from "react";
import { Loader } from "../../Commons";
import { Card } from "../Card";
import styles from "./mosaico.module.scss";
import { CardMobile } from "../../Mobile/Teacher/Lessons/CardMobile";
import { DrawerFilter } from "../NewFilters/DrawerFilter";
import { getLessonsBySubjectId_getLessonsBySubject } from "../../../__generated__/gql-types/getLessonsBySubjectId";
import useIsMobile from "../../../hooks/useIsMobile";
interface MosaicoProps {
  data: getLessonsBySubjectId_getLessonsBySubject[] | null;
  selectedItem: string;
  showDrawer: () => void;
  setLessonId: (id: string) => void;
  setGrade: (value: number) => void;
  grade?: number;
  setBlock: (e: number) => void;
  block?: number;
  sepBookPage?: string;
  sepBookPagesList?: string[];
  setSepBookPage: (value: string) => void;
  setSelectedWeek: (value: string) => void;
  weekList?: string[];
  selectedWeek?: string;
  onCloseFilter: () => void;
  showDrawerFilter: () => void;
  open: boolean;
  setIndividualLesson?: (item: any) => void;
}

const Mosaico: FC<MosaicoProps> = ({
  showDrawer,
  data,
  setLessonId,
  setSepBookPage,
  sepBookPagesList,
  sepBookPage,
  weekList,
  selectedWeek,
  setSelectedWeek,
  setGrade,
  grade,
  setBlock,
  block,
  onCloseFilter,
  setIndividualLesson,
  open,
}) => {
  const isMobile = useIsMobile();

  const validationMobileFunction = () => {
    return isMobile ? (
      data?.map((lesson) => {
        return (
          <CardMobile
            item={lesson}
            showDrawer={showDrawer}
            setLessonId={setLessonId}
            key={lesson?.id + lesson?.title}
          />
        );
      })
    ) : (
      <>
        {data?.map((lesson) => {
          return (
            <Card
              item={lesson}
              showDrawer={showDrawer}
              setLessonId={setLessonId}
              key={lesson?.id + lesson?.title}
              setIndividualLesson={setIndividualLesson}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className={styles.cardsContainer}>
      <div className={styles.container}>
        {/* {renderCardMock()} */}
        {!data ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : data?.length > 0 ? (
          validationMobileFunction()
        ) : (
          <h1> No se encontraron coincidencias...</h1>
        )}
      </div>
      <DrawerFilter
        visible={open}
        onClose={onCloseFilter}
        sepBookPage={sepBookPage}
        sepBookPagesList={sepBookPagesList}
        setSepBookPage={setSepBookPage}
        setGrade={setGrade}
        grade={grade}
        setBlock={setBlock}
        block={block}
        weekList={weekList}
        setSelectedWeek={setSelectedWeek}
        selectedWeek={selectedWeek}
      ></DrawerFilter>
    </div>
  );
};

export default Mosaico;
