import React, { FC, useEffect, useState } from "react";
import styles from "./drawerInteligentStudent.module.scss";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import { Drawer, Tag } from "antd";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import { MainInteligentStudent } from "./MainInteligentStudent";
import { ActivityIndicator } from "../../../ActivityIndicator/ActivityIndicator";
import useIsMobile from "../../../../hooks/useIsMobile";
import UseGetLessonsById from "../../../../api/useGetLessonsById";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import UseGetRedactionCorrection from "../../../../api/useGetRedactionCorrection";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../__generated__/gql-types/getRedactionCorrection";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setDependencyRefresh } from "../../../../redux/slices/dependencyRefresh";
import { DependencyRefresh } from "../../../../redux/store/store";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import useAuth from "../../../../context/useAuth";
import UseStudentReviewHomework from "../../../../api/useStudentReviewHomework";
import { Loader } from "../../../Commons";

interface PropsDrawerInteligentTeacher {
  onCloseDrawer: () => void;
  visibleDrawer: boolean;
  homework: getHomeworkByIdForStudent_getHomeworkByIdForStudent | null;
}
const DrawerInteligentTeacher: FC<PropsDrawerInteligentTeacher> = ({
  onCloseDrawer,
  visibleDrawer,
  homework,
}) => {
  const dispatch = useAppDispatch();
  const { userToken } = useAuth();
  const getLessonsById = UseGetLessonsById();
  const studentReviewHomework = UseStudentReviewHomework();
  const getRedactionCorrection = UseGetRedactionCorrection();
  const [evaluateStudent, setEvaluateStudent] =
    useState<getRedactionCorrection_getRedactionCorrection | null>(null);
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const dependencyRefresh = useAppSelector(DependencyRefresh);

  const redaction_id =
    homework?.lessons && homework?.lessons[0]?.lesson_content?.redaction?.id
      ? Number(homework?.lessons[0]?.lesson_content.redaction.id)
      : null;

  const individualLessonId =
    homework && homework?.lessons && homework?.lessons[0].id
      ? homework?.lessons[0].id
      : null;

  const handleRedactionReview = async () => {
    if (
      homework?.id &&
      homework?.lessons &&
      homework?.lessons[0]?.lesson_type === "redaction" &&
      !homework?.redaction_review_by_student &&
      homework?.redaction_correction
    ) {
      await studentReviewHomework({
        homeworkId: homework?.id,
      });
      dispatch(setDependencyRefresh(!dependencyRefresh));
    }
  };

  // // Leccion Individual
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (individualLessonId) {
          const individualLessonRes = await getLessonsById({
            lessonId: individualLessonId,
          });
          setIndividualLesson(individualLessonRes);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    })();
    return () => {
      handleRedactionReview();
    };
  }, [getLessonsById, individualLessonId]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await getRedactionCorrection({
          teacherModalInfoInput: {
            homework_id: Number(homework?.id),
            redaction_id: Number(redaction_id),
            student_id: Number(userToken?.id),
          },
        });
        setEvaluateStudent(response);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    })();
  }, [getRedactionCorrection, homework?.id, userToken?.id, redaction_id]);

  return (
    <>
      <Drawer
        placement={isMobile ? "bottom" : "right"}
        onClose={onCloseDrawer}
        open={visibleDrawer}
        height={isMobile ? "500px" : ""}
        closable={false}
        styles={{
          wrapper: isMobile
            ? {}
            : { width: "calc(100% - 300px)", minWidth: "768px" },
        }}
        title={
          !isLoading && (
            <div className={styles.task}>
              <div className={styles.firstTaskColumn}>
                <div className={styles.pictureContainer}>
                  <img
                    src={
                      individualLesson &&
                      individualLesson.lesson_content?.videos?.length &&
                      individualLesson.lesson_content?.videos[0]?.imageUrl
                        ? individualLesson.lesson_content?.videos[0].imageUrl
                        : defaultImage
                    }
                    alt="lesson"
                  />
                </div>
              </div>
              <div className={styles.secondTaskColumn}>
                <div className={styles.tagsAndTitle}>
                  <Tag color="white" className={styles.tagsGrade}>
                    {individualLesson?.grade?.grade}
                  </Tag>
                  <Tag color="white" className={styles.tagsGrade}>
                    Semana {individualLesson?.week}
                  </Tag>

                  <h3 className={styles.titleTask}>
                    {individualLesson?.title ?? ""}
                  </h3>
                </div>
                <div>
                  <h3 className={styles.descriptionLesson}>
                    {individualLesson?.description}
                  </h3>
                </div>
              </div>
              <div className={styles.thirdyTaskColumn}>
                <img
                  src={closeIcon}
                  alt=""
                  className={styles.crossImg}
                  onClick={onCloseDrawer}
                />
              </div>
            </div>
          )
        }
        footerStyle={{ zIndex: 100 }}
        footer={
          !isLoading && (
            <div className={styles.footer} onClick={onCloseDrawer}>
              <div className={styles.taskButton}>Volver</div>
            </div>
          )
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <MainInteligentStudent
            individualLesson={individualLesson}
            evaluateStudent={evaluateStudent}
          />
        )}
      </Drawer>
    </>
  );
};

export default DrawerInteligentTeacher;
