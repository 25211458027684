import React, { FC, useState } from "react";
import styles from "./newRelateConcepts.module.scss";
import { PropsRelateQuiz, PropsStateRelate } from "./utils";

import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import { ModalImg } from "../NewQuestions/ModalImg";
import { synthesizeLanguageText } from "../NewQuizz/utils";

const NewRelateConcepts: FC<PropsRelateQuiz> = ({
  arrayRelate,
  stateNext,
  setArrayRelate,
  putFocusFooter,

  content,
  subject_id,
}) => {
  const [stateRelate, setStateRelate] = useState<PropsStateRelate>({
    first: 0,
  });
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const synth = window.speechSynthesis;
  const voices = synth.getVoices();

  const functionPutAlpha = (i?: number) =>
    (i === 0 && "A") ||
    (i === 1 && "B") ||
    (i === 2 && "C") ||
    (i === 3 && "D") ||
    "";

  const functionCleanState = (valueFirstColum: number) => {
    if (arrayRelate[0] && arrayRelate[0]?.first === valueFirstColum) {
      const newArrayRelate = [...arrayRelate];
      newArrayRelate[0] = { first: 0, second: 0 };
      setArrayRelate(newArrayRelate);
      setStateRelate({ first: 0 });
    }
    if (arrayRelate[1] && arrayRelate[1]?.first === valueFirstColum) {
      const newArrayRelate = [...arrayRelate];
      newArrayRelate[1] = { first: 0, second: 0 };
      setArrayRelate(newArrayRelate);
      setStateRelate({ first: 0 });
    }
    if (arrayRelate[2] && arrayRelate[2]?.first === valueFirstColum) {
      const newArrayRelate = [...arrayRelate];
      newArrayRelate[2] = { first: 0, second: 0 };
      setArrayRelate(newArrayRelate);
      setStateRelate({ first: 0 });
    }
    if (arrayRelate[3] && arrayRelate[3]?.first === valueFirstColum) {
      const newArrayRelate = [...arrayRelate];
      newArrayRelate[3] = { first: 0, second: 0 };
      setArrayRelate(newArrayRelate);
      setStateRelate({ first: 0 });
    }
  };

  const setStateCompleted = (i: number, coincidence: number) => {
    if (stateRelate?.first) {
      const newArrayRelate = [...arrayRelate];
      newArrayRelate[i] = {
        first: stateRelate?.first,
        second: coincidence,
        alpha: stateRelate?.alpha,
      };

      if (
        newArrayRelate.length > 3 &&
        newArrayRelate.every((objeto) => objeto?.hasOwnProperty("alpha"))
      ) {
        putFocusFooter();
      }
      setArrayRelate(newArrayRelate);
      setStateRelate({
        first: 0,
      });
    }
  };

  const returnStylesForButton = (i: number) => {
    return i >= 37 ? styles?.buttonContentBig : styles?.buttonContent;
  };
  const returnStylesForCorrectButton = (i: number) => {
    const objCorrect = arrayRelate?.find((element) => {
      return element?.alpha === i;
    });
    return objCorrect?.first === objCorrect?.second
      ? styles?.circleFirstColumnSelectedTrue
      : styles?.circleFirstColumnSelectedFalse;
  };
  const handleVoice = async () => {
    setIsSoundActive(true);
    const question = content?.question ?? "Relaciona las cajas";

    setIsSoundActive(true);
    subject_id && (await synthesizeLanguageText(question, subject_id));
    setIsSoundActive(false);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.titleRelate}>
        {isSoundActive ? (
          <img className={styles.imgSound} src={soundNegative} alt="" />
        ) : (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt=""
          />
        )}
        {content?.question ?? "Relaciona las cajas"}
      </h1>
      <div className={styles.content}>
        <div className={styles.firstColumn}>
          {content?.relationQuestion &&
            content?.relationQuestion[0]?.block1?.map((e: any, i: number) => (
              <div
                className={styles.containerbuttonContent}
                key={i}
                onClick={() => {
                  if (!stateNext) {
                    setStateRelate({
                      ...stateRelate,
                      first: e?.coincidence,
                      alpha: i,
                    });
                    functionCleanState(e?.coincidence);
                  }
                }}
              >
                <div
                  className={returnStylesForButton(
                    content!.relationQuestion![0]?.block1![i]?.text!.length
                  )}
                >
                  {e.text}
                </div>

                <div
                  className={
                    stateNext
                      ? returnStylesForCorrectButton(i)
                      : arrayRelate?.some((element) => {
                          return (
                            element &&
                            element.first &&
                            element.first === e?.coincidence
                          );
                        }) || stateRelate.first === e?.coincidence
                      ? styles.circleFirstColumnSelected
                      : styles.circleFirstColumn
                  }
                >
                  {functionPutAlpha(i)}
                </div>
              </div>
            ))}
        </div>
        <div className={styles.secondColumn}>
          {content?.relationQuestion &&
            content?.relationQuestion[0]?.block2?.map((e: any, i: number) => (
              <div
                className={styles.containerbuttonContent}
                key={i}
                onClick={() => {
                  !stateNext && setStateCompleted(i, e?.coincidence);
                }}
              >
                <div
                  className={
                    stateNext
                      ? arrayRelate[i]?.first === arrayRelate[i]?.second
                        ? styles.circleSelectedTrue
                        : styles.circleSelectedFalse
                      : arrayRelate?.some((element) => {
                          return element?.second === e?.coincidence;
                        })
                      ? styles.circleSelected
                      : styles.circle
                  }
                >
                  {(arrayRelate[0] &&
                    arrayRelate[0]?.second === e?.coincidence &&
                    arrayRelate[0]?.alpha !== null &&
                    functionPutAlpha(arrayRelate[0]?.alpha)) ||
                    (arrayRelate[1] &&
                      arrayRelate[1]?.second === e?.coincidence &&
                      arrayRelate[1]?.alpha !== null &&
                      functionPutAlpha(arrayRelate[1]?.alpha)) ||
                    (arrayRelate[2] &&
                      arrayRelate[2]?.second === e?.coincidence &&
                      arrayRelate[2]?.alpha !== null &&
                      functionPutAlpha(arrayRelate[2]?.alpha)) ||
                    (arrayRelate[3] &&
                      arrayRelate[3]?.second === e?.coincidence &&
                      arrayRelate[3]?.alpha !== null &&
                      functionPutAlpha(arrayRelate[3]?.alpha))}
                </div>

                <div
                  className={returnStylesForButton(
                    content?.relationQuestion![0]?.block2![i]?.text!.length
                  )}
                >
                  {e.text}
                </div>
              </div>
            ))}
        </div>
      </div>
      <ModalImg
        open={open}
        setOpen={setOpen}
        urlImg={content.url ?? ""}
      ></ModalImg>
    </div>
  );
};

export default NewRelateConcepts;
