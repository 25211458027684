import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  GetCoursesForNewContent,
  GetCoursesForNewContentVariables,
} from "../__generated__/gql-types/GetCoursesForNewContent";

const useGetCoursesForNewContent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: GetCoursesForNewContentVariables) => {
      const result: ApolloQueryResult<GetCoursesForNewContent> =
        await client.query({
          query: GET_COURSES_FOR_NEW_CONTENT,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesForNewContent;
    },
    [client]
  );
};

export default useGetCoursesForNewContent;

const GET_COURSES_FOR_NEW_CONTENT = gql`
  query GetCoursesForNewContent(
    $getCoursesForNewContentInput: getCoursesForNewContentInput
  ) {
    getCoursesForNewContent(
      getCoursesForNewContentInput: $getCoursesForNewContentInput
    ) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      english
      reading
      description
      initial_evaluation
      sep_book_pages_english
      sep_book_pages_reading
      threemester_english
      threemester_reading
      week_english
      week_reading
      count
    }
  }
`;
