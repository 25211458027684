import React, { FC, useEffect, useState } from "react";
import styles from "./courseNemSearcher.module.scss";
import { Loader } from "../../Commons";
import lucaBot from "../../../assets/images/lucaBot.png";
import UseGetCoursesBySearch from "../../../api/useGetCoursesBySearch";
import { getCoursesBySearch_getCoursesBySearch } from "../../../__generated__/gql-types/getCoursesBySearch";
import { PanelCourses } from "./PanelCourses";
import useDebounce from "../../../hooks/useDebounce";
interface CourseNemSearchersProps {
  searchInput: string;
  grade: number[];
  subject: number[];
}
const CoursesNemSearcher: FC<CourseNemSearchersProps> = ({
  searchInput,
  grade,
  subject,
}) => {
  const deb = useDebounce(searchInput ?? "", 1200);
  const [loadingDosification, setLoadingDosification] = useState(false);
  const getCoursesBySearch = UseGetCoursesBySearch();
  const [contPage, setContPage] = useState(4);
  const [coursesState, setCoursesState] = useState<
    getCoursesBySearch_getCoursesBySearch[]
  >([]);

  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      try {
        const response = await getCoursesBySearch({
          getCoursesSearchArgs: {
            search: deb,
            subject_ids: subject.length < 1 ? null : subject,
            pageSize: contPage,
            page: 0,
            grade_ids: grade.length > 0 ? grade : null,
          },
        });
        response && setCoursesState(response);

        setLoadingDosification(false);
      } catch (error) {
        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [getCoursesBySearch, grade, deb, contPage, subject]);

  return (
    <div className={styles.containerNem}>
      {!deb ? (
        <div className={styles.containerInfo}>
          <div className={styles.textWithoutInput}>
            Utiliza el buscador para encontrar el contenido que te interesa
            entre más de 3 000 recursos del programa Luca.
          </div>
          <img src={lucaBot} alt="" />
        </div>
      ) : loadingDosification ? (
        <Loader />
      ) : coursesState && coursesState.length > 0 ? (
        <PanelCourses
          courses={coursesState}
          contPage={contPage}
          setContPage={setContPage}
        />
      ) : (
        <div className={styles.containerWithoutCourses}>
          <div className={styles.textWithoutInfo}>
            Tu búsqueda no arroja resultados en nuestras secuencias para el
            Programa Luca y 2017. Es posible que la secuencia aún no haya sido
            producida por nuestro equipo de contenidos. ¡Inténtalo de nuevo con
            una búsqueda diferente o contacta con nosotros para confirmarlo!
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursesNemSearcher;
