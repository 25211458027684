import { useEffect, useState } from "react";
import { Feedback } from "../NewQuizz/Feedback";
import styles from "./fraseDrag.module.scss";
import DragTest from "./DragTest";
import { initialData } from "./utils";
import { useNavigate } from "react-router-dom";

const MajorToMinor = () => {
  let navigate = useNavigate();
  const [seeInstructions, setSeeInstructions] = useState(false);
  const [alertFalse, setAlertFalse] = useState(false);
  const [alertTrue, setAlertTrue] = useState(false);
  const [contQuizFrase, setContQuizFrase] = useState(0);
  const [state, setState] = useState(initialData);
  const [itemsOrdered, setItemsOrdered] = useState(false);
  const [checkButtonPressed, setCheckButtonPressed] = useState(false);
  const [ansersCounter, setAnswersCounter] = useState(0);
  const [testFinished, setTestFinished] = useState(false);
  const stylesBotonNext = () => {
    return ansersCounter === 4
      ? styles.botonNormal
      : checkButtonPressed && itemsOrdered
      ? styles.botonTrue
      : checkButtonPressed && !itemsOrdered
      ? styles.botonFalse
      : styles.botonBloqued;
  };
  const handleInstructions = () => {
    setSeeInstructions(!seeInstructions);
  };

  const functionCheck = (elementsList: any[] | undefined) => {
    let result =
      elementsList && elementsList !== undefined
        ? elementsList.slice(1).every((item, i) => elementsList[i] <= item)
        : false;
    setItemsOrdered(result);
    setAlertFalse(!result);
    setAlertTrue(result);
    setTestFinished(true);
  };

  const functionCont = () => {
    return 5;
  };

  useEffect(() => {
    setAnswersCounter(
      state.rows[1].answers?.length ? state.rows[1].answers?.length : 0
    );
    if (ansersCounter < 4) {
      setCheckButtonPressed(false);
      setItemsOrdered(false);
    }
  }, [checkButtonPressed, itemsOrdered, state]);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <DragTest testResult={state} setTestResult={setState} />
        <p className={styles.leftP}>Mayor</p>
        <p className={styles.rightP}>Menor</p>
        <div className={styles.feedbackContainer}>
          <Feedback alertFalse={alertFalse} alertTrue={alertTrue} />
        </div>
      </div>

      <div className={styles.footerRelate}>
        <button
          disabled={
            state.rows[1].answers && state.rows[1].answers.length === 4
              ? false
              : true
          }
          className={
            checkButtonPressed && itemsOrdered
              ? styles.botonTrue
              : checkButtonPressed && !itemsOrdered
              ? styles.botonFalse
              : stylesBotonNext()
          }
          onClick={() => {
            setCheckButtonPressed(true);

            functionCheck(
              state.rows[1].answers?.map((answer: any) => answer.order)
            );
            if (testFinished) {
              navigate("/mymissions");
            }
          }}
        >
          {ansersCounter === 4 ? "CONTINUAR" : "COMPROBAR"}
        </button>
      </div>
    </div>
  );
};

export default MajorToMinor;
