import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalConfirmation.module.scss";

interface PropsModalConfirmation {
  open: boolean;
  onCloseModalConfirmation: () => void;
  handleRedirection: () => void;
}
const ModalConfirmation: FC<PropsModalConfirmation> = ({
  open,
  onCloseModalConfirmation,
  handleRedirection,
}) => {
  return (
    <Modal
      open={open}
      onCancel={onCloseModalConfirmation}
      closable={false}
      width={"500px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        <div className={styles.containerText}>
          <div className={styles.containerTitle}>
            ¿Seguro que quieres salir?
          </div>
          <div className={styles.containerSubTitle}>
            Si sales de la misión sin terminarla perderás el progreso y los
            puntos obtenidos.
          </div>
        </div>

        <div className={styles.footerDetail}>
          <button
            className={styles.taskButtonDelete}
            onClick={handleRedirection}
          >
            CERRAR MISIÓN
          </button>

          <button
            className={styles.taskButtonMision}
            onClick={onCloseModalConfirmation}
          >
            CONTINUAR MISIÓN
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
