import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLiteracyLessonsSearch,
  getLiteracyLessonsSearchVariables,
} from "../__generated__/gql-types/getLiteracyLessonsSearch";

const UseGetLiteracyLessonsSearch = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getLiteracyLessonsSearchVariables) => {
      const result: ApolloQueryResult<getLiteracyLessonsSearch> =
        await client.query({
          query: GET_LESSONS_SEARCH,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLiteracyLessonsSearch;
    },
    [client]
  );
};

export default UseGetLiteracyLessonsSearch;

const GET_LESSONS_SEARCH = gql`
  query getLiteracyLessonsSearch($getLessonsSearchArgs: GetLessonsSearchArgs) {
    getLiteracyLessonsSearch(GetLessonsSearchArgs: $getLessonsSearchArgs) {
      id
      title
      description
      cover
      week
      sep_book_pages
      sep_name
      featured
      publish

      course_id
      grade_id
      subject_id
      nem
      drive_link
      block_id
      result
      finished
      lesson_content_id
      status
      order_by
      order_reading
      lesson_content {
        literacy {
          id
          header_color
          copyright
          title
          cover
          publish
          content {
            type
            image
            text
            order
          }
        }
      }
      created_at
      updated_at
      deleted_at
      lesson_type
      learnings
    }
  }
`;
