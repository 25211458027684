import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getStudentTriesVariables,
  getStudentTries,
} from "../__generated__/gql-types/getStudentTries";

const useGetStudentTries = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getStudentTriesVariables) => {
      const result: ApolloQueryResult<getStudentTries> = await client.query({
        query: GET_STUDENT_TRIES,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getStudentTries;
    },
    [client]
  );
};

export default useGetStudentTries;

const GET_STUDENT_TRIES = gql`
  query getStudentTries($homeworkId: Int) {
    getStudentTries(homework_id: $homeworkId) {
      tries
    }
  }
`;
