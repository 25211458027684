import { FC, useEffect, useState } from "react";
import styles from "./mainProfile.module.scss";

// Icons
import home from "../../../../assets/icons/school_Drawer.svg";
import union from "../../../../assets/images/Union.svg";
import msgIcon from "../../../../assets/icons/letterBlack.svg";
import familyIcon from "../../../../assets/icons/family.svg";
import Question from "../../../../assets/icons/students/Question.png";
import logoutButton from "../../../../assets/images/logoutButton.svg";
import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";
import { resolveAvatar } from "../../../../utils/functions";
import { Button } from "antd";
import start from "../../../../assets/icons/starBanner.png";
import UseGetClothesAndAccessories from "../../../../api/useGetClothesAndAccessories";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../../../__generated__/gql-types/getClothesAndAccessories";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";
import { Loader } from "../../../Commons";

interface MainProfileProps {
  logout: () => void;
  showDrawerAvatar: () => void;
  showDrawerPassword: () => void;
  userDb: getUserByEmail_getUserByEmail | null;
}

const MainProfile: FC<MainProfileProps> = ({
  userDb,
  logout,
  showDrawerPassword,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    getClothesAndAccessories_getClothesAndAccessories[] | null
  >(null);

  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const getClothesAndAccessories = UseGetClothesAndAccessories();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getClothesAndAccessories();
      const clothesActive = response?.filter((e) => e.active);
      clothesActive && setClothesAndAccessories(clothesActive);
      setIsLoading(false);
    })();
  }, [getClothesAndAccessories]);

  const redirectSchoolBrisas = () => {
    logout();
    window.location.href = `https://www.colegiobrisas.com/aulavirtual`;
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        <div className={styles.firstAvatarContainer}>
          <div className={styles.firstAvatarContainerRelative}>
            <img src={union} alt="" className={styles.globoImg} />

            <div className={styles.textImg}>
              ¡Hola {userDb?.first_name}!
              <p className={styles.textTwoImg}>
                ¡Wow! Tu monstruo cada día está más grande. ¡Sigue ganando
                cristales en las misiones y crecerá cada vez más!
              </p>
            </div>
          </div>
        </div>

        <div className={styles.secondAvatarContainer}>
          {clothesAndAccessories?.map((clothes) => (
            <img
              src={clothes.item_img ?? ""}
              alt="avatar"
              style={{ zIndex: clothes.z_index ?? 0 }}
              className={styles.clothes}
            />
          ))}
          <img
            src={
              userDb?.score?.level && userDb?.score?.level < 6
                ? resolveAvatar(
                    userDb.avatar ?? "polux",
                    userDb?.score?.level?.toString() ?? "1"
                  )
                : functionReturnImgAvatar(userDb?.avatar ?? "")
            }
            alt="avatar"
            className={
              userDb?.score?.level && userDb?.score?.level < 6
                ? styles.avatarImgwithOutLevel // revisar
                : styles.avatarImg
            }
          />
        </div>

        <div className={styles.thirdAvatarContainer}>
          <div className={styles.sqContainer}>
            <h3>Nivel</h3>
            <p>{userDb?.score?.level}</p>
          </div>

          <div className={styles.sqContainer}>
            <h3>EXPERIENCIA</h3>
            <div className={styles.numberContainerTwo}>
              <img alt="start" src={start} />
              <p>{formatNumberWithSpaces(userDb?.score?.main_score ?? 0)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.subContainer}>
        <div className={styles.accountPassword}>
          <h2> Mi cuenta</h2>
          {userDb?.schools[0].name !== "Colegio Brisas" && (
            <Button
              className={styles.buttonProfile}
              onClick={showDrawerPassword}
            >
              Cambiar Contraseña
            </Button>
          )}
        </div>

        <div className={styles.accountContainerTwo}>
          <h3 style={{ marginBottom: "4px" }}>
            {userDb?.first_name} {userDb?.last_name}{" "}
          </h3>

          <div
            className={styles.accountTextRow}
            style={{ marginBottom: "4px" }}
          >
            <img src={home} alt="" />
            <h5>{userDb?.schools && userDb?.schools[0]?.name}</h5>

            <h5 className={styles.gradeText}>
              Grupo {userDb?.groups && userDb?.groups[0]?.grade?.grade}
              {userDb?.groups && userDb?.groups[0]?.name}
            </h5>
          </div>

          <div
            className={styles.accountTextRow}
            style={{ marginBottom: "4px" }}
          >
            <img src={msgIcon} alt="" />
            <h5>{userDb?.email}</h5>
          </div>

          <div className={styles.accountTextRow}>
            <img src={familyIcon} alt="" />
            <h5 className={styles.schoolGrayText}>{userDb?.email_parent}</h5>
          </div>
        </div>
      </div>

      <div className={styles.helpContainer}>
        <img src={Question} alt="question" />
        <div className={styles.needHelp}>¿Necesitas ayuda?</div>
        <div className={styles.writing}> Escríbenos por WhatsApp al</div>
        <a href="https://wa.me/525541691994" className={styles.phone}>
          +52 55 4169 1994.
        </a>
      </div>

      <div
        className={styles.logoutButtonContainer}
        onClick={() => {
          userDb?.schools[0].name === "Colegio Brisas"
            ? redirectSchoolBrisas()
            : logout();
        }}
      >
        <img src={logoutButton} alt="" />
      </div>
    </div>
  );
};

export default MainProfile;
