import styles from "./headerDrawerMobile.module.css";
import cross from "../../../../assets/images/cross.svg";
import arrowBack from "../../../../assets/icons/arrow_back.svg";
interface PropsHeaderDrawer {
  goBack: () => void;
  onClose: () => void;
  title: string;
}
const HeaderDrawerMobile = ({ goBack, onClose, title }: PropsHeaderDrawer) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleHeader}>
        <img src={arrowBack} onClick={goBack} />
        <h1 className={styles.title}> {title}</h1>
      </div>

      <img src={cross} className={styles.crossImg} onClick={onClose} alt="" />
    </div>
  );
};

export default HeaderDrawerMobile;
