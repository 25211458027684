import React, { FC } from "react";
import { getLessonById_getLessonById } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./Headerinteligent.module.scss";
import { Tag } from "antd";
import ensayoMatematica from "../../../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../../../assets/images/thumbnail/ensayoCiencias.png";
import closeIcon from "../../../../../../assets/icons/others/close-icon-blue.png";
import defaultImage from "../../../../../../assets/images/defaultImage.svg";
interface PropsHeader {
  individualLesson: getLessonById_getLessonById;
  onCloseDrawer: () => void;
}

const HeaderInteligentTeacher: FC<PropsHeader> = ({
  onCloseDrawer,
  individualLesson,
}) => {
  return (
    <div className={styles.task}>
      <div className={styles.firstTaskColumn}>
        <div className={styles.pictureContainer}>
          <img
            src={
              individualLesson?.lesson_type === "redaction" &&
              individualLesson.subject_id === 1
                ? ensayoMatematica
                : individualLesson?.lesson_type === "redaction" &&
                  individualLesson.subject_id === 2
                ? ensayoCiencias
                : individualLesson?.lesson_type === "redaction" &&
                  individualLesson.subject_id === 4
                ? ensayoEspañol
                : defaultImage
            }
            alt="lesson"
          />
        </div>
      </div>
      <div className={styles.secondTaskColumn}>
        <div className={styles.tagsAndTitle}>
          <Tag color="white" className={styles.tagsGrade}>
            {individualLesson?.grade?.grade}
          </Tag>
          <Tag color="white" className={styles.tagsGrade}>
            Semana {individualLesson?.week}
          </Tag>

          <h3 className={styles.titleTask}>{individualLesson?.title ?? ""}</h3>
        </div>
        <div>
          <h3 className={styles.descriptionLesson}>
            {individualLesson?.description}
          </h3>
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img src={closeIcon} alt="closeIcon" onClick={onCloseDrawer} />
      </div>
    </div>
  );
};

export default HeaderInteligentTeacher;
