import React, { FC } from "react";
import styles from "./relate.module.scss";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsRelate {
  index: number;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
  subject_id?: number | null;
}

const Relate: FC<PropsRelate> = ({ index, question, subject_id }) => {
  const functionPutAlpha = (i?: number) =>
    (i === 1 && "A") ||
    (i === 2 && "B") ||
    (i === 3 && "C") ||
    (i === 4 && "D") ||
    "";

  console.log(question.processedBlocks)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3 className={styles.titleRelate}>
          {index + 1}.{" "}
          {question.question
            ? question.question
            : subject_id === 16
            ? "Relate to concepts by joining the two columns."
            : "Relaciona con conceptos uniendo las dos columnas."}
        </h3>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.containerBlock}>
          <div className={styles.firstColumn}>
            {question.processedBlocks?.map((e) => {
              const ok = e.block1?.coincidence === e.block2?.coincidence;
              return (
                <div className={styles.containerbuttonContent}>
                  <div className={styles.buttonContent}>
                    <div>{e.block1?.text}</div>
                  </div>
                  <div
                    className={
                      ok
                        ? styles.circleFirstColumnSelectedTrue
                        : styles.circleFirstColumnSelectedFalse
                    }
                  >
                    {functionPutAlpha(e.block1?.coincidence!)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.secondColumn}>
            {question.processedBlocks?.map((e) => {
              const ok = e.block1?.coincidence === e.block2?.coincidence;
              return (
                <div className={styles.containerbuttonContent}>
                  <div
                    className={
                      ok
                        ? styles.circleSelectedTrue
                        : styles.circleSelectedFalse
                    }
                  >
                    {functionPutAlpha(e.block1?.coincidence!)}
                  </div>

                  <div className={styles.buttonContent}>
                    <div>{e.block2?.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relate;
