import { Checkbox, Collapse, Drawer, List } from "antd";
import styles from "./dosificationDrawer.module.scss";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import downarrow from "../../../../assets/icons/downArrow.svg";
import uparrow from "../../../../assets/icons/uparrow.svg";
import arrowback from "../../../../assets/icons/arrow_back.svg";
import { useState } from "react";
import {
	NotificationPositive,
	notificationNegative,
} from "../../../Shared/Notifacation/NotificationLuca";
import axios from "axios";
import { ActivityIndicator } from "../../../ActivityIndicator/ActivityIndicator";
import useIsMobile from "../../../../hooks/useIsMobile";
interface PropsFilter {
	onClose: () => void;
	visible: boolean;
}
const { Panel } = Collapse;

const dosificationCategories123 = [
	{
		name: "Lenguajes",
		pdfs: [
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/1o+Grado+-+Lenguajes+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/2o+Grado+-+Lenguajes+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/3o+Grado+-+Lenguajes+(NEM).pdf",
		],
	},
	{
		name: "Pensamiento científico y matemático",
		pdfs: [
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/1o+grado+-+Matem%C3%A1ticas+y+C%C3%ADencias+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/2o+grado+-+Matem%C3%A1ticas+y+Ciencias+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/3o+grado+-+Matem%C3%A1ticas+y+Ciencias+(NEM).pdf",
		],
	},
];
const dosificationCategories456 = [
	{
		name: "Lenguajes",
		pdfs: [
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/4o+Grado+-+Lenguajes+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/5o+Grado+-+Lenguajes+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/6o+Grado+-+Lenguajes+(NEM)2.pdf",
		],
	},
	{
		name: "Pensamiento científico",
		pdfs: [
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/4o+grado+-+Ciencias+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/5o+grado+-+Ciencias+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/6o+grado+-+Ciencias+(NEM).pdf",
		],
	},
	{
		name: "Pensamiento matemático",
		pdfs: [
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/4o+grado+-+Matem%C3%A1ticas+(NEM).pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/5o+grado+-+Matem%C3%A1ticas+(NEM)2.pdf",
			"https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/6o+grado+-+Matem%C3%A1ticas+(NEM).pdf",
		],
	},
];
const grados = [
	"1º Grado",
	"2º Grado",
	"3º Grado",
	"4º Grado",
	"5º Grado",
	"6º Grado",
];

const DosificationDrawer = ({ onClose, visible }: PropsFilter) => {
	const isMobile = useIsMobile();
	const isPanelActive = (panelKey: string) => activePanels.includes(panelKey);

	const [activePanels, setActivePanels] = useState<string[]>([]);
	const handlePanelChange = (panelKey: string | string[]) => {
		setActivePanels(Array.isArray(panelKey) ? panelKey : [panelKey]);
		setSelectedCategory("");
	};
	const [pdfUrlToDownload, setPdfUrlToDownload] = useState("");
	const [selectedCategory, setSelectedCategory] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);

	const handleCheckboxChange = (category: string, i: number) => {
		const isGrade123 = i <= 2;

		const selectedCategoryObject = isGrade123
			? dosificationCategories123.find((item) => item.name === category)
			: dosificationCategories456.find((item) => item.name === category);

		if (selectedCategoryObject) {
			const pdfs = selectedCategoryObject.pdfs;

			if (isGrade123) {
				if (i >= 0 && i < pdfs.length) {
					const pdfUrl = pdfs[i];
					setPdfUrlToDownload(pdfUrl);

					if (selectedCategory === category) {
						return setSelectedCategory("");
					}
					return setSelectedCategory(category);
				}
			} else {
				const adjustedIndex = i - 3;
				if (adjustedIndex >= 0 && adjustedIndex < pdfs.length) {
					const pdfUrl = pdfs[adjustedIndex];
					setPdfUrlToDownload(pdfUrl);

					if (selectedCategory === category) {
						return setSelectedCategory("");
					}
					return setSelectedCategory(category);
				}
			}
		}
	};

	const handleSubmit = async () => {
		if (selectedCategory) {
			setIsLoading(true);
			try {
				const pdfUrl = pdfUrlToDownload;

				const partesDeUrl = pdfUrl.split("/");
				const nombreDelArchivo = partesDeUrl[partesDeUrl.length - 1];

				const response = await axios.get(pdfUrl, {
					responseType: "blob",
				});

				const blob = new Blob([response.data], { type: "application/pdf" });

				const blobUrl = URL.createObjectURL(blob);

				const link = document.createElement("a");
				link.href = blobUrl;
				link.download = nombreDelArchivo;
				link.style.display = "none";

				document.body.appendChild(link);
				link.click();

				URL.revokeObjectURL(blobUrl);
				NotificationPositive(
					"¡Bien! Se ha descargado el archivo correctamente."
				);
			} catch (error) {
				console.error(error);
				notificationNegative(
					"¡Ups! Algo salió mal. Por favor, inténtalo de nuevo."
				);
			}
			setIsLoading(false);
		} else {
			notificationNegative("¡Ups! No se ha seleccionado una categoría.");
		}
	};

	return (
		<Drawer
			height={isMobile ? "400px" : ""}
			placement={isMobile ? "bottom" : "right"}
			width={500}
			onClose={onClose}
			open={visible}
			closable={false}
			style={{
				background: "white !important",
			}}
			title={
				<div className={styles.header}>
					<div className={styles.headerContainer}>
						<div>
							<img src={arrowback} alt="arrow back" onClick={onClose} />
						</div>
						<div className={styles.titleHeader}>
							<h1>Volver a lecciones</h1>
						</div>
						<img src={closeIcon} alt="close icon" onClick={onClose} />
					</div>
					<div className={styles.descriptionContainer}>
						Descarga las dosificaciones de Luca del curso 23/24 de Lenguajes,
						Pensamiento científico y Pensamiento matemático.
					</div>
				</div>
			}
			footer={
				<div className={styles.footer}>
					<div className={styles.taskButton} onClick={handleSubmit}>
						Descargar dosificación
					</div>
				</div>
			}
		>
			{isLoading ? (
				<ActivityIndicator></ActivityIndicator>
			) : (
				<Collapse
					className={styles.collapseContainer}
					bordered={false}
					accordion
					onChange={handlePanelChange}
					activeKey={activePanels}
				>
					{grados.map((grado, i) => {
						return (
							<Panel
								extra={
									<div>
										{isPanelActive(i.toString()) ? (
											<img src={uparrow} alt="Panel Abierto" />
										) : (
											<img src={downarrow} alt="Panel Cerrado" />
										)}
									</div>
								}
								showArrow={false}
								header={
									<div
										className={
											isPanelActive(i.toString())
												? styles.clickedPanelHeader
												: styles.panelHeader
										}
									>
										{grado}
									</div>
								}
								className={styles.panelStyle}
								key={i}
							>
								<List
									dataSource={
										i > 2
											? dosificationCategories456
											: dosificationCategories123
									}
									renderItem={(dosificationCategory) => (
										<List.Item className={styles.listItem}>
											<Checkbox
												checked={selectedCategory === dosificationCategory.name}
												onChange={() => {
													handleCheckboxChange(dosificationCategory.name, i);
												}}
											/>
											<div
												className={
													selectedCategory === dosificationCategory.name
														? styles.listItemChecked
														: styles.listItem
												}
											>
												{dosificationCategory.name}
											</div>
										</List.Item>
									)}
								/>
							</Panel>
						);
					})}
				</Collapse>
			)}
		</Drawer>
	);
};

export default DosificationDrawer;
