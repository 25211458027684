import { FC } from "react";
import { SelectAvatar } from "../../../components";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";

interface SelectAvatarProps {
  userDb?: getUserByEmail_getUserByEmail | null;
}

const SelectAvatarPage: FC<SelectAvatarProps> = ({ userDb }) => {
  return <SelectAvatar userDb={userDb} />;
};

export default SelectAvatarPage;
