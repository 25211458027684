import { Tabs } from "antd";
import styles from "./drawerAvatar.module.scss";
import candadoVioleta from "../../../../../assets/images/candadoVioleta.svg";
import candadoGris from "../../../../../assets/images/candadoGris.svg";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
import { resolveAvatar } from "../../../../../utils/functions";

interface DrawerProfileProps {
  userDb: getUserByEmail_getUserByEmail | null;
}

const DrawerAvatar = ({ userDb }: DrawerProfileProps) => {
  const { TabPane } = Tabs;

  return (
    <>
      <div className={styles.bodyDrawer}>
        <img
          src={resolveAvatar(
            userDb?.avatar ?? "polux",
            userDb?.score?.level?.toString() ?? "1"
          )}
          alt="avatar_image"
        />

        <div className={styles.candadosContainer}>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="Complementos" key="1">
              <div className={styles.candadosSubContainer}>
                <img src={candadoVioleta} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
              </div>
              <div className={styles.candadosSubContainer}>
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
                <img src={candadoGris} alt="" />
              </div>
            </TabPane>
            <TabPane tab="Ropa" disabled key="2">
              Tab 2
            </TabPane>
            <TabPane tab="Objetos" disabled key="3">
              Tab 3
            </TabPane>
            <TabPane tab="Fondos" disabled key="4">
              Tab 4
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default DrawerAvatar;
