import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./mainAsteroidsPage.module.scss";
import timerColor from "../../../../assets/icons/students/challenges/timer_color.svg";
import papelLapiz from "../../../../assets/icons/students/challenges/papel_lapiz.svg";
import close from "../../../../assets/icons/students/closeMobile.svg";
interface Props {
  setStep2: (e: boolean) => void;
  setShowInstructions: (e: boolean) => void;
}
const MainAsteroidsPage: FC<Props> = ({ setStep2, setShowInstructions }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <img
        src={close}
        alt="exit"
        className={styles.close}
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className={styles.content}>
        <h1>Desafío asteroide</h1>
        <p>
        Acierta las operaciones matemáticas antes de <br/> que los asteroides se acerquen a tu nave.<br />
        </p>
        <div className={styles.timeContainer}>
          <img src={papelLapiz} alt="time" />
          <p>Prepara papel y lápiz</p>
        </div>
        <div className={styles.timeContainer}>
          <img src={timerColor} alt="time" />
          <p>Tienes 1 minuto </p>
        </div>
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.instructions}
          onClick={() => setShowInstructions(true)}
        >
          INSTRUCCIONES
        </button>
        <button className={styles.continue} onClick={() => setStep2(true)}>
          COMENZAR
        </button>
      </div>
    </div>
  );
};

export default MainAsteroidsPage;
