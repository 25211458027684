import React, { FC } from "react";
import styles from "./studentCard.module.scss";
import {
  resolveAvatar,
  resolveColorAvatar,
} from "../../../../../../utils/functions";

interface StudentCardProps {
  user: any;
}

const StudentCard: FC<StudentCardProps> = ({ user }) => {
  return (
    <div className={styles.container}>
      <span
        className={styles.studentAvatar}
        style={{ background: resolveColorAvatar(user.avatar).slice(0, 1) }}
      >
        <img
          src={resolveAvatar(user.avatar ?? "polux", "1")}
          alt="user_avatar"
        />
      </span>

      <div className={styles.right}>
        <h1>
          {user.first_name} {user.last_name}
        </h1>
        <div className={styles.lastRow}>
          <h2>
            COMPLETADA <span style={{ color: "#6A6F8A" }}> {}% </span>
          </h2>
          <h2>
            PROMEDIO <span> {}</span>{" "}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
