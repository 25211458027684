import { FC, useState } from "react";
import styles from "./classes.module.scss";
import { Banner } from "./Banner";
import { Card } from "../Classes/Card";
import { Header } from "../Layout/Header";
import { BannerMobile, CardMobile } from "../Mobile/Teacher/Clasess";
// Api & Types
import useIsMobile from "../../hooks/useIsMobile";
import { ModalError } from "../Students/Missions/NewQuizz/ModalSave";
import { BannerRachas } from "./BannerRachas";
import { useGetUser } from "../../hooks/useGetUser";

const Classes: FC = () => {
  const isMobile = useIsMobile();
  const { userDb } = useGetUser();
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  return (
    <div className={styles.container}>
      <Header title="Mis clases" />

      {isMobile ? (
        <div className={styles.containerMobile}>
          <BannerMobile></BannerMobile>
          {userDb?.classrooms?.map((classRoom) => {
            return <CardMobile item={classRoom} key={classRoom.name} />;
          })}
        </div>
      ) : (
        <div className={styles.content}>
          <Banner />
          <BannerRachas />

          <div className={styles.cardsContainer}>
            {userDb?.classrooms?.map((classRoom) => {
              return <Card item={classRoom} key={classRoom.name} />;
            })}
          </div>
        </div>
      )}

      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default Classes;
