import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { studentFinishLessonInLibraryVariables } from "../__generated__/gql-types/studentFinishLessonInLibrary";

const useStudentFinishLessonInLibrary = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: studentFinishLessonInLibraryVariables) => {
      return await client.mutate({
        mutation: STUDENT_FINISH_LESSON_IN_LIBRARY_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useStudentFinishLessonInLibrary;

const STUDENT_FINISH_LESSON_IN_LIBRARY_EVENT = gql`
  mutation studentFinishLessonInLibrary(
    $typeLesson: String
    $lessonId: String
    $section: String
  ) {
    studentFinishLessonInLibrary(
      typeLesson: $typeLesson
      lessonId: $lessonId
      section: $section
    )
  }
`;
