import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherCreateProjectLog = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_CREATE_PROJECT_LOG,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherCreateProjectLog;

const TEACHER_CREATE_PROJECT_LOG = gql`
  mutation teacherProjectCreateLog {
    teacherProjectCreateLog
  }
`;
