import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getClothesAndAccessories } from "../__generated__/gql-types/getClothesAndAccessories";

const UseGetClothesAndAccessories = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClothesAndAccessories> =
      await client.query({
        query: GET_CLOTHES_AND_ACCESSORIES,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClothesAndAccessories;
  }, [client]);
};

export default UseGetClothesAndAccessories;

const GET_CLOTHES_AND_ACCESSORIES = gql`
  query getClothesAndAccessories {
    getClothesAndAccessories {
      id
      name
      type
      clasification
      z_index
      price
      publish
      active
      purchased
      item_img
      thumbnail_img
      created_at
      updated_at
      deleted_at
    }
  }
`;
