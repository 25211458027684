import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { studentStartHomeworkActionEventVariables } from "../__generated__/gql-types/studentStartHomeworkActionEvent";

const useStudentStartHomeworkActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (origin: studentStartHomeworkActionEventVariables) => {
      return await client.mutate({
        mutation: STUDENT_START_HOMEWORK_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: origin,
      });
    },
    [client]
  );
};
export default useStudentStartHomeworkActionEvent;

const STUDENT_START_HOMEWORK_ACTION_EVENT = gql`
  mutation studentStartHomeworkActionEvent($origin: String, $itemId: Int) {
    studentStartHomeworkActionEvent(origin: $origin, item_id: $itemId)
  }
`;
