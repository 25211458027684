import React from "react";
import styles from "./bannerPoints.module.scss";
import useAuth from "../../../context/useAuth";

import illustration from "../../../assets/images/ilustration.png";
import illustrationMobile from "../../../assets/images/illustrationMobile.svg";
const BannerPoints = () => {
  const { userToken } = useAuth();

  return (
    <div className={styles.container}>
      <div className={styles.bodyContent}>
        <h1 className={styles.title}>¡Hola {userToken?.first_name}!</h1>
        <h3 className={styles.subTitle}>
          Gestiona la clasificación de tus alumnos en Luca y dales puntos en
          clase para potenciar su dedicación.
        </h3>
      </div>
      <img alt="" src={illustration} className={styles.imageIllustration} />
      <img
        alt=""
        src={illustrationMobile}
        className={styles.imageIllustrationMobile}
      />
    </div>
  );
};

export default BannerPoints;
