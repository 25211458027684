import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getCoursesBySearch,
  getCoursesBySearchVariables,
} from "../__generated__/gql-types/getCoursesBySearch";

const UseGetCoursesBySearch = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySearchVariables) => {
      const result: ApolloQueryResult<getCoursesBySearch> = await client.query({
        query: GET_CLASSES,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySearch;
    },
    [client]
  );
};

export default UseGetCoursesBySearch;

const GET_CLASSES = gql`
  query getCoursesBySearch($getCoursesSearchArgs: GetCoursesSearchArgs) {
    getCoursesBySearch(getCoursesSearchArgs: $getCoursesSearchArgs) {
      week
      week_2017
      title
      subject_id
      id
      program_2017
      nem
      sep_book_pages
      sep_book_pages_2017
      grade_id
    }
  }
`;
