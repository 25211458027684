import { FC } from "react";
import XLSX from "xlsx";
import styles from "./downloadStudentsHomeworkResultsAsExcel.module.scss";
import downloader from "../../../../assets/icons/teacher/dowloader.svg";
import { getHomeworkById_getHomeworkById } from "../../../../__generated__/gql-types/getHomeworkById";

interface DownloadStudentsHomeworksResultsAsExcelProps {
  dataToRender: getHomeworkById_getHomeworkById;
  classRoom: any;
}

export const DownloadStudentsHomeworksResultsAsExcel: FC<
  DownloadStudentsHomeworksResultsAsExcelProps
> = ({ dataToRender, classRoom }) => {
  const finalUsers = dataToRender?.students_grades?.map((student) => {
    return {
      Lección: dataToRender?.lessons && dataToRender.lessons[0].title,
      Alumno: `${student.student_name}`,
      Estado: student.delivered ? "Entregada" : "Sin Entregar",
      Intentos: student.tries,
      Mejor: student.high_score,
      Evidencia: student.studentEvidenceUrl?.length ? "Si" : "No",
    };
  });

  const exportFile = () => {
    const wscols = [
      { wch: 20 },
      { wch: 35 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    const ws = XLSX.utils.json_to_sheet(
      finalUsers !== undefined ? finalUsers : []
    );

    ws["!cols"] = wscols;
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFileXLSX(
      wb,
      dataToRender?.__typename === "Homework"
        ? `Reporte Tareas ${
            dataToRender?.lessons && dataToRender.lessons[0].title
          } ${dataToRender?.start_date} ${dataToRender?.end_date}.xlsx`
        : `Reporte Alumnos ${classRoom.name}.xlsx`
    );
  };

  return (
    <>
      <button
        className={`${styles.taskButtonDelete} ${styles.footerButton}`}
        onClick={exportFile}
      >
        Descargar Resultados
        <img src={downloader} alt="downloader" />
      </button>
    </>
  );
};
