import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Row } from "./Row";
import { initialData } from "./utils";

interface DragTestProps {
  testResult: any;
  setTestResult: any;
}

const DragTest: React.FC<DragTestProps> = ({
  testResult,
  setTestResult,
}) => {
  const [state, setState] = useState(initialData);

  const onDragEnd = (result: any, state: any, setState: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = state.rows.filter((row: any) => {
        return row.id === source.droppableId;
      })[0];
      const destColumn = state.rows.filter((row: any) => {
        return row.id === destination.droppableId;
      })[0];
      const sourceItems = [...sourceColumn.answers];
      const destItems = [...destColumn.answers];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      if (sourceColumn.id === "column-1") {
        setState({
          ...state,
          rows: [
            { ...sourceColumn, answers: sourceItems },
            { ...destColumn, answers: destItems },
          ],
        });
      } else {
        setState({
          ...state,
          rows: [
            { ...destColumn, answers: destItems },
            { ...sourceColumn, answers: sourceItems },
          ],
        });
      }
    }
    return;
  };

  return (
    <DragDropContext
      onDragEnd={(result: any) => onDragEnd(result, testResult, setTestResult)}
    >
      {testResult?.rows?.map((row: any, index: any) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
            key={row.id}
          >
            <Row rowItems={row} key={row.id} />
          </div>
        );
      })}
    </DragDropContext>
  );
};

export default DragTest;
