import React, { useState } from "react";
import styles from "./activeFilterSearcher.module.scss";
import leftarrow from "../../../assets/icons/leftarrow.svg";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import useAuth from "../../../context/useAuth";
import closeBubbleIcon from "../../../assets/icons/filters/close-bubble.svg";
import gradeIcon from "../../../assets/icons/filters/gradeInactive.svg";
import gradeIconSelected from "../../../assets/icons/filters/gradeActive.svg";
import subjectSelectActive from "../../../assets/icons/filters/subjectSelectActive.svg";
import subjectSelectInactive from "../../../assets/icons/filters/subjectSelectInactive.svg";
import bookSubject from "../../../assets/icons/filters/espanol.svg";
import calculatorSubject from "../../../assets/icons/filters/matematicas.svg";
import scienceSubject from "../../../assets/icons/filters/ciencias.svg";
import englishSubject from "../../../assets/icons/filters/ingles.svg";
import bookSubjectSelect from "../../../assets/icons/filters/espanolSelect.svg";
import calculatorSubjectSelect from "../../../assets/icons/filters/matematicasSelect.svg";
import scienceSubjectSelect from "../../../assets/icons/filters/cienciasSelect.svg";
import englishSubjectSelect from "../../../assets/icons/filters/inglesSelect.svg";

import { Collapse } from "antd";
const { Panel } = Collapse;
interface propsActiveFilterSearcher {
  setGrade: (e: number[]) => void;
  grade: number[];
  setSubject?: (e: number[]) => void;
  setField?: (e: string[]) => void;
  subject?: number[];
  field?: string[];
  setOpenFilter: (e: boolean) => void;
}
const ActiveFilterSearcher = ({
  grade,
  subject,
  field,
  setSubject,
  setGrade,
  setField,
  setOpenFilter,
}: propsActiveFilterSearcher) => {
  const { userToken } = useAuth();
  const [panelKey, setPanelKey] = useState<string | string[]>("1");
  const handleCleanAll = () => {
    setGrade([]);
    setSubject && setSubject([]);
    setField && setField([]);
  };

  const functionReturnSubject = (e: number) => {
    if (e === 1) {
      return "Matemáticas";
    }
    if (e === 2) {
      return "Español";
    }
    if (e === 4) {
      return "Ciencias";
    }
    if (e === 17) {
      return "Gramática";
    }
    if (e === 16) {
      return "Inglés";
    }
  };

  const addGradeFunction = (num: number) => {
    if (grade.includes(num)) {
      const nuevoArray = grade.filter((numero) => numero !== num);
      setGrade(nuevoArray);
    } else {
      setGrade([...grade, num]);
    }
  };
  const addSubjectFunction = (num: number) => {
    if (subject && subject.includes(num)) {
      const nuevoArray = subject.filter((numero) => numero !== num);
      setSubject && setSubject(nuevoArray);
    } else {
      setSubject && subject && setSubject([...subject, num]);
    }
  };
  const addFieldFunction = (stringField: string) => {
    if (field && field.includes(stringField)) {
      const nuevoArray = field.filter((string) => string !== stringField);
      setField && setField(nuevoArray);
    } else {
      setField && field && setField([...field, stringField]);
    }
  };
  return (
    <div className={field ? styles.fieldContainer : styles.container}>
      <button
        name="filters"
        className={styles.itemsFilter}
        onClick={() => setOpenFilter(false)}
      >
        <span className={styles.itemFilter}>
          <img src={settingsIcon} alt="" style={{ marginRight: "10px" }} />
          Filtros
        </span>
        <img src={leftarrow} alt="" />
      </button>
      <div className={styles.filtersContainer}>
        <div className={styles.filterContent}>
          <div className={styles.filtersAppliedContainer}>
            <div>Filtros aplicados</div>

            <div className={styles.deleteAll} onClick={handleCleanAll}>
              Borrar todo
            </div>
          </div>
          <div className={styles.bubblesContainer} style={{ marginTop: "3%" }}>
            {grade.map((e) => (
              <button
                className={styles.bubbleSelected}
                onClick={() => {
                  addGradeFunction(e);
                }}
              >
                {e}º grado
                <img src={closeBubbleIcon} alt="" />{" "}
              </button>
            ))}
          </div>
          <div className={styles.bubblesContainer} style={{ marginTop: "3%" }}>
            {subject?.map((e) => (
              <button
                className={styles.bubbleSelected}
                onClick={() => {
                  addSubjectFunction(e);
                }}
              >
                {functionReturnSubject(e)}
                <img src={closeBubbleIcon} alt="" />{" "}
              </button>
            ))}
          </div>
          <div className={styles.bubblesContainer} style={{ marginTop: "3%" }}>
            {field?.map((e) => (
              <button
                className={styles.bubbleSelected}
                onClick={() => {
                  addFieldFunction(e);
                }}
              >
                {e}
                <img src={closeBubbleIcon} alt="" />{" "}
              </button>
            ))}
          </div>
        </div>
        <Collapse
          ghost
          activeKey={panelKey}
          expandIconPosition={"end"}
          onChange={(key: string | string[]) => {
            setPanelKey(key.length > 0 ? key[key.length - 1] : key);
          }}
        >
          <Panel
            key="1"
            header={
              <div className={styles.filterTitle}>
                {panelKey.includes("1") ? (
                  <>
                    <img src={gradeIconSelected} alt="grade icon selected" />
                    <h1 className={styles.titleSelected}>Grado </h1>
                  </>
                ) : (
                  <>
                    <img src={gradeIcon} alt="grade icon" />
                    <h1>Grado </h1>
                  </>
                )}
              </div>
            }
          >
            <div className={styles.bubblesContainer}>
              {userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                <button
                  className={
                    grade.includes(1) ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    addGradeFunction(1);
                  }}
                >
                  1º grado
                </button>
              )}
              {userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                <button
                  className={
                    grade.includes(2) ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    addGradeFunction(2);
                  }}
                >
                  2º grado
                </button>
              )}

              <button
                className={
                  grade.includes(3) ? styles.bubbleSelected : styles.bubble
                }
                onClick={() => {
                  addGradeFunction(3);
                }}
              >
                3º grado
              </button>

              {userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                <button
                  className={
                    grade.includes(4) ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    addGradeFunction(4);
                  }}
                >
                  4º grado
                </button>
              )}

              {userToken?.schools[0].name !== "Colegio Iluminación" && (
                <button
                  className={
                    grade.includes(5) ? styles.bubbleSelected : styles.bubble
                  }
                  onClick={() => {
                    addGradeFunction(5);
                  }}
                >
                  5º grado
                </button>
              )}
              {userToken?.schools[0].name !== "Colegio Iluminación" &&
                userToken?.schools[0].name !== "COLEGIO PROGRESO" && (
                  <button
                    className={
                      grade.includes(6) ? styles.bubbleSelected : styles.bubble
                    }
                    onClick={() => {
                      addGradeFunction(6);
                    }}
                  >
                    6º grado
                  </button>
                )}
            </div>
          </Panel>
        </Collapse>
        {subject || field ? (
          <Collapse
            ghost
            activeKey={panelKey}
            expandIconPosition={"end"}
            onChange={(key: string | string[]) => {
              setPanelKey(key.length > 0 ? key[key.length - 1] : key);
            }}
          >
            <Panel
              key="2"
              header={
                <div className={styles.filterTitle}>
                  {panelKey.includes("2") ? (
                    <>
                      <img
                        src={subjectSelectActive}
                        alt="grade icon selected"
                      />
                      <h1 className={styles.titleSelected}>
                        {field ? "Campos " : "Asignaturas "}
                      </h1>
                    </>
                  ) : (
                    <>
                      <img src={subjectSelectInactive} alt="grade icon" />
                      <h1>{field ? "Campos " : "Asignaturas "}</h1>
                    </>
                  )}
                </div>
              }
            >
              <div className={styles.bubblesContainer}>
                {field ? (
                  <>
                    <button
                      className={
                        field?.includes("Saberes")
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addFieldFunction("Saberes");
                      }}
                    >
                      Saberes
                    </button>

                    <button
                      className={
                        field?.includes("Ética, Naturaleza y Sociedades")
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addFieldFunction("Ética, Naturaleza y Sociedades");
                      }}
                    >
                      Ética
                    </button>

                    <button
                      className={
                        field?.includes("De lo Humano y lo Comunitario")
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addFieldFunction("De lo Humano y lo Comunitario");
                      }}
                    >
                      De lo Humano
                    </button>
                    <button
                      className={
                        field?.includes("Lenguajes")
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addFieldFunction("Lenguajes");
                      }}
                    >
                      Lenguajes
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={
                        subject?.includes(1)
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addSubjectFunction(1);
                      }}
                    >
                      <img
                        src={
                          subject?.includes(1)
                            ? calculatorSubjectSelect
                            : calculatorSubject
                        }
                      />
                      <div
                        className={
                          subject?.includes(1)
                            ? styles.buttonTextSelected
                            : styles.buttonText
                        }
                      >
                        Matemáticas
                      </div>
                    </button>
                    <button
                      className={
                        subject?.includes(2)
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addSubjectFunction(2);
                      }}
                    >
                      <img
                        src={
                          subject?.includes(2) ? bookSubjectSelect : bookSubject
                        }
                      />
                      <div
                        className={
                          subject?.includes(2)
                            ? styles.buttonTextSelected
                            : styles.buttonText
                        }
                      >
                        Español
                      </div>
                    </button>
                    <button
                      className={
                        subject?.includes(4)
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addSubjectFunction(4);
                      }}
                    >
                      <img
                        src={
                          subject?.includes(4)
                            ? scienceSubjectSelect
                            : scienceSubject
                        }
                      />
                      <div
                        className={
                          subject?.includes(4)
                            ? styles.buttonTextSelected
                            : styles.buttonText
                        }
                      >
                        Ciencias
                      </div>
                    </button>
                    <button
                      className={
                        subject?.includes(17)
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addSubjectFunction(17);
                      }}
                    >
                      <img
                        src={
                          subject?.includes(17)
                            ? bookSubjectSelect
                            : bookSubject
                        }
                      />
                      <div
                        className={
                          subject?.includes(17)
                            ? styles.buttonTextSelected
                            : styles.buttonText
                        }
                      >
                        Gramática
                      </div>
                    </button>
                    <button
                      className={
                        subject?.includes(16)
                          ? styles.bubbleSelectedSubject
                          : styles.bubbleSubject
                      }
                      onClick={() => {
                        addSubjectFunction(16);
                      }}
                    >
                      <img
                        src={
                          subject?.includes(16)
                            ? englishSubjectSelect
                            : englishSubject
                        }
                      />
                      <div
                        className={
                          subject?.includes(16)
                            ? styles.buttonTextSelected
                            : styles.buttonText
                        }
                      >
                        Inglés
                      </div>
                    </button>
                  </>
                )}
              </div>
            </Panel>
          </Collapse>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ActiveFilterSearcher;
