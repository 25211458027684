import React, { useState } from "react";
import styles from "./pointerPointsSingleClass.module.scss";
import baseAvatar from "../../../assets/images/avatars/baseAvatar.svg";
import baseAvatarSelected from "../../../assets/images/avatars/baseAvatarSelected.svg";
import gema from "../../../assets/icons/gema_es.svg";
import classIcon from "../../../assets/images/avatars/classIcon.svg";
import { getClassroomById_getClassroomById } from "../../../__generated__/gql-types/getClassroomById";
import {
  NotificationPositive,
  notificationNegative,
} from "../../Shared/Notifacation/NotificationLuca";
import UseAddClassroomPoints from "../../../api/useAddClassroomPoints";
import {
  UserScoreInput,
  scoreTypes,
} from "../../../__generated__/gql-types/globalTypes";
import UseSubtractClassroomPoints from "../../../api/useSubtractClassroomPoints";
import { resolveAvatar } from "../../../utils/functions";
import useTeacherAddPoints from "../../../api/useTeacherAddPoints";
import useTeacherSubstractPoints from "../../../api/useTeacherSubstractPoints";
import { functionReturnImgAvatar } from "../../Store/ColumnAvatar/ContainerAvatar/utils";

interface PropsPointer {
  classRomm: getClassroomById_getClassroomById | null;
}
const PointerPointsSingleClass = ({ classRomm }: PropsPointer) => {
  const [contPoints, setContPoints] = useState(10);
  const addClassroomPoints = UseAddClassroomPoints();
  const subtractClassroomPoints = UseSubtractClassroomPoints();
  const [idCards, setIdCards] = useState<string[]>([]);
  const TeacherAddPointsEvent = useTeacherAddPoints();
  const TeacherSubstractPointsEvent = useTeacherSubstractPoints();

  const putIdCards = (id: string) => {
    if (idCards) {
      const updatedIdCards = [];
      for (const e of idCards) {
        if (e !== id) {
          updatedIdCards.push(e);
        }
      }
      if (idCards.includes(id)) {
        setIdCards(updatedIdCards);
      } else {
        updatedIdCards.push(id);
        setIdCards(updatedIdCards);
      }
    }
  };
  const selectAllId = () => {
    if (idCards && classRomm && classRomm.group && classRomm.group.users) {
      if (idCards.length === classRomm.group.users.length) {
        setIdCards([]);
      } else {
        const arrayAllId = [];
        for (const e of classRomm.group.users) {
          if (e.id) {
            arrayAllId.push(e.id);
          }
        }
        setIdCards(arrayAllId);
      }
    }
  };

  const handlePlusMutation = async () => {
    if (idCards?.length !== 0) {
      try {
        const arrayInput: UserScoreInput[] = [];
        for (const id of idCards) {
          arrayInput.push({
            user_id: Number(id),
            score: contPoints > 30 ? 30 : contPoints,
            scoreType: scoreTypes.main_grade_leaderboard_score,
          });
        }
        await addClassroomPoints(arrayInput);
        TeacherAddPointsEvent();
        NotificationPositive("¡Estupendo!  Has enviado puntos a tus alumnos.");
        setIdCards([]);
      } catch (error) {
        notificationNegative(
          "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."
        );
      }
    } else {
      notificationNegative(
        "¡Ups! algo ha ido mal. Por favor, seleccione alumnos."
      );
    }
  };
  const handleRestMutation = async () => {
    if (idCards?.length !== 0) {
      try {
        const arrayInput: UserScoreInput[] = [];
        for (const id of idCards) {
          arrayInput.push({
            user_id: Number(id),
            score: contPoints > 30 ? 30 : contPoints,
            scoreType: scoreTypes.main_grade_leaderboard_score,
          });
        }
        await subtractClassroomPoints(arrayInput);
        TeacherSubstractPointsEvent();
        NotificationPositive("¡Estupendo!  Has restado puntos a tus alumnos.");
        setIdCards([]);
      } catch (error) {
        notificationNegative(
          "¡Ups! algo ha ido mal. Por favor, vuelve a intentarlo."
        );
      }
    } else {
      notificationNegative(
        "¡Ups! algo ha ido mal. Por favor, seleccione alumnos."
      );
    }
  };
  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.cardClass} onClick={selectAllId}>
          <span className={styles.studentAvatar}>
            <img className={styles.avatarIcon} alt="avatar" src={classIcon} />
          </span>
          <div className={styles.cardContainerTextClase}>CLASE</div>
        </div>
        {classRomm?.group?.users?.map((user) => {
          return (
            <div
              key={user.id}
              className={
                idCards?.includes(user.id) ? styles.cardSelected : styles.card
              }
              onClick={() => {
                putIdCards(user.id);
              }}
            >
              <span className={styles.studentAvatarUser}>
                {user.shopActions?.map((clothes) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    user.score?.level && user.score?.level < 6
                      ? resolveAvatar(
                          user.avatar ?? "polux",
                          user.score?.level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(user.avatar ?? "")
                  }
                  alt="avatar"
                  className={
                    user.score?.level && user.score?.level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />

                {idCards?.includes(user.id) && (
                  <img
                    alt="base"
                    src={baseAvatarSelected}
                    className={styles.avatarBase}
                  />
                )}
                {!idCards?.includes(user.id) && (
                  <img
                    alt="base"
                    src={baseAvatar}
                    className={styles.avatarBase}
                  />
                )}
              </span>
              <div className={styles.cardContainerText}>
                {user.first_name} {user.last_name?.slice(0, 1)}.
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.botonPoints}>
        <div className={styles.botonRest} onClick={handleRestMutation}>
          Restar Puntos
        </div>
        <div className={styles.botonContainerPoints}>
          <div
            className={styles.iconPointsRest}
            onClick={() => {
              contPoints !== 10 && setContPoints(contPoints - 10);
            }}
          >
            -
          </div>
          <div className={styles.contPointText}> {contPoints} PUNTOS</div>
          <div
            className={styles.iconPointsPlus}
            onClick={() => {
              if (contPoints < 30) {
                setContPoints(contPoints + 10);
              }
            }}
          >
            +
          </div>
        </div>
        <div className={styles.botonPlus} onClick={handlePlusMutation}>
          Sumar Puntos
        </div>
      </div>

      <div className={styles.botonMobileContainer}>
        <div className={styles.botonContainerPointsMobile}>
          <div
            className={styles.iconPointsRest}
            onClick={() => {
              contPoints !== 10 && setContPoints(contPoints - 10);
            }}
          >
            -
          </div>
          <div className={styles.contPointText}> {contPoints} PUNTOS</div>
          <div
            className={styles.iconPointsPlus}
            onClick={() => {
              if (contPoints < 30) {
                setContPoints(contPoints + 10);
              }
            }}
          >
            +
          </div>
        </div>
        <div className={styles.botonContainerHandle}>
          <div className={styles.botonRestMobile} onClick={handleRestMutation}>
            - <img src={gema} alt="gema" />
          </div>
          <div className={styles.botonPlusMobile} onClick={handlePlusMutation}>
            + <img src={gema} alt="gema" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PointerPointsSingleClass;
