import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherNavigateSiderVariables } from "../__generated__/gql-types/teacherNavigateSider";

const useTeacherNavigateSider = () => {
  const client = useApolloClient();

  return useCallback(
    async (action: teacherNavigateSiderVariables) => {
      return await client.mutate({
        mutation: TEACHER_NAVIGATE_SIDER,
        fetchPolicy: "no-cache",
        variables: action,
      });
    },
    [client]
  );
};
export default useTeacherNavigateSider;

const TEACHER_NAVIGATE_SIDER = gql`
  mutation teacherNavigateSider($action: Int) {
    teacherNavigateSider(action: $action)
  }
`;
