import React, { FC, useEffect, useState } from "react";
import styles from "./videosNemSearcher.module.scss";
import { Card } from "../../Lessons/Card";
import { getLessonById_getLessonById } from "../../../__generated__/gql-types/getLessonById";
import { Loader } from "../../Commons";
import { DrawerLessonNew } from "../../Lessons/Drawer";
import useDebounce from "../../../hooks/useDebounce";
import UseGetLessonsSearch from "../../../api/useGetLessonsSearch";
import { getLessonsSearch_getLessonsSearch } from "../../../__generated__/gql-types/getLessonsSearch";

interface VideosNemSearchersProps {
  searchInput: string;
  grade: number[];
  subject: number[];
}
const VideosNemSearcher: FC<VideosNemSearchersProps> = ({
  searchInput,
  grade,
  subject,
}) => {
  const getLessonsSearch = UseGetLessonsSearch();
  const deb = useDebounce(searchInput ?? "", 1200);
  const [lessonsData, setLessonsData] = useState<
    getLessonsSearch_getLessonsSearch[] | null
  >(null);
  const [loadingLessons, setLoadingLessons] = useState(true);
  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [contPagination, setContPagination] = useState(4);

  useEffect(() => {
    (async () => {
      setLoadingLessons(true);
      const lessonsRes = await getLessonsSearch({
        getLessonsSearchArgs: {
          grade_ids: grade.length > 0 ? grade : null,
          page: null,
          subject_ids: subject.length < 1 ? null : subject,
          pageSize: contPagination,
          search: deb,
        },
      });

      setLessonsData(lessonsRes);

      setLoadingLessons(false);
    })();
  }, [contPagination, deb, grade, getLessonsSearch, subject]);

  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <div className={styles.containerPadre}>
      {!deb ? (
        <div className={styles.containerInfo}>
          <div className={styles.textWithoutInfo}>
            Utiliza el buscador para encontrar los videos que te interesan entre
            más de 1 500 videolecciones.
          </div>
        </div>
      ) : (
        <>
          {loadingLessons ? (
            <Loader></Loader>
          ) : lessonsData && lessonsData.length > 0 ? (
            <div className={styles.container}>
              <div className={styles.content}>
                {lessonsData?.map((lesson) => {
                  return (
                    <Card
                      item={lesson}
                      showDrawer={showDrawer}
                      key={lesson?.id + lesson?.title}
                      setIndividualLesson={setIndividualLesson}
                    />
                  );
                })}
              </div>
              {contPagination <= lessonsData.length && (
                <div
                  className={styles.containerButton}
                  style={{ paddingTop: 20 }}
                >
                  <div
                    className={styles.buttonStyles}
                    onClick={() => {
                      setContPagination(contPagination + 4);
                    }}
                  >
                    Ver más
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.containerWithoutCourses}>
              <div className={styles.textWithoutInfo}>
                No hemos encontrado videos para los criterios de tu búsqueda.
                Por favor, intenta de nuevo con otras palabras clave. Por
                ejemplo: "fracciones equivalentes" o "sistema nervioso".
              </div>
            </div>
          )}

          <DrawerLessonNew
            onClose={onClose}
            visible={visible}
            back={"lessons"}
            individualLesson={individualLesson}
          />
        </>
      )}
    </div>
  );
};

export default VideosNemSearcher;
