import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateStatusItemVariables } from "../__generated__/gql-types/updateStatusItem";

const useUpdateStatusItem = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateStatusItemVariables) => {
      return await client.mutate({
        mutation: UPDATE_STATUS_ITEM,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdateStatusItem;

const UPDATE_STATUS_ITEM = gql`
  mutation updateStatusItem($itemId: ID!) {
    updateStatusItem(item_id: $itemId)
  }
`;
