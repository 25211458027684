import React, { useEffect, useState } from "react";
import { Collapse, Select } from "antd";
import { Loader } from "../../Commons";
import styles from "./dosification.module.scss";
import CardDosification from "./CardDosification";
import UseGetLessonsByCourse from "../../../api/useGetLessonByCourse";
import { getLessonsByCourse_getLessonsByCourse } from "../../../__generated__/gql-types/getLessonsByCourse";
import comingSoon from "../../../assets/images/mes2.png";
import lucaBot from "../../../assets/images/lucaBot.png";
import bookSubject from "../../../assets/icons/book_subject.svg";
import calculatorSubject from "../../../assets/icons/calculator_subject.svg";
import scienceSubject from "../../../assets/icons/science_subject.svg";
import { getCoursesBySubjectIdV2NEM_getCoursesBySubjectIdV2NEM } from "../../../__generated__/gql-types/getCoursesBySubjectIdV2NEM";
import useAuth from "../../../context/useAuth";
import { useLocation } from "react-router-dom";

interface DosificationsProps {
  grade_id?: number;
  month: number;
  setLessonId: (id: string) => void;
  setSelectAcordion: (id: string) => void;
  setSubjectId: (id: string[]) => void;
  setIndividualLesson?: (item: any) => void;
  showDrawer: () => void;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  setGrade: (e: number) => void;
  coursesState: getCoursesBySubjectIdV2NEM_getCoursesBySubjectIdV2NEM[];
  loadingDosification: boolean;
  selectAcordion: string;
}

const { Panel } = Collapse;

export const Dosifications = ({
  grade_id,
  loadingDosification,
  coursesState,
  month,
  selectAcordion,
  setSubjectId,
  setLessonId,
  setSelectAcordion,
  setIndividualLesson,
  showDrawer,
  setMonth,
  setGrade,
}: DosificationsProps) => {
  const { userToken } = useAuth();
  const getLessonsByCourse = UseGetLessonsByCourse();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subjectParams = queryParams.get("subject");

  const courseParams = queryParams.get("course");
  const [CourseLessons, setCourseLessons] = useState<
    getLessonsByCourse_getLessonsByCourse[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const handleChangeGrade = (values: string[]) => {
    setGrade(Number(values[0]));
  };
  const handleChangeTrimester = (trimesterSelected: number) => {
    setMonth(trimesterSelected);
  };
  const onChange = async (key: string | string[]) => {
    try {
      let lessonVideo: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonSlide: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonGuion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonDynamics: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonBookPage: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonRedaccion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonEvaluation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonConversation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonWritingExam: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAudioExam: getLessonsByCourse_getLessonsByCourse[] = [];
      setSelectAcordion(key[0]);
      setLoading(true);

      const lessons = await getLessonsByCourse(Number(key[0]));
      if (lessons) {
        for (let index = 0; index < lessons.length; index++) {
          const typeLesson = lessons[index].lesson_type;
          if (typeLesson === "videolesson") {
            lessonVideo.push(lessons[index]);
          }
          if (typeLesson === "slides") {
            lessonSlide.push(lessons[index]);
          }
          if (
            typeLesson === "classroomguide" &&
            userToken?.role === "teacher"
          ) {
            lessonGuion.push(lessons[index]);
          }
          if (
            typeLesson === "manipulative_dynamics" &&
            userToken?.role === "teacher" &&
            userToken?.schools[0].view_dynamics
          ) {
            lessonDynamics.push(lessons[index]);
          }
          if (typeLesson === "book_pages") {
            lessonBookPage.push(lessons[index]);
          }
          if (typeLesson === "redaction" && userToken?.role === "teacher") {
            lessonRedaccion.push(lessons[index]);
          }
          if (typeLesson === "evaluation") {
            lessonEvaluation.push(lessons[index]);
          }
          if (typeLesson === "conversation") {
            lessonConversation.push(lessons[index]);
          }
          if (typeLesson === "writing_exam") {
            lessonWritingExam.push(lessons[index]);
          }
          if (typeLesson === "audio_exam") {
            lessonAudioExam.push(lessons[index]);
          }
        }

        const lessonOrder = lessonGuion
          .concat(lessonDynamics)
          .concat(lessonVideo)
          .concat(lessonSlide)
          .concat(lessonBookPage)
          .concat(lessonRedaccion)
          .concat(lessonConversation)
          .concat(lessonEvaluation)
          .concat(lessonWritingExam)
          .concat(lessonAudioExam);
        setCourseLessons(lessonOrder);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (courseParams) {
        const response = onChange([courseParams]);
      }
    })();
  }, []);

  return (
    <div className={styles.tableContainer}>
      <Collapse
        defaultActiveKey={selectAcordion}
        accordion={true}
        bordered={false}
        className={styles.collapseContainerNem}
        onChange={onChange}
      >
        <div className={styles.rowContainer}>
          <Select
            disabled={
              userToken?.schools[0].name === "Colegio Iluminación" ||
              userToken?.schools[0].name === "COLEGIO PROGRESO"
                ? true
                : false
            }
            defaultValue={
              userToken?.schools[0].name === "Colegio Iluminación"
                ? ["4"]
                : userToken?.schools[0].name === "COLEGIO PROGRESO"
                ? ["5"]
                : userToken?.role === "student" &&
                  userToken?.groups &&
                  userToken?.groups[0] &&
                  userToken?.groups[0].grade_id
                ? [userToken?.groups[0].grade_id.toString()]
                : grade_id
                ? [grade_id!.toString()]
                : ["6"]
            }
            className={styles.selectGradeNem}
            onChange={handleChangeGrade}
            style={{ borderRadius: 12 }}
            options={
              userToken?.schools[0].name === "Colegio Iluminación"
                ? [{ value: "4", label: "4° Grado" }]
                : userToken?.schools[0].name === "COLEGIO PROGRESO"
                ? [{ value: "5", label: "5° Grado" }]
                : userToken?.role === "student" &&
                  userToken?.groups &&
                  userToken?.groups[0] &&
                  userToken?.groups[0].grade_id
                ? [
                    {
                      value: userToken?.groups[0].grade_id.toString(),
                      label: `${userToken?.groups[0].grade_id}° Grado`,
                    },
                  ]
                : [
                    { value: "1", label: "1° Grado" },
                    { value: "2", label: "2° Grado" },
                    { value: "3", label: "3° Grado" },
                    { value: "4", label: "4° Grado" },
                    { value: "5", label: "5° Grado" },
                    { value: "6", label: "6° Grado" },
                  ]
            }
          />
          {subjectParams !== "17" && (
            <div className={styles.containerMonth}>
              <div
                className={
                  month === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                Primer Trimestre
              </div>
              <div
                className={
                  month === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                Segundo Trimestre
              </div>
              <div
                className={
                  month === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                Tercer Trimestre
              </div>
            </div>
          )}
        </div>
        {coursesState.length > 0 && (
          <div className={styles.tableHeaderContainer}>
            {userToken?.role === "student" && month === 3 ? (
              <></>
            ) : (
              <>
                <p className={styles.courseHeaderWithOutSubject}>Curso</p>
                {subjectParams && subjectParams !== "17" && (
                  <p className={styles.weekHeaderWithOutSubject}>Semana</p>
                )}
                <p
                  className={
                    subjectParams && subjectParams !== "17"
                      ? styles.pagesHeaderWithOutSubject
                      : styles.pagesHeaderWithOutSubjectWithoutWeek
                  }
                >
                  Páginas Luca
                </p>
              </>
            )}
          </div>
        )}

        {loadingDosification ? (
          <Loader />
        ) : userToken?.role === "student" && month === 3 ? (
          <div className={styles.comingSoonImgContainer}>
            <div className={styles.comingSoonInnerTextContainer}>
              <h3
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#cc7700",
                  textAlign: "left",
                  paddingLeft: "5px",
                }}
              >
                Próximamente
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Regular",
                  color: "#cc7700",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                Estamos desarrollando el contenido completo de éste trimestre,
                en breve estará disponible.
              </p>
            </div>
            <div
              style={{
                width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
              }}
            >
              <img
                className={styles.comingSoonImg}
                src={lucaBot}
                alt="Proximamente"
              />
            </div>
          </div>
        ) : coursesState.length > 0 ? (
          <>
            {coursesState.map((course) => {
              return (
                <Panel
                  key={course.id}
                  showArrow={false}
                  header={
                    <>
                      <div
                        className={styles.panelContainer}
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        <div className={styles.panelTitleWithOutSubject}>
                          {course.title}
                        </div>
                        {course.subject_id !== 17 && (
                          <div className={styles.panelSemana}>
                            {course.week ? `Semana ${course.week}` : ""}
                          </div>
                        )}
                        <div
                          className={
                            course.subject_id !== 17
                              ? styles.panelSepBookPages
                              : styles.panelSepBookPagesWithoutWeek
                          }
                        >
                          <div>
                            {course.subject_id === 17
                              ? course.sep_book_pages ??
                                course.sep_book_pages_reading ??
                                " -"
                              : course.sep_book_pages ?? " -"}
                          </div>
                        </div>
                      </div>
                      <div className={styles.panelContainerMobile}>
                        <div className={styles.panelSubject}>
                          {course.subject_id === 1 && (
                            <>
                              <img src={calculatorSubject} />
                              <div style={{ color: "#3843D0" }}>
                                P. matemático
                              </div>
                            </>
                          )}
                          {course.subject_id === 2 && (
                            <>
                              <img src={bookSubject} />
                              <div style={{ color: "#3843D0" }}>Lenguajes</div>
                            </>
                          )}
                          {course.subject_id === 4 && (
                            <>
                              <img src={scienceSubject} />
                              <div style={{ color: "#3843D0" }}>
                                P. científico
                              </div>
                            </>
                          )}
                          {course.subject_id === 17 && (
                            <>
                              <img src={bookSubject} />
                              <div style={{ color: "#3843D0" }}>Gramática</div>
                            </>
                          )}
                        </div>

                        {course.subject_id !== 17 && (
                          <div className={styles.panelSemana}>
                            {course.week ? `Semana ${course.week}` : ""}
                          </div>
                        )}
                        <div className={styles.panelSepBookPages}>
                          {course.subject_id === 17
                            ? course.sep_book_pages ??
                              course.sep_book_pages_reading ??
                              " -"
                            : course.sep_book_pages ?? " -"}
                        </div>
                      </div>
                      <div className={styles.panelTitleMobile}>
                        {course.title}
                      </div>
                    </>
                  }
                  style={{
                    background: "#F3F3F6",
                    marginBottom: 10,
                    borderRadius: 12,
                    backgroundColor: "#F3F3F6",
                    fontStyle: "normal",
                    width: "auto",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "140%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F3F3F6",
                      marginRight: -16,
                      borderRadius: 12,
                    }}
                  >
                    <div
                      className={
                        CourseLessons && CourseLessons.length > 3
                          ? styles.cardsContainer
                          : styles.cardsContainerWithoutScroll
                      }
                      style={{
                        backgroundColor: "#F3F3F6",
                        width: "100%",
                      }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        CourseLessons?.map((lesson, index) => {
                          return (
                            <CardDosification
                              lesson={lesson}
                              index={index}
                              showDrawer={showDrawer}
                              setLessonId={setLessonId}
                              key={lesson?.id + lesson?.title}
                              setIndividualLesson={setIndividualLesson}
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                </Panel>
              );
            })}
          </>
        ) : (
          <div className={styles.comingSoonImgContainer}>
            <div className={styles.comingSoonInnerTextContainer}>
              <h3
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#cc7700",
                  textAlign: "left",
                  paddingLeft: "5px",
                }}
              >
                Próximamente
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Regular",
                  color: "#cc7700",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                Luca se caracteriza por sus contenidos de calidad y, como
                siempre, queremos proporcionarles el contenido más actualizado.
                Debido a los cambios generados por la SEP los contenidos de Luca
                se irán desbloqueando conforme avancemos en las semanas del
                ciclo escolar.
                <br /> <br /> Contaremos en todo momento con al menos dos
                semanas de anticipación, para apoyarles en la planeación de
                acuerdo con la dosificación de la SEP.
              </p>
              <p
                style={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  color: "#cc7700",
                  textAlign: "left",
                }}
              >
                Agradecemos su comprensión.
              </p>
            </div>
            <img
              className={styles.comingSoonImg}
              src={comingSoon}
              alt="Proximamente"
            />
          </div>
        )}
      </Collapse>
    </div>
  );
};
