import React, { useEffect, useState } from "react";
import styles from "./clasification.module.scss";
import { BannerClasification } from "./BannerClasification";
import { useParams } from "react-router-dom";
import { TableClasification } from "./TableClasification";
import UseGetLeaderBoard from "../../../api/useGetLeaderBoard";
import { getGradeLeaderBoards_getGradeLeaderBoards } from "../../../__generated__/gql-types/getGradeLeaderBoards";
import { getClassroomById_getClassroomById } from "../../../__generated__/gql-types/getClassroomById";
import { getDifferenceInDays } from "../../../utils/functions";

interface PropsClasification {
  classRomm: getClassroomById_getClassroomById | null;
}
const ClasificationPointsSingleClass = ({ classRomm }: PropsClasification) => {
  let { id } = useParams();
  const getLeaderBoard = UseGetLeaderBoard();
  const [tablaScore, setTablaScore] =
    useState<getGradeLeaderBoards_getGradeLeaderBoards | null>(null);
  const [differenceInDays, setdifferenceInDays] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const responseLeader = await getLeaderBoard();
        const newTable = responseLeader?.filter(
          (e) =>
            e?.grade === Number(classRomm?.program?.grade?.grade) &&
            e?.group_id === Number(classRomm?.group?.id)
        )[0];

        newTable && setTablaScore(newTable);
        setdifferenceInDays(
          14 -
            getDifferenceInDays(
              new Date(
                newTable?.last_reset_at ? Number(newTable?.last_reset_at) : ""
              )
            )
        );
      } catch (error) {
        console.log("manejar error");
      }
    })();
  }, [getLeaderBoard, id]);
  return (
    <div className={styles.container}>
      {!tablaScore ||
      (tablaScore.scores?.length && tablaScore.scores[0].score === 0) ? (
        <>
          {" "}
          <BannerClasification
            tablaScore={
              tablaScore?.scores?.length && tablaScore.scores[0].score === 0
                ? null
                : tablaScore
            }
          />
          <div className={styles.comingSoonImgContainer}>
            <div className={styles.comingSoonInnerTextContainer}>
              <p
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "15px",
                  color: "#cc7700",
                  textAlign: "left",
                }}
              >
                Asigna evaluaciones y tareas a tus alumnos, o entrega puntos en
                clase con la herramienta puntos, para poder visualizar la tabla
                de clasificación.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <BannerClasification
            tablaScore={tablaScore}
            dateLeader={tablaScore?.last_reset_at ?? ""}
            daysLeft={Number(differenceInDays)}
          />
          <TableClasification tablaScore={tablaScore}></TableClasification>
        </>
      )}
    </div>
  );
};

export default ClasificationPointsSingleClass;
