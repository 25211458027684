import React, { FC } from "react";
import styles from "./fraseDrag.module.scss";
import Latex from "react-latex";
import {
  getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2,
  getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2_answers,
} from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsFrase {
  index: number;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
  subject_id?: number | null;
}

const FraseDrag: FC<PropsFrase> = ({ index, question, subject_id }) => {
  const partes = question.question!.split("__");

  const getColor = (
    answer: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2_answers
  ) => {
    if (answer?.correct) {
      return "#8DCE4F";
    } else {
      return "#FF8283";
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {subject_id === 16
            ? "Fill in the spaces with the right word(s)"
            : "Coloca las fichas en su lugar correspondiente."}
        </div>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.textFill}>
          {partes![0]}&nbsp;
          {question.answers && question.answers[0] && (
            <span
              style={
                question.answers[0].correct === 1
                  ? {
                      minWidth: "68px",
                      minHeight: "38px",
                      display: "inline-block",
                      background: "#8DCE4F",
                      textAlign: "center",
                      lineHeight: "30px",
                      padding: "2px 3px",
                      borderRadius: "12px",
                    }
                  : {
                      minWidth: "68px",
                      minHeight: "38px",
                      display: "inline-block",
                      background: "#FF8283",
                      textAlign: "center",
                      lineHeight: "30px",
                      padding: "2px 3px",
                      borderRadius: "12px",
                    }
              }
            >
              {question.answers &&
                question.answers[0] &&
                question.answers[0]?.answer}
            </span>
          )}
          &nbsp;
          {partes[1]}
          &nbsp;
          {question.answers && question.answers[1] && (
            <span
              style={
                question.answers[1].correct === 1
                  ? {
                      minWidth: "68px",
                      minHeight: "38px",
                      display: "inline-block",
                      background: "#8DCE4F",
                      textAlign: "center",
                      lineHeight: "30px",
                      padding: "2px 8px",
                      borderRadius: "12px",
                    }
                  : {
                      minWidth: "68px",
                      minHeight: "38px",
                      display: "inline-block",
                      background: "#FF8283",
                      textAlign: "center",
                      lineHeight: "30px",
                      padding: "2px 8px",
                      borderRadius: "12px",
                    }
              }
            >
              {question.answers[1] && question.answers[1].answer}
            </span>
          )}
          &nbsp;
          {partes[2]}
        </div>
      </div>
    </div>
  );
};

export default FraseDrag;
