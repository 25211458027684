import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLessonsSearch,
  getLessonsSearchVariables,
} from "../__generated__/gql-types/getLessonsSearch";

const UseGetLessonsSearch = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getLessonsSearchVariables) => {
      const result: ApolloQueryResult<getLessonsSearch> = await client.query({
        query: GET_LESSONS_SEARCH,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonsSearch;
    },
    [client]
  );
};

export default UseGetLessonsSearch;

const GET_LESSONS_SEARCH = gql`
  query getLessonsSearch($getLessonsSearchArgs: GetLessonsSearchArgs) {
    getLessonsSearch(GetLessonsSearchArgs: $getLessonsSearchArgs) {
      id
      finished
      week
      title
      status
      subject_id
      lesson_type
      created_at
      sep_name
      lesson_content {
        videos {
          imageUrl
          url
        }
      }
      grade {
        grade
      }
      sep_book_pages
      subject {
        id
      }
    }
  }
`;
