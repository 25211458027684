import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getCoursesBySubjectIdVariables,
  getCoursesBySubjectId,
} from "../__generated__/gql-types/getCoursesBySubjectId";

const UseGetCoursesBySubjectId = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdVariables) => {
      const result: ApolloQueryResult<getCoursesBySubjectId> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySujectId;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectId;

const GET_COURSES_BY_SUBJECT_ID = gql`
  query getCoursesBySubjectId(
    $getCoursesBySubjectIdInput: GetCoursesBySubjectIdInput
  ) {
    getCoursesBySujectId(
      getCoursesBySubjectIdInput: $getCoursesBySubjectIdInput
    ) {
      id
      title
      subject_id
      lessons {
        id
        title
        description
        cover
        week
        result
        finished
        deleted_at
        tags {
          name
          id
        }

        sep_book_pages
        sep_name
        course_id
        axes {
          id
          name
        }
        themes {
          id
          name
        }
        grade {
          id
          name
          grade
        }
        lesson_content {
          id
          pdf
          slide
          quizzes {
            id
            url
            title
            filename
            order
            questions {
              answers {
                id
                answer
                correct
                order
                image
                url
              }
              question
              id
              image
              url
            }
          }
          videos {
            imageUrl
            id
            url
            title
            file_id
            metadata
            order
          }
        }
        subject {
          id
          name
        }
      }
    }
  }
`;
