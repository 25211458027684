import React, { useEffect, useState } from "react";
import { ListProject } from "./ListProject";
import useGetTeacherProjectByTeacherId from "../../api/useGetTeacherProjectsByTeacherId";
import { getTeacherProjectsByTeacherId_getTeacherProjectsByTeacherId } from "../../__generated__/gql-types/getTeacherProjectsByTeacherId";
import { FormProject } from "./FormProject";
import styles from "./newProject.module.scss";
import { Skeleton } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { DependencyRefresh } from "../../redux/store/store";

const NewProject = () => {
  const getProject = useGetTeacherProjectByTeacherId();
  const [isCreating, setIsCreating] = useState(false);
  const [totalProject, setTotalProject] = useState<
    getTeacherProjectsByTeacherId_getTeacherProjectsByTeacherId[] | null
  >(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstProject, setFirstProject] = useState(false);
  const [grade, setGrade] = useState<number[]>([]);
  const dependencyRefresh = useAppSelector(DependencyRefresh);

  const handleProject = async () => {
    let newGrade = grade.length < 1 ? [1, 2, 3, 4, 5, 6] : grade;
    const response = await getProject({
      getProjectsSearchArgs: {
        search: searchInput,
        grade_ids: newGrade,
      },
    });
    setTotalProject(response);
  };
  useEffect(() => {
    const interval = setInterval(handleProject, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [grade, searchInput]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getProject({
        getProjectsSearchArgs: {
          search: "",
          grade_ids: [1, 2, 3, 4, 5, 6],
        },
      });
      response && response?.length > 0 && setFirstProject(true);
      setIsLoading(false);
    })();
  }, [getProject, dependencyRefresh]);
  useEffect(() => {
    (async () => {
      let newGrade = grade.length < 1 ? [1, 2, 3, 4, 5, 6] : grade;
      const response = await getProject({
        getProjectsSearchArgs: {
          search: searchInput,
          grade_ids: newGrade,
        },
      });
      setTotalProject(response);
    })();
  }, [getProject, searchInput, grade]);

  return isLoading ? (
    <div className={styles.containerLoader}>
      <Skeleton active paragraph={{ rows: 1 }} style={{ margin: "24px 0" }} />
      <Skeleton active paragraph={{ rows: 5 }} style={{ margin: "24px 0" }} />
      <Skeleton active paragraph={{ rows: 7 }} style={{ margin: "24px 0" }} />
    </div>
  ) : isCreating ? (
    <FormProject setIsCreating={setIsCreating} />
  ) : (
    <ListProject
      firstProject={firstProject}
      totalProject={totalProject}
      setIsCreating={setIsCreating}
      setGrade={setGrade}
      grade={grade}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
    />
  );
};

export default NewProject;
