import React, { FC, FormEvent, useEffect, useState } from "react";
import styles from "./reAssingAlumns.module.scss";
import { Checkbox, Table } from "antd";
import searchIcon from "../../../../assets/icons/searchIcon2.svg";
import useIsMobile from "../../../../hooks/useIsMobile";
import { ColumnsType } from "antd/es/table";
import { getHomeworkById_getHomeworkById_students_grades } from "../../../../__generated__/gql-types/getHomeworkById";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface ReassingProps {
  setAlumnsReAssing: (
    e: getHomeworkById_getHomeworkById_students_grades[]
  ) => void;
  alumnsReAssing: getHomeworkById_getHomeworkById_students_grades[];
  totalAlumns: getHomeworkById_getHomeworkById_students_grades[];
  setActiveKey: (e: number) => void;
}

const ReAssingAlumns: FC<ReassingProps> = ({
  alumnsReAssing,
  setAlumnsReAssing,
  totalAlumns,
  setActiveKey,
}) => {
  const isMobile = useIsMobile();
  const [totalAlumnsState, setTotalAlumnsState] =
    useState<getHomeworkById_getHomeworkById_students_grades[]>(totalAlumns);
  const [stateCheck, setStateCheck] = useState([""]);

  const handleCheck = (
    value: getHomeworkById_getHomeworkById_students_grades,
    e: CheckboxChangeEvent
  ) => {
    if (e.target.checked) {
      setAlumnsReAssing([...alumnsReAssing, value]);
    } else {
      setAlumnsReAssing(
        alumnsReAssing.filter(
          (alumn) => alumn?.student_id !== value?.student_id
        )
      );
    }
  };

  const checkStatus = (record: any) => {
    return record.status === "finished"
      ? "Completada"
      : record.status === "inCourse"
      ? "En Curso"
      : "Sin Entregar";
  };

  let columns: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    isMobile
      ? [
          {
            title: () => <div className={styles.tableTitles}>ALUMNO</div>,
            dataIndex: "student_name",
            key: "student_name",
            align: "left",
            render: (text, _) => {
              return (
                <div className={styles.userContainerMobile}>
                  <div className={styles.name}> {_.student_name}</div>
                  <p
                    className={
                      checkStatus(_) === "Sin Entregar"
                        ? styles.notDelivered
                        : checkStatus(_) === "Completada"
                        ? styles.delivered
                        : styles.inCourse
                    }
                  >
                    {checkStatus(_)}
                  </p>
                </div>
              );
            },
          },
          {
            title: () => <div className={styles.tableTitles}>CALIFICACIÓN</div>,
            dataIndex: "high_score",
            key: "high_score",
            align: "center",
            render: (i, _) => {
              return (
                <div className={styles.calification}>
                  {_.high_score
                    ? _.high_score
                    : _.high_score === 0
                    ? "0"
                    : "--"}
                </div>
              );
            },
          },
          {
            title: () => <h1 className={styles.tableTitles}></h1>,
            dataIndex: "",
            key: "",
            align: "center",
            render: (i, _) => {
              return (
                <Checkbox
                  onChange={(e) => handleCheck(_, e)}
                  checked={
                    !!alumnsReAssing.find(
                      (alumn) => alumn?.student_id === _?.student_id
                    )
                  }
                ></Checkbox>
              );
            },
          },
        ]
      : [
          {
            title: () => <div className={styles.tableTitles}>ALUMNO</div>,
            dataIndex: "student_name",
            key: "student_name",
            width: 200,
            render: (text, _) => {
              return (
                <div className={styles.userContainer}>
                  <h1> {_.student_name}</h1>
                </div>
              );
            },
          },
          {
            title: () => <div className={styles.tableTitles}>ESTADO</div>,
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 150,
            render: (i, record) => {
              return (
                <p
                  className={
                    checkStatus(record) === "Sin Entregar"
                      ? styles.notDelivered
                      : checkStatus(record) === "Completada"
                      ? styles.delivered
                      : styles.inCourse
                  }
                >
                  {checkStatus(record)}
                </p>
              );
            },
          },

          {
            title: () => <div className={styles.tableTitles}>CALIFICACIÓN</div>,
            dataIndex: "high_score",
            key: "high_score",
            align: "center",
            render: (i, _) => {
              return (
                <div className={styles.calification}>
                  {_.high_score
                    ? _.high_score
                    : _.high_score === 0
                    ? "0"
                    : "--"}
                </div>
              );
            },
          },
          {
            title: () => <div className={styles.tableTitles}></div>,
            dataIndex: "",
            key: "",
            align: "center",
            render: (i, _) => {
              return (
                <Checkbox
                  onChange={(e) => handleCheck(_, e)}
                  checked={
                    !!alumnsReAssing.find(
                      (alumn) => alumn?.student_id === _?.student_id
                    )
                  }
                ></Checkbox>
              );
            },
          },
        ];

  const handleCheckBox = (e: CheckboxChangeEvent) => {
    if (e.target.name === "suspended") {
      if (e.target.checked) {
        let alumnsSupended: getHomeworkById_getHomeworkById_students_grades[] =
          [];
        totalAlumns.forEach((alumn) => {
          if (
            (alumn.high_score && alumn.high_score < 6) ||
            alumn.high_score === 0
          ) {
            alumnsSupended.push(alumn);
          }
        });
        setAlumnsReAssing(alumnsReAssing.concat(alumnsSupended));
      } else {
        setAlumnsReAssing(
          alumnsReAssing.filter(
            (alumn) =>
              !(
                (alumn.high_score && alumn.high_score < 6) ||
                alumn.high_score === 0
              )
          )
        );
      }
    }

    if (e.target.name === "undelivered") {
      if (e.target.checked) {
        let alumnsUndelivered: getHomeworkById_getHomeworkById_students_grades[] =
          [];
        totalAlumns.forEach((alumn) => {
          !alumn.delivered && alumnsUndelivered.push(alumn);
        });
        setAlumnsReAssing(alumnsReAssing.concat(alumnsUndelivered));
      } else {
        setAlumnsReAssing(alumnsReAssing.filter((alumn) => alumn.delivered));
      }
    }

    if (e.target.name === "all") {
      if (e.target.checked) {
        setAlumnsReAssing(totalAlumns);
      } else {
        setAlumnsReAssing([]);
      }
    }
  };
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const filteredData = totalAlumns.filter((item) =>
      item
        .student_name!.toLowerCase()
        .includes(event.currentTarget.value.toLowerCase())
    );

    setTotalAlumnsState(filteredData ?? []);
  };

  useEffect(() => {
    let partialStateCheck: string[] = stateCheck;

    let contSuspend = totalAlumns.filter(
      (alumn) =>
        (alumn.high_score && alumn.high_score < 6) || alumn.high_score === 0
    );
    let contUndelivered = totalAlumns.filter((alumn) => !alumn.delivered);

    contSuspend.length ===
    alumnsReAssing.filter(
      (alumn) =>
        (alumn.high_score && alumn.high_score < 6) || alumn.high_score === 0
    ).length
      ? (partialStateCheck = [...partialStateCheck, "suspended"])
      : (partialStateCheck = partialStateCheck.filter(
          (e) => e !== "suspended"
        ));

    contUndelivered.length ===
    alumnsReAssing.filter((alumn) => !alumn.delivered).length
      ? (partialStateCheck = [...partialStateCheck, "undelivered"])
      : (partialStateCheck = partialStateCheck.filter(
          (e) => e !== "undelivered"
        ));

    totalAlumns.length === alumnsReAssing.length
      ? (partialStateCheck = ["undelivered", "suspended", "all"])
      : (partialStateCheck = partialStateCheck.filter((e) => e !== "all"));
    setStateCheck(partialStateCheck);
  }, [alumnsReAssing, totalAlumns]);

  return (
    <div className={styles.container}>
      <div className={styles.containerCheckBox}>
        <div className={styles.firstColumn}>
          {totalAlumns.find(
            (alumn) =>
              (alumn.high_score && alumn.high_score < 6) ||
              alumn.high_score === 0
          ) && (
            <Checkbox
              className={styles.styleCheck}
              onChange={handleCheckBox}
              name="suspended"
              checked={stateCheck.includes("suspended")}
            >
              No aprobados
            </Checkbox>
          )}
          {totalAlumns.find((alumn) => !alumn.delivered) && (
            <Checkbox
              className={styles.styleCheck}
              onChange={handleCheckBox}
              name="undelivered"
              checked={stateCheck.includes("undelivered")}
            >
              No entregados
            </Checkbox>
          )}
          <Checkbox
            className={styles.styleCheck}
            onChange={handleCheckBox}
            name="all"
            checked={stateCheck.includes("all")}
          >
            Todos
          </Checkbox>
        </div>
        <div className={styles.containerDeselect}>
          <div
            onClick={() => {
              setAlumnsReAssing([]);
            }}
            className={styles.deselect}
          >
            Deseleccionar todos
          </div>
        </div>
      </div>
      <div
        className={styles.containerSearch}
        style={{ height: isMobile ? "40px" : "" }}
      >
        <img src={searchIcon} alt="" />
        <input type="text" placeholder="Buscar" onChange={handleChange} />
      </div>
      <Table
        columns={columns}
        dataSource={totalAlumnsState ?? []}
        pagination={false}
      />
      <div className={styles.containerFooter}>
        <div
          className={styles.taskButton}
          onClick={() => {
            setActiveKey(0);
          }}
        >
          Confirmar
        </div>
      </div>
    </div>
  );
};

export default ReAssingAlumns;
