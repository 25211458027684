import React, { FC } from "react";
import styles from "./headerConversation.module.scss";
import { Tag } from "antd";
import closeIcon from "../../../../../assets/icons/others/close-icon.svg";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";

interface PropsHeader {
  individualLesson: getLessonById_getLessonById;
  onCloseDrawer: () => void;
}

const HeaderConversationn: FC<PropsHeader> = ({
  onCloseDrawer,
  individualLesson,
}) => {
  return (
    <div className={styles.task}>
      <div className={styles.firstTaskColumn}>
        <div className={styles.pictureContainer}>
          <img
            src={
              individualLesson?.lesson_content.aiconversations?.cover_image ??
              ""
            }
            alt="lesson"
          />
        </div>
      </div>
      <div className={styles.secondTaskColumn}>
        <div className={styles.tagsAndTitle}>
          <Tag color="white" className={styles.tagsGrade}>
            {individualLesson?.grade?.grade}
          </Tag>
          <Tag color="white" className={styles.tagsGrade}>
            Semana {individualLesson?.week}
          </Tag>

          <h3 className={styles.titleTask}>{individualLesson?.title ?? ""}</h3>
        </div>
        <div>
          <h3 className={styles.descriptionLesson}>
            {individualLesson?.description}
          </h3>
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img
          src={closeIcon}
          alt=""
          className={styles.crossImg}
          onClick={onCloseDrawer}
        />
      </div>
    </div>
  );
};

export default HeaderConversationn;
