import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { getUserByEmail_getUserByEmail } from "./../../__generated__/gql-types/getUserByEmail";
import jwt from "jwt-decode";
import { notification } from "antd";

export interface PropsState {
  token: string;
  isLoading: boolean;
  isAuthToken: boolean;
  userToken: getUserByEmail_getUserByEmail | null;
  errorToken: string;
  emailOrUsername: string;
}

let userToken = null;
try {
  const tokenFromLocalStorage = localStorage.getItem("token");
  if (tokenFromLocalStorage) {
    userToken = jwt(tokenFromLocalStorage) as getUserByEmail_getUserByEmail;
  }
} catch (error) {
  notification.error({
    message:
      "Ocurrió un error o tu sesión expiró, por favor ingresa nuevamente.",
  });
  console.error("Error al decodificar el token JWT:", error);
}

const initialState = {
  token: localStorage.getItem("token") || "",
  isLoading: false,
  isAuthToken: !!userToken,
  userToken: userToken,
  errorToken: "",
  emailOrUsername: "",
} as PropsState;

export const tokenSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    setisLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setisAuthToken: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAuthToken: action.payload,
    }),
    setUserToken: (
      state,
      action: PayloadAction<getUserByEmail_getUserByEmail | null>
    ) => ({
      ...state,
      userToken: action.payload,
    }),
    outToken: (state) => ({
      ...state,
      token: "",
    }),
    setErrorToken: (state, action: PayloadAction<string>) => ({
      ...state,
      errorToken: action.payload,
    }),
    setEmailOrUsername: (state, action: PayloadAction<string>) => ({
      ...state,
      emailOrUsername: action.payload,
    }),
  },
});

export const {
  setToken,
  outToken,
  setisLoading,
  setisAuthToken,
  setUserToken,
  setErrorToken,
  setEmailOrUsername,
} = tokenSlice.actions;
export const selectToken = (state: RootState) => state.tokens.token;

export default tokenSlice.reducer;
