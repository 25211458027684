import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherAssignClassroomLessonNEMActionEventVariables } from "../__generated__/gql-types/teacherAssignClassroomLessonNEMActionEvent";

const useTeacherAssignClassroomLessonNEMActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherAssignClassroomLessonNEMActionEventVariables) => {
      return await client.mutate({
        mutation: TEACHER_ASSIGN_CLASSROOM_LESSON_NEM_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherAssignClassroomLessonNEMActionEvent;

const TEACHER_ASSIGN_CLASSROOM_LESSON_NEM_ACTION_EVENT = gql`
  mutation teacherAssignClassroomLessonNEMActionEvent(
    $contentId: Int
    $startDate: String
  ) {
    teacherAssignClassroomLessonNEMActionEvent(
      contentId: $contentId
      start_date: $startDate
    )
  }
`;
