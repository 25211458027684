import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./matching.module.scss";
import arrowGreen from "../../../../../../assets/icons/arrows/arrow-green.svg";
interface PropsMatching {
  index: number;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
  subject_id?: number | null;
}

const MatchingTextQuiz: FC<PropsMatching> = ({
  index,
  question,
  subject_id,
}) => {
  const blockItems = question.dAndDQuestion![0].blockItems?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );
  const blockContainer = question.dAndDQuestion![0].blockContainer?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {question.question
            ? question.question
            : subject_id === 16
            ? "Relate the concepts by dragging the card into place."
            : "Relaciona los conceptos arrastrando la ficha a su lugar."}
        </div>
        <div className={styles.containerBlock}>
          <div className={styles.firstColumn}>
            {blockItems?.map((e) => {
              return (
                <div className={styles.containerImageButton}>
                  <div className={styles.containerbuttonContent}>{e.text}</div>
                  <img
                    src={arrowGreen}
                    style={{ width: "20px" }}
                    className={styles.postionImage}
                    alt="arrow"
                  ></img>
                </div>
              );
            })}
          </div>
          <div className={styles.secondColumn}>
            {blockContainer?.map((e) => {
              return (
                <div className={styles.containerbuttonContent}>
                  {e.image && e.url && e.image?.length > 0 ? (
                    <img
                      style={{ maxWidth: "90%", maxHeight: "85%" }}
                      src={e.url}
                    ></img>
                  ) : (
                    `${e.text}`
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchingTextQuiz;
