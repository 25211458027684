import { useState, useEffect } from "react";
import styles from "./drawerHeaderStudent.module.scss";

// Components && utils
import { Drawer } from "antd";
import { Button } from "../../../Shared/Button";
import { MainProfile } from "../../../Students/Profile";
import { DrawerAvatar } from "./DrawerAvatar";
import { DrawerPassword } from "../../../Shared/DrawerPassword";
import useAuth from "../../../../context/useAuth";

// Api && Types
import UseGetUserByEmail from "../../../../api/useGetUserByEmail";
import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";

// Icons
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import arrowBack from "../../../../assets/icons/arrows/arrow-back.svg";

interface DrawerHeaderProps {
  title: string;
  visible?: boolean;
  onClose: () => void;
  userDb: getUserByEmail_getUserByEmail;
  handleOffFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: {
    error: boolean;
    passwordOld: string;
    passwordNew1: string;
    passwordNew2: string;
  };
  validError: {
    error: boolean;
    errorText: string;
  };
}

const DrawerHeaderStudent = ({
  onClose,
  visible,
  title,
  handleSubmit,
  handleChange,
  handleOffFocus,
  validError,
  password,
}: DrawerHeaderProps) => {
  const { userToken, logout } = useAuth();
  const getUserByEmail = UseGetUserByEmail();
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [userDb, setUserDb] = useState<getUserByEmail_getUserByEmail | null>(
    null
  );

  useEffect(() => {
    (async () => {
      const userRes = await getUserByEmail({
        userEmail: userToken?.email,
      });
      setUserDb(userRes);
    })();
  }, [getUserByEmail, userToken?.email]);

  const showDrawerPassword = () => {
    setVisiblePassword(true);
  };

  const showDrawerAvatar = () => {
    setVisibleAvatar(true);
  };

  const goBack = () => {
    setVisiblePassword(false);
    setVisibleAvatar(false);
  };

  return (
    <>
      <Drawer
        width={500}
        closable={false}
        onClose={onClose}
        visible={visible}
        placement={"right"}
        title={
          <div className={styles.header}>
            {(visibleAvatar || visiblePassword) && (
              <div className={styles.titleHeader}>
                <img src={arrowBack} alt="arrow_back" onClick={goBack} />
                <h1>{visibleAvatar ? "Mi perfil" : "Cambiar contraseña"}</h1>
              </div>
            )}

            <img src={closeIcon} alt="close icon" onClick={onClose} />
          </div>
        }
        footer={
          visiblePassword && (
            <div className={styles.footer}>
              <div style={{ marginRight: "16px" }}>
                <Button type={"stroke"} text={"cancelar"} onClick={goBack} />
              </div>

              <Button
                type={"primary"}
                text={"guardar"}
                onClick={handleSubmit}
                disabled={password.error ?? false}
              />
            </div>
          )
        }
      >
        {!visibleAvatar && !visiblePassword && (
          <MainProfile
            userDb={userDb}
            showDrawerAvatar={showDrawerAvatar}
            showDrawerPassword={showDrawerPassword}
            logout={logout}
          />
        )}

        {visibleAvatar && <DrawerAvatar userDb={userDb} />}

        {visiblePassword && (
          <DrawerPassword
            handleOffFocus={handleOffFocus}
            handleChange={handleChange}
            password={password}
            validError={validError}
            handleSubmit={handleSubmit}
          />
        )}
      </Drawer>
    </>
  );
};

export default DrawerHeaderStudent;
