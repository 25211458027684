import conversation from "../../../assets/icons/tasktable/conversation.png";
import evaluation from "../../../assets/icons/tasktable/evaluation.png";
import listening from "../../../assets/icons/tasktable/listening.png";
import reading from "../../../assets/icons/tasktable/reading.png";
import redaction from "../../../assets/icons/tasktable/redaction.png";
import videolesson from "../../../assets/icons/tasktable/videolesson.png";
import literacy from "../../../assets/icons/tasktable/literacy.png";

export const lessonTypeIconResolve = (lessontype: string | null) => {
  if (lessontype === "redaction") return redaction;
  if (lessontype === "evaluation") return evaluation;
  if (lessontype === "listening") return listening;
  if (lessontype === "reading") return reading;
  if (lessontype === "conversation") return conversation;
  if (lessontype === "literacy") return literacy;
  return videolesson;
};
