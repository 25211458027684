import React, { FC } from "react";
import styles from "./questions.module.scss";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import Latex from "react-latex";

interface PropsQuestions {
  index: number;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
  subject_id?: number | null;
}
const Questions: FC<PropsQuestions> = ({ index, question, subject_id }) => {
  console.log(question);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {question.question && <Latex>{question.question}</Latex>}
        </div>
        {question.url && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.url ?? ""}
              alt="asdsa"
            />
          </div>
        )}
        {question.paragraph && (
          <div className={styles.containerParagraph}>{question.paragraph}</div>
        )}
        <div className={styles.textFill}>
          {question.answers?.map((e) => {
            return (
              <div
                className={
                  e.correct ? styles.answerSelected : styles.answerOutSelected
                }
              >
                {e.answer ? (
                  <Latex>{e.answer!}</Latex>
                ) : (
                  <img src={e.url ?? ""} alt="" width={"90px"} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Questions;
