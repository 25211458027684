import React, { useEffect, useState } from "react";
import styles from "./newFraseDrag.module.scss";
import {
  PropsNewFraseDrag,
  getListOptionsStyle,
  getListStyle,
  onDragEnd,
} from "./newUtils";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Task } from "./Task";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import { getLessonById_getLessonById_lesson_content_quizzes_questions_answers } from "../../../../__generated__/gql-types/getLessonById";
import { synthesizeLanguageText } from "../NewQuizz/utils";

const NewFraseDrag = ({
  content,
  setColumns,
  alertFalse,
  alertTrue,
  alertMid,
  columns,
  subject_id,
}: PropsNewFraseDrag) => {
  const [partes, setPartes] = useState<string[]>([]);
  const [cont, setCont] = useState(0);
  const [answersReponse, setAnswersReponse] = useState<
    getLessonById_getLessonById_lesson_content_quizzes_questions_answers[]
  >([]);
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const alertas = [alertFalse, alertMid];

  useEffect(() => {
    let answerIds: string[] = [];
    content.answers?.forEach((e) => {
      answerIds.push(e.id);
    });
    let contador = (content.question!.match(/__/g) || []).length;
    const newArray = [
      {
        id: 1,
        title: "Column 1",
        taskIds: [],
      },
      {
        id: 2,
        title: "Column 2",
        taskIds: contador === 2 ? [] : answerIds,
      },
      ...(contador === 2
        ? [
            {
              id: 3,
              title: "Column 3",
              taskIds: answerIds,
            },
          ]
        : []),
    ];
    setPartes(content.question!.split("__"));
    setCont(contador);
    setColumns(newArray);
  }, [content.answers, setColumns, content.question]);

  useEffect(() => {
    let answersCorrect: getLessonById_getLessonById_lesson_content_quizzes_questions_answers[] =
      [];
    content.answers?.forEach((e) => {
      e.correct && answersCorrect.push(e);
    });

    answersCorrect.sort((a, b) => a.order! - b.order!);
    setAnswersReponse(answersCorrect);
  }, [content.answers]);

  const functionRecovery = (columnId: number) => {
    const column = columns.find((x) => x.id === columnId);
    if (!column) return [];

    return column.taskIds.flatMap(
      // flatMap: cuando el find no encuentra aplana el []vacio
      (taskId) => content.answers?.find((x: any) => x.id === taskId) || []
    );
  };

  const taskMapping = (columnId: number) => {
    return functionRecovery(columnId).map((task, index) => (
      <Task
        alertMid={alertMid}
        key={task.id}
        task={task}
        columns={columns}
        index={index}
        alertFalse={alertFalse}
        columnId={columnId}
        alertTrue={alertTrue}
      />
    ));
  };

  const taskList =
    cont === 2 ? taskMapping(columns[2].id) : taskMapping(columns[1].id);

  const handleVoice = async () => {
    const questionToSynth =
      content.question && content.question.replace(/__/g, " ");
    setIsSoundActive(true);
    subject_id &&
      questionToSynth &&
      (await synthesizeLanguageText(questionToSynth, subject_id));
    setIsSoundActive(false);
  };

  return (
    <div className={styles.content}>
      <DragDropContext
        onDragEnd={(result) => onDragEnd({ result, columns, setColumns })}
      >
        <div className={styles.containerFilas}>
          <div className={styles.titleQuiz}>
            <p>
              {isSoundActive ? (
                <img className={styles.imgSound} src={soundNegative} alt="" />
              ) : (
                <img
                  className={styles.imgSound}
                  src={soundActive}
                  onClick={handleVoice}
                  alt=""
                />
              )}
              {partes[0]}
              <span className={styles.spaceDrag}>
                <Droppable
                  droppableId={`${columns[0].id}`}
                  type="TASK"
                  isCombineEnabled={false}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // style={getListStyle()}
                      className={styles.containerFichas}
                    >
                      {functionRecovery(columns[0].id).map((task, index) => (
                        <Task
                          alertMid={alertMid}
                          columns={columns}
                          key={task.id}
                          task={task}
                          index={index}
                          alertFalse={alertFalse}
                          alertTrue={alertTrue}
                          columnId={columns[0].id}
                        />
                      ))}

                      {(columns[0].taskIds.length < 1 ||
                        snapshot.isUsingPlaceholder) && (
                        <div className={styles.spaceAnswer}>.</div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </span>

              {partes[1]}
              {cont === 2 && (
                <span className={styles.spaceDrag}>
                  <Droppable
                    droppableId={`${columns[1].id}`}
                    type="TASK"
                    isCombineEnabled={false}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.containerFichas}
                      >
                        {functionRecovery(columns[1].id).map((task, index) => (
                          <Task
                            alertMid={alertMid}
                            columns={columns}
                            key={task.id}
                            task={task}
                            index={index}
                            alertFalse={alertFalse}
                            alertTrue={alertTrue}
                            columnId={columns[1].id}
                          />
                        ))}

                        {(columns[1].taskIds.length < 1 ||
                          snapshot.isUsingPlaceholder) && (
                          <div className={styles.spaceAnswer}>.</div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </span>
              )}
              {partes[2]}
            </p>
          </div>
          {content.url && (
            <img src={content.url} className={styles.imgQuestions} alt="img" />
          )}
          {alertas.includes(true) && (
            <div className={styles.textCorrect}>
              <span>
                {partes[0]}{" "}
                <span className={styles.textGreen}>
                  {answersReponse[0] && answersReponse[0].answer}
                </span>{" "}
                {partes[1]}
                <span className={styles.textGreen}>
                  {answersReponse[1] && answersReponse[1].answer}
                </span>
                {partes[2]}
              </span>
            </div>
          )}
          <Droppable
            droppableId={cont === 2 ? `${columns[2].id}` : `${columns[1].id}`}
            type="TASK"
            direction="horizontal"
            isCombineEnabled={false}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.containerOptionsFichas}
                // style={getListOptionsStyle()}
              >
                {taskList}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default NewFraseDrag;
