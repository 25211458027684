import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { studentActionsLibraryVariables } from "../__generated__/gql-types/studentActionsLibrary";

const useStudentActionLibrary = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: studentActionsLibraryVariables) => {
      return await client.mutate({
        mutation: STUDENT_ACTION_LIBRARY_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useStudentActionLibrary;

const STUDENT_ACTION_LIBRARY_EVENT = gql`
  mutation studentActionsLibrary(
    $action: String
    $lessonId: String
    $typeLesson: String
    $section: String
  ) {
    studentActionsLibrary(
      action: $action
      lessonId: $lessonId
      typeLesson: $typeLesson
      section: $section
    )
  }
`;
