import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateDashboardVisibleHomeworkVariables } from "../__generated__/gql-types/updateDashboardVisibleHomework";

const useUpdateDashboardVisibleHomework = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateDashboardVisibleHomeworkVariables) => {
      return await client.mutate({
        mutation: UPDATE_DASHBOARD_VISIBLE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdateDashboardVisibleHomework;

const UPDATE_DASHBOARD_VISIBLE_HOMEWORK = gql`
  mutation updateDashboardVisibleHomework($visible: Boolean, $homeworkId: Int) {
    updateDashboardVisibleHomework(
      visible: $visible
      homework_id: $homeworkId
    ) {
      id
      title
    }
  }
`;
