import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherAddPoints = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_ADD_POINTS,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherAddPoints;

const TEACHER_ADD_POINTS = gql`
  mutation teacherAddPoints {
    teacherAddPoints
  }
`;
