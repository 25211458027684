import { FC, useState } from "react";
import styles from "./courseSelected.module.scss";
import arrowTop from "../../../../assets/pages/newProject/arrowTop.png";
import arrowDownPanel from "../../../../assets/pages/newProject/arrowDownPanel.png";
import { getPDACourses_getPDACourses } from "../../../../__generated__/gql-types/getPDACourses";

interface PropsCourseSelected {
  setCheckedCourse: (e: getPDACourses_getPDACourses[] | null) => void;
  checkedCourse: getPDACourses_getPDACourses[] | null;
  openPanel: boolean;
  setOpenPanel: (e: boolean) => void;
  setOpenForm: (e: boolean) => void;
}

const CourseSelected: FC<PropsCourseSelected> = ({
  checkedCourse,
  openPanel,
  setCheckedCourse,
  setOpenForm,
  setOpenPanel,
}) => {
  const cutText = (e: string) => {
    if (e === "Ética, Naturaleza y Sociedades") return "Ética";
    if (e === "De lo Humano y lo Comunitario") return "De lo Humano";
    return e;
  };

  const [showPanel, setShowPanel] = useState(false);

  return (
    <div
      className={styles.container}
      onClick={() => {
        setShowPanel(true);
      }}
      style={
        showPanel && checkedCourse && checkedCourse?.length > 0
          ? { gap: 16 }
          : { cursor: "pointer" }
      }
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          TEMAS A INCLUIR EN TU PROYECTO ( {checkedCourse?.length ?? 0} )
        </div>
        {openPanel ? (
          showPanel ? (
            checkedCourse && checkedCourse?.length > 0 ? (
              <img
                src={arrowTop}
                alt="arrow"
                onClick={(event) => {
                  event.stopPropagation();
                  event.isPropagationStopped();
                  setShowPanel(false);
                }}
              />
            ) : (
              <img
                src={arrowDownPanel}
                alt="arrow"
                onClick={() => {
                  setShowPanel(true);
                }}
              />
            )
          ) : (
            <></>
          )
        ) : (
          <div
            className={styles.edit}
            onClick={(event) => {
              event.stopPropagation();
              event.isPropagationStopped();
              setOpenPanel(true);
              setOpenForm(false);
            }}
          >
            buscar temas
          </div>
        )}
      </div>

      <div className={styles.containerPanel}>
        {showPanel &&
          checkedCourse?.map((e) => (
            <div className={styles.panel}>
              <div className={styles.grade}> {e.grade_id}°</div>
              <div className={styles.containerImage}>
                <div>{e.subject && cutText(e.subject)}</div>
              </div>
              <div className={styles.panelTitle}>{e.title}</div>
              <div
                className={styles.textDelete}
                onClick={() => {
                  setCheckedCourse(
                    checkedCourse.filter((course) => e.id !== course.id)
                  );
                }}
              >
                Eliminar
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseSelected;
