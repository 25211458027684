import { FC } from "react";
import styles from "./cardDosification.module.scss";
import defaultImage from "../../../assets/images/defaultImage.svg";
import videoLight from "../../../assets/icons/Video_light.svg";
import evaluationIcon from "../../../assets/icons/evaluationIcon.svg";
import conversationIcon from "../../../assets/icons/conversation.svg";
import guionIcon from "../../../assets/icons/guionIcon.svg";
import pizza from "../../../assets/dataMock/Pizza_portada.png";
import thumbnailCiencias from "../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../assets/images/thumbnail/thumbnailEspañol.png";
import thumbnailClase from "../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailDynamics from "../../../assets/images/thumbnail/thumbnailDynamics.png";
import thumbnailDiapositivas from "../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import ensayoMatematica from "../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../assets/images/thumbnail/ensayoCiencias.png";
import thumbnailBookPages from "../../../assets/images/thumbnail/thumbnailBookPages.png";
import gemPositive from "../../../assets/icons/gems/gemPositive.svg";
import gemNegative from "../../../assets/icons/gems/gemNegative.svg";
import listeningExam from "../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../assets/images/thumbnail/writing_exam.png";
import writingExamSpain from "../../../assets/images/thumbnail/coverExamSpain.png";
import { getLessonsByCourse_getLessonsByCourse } from "../../../__generated__/gql-types/getLessonsByCourse";
import useAuth from "../../../context/useAuth";

interface CardProps {
  setLessonId: (id: string) => void;
  showDrawer: () => void;
  lesson: getLessonsByCourse_getLessonsByCourse;
  index: number;
  setIndividualLesson?: (lesson: any) => void;
}

const CardDosification: FC<CardProps> = ({
  lesson,
  showDrawer,
  setLessonId,
  setIndividualLesson,
}) => {
  const functionReturnImg = () => {
    if (lesson.lesson_type === "classroomguide") {
      return thumbnailClase;
    }
    if (lesson.lesson_type === "manipulative_dynamics") {
      return thumbnailDynamics;
    }

    if (lesson.lesson_type === "slides") {
      return thumbnailDiapositivas;
    }
    if (lesson.lesson_type === "book_pages") {
      return thumbnailBookPages;
    }
    if (lesson.lesson_type === "evaluation") {
      if (lesson.subject?.id === "1") {
        return thumbnailMate;
      }
      if (lesson.subject?.id === "2" || lesson.subject?.id === "17") {
        return thumbnailEspañol;
      }
      if (lesson.subject?.id === "4") {
        return thumbnailCiencias;
      }
    }
    if (lesson.lesson_type === "redaction") {
      if (lesson.subject?.id === "1") {
        return ensayoMatematica;
      }
      if (lesson.subject?.id === "2" || lesson.subject?.id === "17") {
        return ensayoEspañol;
      }
      if (lesson.subject?.id === "4") {
        return ensayoCiencias;
      }
    }
    if (lesson.lesson_type === "writing_exam") {
   
      if (lesson.subject?.id === "16") {
        return writingExam;
      } else {
        return writingExamSpain;
      }
    }
    if (lesson.lesson_type === "audio_exam") {
      return listeningExam;
    }
  };
  const { userToken } = useAuth();
  const userRole = userToken?.role;
  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        setIndividualLesson && setIndividualLesson(lesson);
        setLessonId(lesson?.id);
        showDrawer();
      }}
    >
      <div className={styles.videoQuizContainer}>
        <img
          src={
            lesson.lesson_type === "videolesson"
              ? videoLight
              : lesson.lesson_type === "classroomguide" ||
                lesson.lesson_type === "redaction" ||
                lesson.lesson_type === "manipulative_dynamics"
              ? guionIcon
              : lesson.lesson_type === "evaluation"
              ? evaluationIcon
              : lesson.lesson_type === "audio_exam"
              ? evaluationIcon
              : lesson.lesson_type === "writing_exam"
              ? evaluationIcon
              : lesson.lesson_type === "conversation"
              ? conversationIcon
              : videoLight
          }
          style={{ width: "100%" }}
        />
        {lesson.lesson_type === "videolesson"
          ? "Video Lección"
          : lesson.lesson_type === "classroomguide" ||
            lesson.lesson_type === "manipulative_dynamics"
          ? "Orientación didáctica"
          : lesson.lesson_type === "redaction"
          ? "Redacción"
          : lesson.lesson_type === "evaluation"
          ? "Evaluación"
          : lesson.lesson_type === "slides"
          ? "Diapositivas"
          : lesson.lesson_type === "book_pages"
          ? "Libro"
          : lesson.lesson_type === "conversation"
          ? "Conversación"
          : lesson.lesson_type === "audio_exam"
          ? "Listening Exam"
          : lesson.lesson_type === "writing_exam"
          ? lesson.subject?.id === "16"
            ? "Writing Exam"
            : "Examen"
          : "Video Lección"}
      </div>
      {
        <img
          src={
            Number(lesson?.id) === 1812
              ? pizza
              : functionReturnImg()
              ? functionReturnImg()
              : lesson.lesson_content?.videos?.length &&
                lesson.lesson_content?.videos[0].imageUrl
              ? lesson.lesson_content?.videos[0].imageUrl
              : lesson.lesson_content?.aiconversations &&
                lesson.lesson_content?.aiconversations.card_image
              ? lesson.lesson_content?.aiconversations?.card_image
              : defaultImage
          }
          style={{ width: "100%" }}
          alt="img"
        />
      }

      <div className={styles.cardContent}>
        <div className={styles.titleCard}>{lesson?.title}</div>
        <div>
          {userRole !== "student" && <h3>{lesson?.sep_name}</h3>}
          <div className={styles.containerPages}>
            {lesson?.result !== null ? (
              <div className={styles.containerGem}>
                {lesson?.result && lesson?.result >= 3 ? (
                  <img src={gemPositive} alt="" />
                ) : (
                  <img src={gemNegative} alt="" />
                )}
                {lesson?.result && lesson?.result >= 6 ? (
                  <img src={gemPositive} alt="" />
                ) : (
                  <img src={gemNegative} alt="" />
                )}
                {lesson?.result && lesson?.result > 9 ? (
                  <img src={gemPositive} alt="" />
                ) : (
                  <img src={gemNegative} alt="" />
                )}
              </div>
            ) : (
              <div className={styles.pages}>
                {(lesson?.lesson_type === "videolesson" ||
                  lesson?.lesson_type === "classroomguide" ||
                  lesson?.lesson_type === "manipulative_dynamics" ||
                  lesson?.lesson_type === "slides") &&
                  "Didáctica"}
                {(lesson?.lesson_type === "book_pages" ||
                  lesson?.lesson_type === "evaluation" ||
                  lesson?.lesson_type === "audio_exam" ||
                  lesson?.lesson_type === "writing_exam" ||
                  lesson?.lesson_type === "redaction" ||
                  lesson?.lesson_type === "literacy" ||
                  lesson?.lesson_type === "conversation") &&
                  "Práctica"}
              </div>
            )}

            <div className={styles.trim}>
              <div className={styles.bubble}>{lesson?.grade_id}°</div>
              <div className={styles.bubble}>Semana {lesson?.week}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDosification;
