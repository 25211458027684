import rigel from "../../../../assets/pages/store/RIGEL.svg";
import polux from "../../../../assets/pages/store/POLLUX.svg";
import colmillu from "../../../../assets/pages/store/COLMILLU.svg";
import shaula from "../../../../assets/pages/store/SHAULA.svg";
import tatooine from "../../../../assets/pages/store/TATOOINE.svg";
import spica from "../../../../assets/pages/store/SPICA.svg";

export const functionReturnImgAvatar = (avatarName: string) => {
  if (avatarName === "polux") return polux;
  if (avatarName === "rigel") return rigel;
  if (avatarName === "spica") return spica;
  if (avatarName === "tatooine") return tatooine;
  if (avatarName === "shaula") return shaula;
  if (avatarName === "colmillu") return colmillu;
  return spica;
};
