import React, { FC, useState } from "react";
import styles from "./columnStoreMobile.module.scss";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../../../__generated__/gql-types/getClothesAndAccessories";
import gemstore from "../../../../assets/pages/store/gemStore.png";
import storeOpen from "../../../../assets/pages/store/storeOpen.png";
import gem from "../../../../assets/pages/store/gem.png";
import { Accessories } from "../../ColumnStore/Accessories";
import { useNavigate } from "react-router-dom";
import storeClose from "../../../../assets/pages/store/storeClose.png";
import useUpdateShowCloseStore from "../../../../api/useUpdateShowCloseStore";

interface PropsStoreMobile {
  setClothesAndAccessories: (e: PropsClothes[] | null) => void;
  userScore: getUserScore_getUserScore | undefined;
  setUserScore: (e: getUserScore_getUserScore | undefined) => void;
  clothesAndAccessories: PropsClothes[] | null;
  setOpenStore: (e: boolean) => void;
  openStore: boolean;
}

const ColumnStoreMobile: FC<PropsStoreMobile> = ({
  clothesAndAccessories,
  userScore,
  setClothesAndAccessories,
  setUserScore,
  setOpenStore,
  openStore,
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("clothes");
  const updateShowClose = useUpdateShowCloseStore();
  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return userScore?.level && userScore?.level < 6 ? (
    <div className={styles.containerStoreClose}>
      <div className={styles.containerImage}>
        <img src={storeClose} alt="storeClose" />
      </div>
      <div className={styles.containerInfo}>
        <div className={styles.title}>¡La tienda Luca está cerrada!</div>
        <div className={styles.description}>
          Podrás acceder a ella para comprar ropa para tu avatar, ¡cuando hayas
          alcanzado el nivel 6!
        </div>
        <div
          className={styles.completedButton}
          onClick={() => navigate("/mymissions")}
        >
          COMPLETA MÁS MISIONES
        </div>
      </div>
    </div>
  ) : openStore || !userScore?.user?.avatar_state?.show_image_close_store ? (
    <div className={styles.container}>
      <div className={styles.headerAvatar}>
        <div className={styles.containerPoints}>
          <div>Cristales disponibles</div>
          <div className={styles.containerStart}>
            <img src={gem} alt="start" />
            <div>
              {formatNumberWithSpaces(
                userScore?.user?.avatar_state?.available_balance ?? 0
              )}
            </div>
          </div>
        </div>

        <div className={styles.containerTab}>
          <div
            className={tab === "clothes" ? styles.tabOn : styles.tabOff}
            onClick={() => setTab("clothes")}
          >
            Ropa
          </div>
          <div
            className={tab === "accessories" ? styles.tabOn : styles.tabOff}
            onClick={() => setTab("accessories")}
          >
            Accesorios
          </div>
          <div
            className={tab === "costume" ? styles.tabOn : styles.tabOff}
            onClick={() => setTab("costume")}
          >
            Disfraces
          </div>
        </div>
      </div>
      {tab === "accessories" && (
        <div className={styles.containerAccessories}>
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            title={"Gafas"}
            setClothesAndAccessories={setClothesAndAccessories}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "accessories" && e.clasification === "glasses"
              ) ?? null
            }
          />
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            title={"Sombreros"}
            setClothesAndAccessories={setClothesAndAccessories}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "accessories" && e.clasification === "hats"
              ) ?? null
            }
          />
        </div>
      )}
      {tab === "costume" && (
        <div className={styles.containerAccessories}>
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            setClothesAndAccessories={setClothesAndAccessories}
            title={"Disfraces"}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "costume" && e.clasification === "costume"
              ) ?? null
            }
          />
        </div>
      )}
      {tab === "clothes" && (
        <div className={styles.containerAccessories}>
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            setClothesAndAccessories={setClothesAndAccessories}
            title={"Parte de arriba"}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "clothes" && e.clasification === "top"
              ) ?? null
            }
          />
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            title={"Parte de abajo"}
            setClothesAndAccessories={setClothesAndAccessories}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "clothes" && e.clasification === "bottom"
              ) ?? null
            }
          />{" "}
          <Accessories
            userScore={userScore}
            setUserScore={setUserScore}
            setClothesAndAccessories={setClothesAndAccessories}
            title={"Traje"}
            clothesAndAccessories={
              clothesAndAccessories?.filter(
                (e) => e.type === "clothes" && e.clasification === "suite"
              ) ?? null
            }
          />
        </div>
      )}
    </div>
  ) : (
    <div className={styles.containerStoreClose}>
      <div className={styles.containerImage}>
        <img src={storeOpen} alt="storeClose" />
      </div>
      <div className={styles.containerInfo}>
        <div className={styles.title}>
          <img src={gemstore} alt="" />
          ¡La tienda Luca está abierta!
        </div>
        <div className={styles.description}>
          Ahora puedes comprar ropa y accesorios para tu avatar, ¡y
          personalizarlo como tu quieras!
        </div>
        <div
          className={styles.completedButton}
          onClick={async () => {
            setOpenStore(true);
            await updateShowClose();
          }}
        >
          ACCEDE A LA TIENDA
        </div>
      </div>
    </div>
  );
};

export default ColumnStoreMobile;
