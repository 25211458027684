import React, { FC, Fragment, useState } from "react";
import styles from "./validationProgress.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Progress } from "antd";
import cross from "../../../../../assets/images/cross.svg";
import crossMobile from "../../../../../assets/icons/students/closeMobile.svg";
import gemaLengua from "../../../../../assets/icons/gemaLengua.png";
import gemaCiencias from "../../../../../assets/icons/gemaCiencias.png";
import gemaMatematica from "../../../../../assets/icons/gemaMatematica.png";
import instructions from "../../../../../assets/icons/students/instructions.svg";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { ModalConfirmation } from "../../../../Shared/ModalConfirmation";

interface ValidationProgressProps {
  individualLesson: getLessonById_getLessonById | null;
  contProgress: number;
  total: number;
  handleInstructions: () => void;
  selectedKeyLiteracy: string;
}

const ValidationProgress: FC<ValidationProgressProps> = ({
  individualLesson,
  contProgress,
  total,
  selectedKeyLiteracy,
  handleInstructions,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const onCloseModalConfirmation = () => {
    setOpen(false);
  };
  const isLiteracy = individualLesson?.lesson_type === "literacy";

  const SubjectGema = () => {
    return (
      <>
        {individualLesson?.subject?.name === "Matemáticas" && (
          <img
            src={gemaMatematica}
            alt=""
            className={!isMobile ? styles.imgGema : styles.imgGemaMobile}
          />
        )}
        {individualLesson?.subject?.name === "Español" && (
          <img
            src={gemaLengua}
            alt=""
            className={!isMobile ? styles.imgGema : styles.imgGemaMobile}
          />
        )}
        {individualLesson?.subject?.name === "Ciencias" && (
          <img
            src={gemaCiencias}
            alt=""
            className={!isMobile ? styles.imgGema : styles.imgGemaMobile}
          />
        )}
        {!individualLesson && (
          <img
            src={gemaMatematica}
            alt=""
            className={!isMobile ? styles.imgGema : styles.imgGemaMobile}
          />
        )}
        <div className={styles.num}> 100 </div>
      </>
    );
  };

  const ProgressBar = () => {
    return (
      <Progress
        percent={(contProgress / (total ?? 0)) * 100}
        showInfo={false}
        trailColor={"#CBCEDE"}
        strokeColor={"#3843D0"}
      />
    );
  };

  const handleRedirection = () => {
    if (location?.state?.from) {
      return navigate(-1);
    } else {
      return navigate("/mymissions");
    }
  };

  return (
    <>
      {!isMobile ? (
        <React.Fragment>
          <div className={styles.containerProgress}>
            <SubjectGema />
            <ProgressBar />
            {!isLiteracy && (
              <div className={styles.cross} onClick={() => setOpen(true)}>
                <img src={cross} alt="" />
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className={styles.containerProgressMobile}>
          <div className={styles.containerGemCrossMobile}>
            {(!isLiteracy || selectedKeyLiteracy === "2") && (
              <div className={styles.containerGemMobile}>
                <SubjectGema />
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              {(!isLiteracy || selectedKeyLiteracy === "2") && (
                <button onClick={handleInstructions}>
                  <img src={instructions} alt="instructions" /> Instrucciones
                </button>
              )}
              <div className={styles.cross} onClick={() => setOpen(true)}>
                <img src={crossMobile} alt="" />
              </div>
            </div>
          </div>
          <ProgressBar />
        </div>
      )}
      <ModalConfirmation
        open={open}
        onCloseModalConfirmation={onCloseModalConfirmation}
        handleRedirection={handleRedirection}
      />
    </>
  );
};

export default ValidationProgress;
