import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserInput } from "../__generated__/gql-types/globalTypes";

const UseDeleteStudentByTeacher = () => {
  const client = useApolloClient();

  return useCallback(
    async (userInput: UserInput) => {
      return await client.mutate({
        mutation: DELETE_STUDENT_BY_TEACHER,
        fetchPolicy: "no-cache",
        variables: { userInput },
      });
    },
    [client]
  );
};
export default UseDeleteStudentByTeacher;

const DELETE_STUDENT_BY_TEACHER = gql`
mutation deleteStudentByTeacher($userInput: UserInput!) {
  deleteStudentByTeacher(userInput: $userInput) {
    id
    first_name
    last_name
    email
  }
}
`;
