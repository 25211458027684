export const encontrarValorMayor = (
  variable1: number | null,
  variable2: number | null
) => {
  if (variable1 !== null && variable2 !== null) {
    return Math.max(variable1, variable2);
  } else if (variable1 !== null) {
    return variable1;
  } else if (variable2 !== null) {
    return variable2;
  } else {
    return null;
  }
};
