import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherDownloadBookPagesVariables } from "../__generated__/gql-types/teacherDownloadBookPages";

const useTeacherDownloadBookPages = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherDownloadBookPagesVariables) => {
      return await client.mutate({
        mutation: TEACHER_DONWLOAD_BOOK_PAGES,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherDownloadBookPages;

const TEACHER_DONWLOAD_BOOK_PAGES = gql`
  mutation teacherDownloadBookPages($contentId: Int) {
    teacherDownloadBookPages(contentId: $contentId)
  }
`;
