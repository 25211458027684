import illustration from "../../../../../assets/images/bannerMissionsMobile.svg";
import useAuth from "../../../../../context/useAuth";

import styles from "./bannerMobile.module.css";

const BannerMobile = () => {
  const { userToken } = useAuth();
  return (
    <div className={styles.container}>
      <img alt="" src={illustration} className={styles.image2} />
      <div className={styles.bodyContent}>
        <h1 className={styles.title}>¡Hola {userToken?.first_name}!</h1>
        <h3 className={styles.subTitle}>
          Tus alumnos han completado una nueva tarea, ya puedes revisar sus
          resultados.
        </h3>
      </div>
    </div>
  );
};

export default BannerMobile;
