import { FC } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import styles from "./activeFilters.module.scss";

import sortIcon from "../../../../assets/icons/sortIcon.svg";
import eyeIcon from "../../../../assets/icons/eyeIcon.svg";
import useIsMobile from "../../../../hooks/useIsMobile";
interface ActiveFiltersProps {
	filterActive: boolean;
	selectedStatusTask?: string;
	setSelectedStatusTask?: (e: string) => void;
	setSortedInfo?: (e: {}) => void;
	sortedInfo?: {};
}

const TaskActiveFilters: FC<ActiveFiltersProps> = ({
	filterActive,
	setSelectedStatusTask,
	selectedStatusTask,
	setSortedInfo,
}) => {
	const isMobile = useIsMobile();

	const { Option } = Select;

	const setDateSort = (data: string = "recientes") => {
		data === "recientes"
			? setSortedInfo &&
			  setSortedInfo({
					order: "descend",
					columnKey: "start_date",
			  })
			: setSortedInfo &&
			  setSortedInfo({
					order: "ascend",
					columnKey: "start_date",
			  });
	};

	return (
		<div className={styles.filtersContainer}>
			<div className={styles.filterContent}>
				<div className={styles.filtersAppliedContainer}>
					{isMobile && (
						<span>
							<img src={sortIcon} alt="sortIcon" />
						</span>
					)}{" "}
					<h1 className={styles.titleSelected}>Ordenar por </h1>
				</div>
				<Select
					defaultValue="recientes"
					className={styles.selectContent}
					onChange={(data) => setDateSort(data)}
				>
					<Option value="recientes">Mas recientes</Option>
					<Option value="antiguas">Mas antiguas</Option>
				</Select>
			</div>

			<div className={styles.filterContent}>
				<div className={styles.filtersAppliedContainer}>
					{isMobile && (
						<span>
							<img src={eyeIcon} alt="eyeIcon" />
						</span>
					)}{" "}
					<h1 className={styles.titleSelected}>Mostrar</h1>
				</div>

				<div className={styles.bubblesContainer}>
					{/* <div className={styles.bubble}>Todos</div> */}
					<button
						className={
							selectedStatusTask === "all"
								? styles.bubbleSelected
								: styles.bubble
						}
						name="all"
						onClick={(event) => {
							const { name } = event.target as HTMLButtonElement;
							setSelectedStatusTask && setSelectedStatusTask(name);
						}}
					>
						Todos
					</button>
					<button
						className={
							selectedStatusTask === "finished"
								? styles.bubbleSelected
								: styles.bubble
						}
						name="finished"
						onClick={(event) => {
							const { name } = event.target as HTMLButtonElement;
							setSelectedStatusTask && setSelectedStatusTask(name);
						}}
					>
						Finalizadas
					</button>
					<button
						className={
							selectedStatusTask === "programed"
								? styles.bubbleSelected
								: styles.bubble
						}
						name="programed"
						onClick={(event) => {
							const { name } = event.target as HTMLButtonElement;
							setSelectedStatusTask && setSelectedStatusTask(name);
						}}
					>
						Programadas
					</button>
					<button
						className={
							selectedStatusTask === "inCourse"
								? styles.bubbleSelected
								: styles.bubble
						}
						name="inCourse"
						onClick={(event) => {
							const { name } = event.target as HTMLButtonElement;
							setSelectedStatusTask && setSelectedStatusTask(name);
						}}
					>
						En curso
					</button>
				</div>
			</div>
		</div>
	);
};

export default TaskActiveFilters;
