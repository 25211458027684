import React, { FC, useState } from "react";
import styles from "./withoutproject.module.scss";
import lucaBot from "../../../../assets/pages/newProject/bot.svg";
interface PropsProject {
  setIsCreating: (e: boolean) => void;
}
const WithoutProject: FC<PropsProject> = ({ setIsCreating }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.containerText}>
            <div className={styles.title}>Creador de proyectos por AI</div>
            <div className={styles.description}>
              Bienvenido al creador de proyectos generados por AI. Ahora puedes
              crear tus propios proyectos adaptados a las necesidades de tu
              clase y basados en los aprendizajes que más te interese impartir.
            </div>
          </div>
          <img src={lucaBot} alt="lucaBot" />
        </div>
        <div
          className={styles.buttom}
          onClick={() => setIsCreating && setIsCreating(true)}
        >
          Crea tu primer proyecto
        </div>
      </div>
    </div>
  );
};

export default WithoutProject;
