import { ChangeEvent, FC, useEffect, useState } from "react";
import { notification } from "antd";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import styles from "./uploadFile.module.scss";
import axios from "axios";
import { environment, resolveEnv } from "../../../configuration/environment";
import useAuth from "../../../context/useAuth";
import aws from "aws-sdk";
import clip from "../../../assets/icons/clip.svg";
import { HomeworkInput } from "../../../__generated__/gql-types/globalTypes";
interface FileUploaderProps {
  folder: string;
  videoUrl?: string;
  setFilesAws: (e: File) => void;
  setActiveKey: (e: number) => void;
  homework: HomeworkInput;
  setHomework: (e: HomeworkInput) => void;
}
aws.config = new aws.Config();
aws.config.update({
  region: "us-west-2",
  apiVersion: "2006-03-01",
  credentials: {
    accessKeyId: "AKIAQSPAY2Y4YJBSLTUD",
    secretAccessKey: "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",
  },
  signatureVersion: "v4",
});
const s3 = new aws.S3({ apiVersion: "2006-03-01", signatureVersion: "v4" });
export const FileUploader: FC<FileUploaderProps> = ({
  folder,
  videoUrl,
  setFilesAws,
  setActiveKey,
  homework,
  setHomework,
}) => {
  const { token } = useAuth();
  const [loading, setloading] = useState(false);
  const [url, setUrl] = useState("");
  const [uploadPercent, setUploadPercent] = useState(0);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0];

    setActiveKey(4);
    setFilesAws(file);
    setloading(true);
    try {
      await completeUpload(file);
      setloading(false);
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: "El video no pudo ser subido, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };
  //get presigned url to put video on s3 bucket
  const getPresignedUrl = async (file: File) => {
    try {
      const presignedUrl = await axios.post(
        `${environment.backendUrl}/urlvideo`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );
      return presignedUrl;
    } catch (error) {
      notification["error"]({
        message: "Error",
        description:
          "El video no pudo ser subido, por favor, reintente. Si el problema persiste, póngase en contacto con el administrador",
        placement: "top",
        duration: 3,
      });
    }
  };

  const getDownloadPresignedUrl = (file: File, key: any) => {
    let downloadParams = {
      Bucket: `vod-private-global/${resolveEnv()}/luca2-backend`,
      Key: key,
      ResponseContentDisposition: `attachment; filename="${file.name}"`,
      Expires: 604800, // 1 week maximum supported
    };

    let downloadUrl = s3.getSignedUrl("getObject", downloadParams);
    const url = `${environment.awsBucketVideos}${key}`;
    setUrl(url);
    setHomework({ ...homework, teacher_file_url: key });
  };
  const uploadVideoToS3Bucket = async (url: any, file: File) => {
    await axios.put(url, file, {
      headers: {
        "content-type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent <= 100) {
          setUploadPercent(percent);
        }
      },
    });
  };

  const completeUpload = async (file: File) => {
    try {
      const presignedUrl = await getPresignedUrl(file);
      await uploadVideoToS3Bucket(
        presignedUrl ? presignedUrl.data.url : undefined,
        file
      );

      getDownloadPresignedUrl(
        file,
        presignedUrl ? presignedUrl.data.key : undefined
      );

      uploadPercent === 100 && setUploadPercent(100);
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: "El video no pudo ser subido, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {}, [url]);
  return (
    <div className={styles.container}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <label htmlFor="formId" className={styles.attachButton}>
          <img src={clip} alt="" />
          <div>Adjuntar archivo</div>
          <input
            onChange={handleFileChange}
            name=""
            type="file"
            id="formId"
            hidden
          />
        </label>
      )}

      {/* <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {uploadPercent > 0 ? (
          <React.Fragment>
            <Progress
              percent={uploadPercent}
              status={uploadPercent === 100 ? "success" : "active"}
            />
          </React.Fragment>
        ) : null}
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Row style={{ padding: 20 }}>
            <React.Fragment>
              {url ? <VideoPlayer url={url} title={"titulo"} /> : null}
            </React.Fragment>
          </Row>
        )}
      </Col> */}
    </div>
  );
};

export default FileUploader;
