import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./extraPoints.module.scss";

// Components
import { Tabs } from "../Shared";
import { Header } from "../../Layout/Header";

// Api && Types
import UseGetCoursesBySubjectId from "../../../api/useGetCoursesBySubjectId";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";
import { getCoursesBySubjectId_getCoursesBySujectId } from "../../../__generated__/gql-types/getCoursesBySubjectId";
import desktopBlueMobile from "../../../assets/images/desktopBlueMobile.svg";
import { mathProblemGenerator } from "../AsteroidsChallenge/utils/ProblemGenerator";
import { PaginationProgressBar } from "../../Shared/PaginationProgressBar";
import UseGetCoursesBySubjectIdLength from "../../../api/useGetCoursesBySujectIdLength";
interface ExtraPointsPageProps {
  userDb: getUserByEmail_getUserByEmail;
}

const ExtraPoints: FC<ExtraPointsPageProps> = ({ userDb }) => {
  const problems = mathProblemGenerator(2);
  let shuffledMathProblems = problems
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  const navigate = useNavigate();
  const getCoursesBySubjectId = UseGetCoursesBySubjectId();
  const getCoursesBySubjectIdLength = UseGetCoursesBySubjectIdLength();
  const [searchInput, setSearchInput] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [subjectId, setSubjectId] = useState<string | null>("1");
  const [subjectIdKey, setSubjectIdKey] = useState<string | null>("1");
  const [missionsCounter, setMissionsCounter] = useState(3);
  const [coursesLength, setCoursesLength] = useState(0);

  const [coursesData, setCoursesData] = useState<
    getCoursesBySubjectId_getCoursesBySujectId[]
  >([]);
  const [showUnlock, setShowUnlock] = useState(false);
  const [limit, setLimit] = useState(5);

  const subjectsObjectives = [
    {
      subjectName: "Matemáticas",
      id: "1",
    },
    {
      subjectName: "Español",
      id: "2",
    },
    {
      subjectName: "Ciencias",
      id: "4",
    },
  ];

  useEffect(() => {
    if (missionsCounter < 3) {
      setTimeout(() => {
        setMissionsCounter(missionsCounter + 1);
      }, 15000);
    }
    if (missionsCounter === 3) return setShowUnlock(true);
  }, [missionsCounter]);
  useEffect(() => {
    setTimeout(() => {
      setShowUnlock(!showUnlock);
    }, 3000);
  }, [showUnlock]);

  useEffect(() => {
    if (missionsCounter === 3 && showUnlock)
      setTimeout(() => setShowUnlock(false), 5000);
    if (missionsCounter === 3 && !showUnlock)
      setTimeout(() => setShowUnlock(true), 5000);
    if (missionsCounter < 3 && showUnlock)
      setTimeout(() => setShowUnlock(false), 10000);
    if (missionsCounter < 3 && !showUnlock)
      setTimeout(() => setShowUnlock(true), 5000);
  }, [showUnlock, missionsCounter]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const allCourses = await getCoursesBySubjectId({
        getCoursesBySubjectIdInput: {
          subject_id: Number(subjectId),
          grade_id: Number(userDb?.groups && userDb?.groups[0]?.grade?.grade),
          searchArgs: searchInput ?? null,
          paginationArgs: {
            resultsPerPage: limit,
            currentPageNumber: 0,
          },
        },
      });
      setIsLoading(false);
      setCoursesData(allCourses ?? []);
      setIsLoaded(true);
    })();
  }, [subjectId, getCoursesBySubjectId, searchInput, limit]);

  useEffect(() => {
    (async () => {
      const allCourses = await getCoursesBySubjectIdLength({
        getCoursesBySubjectIdInput: {
          subject_id: Number(subjectId),
          grade_id: Number(userDb?.groups && userDb?.groups[0]?.grade?.grade),
          searchArgs: searchInput ?? null,
        },
      });

      setIsLoading(false);

      setCoursesLength(allCourses?.length ?? 0);
      setIsLoaded(true);
    })();
  }, [subjectId, getCoursesBySubjectIdLength, searchInput]);

  return (
    <div className={styles.container}>
      <Header
        userDb={userDb}
        title={"Puntos extra"}
        headerWithOutMobile={true}
      />

      <div className={styles.containerMobile}>
        <img
          src={desktopBlueMobile}
          alt=""
          className={styles.destkopMobileImg}
        />
      </div>

      <Tabs
        coursesData={coursesData}
        searchInput={searchInput}
        subjectIdKey={subjectIdKey}
        setSubjectIdKey={setSubjectIdKey}
        setSubjectId={setSubjectId}
        setSearchInput={setSearchInput}
        subjectsObjectives={subjectsObjectives}
        isLoading={isLoading}
        isLoaded={isLoaded}
      />

      {/* {subjectId === "1" && (
				<>
					<div className={styles.challenge}>
						<div
							className={
								showUnlock ? styles.challengeInfoShow : styles.challengeInfo
							}
						>
							{showUnlock && missionsCounter === 3 && (
								<>
									<h1>Desafío asteroide</h1>
									<h2>¡Desbloqueado!</h2>
								</>
							)}
							{showUnlock && missionsCounter < 3 && (
								<h2>
									¡Te faltan {3 - missionsCounter} misiones para <br />{" "}
									desbloquear el desafío!
								</h2>
							)}
						</div>
					</div>

					{coursesData && (
						<Progress
							type="circle"
							percent={(missionsCounter * 100) / 3}
							strokeWidth={20}
							strokeColor="#3843D0"
							strokeLinecap={"square"}
							trailColor="#888DA6"
							format={() => (
								<img
									src={asteroidChallenge}
									alt="asteroidChallenge"
									onClick={
										missionsCounter === 3
											? () =>
													navigate("/asteroidschallenge", {
														state: shuffledMathProblems,
													})
											: () => {}
									}
								/>
							)}
							width={75}
							className={styles.asteroidsLogo}
							style={{
								cursor: missionsCounter === 3 ? "pointer" : "not-allowed",
							}}
						/>
					)}
				</>
			)} */}
      {coursesData && (
        <PaginationProgressBar
          limit={limit}
          setLimit={setLimit}
          itemsLength={coursesData.length}
          totalLength={coursesLength}
        />
      )}
    </div>
  );
};

export default ExtraPoints;
