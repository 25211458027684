import React from "react";
import styles from "./report.module.scss";

const Report = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>AVISO</div>
      <div className={styles.subtitle}>
        Hemos detectado un comportamiento inapropiado por parte del usuario y
        esta misión se cerrará.
      </div>
      <div className={styles.description}>
        Te recordamos la importancia de mantener un lenguaje apropiado dentro de
        nuestra plataforma, conforme a nuestras normas de conducta. Agradecemos
        tu cooperación para mantener un ambiente respetuoso. (párrafo a revisar)
      </div>
      <div className={styles.buttom}>ENTENDIDO</div>
    </div>
  );
};

export default Report;
