import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { HomeworkInput } from "../__generated__/gql-types/globalTypes";

const UseCreateHomework = () => {
  const client = useApolloClient();

  return useCallback(
    async (homeworkInput: HomeworkInput) => {
      return await client.mutate({
        mutation: CREATE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: { homeworkInput },
      });
    },
    [client]
  );
};
export default UseCreateHomework;

const CREATE_HOMEWORK = gql`
  mutation createHomework($homeworkInput: HomeworkInput) {
    createHomework(homeworkInput: $homeworkInput) {
      id
    }
  }
`;
