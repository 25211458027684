import { FC } from "react";
import styles from "./studentsTable.module.scss";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import { getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users } from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import { resolveAvatar, resolveColorAvatar } from "../../../utils/functions";

interface IStudentsTableProps {
  classroomStudents:
    | getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users[]
    | undefined;
  setUpdateStudentAccount: (e: boolean) => void;
  setStudentId: (e: string) => void;
  isStudentsTableLoading: boolean;
  dataCalificationsTableToDownload: any;
}

const StudentsTable: FC<IStudentsTableProps> = ({
  classroomStudents,
  setUpdateStudentAccount,
  setStudentId,
  isStudentsTableLoading,
  dataCalificationsTableToDownload,
}) => {
  const columns: ColumnsType<getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users> =
    [
      {
        title: "ALUMNO",
        dataIndex: "last_name",
        key: "last_name",
        width: 100,
        sorter: (a, b) => {
          if (
            a?.last_name &&
            b?.last_name &&
            a?.last_name.slice(0, 1) > b?.last_name.slice(0, 1)
          )
            return 1;
          if (
            a?.last_name &&
            b?.last_name &&
            a?.last_name.slice(0, 1) < b?.last_name.slice(0, 1)
          )
            return -1;
          return 0;
        },
        defaultSortOrder: "ascend",
        sortDirections: ["descend", "ascend", "descend"],
        render: (_, student) => {
          const avatarColor = resolveColorAvatar(student?.avatar ?? "polux");

          const avatar = resolveAvatar(
            student?.avatar ? student?.avatar : "polux",
            "1"
          );

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <span
                className={styles.studentAvatar}
                style={{ background: avatarColor }}
              >
                <img
                  src={avatar}
                  alt="avatar_image_alumnos"
                  style={{
                    width: "50px",
                    height: "80px",
                  }}
                />{" "}
              </span>

              <p
                style={{ marginLeft: "25px" }}
              >{`${student?.first_name} ${student?.last_name}`}</p>
            </div>
          );
        },
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        width: 50,
        render: (_, student) => {
          return (
            <div>
              <p>{student?.email}</p>
            </div>
          );
        },
      },
      {
        title: "",
        width: 100,
        render: (_: any, record) => {
          return (
            <div>
              <p
                onClick={() => {
                  setStudentId(record?.id);
                  setUpdateStudentAccount(true);
                }}
                style={{
                  color: "blue",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
              >
                Editar
              </p>
            </div>
          );
        },
      },
    ];
  return (
    <div>
      {isStudentsTableLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <div className={styles.containerMobile}>
            {dataCalificationsTableToDownload?.map((student: any) => {
              const avatarColor = resolveColorAvatar(
                student?.avatar ?? "polux"
              );

              const avatar = resolveAvatar(
                student?.avatar ? student?.avatar : "polux",
                "1"
              );
              return (
                student.student_id && (
                  <div className={styles.containerCard}>
                    <div className={styles.containerImage}>
                      <span
                        className={styles.studentAvatar}
                        style={{ background: avatarColor }}
                      >
                        <img
                          src={avatar}
                          alt="avatar_image_alumnos"
                          style={{
                            width: "50px",
                            height: "80px",
                          }}
                        />
                      </span>
                    </div>
                    <div className={styles.containerText}>
                      <div>{student.student_name}</div>
                      <div>{student.email}</div>
                      <div className={styles.containerActions}>
                        <div
                          onClick={() => {
                            setStudentId(student?.student_id.toString());
                            setUpdateStudentAccount(true);
                          }}
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    <div> {student.total_avg ?? "0"}</div>
                  </div>
                )
              );
            })}
          </div>
          <div className={styles.containerDesktop}>
            <Table
              dataSource={classroomStudents}
              pagination={false}
              columns={columns}
              rowClassName={styles.cursorPoint}
              onRow={(record) => {
                return {
                  onClick: () => {
                    setStudentId(record?.id);
                  },
                };
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default StudentsTable;
