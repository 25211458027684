import { FC } from "react";
import { Drawer } from "antd"
import styles from "./instructionsModal.module.scss"
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import meteors from "../../../../assets/icons/students/challenges/meteors.svg"
import instructions1 from "../../../../assets/icons/students/challenges/asteroids/instructions1.svg"
import instructions2 from "../../../../assets/icons/students/challenges/asteroids/instructions2.svg"
import instructions3 from "../../../../assets/icons/students/challenges/asteroids/instructions4.svg"
interface ModalProps {
  handleInstructions: () => void;
  visible: boolean;
  setStep2: (e: boolean) => void;
  step2: boolean;
}

const InstructionsModal: FC<ModalProps> = ({ handleInstructions, visible, setStep2, step2 }) => {
  return (
    <Drawer
      placement={"right"}
      width={500}
      maskStyle={{
        left: "0",
        width: "calc(100% + 245px)",
      }}
      onClose={handleInstructions}
      visible={visible}
      closable={false}
      title={
        <div className={styles.header}>
          <h1>Instrucciones del juego</h1>
          <img src={closeIcon} alt="close icon" onClick={handleInstructions} />
        </div>
      }
      footer={
        <div className={styles.footer} >
          <button onClick={() => {
            !step2 && setStep2(true)
            handleInstructions()
          }}>{step2 ? "CONTINUAR" : "COMENZAR"}</button>
        </div>
      }
    >
      <div className={styles.contentTarget}>

        <div>
          <h1>¿Cuál es el objetivo?</h1>
          <p>Te estás adentrando en un campo de asteroides y deberás destruirlos antes de que se acerquen, de lo contrario tu nave resultará dañada.</p>
        </div>
        <img src={meteors} alt="meteors" />
      </div>

      <div className={styles.targetInstructions}>
        <h1>¿Cómo superar el desafío?</h1>
        <div className={styles.instructionsImageLeft}>
          <img src={instructions1} alt="instructions1" />
          <p>Deberás responder correctamente las operaciones matemáticas que aparecen en tu pantalla.</p>
        </div>
        <div className={styles.instructionsImageRight}>
          <p>Dispondrás de 1 minuto. Con cada acierto destruirás un asteroide y ganarás 1 punto.</p>
          <img src={instructions2} alt="instructions1" />
        </div>
        <div className={styles.instructionsImageLeft}>
          <img src={instructions3} alt="instructions3" />
          <p>Contesta rápido antes de que los asteroides se aproximen a tu nave. ¡Mucha suerte!</p>
        </div>
        {/* <div className={styles.instructionsImageRight} style={{ marginBottom: 0 }}>
          <p>Contesta rápido, antes de que los asteroides se aproximen a tu nave.</p>
          <img src={instructions4} alt="instructions1" />
        </div> */}
      </div>
    </Drawer>
  )
}

export default InstructionsModal;