import { FC } from "react";
import { ExtraPoints } from "../../../components";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";

interface ExtraPointsPageProps {
  userDb: getUserByEmail_getUserByEmail
}

const ExtraPointsPage: FC<ExtraPointsPageProps> = ({ userDb }) => {
  return <ExtraPoints userDb={userDb}  />;
};

export default ExtraPointsPage;
