import styles from "./privacidad.module.scss";

const Privacidad = () => {

  return (
    <>
      <div className={styles.bodyDrawer}>
        <h3>
          <strong>POLÍTICA DE PRIVACIDAD</strong>
        </h3>
        <h6>
          <strong>Última actualización: Julio 2022.</strong>
        </h6>
        <h4>
          <strong>1. INFORMACIÓN AL USUARIO</strong>
        </h4>
        <p>
          <strong>Luca Learning Systems, S.L.U.,</strong> como Responsable del
          Tratamiento, le informa que, según lo dispuesto en el Reglamento (UE)
          2016/679, de 27 de abril, (RGPD) y en la L.O. 3/2018, de 5 de
          diciembre, de protección de datos y garantía de los derechos digitales
          (LOPDGDD), trataremos su datos tal y como reflejamos en la presente
          Política de Privacidad. En esta Política de Privacidad describimos
          cómo recogemos sus datos personales y por qué los recogemos, qué
          hacemos con ellos, con quién los compartimos, cómo los protegemos y
          sus opciones en cuanto al tratamiento de sus datos personales. Esta
          Política se aplica al tratamiento de sus datos personales recogidos
          por la empresa para la prestación de sus servicios. Si acepta las
          medidas de esta Política, acepta que tratemos sus datos personales
          como se define en esta Política.
        </p>
        <h4>
          <strong>2. CONTACTO</strong>
        </h4>
        <p>
          Denominación social: <strong>Luca Learning Systems, S.L.U.</strong>
        </p>
        <p>
          Nombre comercial:<strong>Luca</strong>{" "}
        </p>
        <p>
          CIF: <strong>B01826924</strong>
        </p>
        <p>
          e-mail: <strong>luca@lucaedu.com</strong>{" "}
        </p>
        <h4>
          <strong>3.PRINCIPIOS CLAVE</strong>
        </h4>
        <p>
          Siempre hemos estado comprometidos con prestar nuestros servicios con
          el más alto grado de calidad, lo que incluye tratar sus datos con
          seguridad y transparencia. Nuestros principios son:
        </p>
        <ul>
          <li>
            <strong>Legalidad:</strong> Sólo recopilaremos sus Datos personales
            para fines específicos, explícitos y legítimos.{" "}
          </li>

          <li>
            <strong>Minimización de datos: </strong>Limitamos la recogida de
            datos de carácter personal a lo que es estrictamente relevante y
            necesario para los fines para los que se han recopilado.
          </li>
          <li>
            <strong>Limitación de la Finalidad: </strong>Sólo recogeremos sus
            datos personales para los fines declarados y sólo según sus deseos.
          </li>
          <li>
            <strong>Precisión: </strong>Mantendremos sus datos personales
            exactos y actualizados.
          </li>
          <li>
            <strong>Seguridad de los Datos: </strong>
            Aplicamos las medidas técnicas y organizativas adecuadas y
            proporcionales a los riesgos para garantizar que sus datos no sufran
            daños, tales como divulgación o acceso no autorizado, la destrucción
            accidental o ilícita o su pérdida accidental o alteración y
            cualquier otra forma de tratamiento ilícito.
          </li>
          <li>
            <strong>Conservación: </strong>
            Conservamos sus datos personales de manera legal y apropiada y sólo
            mientras es necesario para los fines para los que se han recopilado.
          </li>
          <li>
            <strong>Terceros: </strong> El acceso y transferencia de datos
            personales a terceros se llevan a cabo de acuerdo con las leyes y
            reglamentos aplicables y con las garantías contractuales adecuadas.
          </li>
          <li>
            <strong>Marketing Directo y cookies: </strong>Cumplimos con la
            legislación aplicable en materia de publicidad y cookies.
          </li>
        </ul>
        <h4>
          <strong>4. RECOGIDA Y TRATAMIENTO DE SUS DATOS PERSONALES</strong>
        </h4>
        <p>Las tipos de datos que se pueden solicitar y tratar son:</p>
        <ul>
          <li>Datos de carácter identificativo.</li>
        </ul>
        <p>
          También recogemos de forma automática datos sobre su visita a nuestro
          sitio web según se describe en la política de cookies.
        </p>
        <h4>
          <strong>5. LEGITIMIDAD</strong>
        </h4>
        <p>
          De acuerdo con la normativa de protección de datos aplicable, sus
          datos personales podrán tratarse siempre que:
        </p>
        <ul>
          <li>
            Nos ha dado su consentimiento a los efectos del tratamiento. Por
            supuesto podrá retirar su consentimiento en cualquier momento.
          </li>
          <li>Por requerimiento legal.</li>
          <li>
            Por existir un interés legítimo que no se vea menoscabado por sus
            derechos de privacidad, como por ejemplo el envío de información
            comercial bien por suscripción a nuestra newsletter o por su
            condición de cliente.
          </li>
          <li>
            Por ser necesaria para la prestación de alguno de nuestros servicios
            mediante relación contractual entre usted y nosotros.
          </li>
        </ul>
        <h4>
          <strong>6. COMUNICACIÓN DE DATOS PERSONALES</strong>
        </h4>
        <p>
          Los datos pueden ser comunicados a empresas relacionadas con{" "}
          <strong>Luca Learning Systems, S.L.U.</strong> para la prestación de
          los diversos servicios en calidad de Encargados del Tratamiento. La
          empresa no realizará ninguna cesión, salvo por obligación legal.
        </p>
        <h4>
          <strong>7. SUS DERECHOS</strong>
        </h4>
        <p>
          En relación con la recogida y tratamiento de sus datos personales,
          puede ponerse en contacto con nosotros en cualquier momento para:
        </p>
        <ul>
          <li>
            Acceder a sus datos personales y a cualquier otra información
            indicada en el Artículo 15.1 del RGPD.
          </li>
          <li>
            Rectificar sus datos personales que sean inexactos o estén
            incompletos de acuerdo con el Artículo 16 del RGPD.
          </li>
          <li>
            Suprimir sus datos personales de acuerdo con el Artículo 17 del
            RGPD.
          </li>
          <li>
            Limitar el tratamiento de sus datos personales de acuerdo con el
            Artículo 18 del RGPD.
          </li>
          <li>
            Solicitar la portabilidad de sus datos de acuerdo con el Artículo 20
            del RGPD.
          </li>
          <li>
            Oponerse al tratamiento de sus datos personales de acuerdo con el
            artículo 21 del RGPD.
          </li>
        </ul>
        <p>
          Si ha otorgado su consentimiento para alguna finalidad concreta, tiene
          derecho a retirar el consentimiento otorgado en cualquier momento, sin
          que ello afecte a la licitud del tratamiento basado en el
          consentimiento previo a su retirada rrhh.
        </p>
        <p>
          Puede ejercer estos derechos enviando comunicación, motivada y
          acreditada, a luca@lucaedu.com
        </p>
        <p>
          También tiene derecho a presentar una reclamación ante la Autoridad de
          control competente (www.aepd.es) si considera que el tratamiento no se
          ajusta a la normativa vigente.
        </p>
        <h4>
          <strong>8. INFORMACIÓN LEGAL</strong>
        </h4>
        <p>
          Los requisitos de esta Política complementan, y no reemplazan,
          cualquier otro requisito existente bajo la ley de protección de datos
          aplicable, que será la que prevalezca en cualquier caso.
        </p>
        <p>
          Esta Política está sujeta a revisiones periódicas y la empresa puede
          modificarla en cualquier momento. Cuando esto ocurra, le avisaremos de
          cualquier cambio y le pediremos que vuelva a leer la versión más
          reciente de nuestra Política y que confirme su aceptación.
        </p>
      </div>

    </>
  );
};

export default Privacidad;
