import React, { FC } from "react";
import styles from "./assingDate.module.scss";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
interface PropsAssingData {
  startTime: string | dayjs.Dayjs;
  startDate: string | dayjs.Dayjs;
  endTime: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  startDateForDisabledEndDate: string | dayjs.Dayjs;
  setStartDate: (e: string | dayjs.Dayjs) => void;
  setStartTime: (e: string | dayjs.Dayjs) => void;
  setEndTime: (e: string | dayjs.Dayjs) => void;
  setEndDate: (e: string | dayjs.Dayjs) => void;
  setStartDateForDisabledEndDate: (e: string | dayjs.Dayjs) => void;
}

const AssingDate: FC<PropsAssingData> = ({
  startTime,
  endDate,
  startDate,
  setStartDate,
  endTime,
  startDateForDisabledEndDate,
  setEndDate,
  setEndTime,
  setStartTime,
  setStartDateForDisabledEndDate,
}) => {
  const timeFormat = "HH:mm";
  const dateFormat = "DD-MM-YYYY";

  const disableEndTime = () => {
    return {
      disabledHours: () => disabledEndHours(),
      disabledMinutes: (hour: number) => disabledEndMinutes(hour),
    };
  };

  const disabledEndMinutes = (endHour: number) => {
    const minutes: number[] = [];
    if (
      startDate &&
      typeof startTime === "string" &&
      Number(startTime.slice(0, 2)) !== endHour
    ) {
      return minutes;
    }
    if (
      endDate === dayjs().format("DD/MM/YYYY") &&
      typeof startTime === "string"
    ) {
      for (let i = 0; i < Number(startTime.slice(3, 5)) + 1; i += 1)
        minutes.push(i);
      return minutes;
    }
    if (startDate && startDate === endDate && typeof startTime === "string") {
      for (let i = 0; i < Number(startTime.slice(3, 5)) + 1; i += 1)
        minutes.push(i);
      return minutes;
    }

    return minutes;
  };

  const disabledEndHours = () => {
    const hours: number[] = [];
    if (startDate === endDate && typeof startTime === "string") {
      for (let i = 0; i < Number(startTime.slice(0, 2)); i += 1) hours.push(i);
      return hours;
    }
    return hours;
  };
  const disabledStartHours = () => {
    const today = dayjs();
    const formattedDate = today.format("DD-MM-YYYY");
    const hours: number[] = [];
    if (formattedDate === startDate) {
      for (let i = 0; i < dayjs().hour(); i += 1) hours.push(i);
      return hours;
    }
    return hours;
  };

  const disabledEndDate = (current: dayjs.Dayjs) => {
    return current < dayjs(startDateForDisabledEndDate);
  };

  const disableStartTime = () => {
    return {
      disabledHours: () => disabledStartHours(),
      // disabledMinutes: (hour: number) => disabledStartMinutes(hour),
    };
  };
  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().subtract(1, "day");
  };

  const startTimePickerOnChange = (time: dayjs.Dayjs | null) => {
    setStartTime(time ? time.format(timeFormat) : "");
    if (startDate === endDate) {
      if (
        time &&
        endTime &&
        typeof endTime !== "string" &&
        endTime.hour() <= time?.hour()
      ) {
        setEndTime(time.add(5, "minute"));
      }
    }
  };
  const endTimePickerOnChange = (time: dayjs.Dayjs | null) => {
    setEndTime(time ? time.format(timeFormat) : "");
    if (endTime !== time?.format(timeFormat)) {
      setEndTime(time ? time.format(timeFormat) : "");
    }
  };

  return (
    <div>
      <div className={styles.programPanel}>
        <h1 className={styles.typography}>
          Selecciona cuando quieres que les aparezca activa la tarea a tus
          alumnos y la fecha de entrega
        </h1>
        <p style={{ marginTop: "3%" }}>Inicia</p>{" "}
        <div className={styles.programInputs}>
          <DatePicker
            onChange={(date) => {
              if (
                date &&
                dayjs(date, dateFormat).isAfter(dayjs(endDate, dateFormat))
              ) {
                setEndDate(date.add(2, "day").format(dateFormat));
                setEndTime("23:59");
              }
              setStartDate(date ? date.format(dateFormat) : startDate);
              setStartDateForDisabledEndDate(
                date ? date.format("YYYY-MM-DD") : ""
              );
            }}
            style={{ width: "48%" }}
            value={dayjs(startDate, dateFormat)}
            showNow={false}
            changeOnBlur={true}
            format={dateFormat}
            disabledDate={disabledDate}
            allowClear={false}
          />
          <TimePicker
            value={dayjs(startTime, timeFormat)}
            format={timeFormat}
            showNow={false}
            changeOnBlur={true}
            onChange={startTimePickerOnChange}
            disabled={startDate ? false : true}
            disabledTime={disableStartTime}
            style={{ width: "48%" }}
            placeholder="Ahora"
          />
        </div>
      </div>
      <div className={styles.programPanel}>
        <p>Finaliza</p>
        <div className={styles.programInputs}>
          <DatePicker
            allowClear={false}
            onChange={(date) => {
              setEndDate(date ? date.format(dateFormat) : endDate);
            }}
            style={{ width: "48%" }}
            showNow={false}
            value={dayjs(endDate, dateFormat)}
            format={dateFormat}
            changeOnBlur={true}
            disabledDate={disabledEndDate}
            disabled={startTime && endDate ? false : true}
          />
          <TimePicker
            onChange={endTimePickerOnChange}
            disabledTime={disableEndTime}
            showNow={false}
            disabled={endDate && startTime ? false : true}
            value={dayjs(endTime, timeFormat)}
            format={timeFormat}
            changeOnBlur={true}
            style={{ width: "48%" }}
            placeholder="Final del día"
          />
        </div>
      </div>
    </div>
  );
};

export default AssingDate;
