import React, { FC, useEffect, useState } from "react";
import styles from "./containerAlumn.module.scss";
import useAuth from "../../../../../../../../context/useAuth";
import error from "../../../../../../../../assets/pages/quiz/error.png";
import { PropsMessage } from "../../../utils";
import {
  resolveAvatar,
  resolveColorAvatar,
} from "../../../../../../../../utils/functions";
import { GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log } from "../../../../../../../../__generated__/gql-types/GetChatLogForTeacherCorrection";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

interface PropsContainerAlumn {
  message:
    | PropsMessage
    | GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log;
}
const ContainerAlumn: FC<PropsContainerAlumn> = ({ message }) => {
  const { userToken } = useAuth();
  const [isError, setIsError] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>();

  useEffect(() => {
    (async () => {
      try {
        setEditorState(() => {
          if (
            message?.errorUser?.split("Errors:") &&
            message?.errorUser?.split("Errors:")[1]
          ) {
            const contentBlock = htmlToDraft(
              message?.errorUser?.split("Errors:")[1]
            );
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            return EditorState.createWithContent(contentState);
          } else {
            return EditorState.createEmpty();
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [message?.errorUser]);
  return (
    <div className={styles.container}>
      <div className={styles.containerAlumn}>
        <div className={styles.containerTextAi}>
          {message.errorUser && (
            <img
              src={error}
              alt="error"
              onClick={() => {
                setIsError(true);
              }}
            />
          )}
          <div className={styles.textAI}>{message.message}</div>
        </div>
        <img
          style={{
            background: `${resolveColorAvatar(userToken?.avatar ?? "polux")}`,
          }}
          src={resolveAvatar(
            userToken?.avatar ?? "polux",
            userToken?.score?.level?.toString() ?? "1"
          )}
          alt="avatar"
          className={styles.backgrounAvatar}
        />
      </div>
      {isError && (
        <Editor
          editorState={editorState}
          readOnly={false}
          placeholder="Escribe aquí tu respuesta"
          editorStyle={{
            backgroundColor: "white",
            fontWeight: 600,
            fontSize: "16px",
            fontFamily: "Poppins-SemiBold",
            color: "#4c4f63",
            borderRadius: 12,
            padding: 10,
            overflow: "hidden",
          }}
          toolbarHidden={true}
          localization={{
            locale: "es",
          }}
        />
      )}
    </div>
  );
};

export default ContainerAlumn;
