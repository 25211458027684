import { FC } from "react";
import styles from "./filters.module.scss";

// Icons
import matematica from "../../../assets/icons/subjects/matematica.svg";
import matematicaSelected from "../../../assets/icons/subjects/matematica-selected.svg";
import ciencias from "../../../assets/icons/subjects/ciencias.svg";
import cienciasSelected from "../../../assets/icons/subjects/ciencias-selected.svg";
import spanish from "../../../assets/icons/subjects/spanish.svg";
import spanishSelected from "../../../assets/icons/subjects/spanish-selected.svg";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import useAuth from "../../../context/useAuth";
import useTeacherNavigateLessonDosification from "../../../api/useTeacherNavigateLessonDosification";
import useTeacherNavigateLessonSearch from "../../../api/useTeacherNavigateLessonSearch";
import useIsMobile from "../../../hooks/useIsMobile";

interface FiltersProps {
  selectedItem?: string;
  lessonViewSelected: string;
  setLessonViewSelected: React.Dispatch<React.SetStateAction<string>>;
  setSelectedItem: (event: string) => void;
  setLimit: (e: number) => void;
  setOpenFilter: (e: boolean) => void;
  showDrawer: () => void;
  openFilter: boolean;
  loadingLessons: boolean;
}

const Filters: FC<FiltersProps> = ({
  selectedItem,
  lessonViewSelected,
  openFilter,
  loadingLessons,
  setLessonViewSelected,
  setSelectedItem,
  setLimit,
  setOpenFilter,
  showDrawer,
}) => {
  const isMobile = useIsMobile();
  const { userToken } = useAuth();
  const dosificationNavigateLog = useTeacherNavigateLessonDosification();
  const lessonsSearchNavigateLog = useTeacherNavigateLessonSearch();
  const showDosificationForSchool = () => {
    let showDosification = true;
    userToken?.schools.map((school) => {
      if (
        school.name === "Escuela Piloto" ||
        school.name === "Escuela Demo" ||
        school.name === "Demo en Casa" ||
        school.name === "Union"
      ) {
        showDosification = false;
      }
    });

    if (!showDosification) {
      setLessonViewSelected("search");
    }

    return showDosification;
  };

  return isMobile ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          marginBottom: "16px",
        }}
      >
        <button
          name="search"
          className={
            lessonViewSelected === "search"
              ? styles.itemSearchSelected
              : styles.itemsSearch
          }
          onClick={() => {
            setLessonViewSelected("search");
            lessonsSearchNavigateLog();
          }}
        >
          Buscador
        </button>
        <button
          name="dosification"
          className={
            lessonViewSelected === "dosification"
              ? styles.itemSearchSelected
              : styles.itemsSearch
          }
          onClick={() => {
            setLessonViewSelected("dosification");
            dosificationNavigateLog();
          }}
        >
          {" "}
          Dosificación
        </button>
      </div>

      <div className={styles.container}>
        <button
          name="1"
          className={selectedItem === "1" ? styles.itemSelected : styles.items}
          onClick={() => {
            setLimit(8);
            setSelectedItem("1");
          }}
        >
          {selectedItem === "1" ? (
            <img src={matematicaSelected} alt="matematicaSelected" />
          ) : (
            <img src={matematica} alt="matematica" />
          )}
          Matemáticas
        </button>
        <button
          name="2"
          className={selectedItem === "2" ? styles.itemSelected : styles.items}
          onClick={() => {
            setLimit(8);
            setSelectedItem("2");
          }}
        >
          {selectedItem === "2" ? (
            <img src={spanishSelected} alt="spanishSelected" />
          ) : (
            <img src={spanish} alt="spanish" />
          )}
          Español
        </button>
        <button
          name="4"
          className={selectedItem === "4" ? styles.itemSelected : styles.items}
          onClick={() => {
            setLimit(8);
            setSelectedItem("4");
          }}
        >
          {selectedItem === "4" ? (
            <img src={cienciasSelected} alt="cienciasSelected" />
          ) : (
            <img src={ciencias} alt="ciencias" />
          )}
          Ciencias
        </button>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <>
        {!openFilter && lessonViewSelected === "search" && (
          <div
            className={styles.items}
            onClick={() => {
              isMobile ? showDrawer() : setOpenFilter(true);
            }}
          >
            <img src={settingsIcon} alt="" className={styles.filterImg} />
            <div>Filtros</div>
          </div>
        )}
        <button
          name="1"
          className={selectedItem === "1" ? styles.itemSelected : styles.items}
          disabled={userToken?.schools[0].name === "Legionarios" ? true : false}
          onClick={() => {
            setLimit(8);
            !loadingLessons && setSelectedItem("1");
          }}
        >
          {selectedItem === "1" ? (
            <img src={matematicaSelected} alt="matematicaSelected" />
          ) : (
            <img src={matematica} alt="matematica" />
          )}
          Matemáticas
        </button>
        <button
          name="2"
          className={selectedItem === "2" ? styles.itemSelected : styles.items}
          onClick={() => {
            setLimit(8);
            !loadingLessons && setSelectedItem("2");
          }}
        >
          {selectedItem === "2" ? (
            <img src={spanishSelected} alt="spanishSelected" />
          ) : (
            <img src={spanish} alt="spanish" />
          )}
          Español
        </button>
        <button
          name="4"
          className={selectedItem === "4" ? styles.itemSelected : styles.items}
          disabled={userToken?.schools[0].name === "Legionarios" ? true : false}
          onClick={() => {
            setLimit(8);
            !loadingLessons && setSelectedItem("4");
          }}
        >
          {selectedItem === "4" ? (
            <img src={cienciasSelected} alt="cienciasSelected" />
          ) : (
            <img src={ciencias} alt="ciencias" />
          )}
          Ciencias
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {showDosificationForSchool() && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                name="search"
                className={
                  lessonViewSelected === "search"
                    ? styles.itemSearchSelected
                    : styles.itemsSearch
                }
                onClick={() => {
                  setLessonViewSelected("search");
                  lessonsSearchNavigateLog();
                }}
              >
                {" "}
                Buscador
              </button>
              <button
                name="dosification"
                className={
                  lessonViewSelected === "dosification"
                    ? styles.itemSearchSelected
                    : styles.itemsSearch
                }
                onClick={() => {
                  setLessonViewSelected("dosification");
                  dosificationNavigateLog();
                }}
              >
                {" "}
                Dosificación
              </button>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default Filters;
