import React, { FC, useState } from "react";
import styles from "./containerAvatar.module.scss";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../../../__generated__/gql-types/getClothesAndAccessories";
import baseAvatar from "../../../../assets/pages/store/store.png";
import useAuth from "../../../../context/useAuth";
import { functionReturnImgAvatar } from "./utils";
import { resolveAvatar } from "../../../../utils/functions";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
interface PropsAvatar {
  clothesActive: PropsClothes[] | undefined;
  userScore: getUserScore_getUserScore | undefined;
}
const ContainerAvatar: FC<PropsAvatar> = ({ clothesActive, userScore }) => {
  const { userToken } = useAuth();

  return (
    <div className={styles.containerAvatar}>
      {clothesActive?.map((clothes) => (
        <img
          src={clothes.item_img ?? ""}
          alt="avatar"
          style={{ zIndex: clothes.z_index ?? 0 }}
          className={styles.clothes}
        />
      ))}
      <img
        src={
          userScore?.level && userScore?.level < 6
            ? resolveAvatar(
                userScore?.user?.avatar_state?.name ?? "polux",
                userScore?.level.toString() ?? "1"
              )
            : functionReturnImgAvatar(userToken?.avatar ?? "")
        }
        alt="avatar"
        className={
          userScore?.level && userScore?.level < 6
            ? styles.avatarImgwithOutLevel // revisar
            : styles.avatarImg
        }
      />
      <img src={baseAvatar} alt="base" />
    </div>
  );
};

export default ContainerAvatar;
