import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserInput } from "../__generated__/gql-types/globalTypes";

const UseAddStudentByTeacher = () => {
  const client = useApolloClient();

  return useCallback(
    async (userInput: UserInput) => {
      return await client.mutate({
        mutation: ADD_STUDENT_BY_TEACHER,
        fetchPolicy: "no-cache",
        variables: { userInput },
      });
    },
    [client]
  );
};
export default UseAddStudentByTeacher;

const ADD_STUDENT_BY_TEACHER = gql`
 mutation addStudentByTeacher($userInput: UserInput!) {
  addStudentByTeacher(userInput: $userInput) {
    id
    first_name
    last_name
    email
  }
}
`;
