import React, { FC, useEffect, useState } from "react";
import { BannerClasification } from "./BannerClasification";
import {
  getInfoTournament_getInfoTournament,
  getInfoTournament_getInfoTournament_userInfo as userChampion,
} from "../../../__generated__/gql-types/getInfoTournament";
import { TableClasification } from "./TableClasification";
import { ModalPosition } from "./ModalPosition";
import { getUserScore_getUserScore } from "../../../__generated__/gql-types/getUserScore";
import UseGetInfoTournament from "../../../api/useGetInfoTournament";
import { Skeleton } from "antd";
import styles from "../store.module.scss";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";

interface ChampionshipProps {}

const Championship: FC<ChampionshipProps> = ({}) => {
  const [openModal, setOpenModal] = useState(false);
  const [userModal, setUserModal] = useState<userChampion>();
  const [isLoading, setIsLoading] = useState(false);
  const getInfoTournament = UseGetInfoTournament();
  const [infoTournament, setInfoTournament] =
    useState<getInfoTournament_getInfoTournament | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const infoTournament = await getInfoTournament();
      infoTournament && setInfoTournament(infoTournament);
      setIsLoading(false);
    })();
  }, [getInfoTournament]);

  const onCloseModalPosition = () => {
    setOpenModal(false);
  };

  return isLoading ? (
    <ActivityIndicator />
  ) : (
    <>
      <BannerClasification
        setUserModal={setUserModal}
        setOpenModal={setOpenModal}
        infoTournament={infoTournament}
        dateLeader={infoTournament?.start_date.split("T")[0]}
        daysLeft={Number(infoTournament?.difference_in_days)}
      />
      <TableClasification
        setOpenModal={setOpenModal}
        infoTournament={infoTournament}
        setUserModal={setUserModal}
      ></TableClasification>
      <ModalPosition
        onCloseModalPosition={onCloseModalPosition}
        openModal={openModal}
        userModal={userModal}
      ></ModalPosition>
    </>
  );
};

export default Championship;
