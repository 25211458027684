import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherClickSeeVideoLessonNotNEMVariables } from "../__generated__/gql-types/teacherClickSeeVideoLessonNotNEM";

const useTeacherClickSeeVideoLessonNotNEM = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherClickSeeVideoLessonNotNEMVariables) => {
      return await client.mutate({
        mutation: TEACHER_CLICK_SEE_VIDEO_LESSON_NOT_NEM,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherClickSeeVideoLessonNotNEM;

const TEACHER_CLICK_SEE_VIDEO_LESSON_NOT_NEM = gql`
  mutation teacherClickSeeVideoLessonNotNEM($contentId: Int) {
    teacherClickSeeVideoLessonNotNEM(contentId: $contentId)
  }
`;
