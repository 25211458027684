import { FC, useState } from "react";
import { Collapse } from "antd";
import styles from "./activefilters.module.scss";
import { FilterBubbles } from "../../../Shared/FilterBubbles";

// Icons
import paginaIcon from "../../../../assets/icons/filters/pagina.svg";
import paginaSelected from "../../../../assets/icons/filters/pagina-selected.svg";
import dosificacionIcon from "../../../../assets/icons/filters/dosificacion.svg";
import dosificacionSelected from "../../../../assets/icons/filters/dosificacion-selected.svg";
import useIsMobile from "../../../../hooks/useIsMobile";

const { Panel } = Collapse;
interface ActiveFiltersProps {
	filterActive: boolean;
	weekList?: string[];
	sepBookPage?: string;
	selectedWeek?: string;
	sepBookPagesList?: string[];
	setPageNumber?: (e: number) => void;
	setSepBookPage?: (value: string) => void;
	setSelectedWeek?: (value: string) => void;
}

const RecommendedContentActiveFilters: FC<ActiveFiltersProps> = ({
	filterActive,
	setSepBookPage,
	setPageNumber,
	sepBookPage,
	sepBookPagesList,
	weekList,
	selectedWeek,
	setSelectedWeek,
}) => {
	const isMobile = useIsMobile();

	const [panelKey, setPanelKey] = useState<string | string[]>(["1"]);

	const widthStyle = () => {
		if (!isMobile) {
			if (filterActive) return "24%";
			else return "0%";
		} else {
			return "100%";
		}
	};

	return (
		<div
			className={styles.filtersContainer}
			style={{
				width: widthStyle(),
				display: filterActive ? "flex" : "none",
			}}
		>
			{!isMobile && (
				<div className={styles.filterContent}>
					<div className={styles.filtersAppliedContainer}>
						<div>Filtros aplicados</div>
						<div
							style={{ color: "gray", cursor: "pointer" }}
							//onClick={handleCleanAll}
						>
							Borrar todo
						</div>
					</div>
					<div
						className={styles.bubblesContainer}
						style={{ marginTop: "3%" }}
					></div>
				</div>
			)}
			<Collapse
				ghost
				defaultActiveKey={["1"]}
				expandIconPosition={"end"}
				style={{ width: "100%" }}
				onChange={(key: string | string[]) => {
					setPanelKey(key);
				}}
				destroyInactivePanel={true}
			>
				<Panel
					key="3"
					header={
						<div className={styles.filterTitle}>
							{panelKey.includes("3") ? (
								<>
									<img src={paginaSelected} alt="Páginas del libro selected " />
									<h1 className={styles.titleSelected}>Páginas del libro </h1>
								</>
							) : (
								<>
									<img src={paginaIcon} alt="Páginas del libro " />
									<h1>Páginas del libro </h1>
								</>
							)}
						</div>
					}
				>
					<FilterBubbles
						type="page"
						itemList={sepBookPagesList}
						selectedItem={sepBookPage}
						setPageNumber={setPageNumber}
						setSelectedItem={setSepBookPage}
					/>
				</Panel>

				<Panel
					key="4"
					header={
						<div className={styles.filterTitle}>
							{panelKey.includes("4") ? (
								<>
									<img
										src={dosificacionSelected}
										alt="dosificación icon selected"
									/>
									<h1 className={styles.titleSelected}>Dosificación </h1>
								</>
							) : (
								<>
									<img src={dosificacionIcon} alt="dosificación icon" />
									<h1> Dosificación </h1>
								</>
							)}
						</div>
					}
				>
					<FilterBubbles
						type="week"
						itemList={weekList}
						selectedItem={selectedWeek}
						setPageNumber={setPageNumber}
						setSelectedItem={setSelectedWeek}
					/>
				</Panel>
			</Collapse>
		</div>
	);
};

export default RecommendedContentActiveFilters;
