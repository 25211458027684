import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getCoursesBySubjectIdProgram2425Variables,
  getCoursesBySubjectIdProgram2425,
} from "../__generated__/gql-types/getCoursesBySubjectIdProgram2425";

const UseGetCoursesBySubjectIdProgram2425 = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdProgram2425Variables) => {
      const result: ApolloQueryResult<getCoursesBySubjectIdProgram2425> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID_V2_2425,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySubjectIdProgram2425;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectIdProgram2425;

const GET_COURSES_BY_SUBJECT_ID_V2_2425 = gql`
  query getCoursesBySubjectIdProgram2425(
    $getCoursesBySubjectIdInputProgram2425: GetCoursesBySubjectIdInputProgram2425
  ) {
    getCoursesBySubjectIdProgram2425(
      GetCoursesBySubjectIdInputProgram2425: $getCoursesBySubjectIdInputProgram2425
    ) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      program_2425
      description
      initial_evaluation
      sep_book_pages
      sep_book_pages_2425
      threemester
      threemester_2425
      week
      week_2425
      count
    }
  }
`;
