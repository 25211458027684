import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getInfoTournament } from "../__generated__/gql-types/getInfoTournament";

const UseGetInfoTournament = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getInfoTournament> = await client.query({
      query: GET_INFO_TOURNAMENT,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getInfoTournament;
  }, [client]);
};

export default UseGetInfoTournament;

const GET_INFO_TOURNAMENT = gql`
  query getInfoTournament {
    getInfoTournament {
      difference_in_days
      end_date
      start_date
      userInfo {
        id
        score
        main_score
        position
        movement
        level
        name
        avatar_id
        avatar_name
        shopActions {
          item_img
          z_index
        }
      }
    }
  }
`;
