import React, { FC } from "react";
import gemPositive from "../../../../assets/icons/gems/gemPositive.svg";
import gemNegative from "../../../../assets/icons/gems/gemNegative.svg";
import styles from "./cardLiteracy.module.scss";
import { getLiteracyLessonsSearch_getLiteracyLessonsSearch as PropsLiteracy } from "../../../../__generated__/gql-types/getLiteracyLessonsSearch";
import imageLiteracyDrawer from "../../../../assets/images/imageLiteracyDrawer.png";
interface PropsCard {
  setLessonId: (e: string) => void;
  setVisible: (e: boolean) => void;
  literacy: PropsLiteracy;
}

const CardLiteracy: FC<PropsCard> = ({ setLessonId, literacy, setVisible }) => {
  return (
    <div
      className={styles.card}
      onClick={() => {
        setLessonId(literacy.id);
        setVisible(true);
      }}
    >
      <div
        className={styles.containerHeader}
        style={{
          backgroundColor: literacy.lesson_content.literacy?.header_color
            ? literacy.lesson_content.literacy?.header_color
            : "",
        }}
      >
        <div className={styles.textHeader}>{literacy.title}</div>
      </div>
      {literacy?.result && (
        <div className={styles.containerGem}>
          {literacy.result >= 3 ? (
            <img src={gemPositive} alt="" />
          ) : (
            <img src={gemNegative} alt="" />
          )}
          {literacy.result >= 6 ? (
            <img src={gemPositive} alt="" />
          ) : (
            <img src={gemNegative} alt="" />
          )}
          {literacy.result > 9 ? (
            <img src={gemPositive} alt="" />
          ) : (
            <img src={gemNegative} alt="" />
          )}
        </div>
      )}
      <img
        src={
          literacy.lesson_content.literacy?.cover
            ? literacy.lesson_content.literacy?.cover
            : imageLiteracyDrawer
        }
        alt="as"
        className={styles.imagenCard}
      />
    </div>
  );
};

export default CardLiteracy;
