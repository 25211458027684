import React, { FC, useState } from "react";
import { Button, Drawer, Space } from "antd";
import styles from "./downloadTableModal.module.scss";
import cross from "../../../assets/images/cross.svg";
import arrowIcon from "../../../assets/icons/arrows/arrow-back.svg";
import { DownloadTableAsExcel } from "../DownloadTableAsExcel/DownloadTableAsExcel";
import useIsMobile from "../../../hooks/useIsMobile";

interface IDownloadTableModalProps {
	visible: boolean;
	setIsDownloadModalOpen: (value: boolean) => void;
	dataCalificationsTableToDownload?: any;
	classRoom?: any;
	filterDatesRange?: string | null;
	homeworkState?: string[] | null;
}

const DownloadTableModal: FC<IDownloadTableModalProps> = ({
	visible,
	setIsDownloadModalOpen,
	dataCalificationsTableToDownload,
	classRoom,
	filterDatesRange,
	homeworkState,
}) => {
	const isMobile = useIsMobile();
	const [selectedFileFormat, setSelectedFileFormat] = useState("");

	const formatDateRange = (dateRange: any) => {
		if (dateRange.length > 1 && dateRange !== null && dateRange !== undefined) {
			const dates = dateRange.split("-");
			if (dates.includes("undefined")) return `Todas las tareas.`;
			const firstDate = `${dates[0]} / ${dates[1]} / ${dates[2]?.slice(0, 2)}`;
			const secondDate = `${dates[3]} / ${dates[4]} / ${dates[5]?.slice(0, 2)}`;

			const startDate = new Date(firstDate);
			const endDate = new Date(secondDate);

			const startFormatted = `${startDate.getDate()}/${
				startDate.getMonth() + 1
			}/${startDate.getFullYear()}`;
			const endFormatted = `${endDate.getDate()}/${
				endDate.getMonth() + 1
			}/${endDate.getFullYear()}`;

			return `del ${startFormatted} al ${endFormatted}`;
		}
		return `Todas las tareas.`;
	};

	const homeworkStatusToShow = (homeworkState: any) => {
		if (homeworkState !== null) {
			if (homeworkState.length === 3) {
				return "Todos los estados.";
			}
			let result: any;
			let finished: any;
			let inCourse: any;
			let programmed: any;
			homeworkState.forEach((element: any) => {
				switch (element) {
					case "finished":
						result += "Finalizadas";
						finished = "Finalizadas";
						break;
					case "inCourse":
						inCourse = "En curso";
						result += "En curso";
						break;
					case "programed":
						programmed = "Programadas";
						result += "Programadas";
						break;
				}
			});
			const final = `${programmed !== undefined ? programmed + " - " : ""}${
				inCourse !== undefined ? inCourse + " - " : ""
			}${finished !== undefined ? finished : ""}`;
			return final;
		}
	};
	return (
		<>
			<Drawer
				height={isMobile ? "500px" : ""}
				placement={isMobile ? "bottom" : "right"}
				open={visible}
				closable={false}
				bodyStyle={{ background: "#F5F5F9" }}
				title={
					<div
						style={{
							height: "120px",
							width: "85%",
							display: "flex",
							alignItems: "flex-start",
							flexDirection: "column",
							justifyContent: "space-between",
							//border: "1px solid #E5E5",
							paddingTop: "10px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								height: "30%",
							}}
						>
							<img
								style={{ cursor: "pointer" }}
								src={arrowIcon}
								alt="go-back"
								onClick={() => {
									setIsDownloadModalOpen(false);
								}}
							/>

							<p
								style={{
									fontFamily: "Poppins-Bold",
									fontWeight: "bold",
									fontSize: "14px",
									lineHeight: "19.6px",
									marginTop: "3%",
								}}
							>
								Regresa al listado de alumnos
							</p>
						</div>

						<h2
							style={{
								fontFamily: "Poppins-Bold",
								fontWeight: "bold",
								fontSize: "24px",
								lineHeight: "24px",
								letterSpacing: "0.38px",
							}}
						>
							Prepara tu documento de descarga
						</h2>
					</div>
				}
				extra={
					<Space>
						<img
							src={cross}
							alt=""
							className={styles.crossImg}
							onClick={() => {
								setIsDownloadModalOpen(false);
							}}
						/>
					</Space>
				}
				headerStyle={{
					background: "#fff",
				}}
				footer={
					<div className={styles.footer}>
						{selectedFileFormat === "EXCEL" || "CSV" || "PDF" ? (
							<DownloadTableAsExcel
								dataToRender={dataCalificationsTableToDownload}
								classRoom={classRoom}
								format={selectedFileFormat}
							/>
						) : (
							<Button
								className={styles.taskButton}
								disabled={selectedFileFormat.length === 0}
								style={{
									backgroundColor: "rgb(255, 188, 65)",
									borderRadius: "11px",
									width: "160px",
									textAlign: "center",
								}}
								onClick={() => {
									//here we make call to the download function! ex: downloadStudentsCalificationsTable()
									alert("Descargando");
								}}
							>
								Descargar
							</Button>
						)}
					</div>
				}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						borderRadius: 20,
						backgroundColor: "#ffff",
						height: "30%",
						width: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0 20px",
						}}
					>
						<h3
							style={{
								fontFamily: "Poppins-Bold",
								fontWeight: "bold",
								fontSize: "14px",
								lineHeight: "24px",
								letterSpacing: "0.38px",
							}}
						>
							Filtros aplicados para el documento de descarga
						</h3>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								//padding: "0 20px",
								width: "90%",
							}}
						>
							<p
								style={{
									fontFamily: "Poppins-Regular",
									fontWeight: "lighter",
									fontSize: "14px",
									lineHeight: "24px",
									letterSpacing: "0.38px",
								}}
							>
								Rango de Fechas.
							</p>
							<p
								style={{
									fontFamily: "Poppins-Regular",
									fontWeight: "lighter",
									fontSize: "14px",
									lineHeight: "24px",
									letterSpacing: "0.38px",
								}}
							>
								{formatDateRange(filterDatesRange)}
							</p>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								width: "90%",
							}}
						>
							<p
								style={{
									fontFamily: "Poppins-Regular",
									fontWeight: "lighter",
									fontSize: "14px",
									lineHeight: "24px",
									letterSpacing: "0.38px",
								}}
							>
								Estado de las tareas.
							</p>
							<p
								style={{
									fontFamily: "Poppins-Regular",
									fontWeight: "lighter",
									fontSize: "14px",
									lineHeight: "24px",
									letterSpacing: "0.38px",
								}}
							>
								{homeworkStatusToShow(homeworkState)}
							</p>
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						borderRadius: 20,
						backgroundColor: "#ffff",
						height: "25%",
						width: "100%",
						marginTop: "20px",
					}}
				>
					<h3
						style={{
							fontFamily: "Poppins-Bold",
							fontWeight: "bold",
							fontSize: "14px",
							lineHeight: "24px",
							letterSpacing: "0.38px",
							marginLeft: "25px",
						}}
					>
						Formato de Descarga
					</h3>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-end",
							justifyContent: "space-evenly",
						}}
					>
						<Button
							style={{
								borderRadius: 10,
								borderColor: "#6A6F8A",
								backgroundColor:
									selectedFileFormat === "CSV" ? "#6A6F8A" : "#FFFFFF",
								width: "100px",
								height: "35px",
							}}
							onClick={() => {
								setSelectedFileFormat("CSV");
							}}
						>
							CSV
						</Button>
						<Button
							style={{
								borderRadius: 10,
								borderColor: "#6A6F8A",
								backgroundColor:
									selectedFileFormat === "EXCEL" ? "#6A6F8A" : "#FFFFFF",
								width: "100px",
								height: "35px",
							}}
							onClick={() => {
								setSelectedFileFormat("EXCEL");
							}}
						>
							EXCEL
						</Button>
					</div>
				</div>
			</Drawer>
		</>
	);
};

export default DownloadTableModal;
