import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherDownloadODVariables } from "../__generated__/gql-types/teacherDownloadOD";

const useTeacherDownloadOD = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherDownloadODVariables) => {
      return await client.mutate({
        mutation: TEACHER_DONWLOAD_OD,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherDownloadOD;

const TEACHER_DONWLOAD_OD = gql`
  mutation teacherDownloadOD($contentId: Int) {
    teacherDownloadOD(contentId: $contentId)
  }
`;
