import React, { FC, useEffect, useState } from "react";
import { getLessonsBySubjectId_getLessonsBySubject } from "../../../../../__generated__/gql-types/getLessonsBySubjectId";
import defaultImage from "../../../../../assets/images/defaultImage.svg";
import order from "../../../../../assets/icons/order.svg";
import styles from "./cardMobile.module.scss";
import { Tags } from "../../../../Shared/Tags";

interface CardProps {
  setLessonId: (id: string) => void;
  showDrawer: () => void;
  item: getLessonsBySubjectId_getLessonsBySubject;
}

const CardMobile: FC<CardProps> = ({ item, showDrawer, setLessonId }) => {
  const [itemImage, setItemImage] = useState<string | null>(null);

  useEffect(() => {
    setItemImage(
      item?.lesson_content?.videos && item.lesson_content?.videos?.length
        ? item.lesson_content.videos[0].imageUrl
        : defaultImage
    );
  }, [item?.lesson_content?.videos]);

  return (
    <div
      className={styles.container}
      onClick={() => {
        setLessonId(item?.id);
        showDrawer();
      }}
    >
      <img src={itemImage ?? ""} style={{ width: "100%" }} />

      <div className={styles.cardContent}>
        <div className={styles.title}>{item?.title}</div>
        <div className={styles.containerSubTitle}>
          <div className={styles.subTitle}>{item?.sep_name ?? ""}</div>
          <div className={styles.pages}>
            <img src={order}></img> Pág. {item?.sep_book_pages}{" "}
          </div>
        </div>
        {<Tags item={item}></Tags>}
      </div>
    </div>
  );
};

export default CardMobile;
