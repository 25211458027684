import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherDownloadSlidesModActionEventVariables } from "../__generated__/gql-types/teacherDownloadSlidesModActionEvent";

const useTeacherDownloadSlidesModActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherDownloadSlidesModActionEventVariables) => {
      return await client.mutate({
        mutation: TEACHER_DONWLOAD_SLIDES_MOD_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherDownloadSlidesModActionEvent;

const TEACHER_DONWLOAD_SLIDES_MOD_ACTION_EVENT = gql`
  mutation teacherDownloadSlidesModActionEvent($contentId: Int) {
    teacherDownloadSlidesModActionEvent(contentId: $contentId)
  }
`;
