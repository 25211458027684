import styles from "./bannerNoHomework.module.scss";
import LucaBeach from "../../../../assets/pages/classes/LucaBeach.png";

const BannerNoHomework = () => {
  return (
    <div className={styles.container}>
      <img src={LucaBeach} alt="robotLuca" className={styles.LucaBeach} />

      <div className={styles.secondColumn}>
        <div className={styles.title}>¡ENHORABUENA!</div>
        <div className={styles.description}>Revisaste todas tus tareas.</div>
      </div>
    </div>
  );
};

export default BannerNoHomework;
