import { FC } from "react";
import styles from "./nextlevel.module.css";

// Icons
import zafire from "../../../../assets/icons/students/zafire.svg";
import nextLevelStar from "../../../../assets/icons/students/nextlevel.png";

interface NextLevelProps {
  userDb?: any;
  handleRedirection: () => void;
}

const NextLevel: FC<NextLevelProps> = ({ userDb, handleRedirection }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imagenContainer}>
        <img src={nextLevelStar} alt="nextLevelStar" />
        <h1>{userDb?.score?.level}</h1>
      </div>

      <h1>¡Nivel {userDb?.score?.level} desbloqueado!</h1>
      <p>
        ¡Enhorabuena! Descubre las sorpresas que <br /> esconde este nivel
      </p>

      <button className={styles.modalPoints}>
        Puntos totales{" "}
        <span>
          {" "}
          <img src={zafire} alt="zafire" />
          <span className={styles.textPoints}>
            {" "}
            {userDb?.score?.main_score}{" "}
          </span>{" "}
        </span>
      </button>

      <button onClick={handleRedirection} className={styles.nextButton}>
        CONTINUAR
      </button>
    </div>
  );
};

export default NextLevel;
