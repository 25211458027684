import React, { FC } from "react";
import styles from "./relate.module.scss";
import {
  getResponsesByHomework_getResponsesByHomework,
  getResponsesByHomework_getResponsesByHomework_relationQuestion_block1,
  getResponsesByHomework_getResponsesByHomework_relationQuestion_block2,
} from "../../../../../__generated__/gql-types/getResponsesByHomework";

interface PropsRelate {
  index: number;
  question: getResponsesByHomework_getResponsesByHomework;
  subject_id?: number | null;
}
const Relate: FC<PropsRelate> = ({ index, question, subject_id }) => {
  const functionPutAlpha = (i?: number) =>
    (i === 1 && "A") ||
    (i === 2 && "B") ||
    (i === 3 && "C") ||
    (i === 4 && "D") ||
    "";

  const block1 = question.relationQuestion![0].block1?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );
  const block2 = question.relationQuestion![0].block2?.sort(
    (a, b) => a.coincidence! - b.coincidence!
  );

  interface RenderContentProps {
    image: string | null;
    text: string | null;
    coincidence: number | null;
    totalItems: number | null;
    url: string | null;
    isWhite: boolean;
    percent: number;
    correct: boolean;
  }

  const calculatePercents = (
    question: getResponsesByHomework_getResponsesByHomework,
    block1:
      | getResponsesByHomework_getResponsesByHomework_relationQuestion_block1[]
      | undefined,
    block2:
      | getResponsesByHomework_getResponsesByHomework_relationQuestion_block2[]
      | undefined
  ) => {
    let renderContent: RenderContentProps[][] = [];
    if (block1 && block2) {
      renderContent = Array.from({ length: block1.length + 1 }, () => []);

      // agregamos el primer elemento nulo y los blockItems
      renderContent[0].push({
        image: "",
        text: "",
        coincidence: 0,
        totalItems: 0,
        url: "",
        isWhite: true,
        percent: -1,
        correct: false,
      });

      // agreglo los elementos a la primera fila
      block1.forEach((elem) => {
        renderContent[0].push({
          coincidence: elem.coincidence,
          image: elem.image,
          text: elem.text,
          isWhite: false,
          percent: -1,
          totalItems: 0,
          url: elem.url,
          correct: false,
        });
      });

      // agregar a los demás array's el contenedor como primer elemento
      block2
        .sort((a, b) => a!.coincidence! - b!.coincidence!)
        .forEach((elem, index) => {
          renderContent[index + 1].push({
            coincidence: elem.coincidence,
            image: elem.image,
            isWhite: false,
            percent: -1,
            text: elem.text,
            totalItems: 0,
            url: "",
            correct: false,
          });
        });

      question.student_relation_DaD_responses?.forEach((item) => {
        renderContent[item?.second!][item?.first!] = {
          coincidence: -1,
          image: "",
          isWhite: false,
          percent: item?.percent!,
          text: "",
          totalItems: 0,
          url: "",
          correct: item?.second === item?.first,
        };
      });
    }

    return renderContent;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3 className={styles.titleRelate}>
          {index + 1}.{" "}
          {question.question
            ? question.question
            : subject_id === 16
            ? "Relate to concepts by joining the two columns."
            : "Relaciona con conceptos uniendo las dos columnas."}
        </h3>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="asdas"
            />
          </div>
        )}
        {calculatePercents(question, block1, block2).map((fila) => {
          return (
            <div className={styles.containerBlock}>
              <div className={styles.firstColumn}>
                {fila.map((elem) => {
                  return elem.isWhite ? (
                    <div className={styles.containerImageButtonWhite}>
                      <div className={styles.containerbuttonContent}></div>
                    </div>
                  ) : elem.image ? (
                    <div className={styles.containerImageButton}>
                      <div className={styles.containerbuttonContentImage}>
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "85%",
                            background: "#dadbe7",
                            borderRadius: 15,
                          }}
                          src={elem.url!}
                        ></img>
                      </div>
                    </div>
                  ) : !elem.isWhite && elem.percent < 0 ? (
                    <div className={styles.containerImageButton}>
                      <div className={styles.containerbuttonContent}>
                        {elem.text}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.containerImageButton}>
                      {elem.correct ? (
                        <>
                          {elem.percent >= 0 && elem.percent <= 29 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectBad
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 30 && elem.percent <= 59 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectMid
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 60 && elem.percent <= 100 && (
                            <div
                              className={
                                styles.containerbuttonContentCorrectGood
                              }
                            >
                              {elem.percent}%
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {elem.percent >= 0 && elem.percent <= 29 && (
                            <div
                              className={styles.containerbuttonContentWorngBad}
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 30 && elem.percent <= 59 && (
                            <div
                              className={styles.containerbuttonContentWorngMid}
                            >
                              {elem.percent}%
                            </div>
                          )}
                          {elem.percent >= 60 && elem.percent <= 100 && (
                            <div
                              className={styles.containerbuttonContentWorngGood}
                            >
                              {elem.percent}%
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Relate;
