import React, { useState } from "react";
import styles from "./drawerHeader.module.scss";
import { Drawer } from "antd";
import { Button } from "../../../Shared/Button";
import { DrawerPassword } from "../../../Shared/DrawerPassword";
import {
  MainProfile,
  Privacidad,
  Condiciones,
} from "../../../Teachers/Profile";

import useAuth from "../../../../context/useAuth";

// Icons
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import arrowBack from "../../../../assets/icons/arrows/arrow-back.svg";
import { useGetUser } from "../../../../hooks/useGetUser";
import useIsMobile from "../../../../hooks/useIsMobile";

interface DrawerHeaderProps {
  onClose: () => void;
  visible?: boolean;
  handleOffFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: {
    error: boolean;
    passwordOld: string;
    passwordNew1: string;
    passwordNew2: string;
  };
  validError: {
    error: boolean;
    errorText: string;
  };
}

const DrawerHeader = ({
  onClose,
  visible,
  handleSubmit,
  handleChange,
  handleOffFocus,
  validError,
  password,
}: DrawerHeaderProps) => {
  const { logout } = useAuth();
  const { userDb: userToken } = useGetUser();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visiblePrivacidad, setVisiblePrivacidad] = useState(false);
  const [visibleCondiciones, setVisibleCondiciones] = useState(false);
  const isMobile = useIsMobile();

  const showDrawerCondiciones = () => {
    setVisibleCondiciones(true);
  };

  const showDrawerPrivacidad = () => {
    setVisiblePrivacidad(true);
  };

  const showDrawerPassword = () => {
    setVisiblePassword(true);
  };

  const goBack = () => {
    setVisiblePassword(false);
    setVisiblePrivacidad(false);
    setVisibleCondiciones(false);
  };

  const selectedTitle = () => {
    if (visiblePassword) return "Cambiar contraseña";
    if (visiblePrivacidad) return "Privacidad";
    if (visibleCondiciones) return "Términos y Condiciones";
    return "Mi Perfil";
  };

  const goBackImg = () => {
    if (visiblePassword) return true;
    if (visiblePrivacidad) return true;
    if (visibleCondiciones) return true;
    return false;
  };

  return (
    <Drawer
      placement={isMobile ? "bottom" : "right"}
      width={500}
      onClose={onClose}
      visible={visible}
      height={isMobile ? "500px" : ""}
      closable={false}
      style={{
        background: "white !important",
      }}
      title={
        <div className={styles.header}>
          {
            <div className={styles.titleHeader}>
              {goBackImg() && (
                <img src={arrowBack} alt="arrow_back" onClick={goBack} />
              )}
              <h1>{selectedTitle()}</h1>
            </div>
          }

          <img src={closeIcon} alt="close icon" onClick={onClose} />
        </div>
      }
      footer={
        visiblePassword && (
          <div className={styles.footer}>
            <div style={{ marginRight: "16px" }}>
              <Button type={"stroke"} text={"cancelar"} onClick={goBack} />
            </div>

            <Button
              type={"primary"}
              text={"guardar"}
              onClick={handleSubmit}
              disabled={password.error ?? false}
            />
          </div>
        )
      }
    >
      {visible &&
        !visibleCondiciones &&
        !visiblePassword &&
        !visiblePrivacidad && (
          <MainProfile
            logout={logout}
            userToken={userToken}
            showDrawerPassword={showDrawerPassword}
            showDrawerPrivacidad={showDrawerPrivacidad}
            showDrawerCondiciones={showDrawerCondiciones}
          />
        )}
      {visiblePassword && (
        <DrawerPassword
          password={password}
          validError={validError}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleOffFocus={handleOffFocus}
        />
      )}

      {visiblePrivacidad && <Privacidad />}
      {visibleCondiciones && <Condiciones />}
    </Drawer>
  );
};

export default DrawerHeader;
