import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherDownloadCalifications = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_DONWLOAD_CALIFICATIONS,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherDownloadCalifications;

const TEACHER_DONWLOAD_CALIFICATIONS = gql`
  mutation teacherDownloadCalifications {
    teacherDownloadCalifications
  }
`;
