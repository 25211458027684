import React, { FC, useEffect, useState } from "react";

import styles from "./matchingText.module.scss";
import MatchingText from "./MatchingText";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { usePreview } from "react-dnd-preview";
import { BoxState, DustbinState } from "./interfaces";
import update from "immutability-helper";

interface MatchingTextQuizProps {
  droppedBoxNames: string[];
  setDustbins: (e: DustbinState[]) => void;
  itemsMatched: boolean;
  dustbins: DustbinState[] | undefined;
  content: any;
  setDroppedBoxNames: (e: string[]) => void;
  checkButtonPressed: boolean;
  subject_id: number | null;
  putFocusFooter: () => void;
}

const MatchingTextQuiz: FC<MatchingTextQuizProps> = ({
  content,
  setDroppedBoxNames,
  setDustbins,
  droppedBoxNames,
  putFocusFooter,
  checkButtonPressed,
  dustbins,
  itemsMatched,
  subject_id,
}) => {
  const [boxes, setBoxes] = useState<BoxState[]>([]);

  const MyPreview = () => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { item, style } = preview;
    return (
      <div className={styles.box} style={style}>
        {(item as { name: string }).name}
      </div>
    );
  };

  const handleDrop = (index: number, item: { name: string; type: any }) => {
    const { name } = item;
    setDroppedBoxNames(
      update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
    );
    setDustbins(
      update(dustbins!, {
        [index]: {
          lastDroppedItem: {
            $set: item,
          },
        },
      })
    );
    putFocusFooter();
  };

  useEffect(() => {
    const questionContainer =
      content?.dAndDQuestion?.length > 0
        ? content.dAndDQuestion
        : content.relationQuestion;
    const finalDustbins = questionContainer![0].blockContainer.map(
      (el: any) => {
        return {
          accepts: ["1", "2", "3", "4"],
          type: el.coincidence,
          lastDroppedItem: null,
          name: el.text,
          img: el.url,
        };
      }
    );
    const finalBoxes = content.dAndDQuestion![0].blockItems?.map((el: any) => {
      return {
        name: el.text,
        type: el.coincidence,
      };
    });
    finalDustbins && setDustbins(finalDustbins);
    finalBoxes && setBoxes(finalBoxes);
  }, [content, setDustbins]);

  return (
    <div className={styles.content}>
      <DndProvider
        backend={TouchBackend}
        options={{
          enableTouchEvents: true,
          enableMouseEvents: true,
          enableKeyboardEvents: true,
        }}
      >
        <MyPreview />
        <MatchingText
          boxes={boxes}
          handleDrop={handleDrop}
          droppedBoxNames={droppedBoxNames}
          dustbins={dustbins}
          itemsMatched={itemsMatched}
          checkButtonPressed={checkButtonPressed}
          content={content}
          subject_id={subject_id}
        />
      </DndProvider>
    </div>
  );
};

export default MatchingTextQuiz;
