import { Checkbox, List } from "antd";

import { getClassroomsByTeacher_getClassroomsByTeacher } from "../../../../__generated__/gql-types/getClassroomsByTeacher";
import styles from "../assingHomeworkDrawer.module.scss";

const AssignClassroom = ({
  selectedClassrooms,
  setSelectedClassrooms,
  classRooms,
}: {
  selectedClassrooms: getClassroomsByTeacher_getClassroomsByTeacher[] | null;
  classRooms: getClassroomsByTeacher_getClassroomsByTeacher[] | null;
  setSelectedClassrooms: (
    p: getClassroomsByTeacher_getClassroomsByTeacher[] | null
  ) => void;
}) => {
  const onChangeClassrooms = (
    item: getClassroomsByTeacher_getClassroomsByTeacher
  ) => {
    if (selectedClassrooms?.find((clasroom) => clasroom.id === item.id)) {
      const nuevoArray = selectedClassrooms.filter(
        (clasroom) => clasroom.id !== item.id
      );

      setSelectedClassrooms(nuevoArray);
    } else {
      selectedClassrooms
        ? setSelectedClassrooms([...selectedClassrooms, item])
        : setSelectedClassrooms([item]);
    }
  };

  return classRooms?.length ? (
    <List
      dataSource={classRooms}
      renderItem={(item, i) => (
        <List.Item className={styles.listItem}>
          <div className={styles.listItemContainer}>
            {item.name}
            <Checkbox
              checked={
                selectedClassrooms?.find(
                  (classrooom) => item.id === classrooom.id
                )
                  ? true
                  : false
              }
              onChange={() => onChangeClassrooms(item)}
              name={item?.name ? item.name : undefined}
            ></Checkbox>
          </div>
        </List.Item>
      )}
    />
  ) : (
    <p style={{ fontFamily: "Poppins-Regular" }}>
      No posees clases asignadas a tu nombre o no existen clases
      correspondientes a la asignatura elegida.{" "}
    </p>
  );
};

export default AssignClassroom;
