import React, { FC } from "react";
import styles from "./headerLiteracy.module.scss";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import gemPositve from "../../../../../assets/icons/gems/gemPositive.svg";
import imageLiteracyDrawer from "../../../../../assets/images/imageLiteracyDrawer.png";
interface PropsHeader {
  individualLesson: getLessonById_getLessonById;
}
const HeaderLiteracy: FC<PropsHeader> = ({ individualLesson }) => {
  return (
    <div className={styles.lessonDetailContainer}>
      <div className={styles.foto}>
        <img
          src={
            individualLesson?.lesson_content?.literacy?.cover
              ? individualLesson?.lesson_content?.literacy?.cover
              : imageLiteracyDrawer
          }
          alt="default"
        />
      </div>

      <div className={styles.titleContainer}>
        <span className={styles.textTitle}>{individualLesson?.title}</span>
        <div className={styles.containerGem}>
          <div className={styles.textGem}>Completa esta misión y consigue</div>
          <div className={styles.numberGem}>
            <div>100</div>
            <img src={gemPositve} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLiteracy;
