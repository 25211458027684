import { BackgroundLogin } from "../../components/Login/BackgroundLogin";
import { RecoveryPassword } from "../../components/Login/RecoveryPassword";

const RecoveryPasswordPage = () => {
  return (
    <BackgroundLogin>
      <RecoveryPassword></RecoveryPassword>
    </BackgroundLogin>
  );
};

export default RecoveryPasswordPage;
