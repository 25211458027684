import React, { FC, useEffect, useState } from "react";
import styles from "./formProject.module.scss";
import { PanelProject } from "./PanelProject";
import { CourseSelected } from "./CourseSelected";
import { ListCourse } from "./ListCourse";
import { teacherProjectInput } from "../../../__generated__/gql-types/globalTypes";
import UseCreateTeacherProject from "../../../api/useCreateTeacherProject";
import UseGetPDACourse from "../../../api/useGetPDACourses";
import { getPDACourses_getPDACourses } from "../../../__generated__/gql-types/getPDACourses";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { setDependencyRefresh } from "../../../redux/slices/dependencyRefresh";
import { ModalMissProject } from "../../Shared/ModalMissProject";
import useDebounce from "../../../hooks/useDebounce";
import useTeacherCreateProjectLog from "../../../api/useTeacherCreateProjectLog";
interface PropsFormProject {
  setIsCreating: (e: boolean) => void;
}
const FormProject: FC<PropsFormProject> = ({ setIsCreating }) => {
  const createProject = UseCreateTeacherProject();
  const getPDAProject = UseGetPDACourse();
  const createProjectLog = useTeacherCreateProjectLog();
  const [projectInput, setProjectInput] = useState<teacherProjectInput | null>({
    grade: 1,
    students: 1,
    duration: 1,
    week_hours: 1,
    activity_type: "mixtas",
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [grade, setGrade] = useState<number[]>([]);
  const [field, setField] = useState<string[]>([]);
  const [courseLoading, setCourseLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [openForm, setOpenForm] = useState(true);
  const [totalCourse, setTotalCourse] = useState<
    getPDACourses_getPDACourses[] | null
  >(null);
  const [checkedCourse, setCheckedCourse] = useState<
    getPDACourses_getPDACourses[] | null
  >(null);
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const onCloseModalDelete = () => {
    setOpenModal(false);
  };
  const [statusList, setStatusList] = useState("searcher");
  const [prevStatusList, setPrevStatusList] = useState("searcher");

  const deb = useDebounce(searchInput ?? "", 1200);
  useEffect(() => {
    if (prevStatusList !== statusList) {
      setCourseLoading(true);
    }
    let newGrade = grade.length < 1 ? [1, 2, 3, 4, 5, 6] : grade;
    let newField =
      field.length < 1
        ? [
            "Saberes",
            "Lenguajes",
            "De lo Humano y lo Comunitario",
            "Ética, Naturaleza y Sociedades",
          ]
        : field;
    (async () => {
      const response = await getPDAProject({
        getCoursesSearchArgs: {
          search:
            statusList === "dosification"
              ? ""
              : deb.length < 1
              ? "asdniowqjid(SOY UN KPO)qwiofjqoi"
              : deb,
          grade_ids: newGrade,
          subjects: newField,
        },
      });
      setPrevStatusList(statusList);
      setTotalCourse(response);
      setCourseLoading(false);
    })();
  }, [deb, grade, getPDAProject, field]);

  const handleCreate = async () => {
    const arrayIdThems = checkedCourse?.map((course) => Number(course.id));
    try {
      await createProject({
        teacherProjectInput: {
          ...projectInput,
          selected_themes_ids: arrayIdThems,
          url: null,
        },
      });
      createProjectLog(); //esta funcion crea un registro para bigquery
      dispatch(setDependencyRefresh(!dependencyRefresh));
      setIsCreating(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <div className={styles.textTitle}>Crea tu proyecto</div>
        <div
          className={
            checkedCourse &&
            checkedCourse?.length > 0 &&
            projectInput?.students &&
            projectInput?.themes_of_interest
              ? styles.button
              : styles.buttonBloqued
          }
          onClick={() => {
            checkedCourse &&
            checkedCourse?.length > 0 &&
            projectInput?.themes_of_interest &&
            projectInput?.students
              ? handleCreate()
              : setOpenModal(true);
          }}
        >
          Crear proyecto
        </div>
      </div>
      <PanelProject
        setOpenForm={setOpenForm}
        openForm={openForm}
        setProjectInput={setProjectInput}
        projectInput={projectInput}
      />
      <CourseSelected
        setOpenPanel={setOpenPanel}
        openPanel={openPanel}
        setOpenForm={setOpenForm}
        checkedCourse={checkedCourse}
        setCheckedCourse={setCheckedCourse}
      />
      {openPanel ? (
        <ListCourse
          statusList={statusList}
          setStatusList={setStatusList}
          totalCourse={totalCourse}
          checkedCourse={checkedCourse}
          setGrade={setGrade}
          setField={setField}
          loading={courseLoading}
          grade={grade}
          field={field}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          setCheckedCourse={setCheckedCourse}
        />
      ) : (
        <></>
      )}

      <ModalMissProject
        modalDelete={openModal}
        onCloseModalDelete={onCloseModalDelete}
      />
    </div>
  );
};

export default FormProject;
