import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createChatLogVariables } from "../__generated__/gql-types/createChatLog";

const UseCreateChat = () => {
  const client = useApolloClient();

  return useCallback(
    async (userInput: createChatLogVariables) => {
      return await client.mutate({
        mutation: CREATE_CHAT,
        fetchPolicy: "no-cache",
        variables: userInput,
      });
    },
    [client]
  );
};
export default UseCreateChat;

const CREATE_CHAT = gql`
  mutation createChatLog($aiChatLogInput: AIChatLogInput) {
    createChatLog(AIChatLogInput: $aiChatLogInput) {
      chat_log {
        errorUser
        messageUser
        message
        sender
      }
    }
  }
`;
