import React, { FC } from "react";
import styles from "./questions.module.scss";
import Latex from "react-latex";
import {
  getResponsesByHomework_getResponsesByHomework,
  getResponsesByHomework_getResponsesByHomework_answers,
} from "../../../../../__generated__/gql-types/getResponsesByHomework";

interface PropsQuestions {
  index: number;
  question: getResponsesByHomework_getResponsesByHomework;
}
const Questions: FC<PropsQuestions> = ({ index, question }) => {
  const getColor = (
    answer: getResponsesByHomework_getResponsesByHomework_answers
  ) => {
    if (answer?.correct) {
      return answer.percent < 30
        ? "#D2EFB5"
        : answer.percent < 60
        ? "#B6DE8F"
        : "#8DCE4F";
    } else {
      return answer.percent < 30
        ? "#FFDADA"
        : answer.percent < 60
        ? "#FFADAD"
        : "#FF8283";
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {question.question && <Latex>{question.question}</Latex>}
        </div>
        {question.paragraph && (
          <div className={styles.containerParagraph}>{question.paragraph}</div>
        )}

        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="quiz"
            />
          </div>
        )}
        <div className={styles.textFill}>
          {question.answers?.map((e) => {
            return (
              <div className={styles.containerAnswer}>
                <div
                  className={
                    e.correct ? styles.answerSelected : styles.answerOutSelected
                  }
                  style={{ background: getColor(e) }}
                >
                  {e.answer ? (
                    <Latex>{e.answer!}</Latex>
                  ) : (
                    <img src={e.image ?? ""} alt="" width={"90px"} />
                  )}
                </div>
                <div className={styles.containerGrey}>
                  {Math.round(e.percent)}%
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Questions;
