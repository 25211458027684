import { createContext, ReactNode, useContext, useState } from "react";
import { getUserByEmail_getUserByEmail } from "../__generated__/gql-types/getUserByEmail";

interface AuthContextType {
  userAuthDb: getUserByEmail_getUserByEmail | null;
  setAuthUserDb: (e: getUserByEmail_getUserByEmail | null) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [userAuthDb, setAuthUserDb] =
    useState<getUserByEmail_getUserByEmail | null>(null);

  const state = {
    userAuthDb,
    setAuthUserDb,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default function useUserDb() {
  return useContext(AuthContext);
}
