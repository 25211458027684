import { Drawer } from "antd";
import { Link } from "react-router-dom";
import styles from "./drawerLiteracy.module.scss";
import cross from "../../../../assets/icons/others/close-icon.svg";
import { FC, useEffect, useState } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import UseGetHomeworkByIdForStudent from "../../../../api/useGetHomeworkByIdForStudent";
import UseGetLessonsById from "../../../../api/useGetLessonsById";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { Loader } from "../../../Commons";

import { HeaderLiteracy } from "./HeaderLiteracy";
import { ProgressHomework } from "../../../Students/Missions/DrawerHomeworkStudent/ProgressHomework";
interface DrawerProp {
  onCloseLesson: () => void;
  visibleLesson: boolean;
  id: string;
}
//REVISAR ESTO CUANDO VUELVAN LOS PUNTOS EXTRA
const DrawerLiteracy: FC<DrawerProp> = ({
  onCloseLesson,
  visibleLesson,
  id,
}) => {
  const isMobile = useIsMobile();
  const getLessonById = UseGetLessonsById();
  const getHomeworkByIdForStudent = UseGetHomeworkByIdForStudent();

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);

  useEffect(() => {
    (async () => {
      const response = await getLessonById({
        lessonId: id,
      });
      setIndividualLesson(response);
    })();
  }, [id, getHomeworkByIdForStudent, getLessonById]);

  return (
    <Drawer
      closable={false}
      placement={isMobile ? "bottom" : "right"}
      onClose={onCloseLesson}
      open={visibleLesson}
      title={""}
      footer={
        individualLesson && (
          <div className={styles.footerDetail}>
            <div className={styles.containerButtons}>
              <Link
                className={styles.taskButtonMision}
                to={`/task/${id}`}
                state={{
                  origin: "lesson",
                  from: "literacy",
                  onNavigate: true,
                }}
              >
                LEER
              </Link>
            </div>
          </div>
        )
      }
    >
      {!individualLesson ? (
        <Loader />
      ) : (
        <>
          <div className={styles.closeHeader}>
            <img src={cross} alt="cross" onClick={onCloseLesson} />
          </div>

          <HeaderLiteracy individualLesson={individualLesson} />

          <div className={styles.container}>
            <div className={styles.textInstrucciones}>INSTRUCCIONES</div>
            <div className={styles.descriptionLesson}>
              <div className={styles.containerRequiereEvidence}>
                <div className={styles.textRequiereEvidence}>
                  Presta atención a la lectura para poder responder
                  correctamente a las preguntas que se te harán después.
                </div>
              </div>
            </div>
            {individualLesson.lesson_content.literacy?.copyright && (
              <div className={styles.descriptionLesson}>
                <div className={styles.containerRequiereEvidence}>
                  <div className={styles.textRequiereEvidence}>
                    {individualLesson.lesson_content.literacy?.copyright}
                  </div>
                </div>
              </div>
            )}
            {individualLesson.result && (
              <ProgressHomework
                resultHomework={individualLesson.result}
              ></ProgressHomework>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default DrawerLiteracy;
