import { FC, lazy, Suspense } from "react";

const Program2425 = lazy(() =>
  import("../../components").then((module) => {
    return { default: module.Program2425 };
  })
);

const Program2425Page: FC = () => {
  return (
    <Suspense fallback={<h2 style={{ color: "black" }}>Loading....</h2>}>
      <Program2425 />
    </Suspense>
  );
};

export default Program2425Page;
