import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteHomeworkVariables } from "../__generated__/gql-types/deleteHomework";

const UseDeleteHomework = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteHomeworkVariables) => {
      return await client.mutate({
        mutation: DELETE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseDeleteHomework;

const DELETE_HOMEWORK = gql`
  mutation deleteHomework($homeworkId: ID!) {
    deleteHomework(homeworkId: $homeworkId) {
      id
      description
    }
  }
`;
