import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getHomeworksByClassroom,
  getHomeworksByClassroomVariables,
} from "../__generated__/gql-types/getHomeworksByClassroom";

const UseGetHomeworksByClassroom = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getHomeworksByClassroomVariables) => {
      const result: ApolloQueryResult<getHomeworksByClassroom> =
        await client.query({
          query: GET_HOMEWORKS_BY_CLASSROOM,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getHomeworksByClassroom;
    },
    [client]
  );
};

export default UseGetHomeworksByClassroom;

const GET_HOMEWORKS_BY_CLASSROOM = gql`
  query getHomeworksByClassroom($classroomId: Int) {
    getHomeworksByClassroom(classroom_id: $classroomId) {
      id
      users {
        id
      }
      classroom {
        id
      }
      individualHomework
      title
      description
      status
      result
      post_homework_result
      finished
      start_date
      studentTries
      tries
      completion_date
      end_date
      cta_button
      redaction_correction
      redaction_review_by_student
      studentEvidenceEvent
      deleted_at
      needs_file_evidence
      redaction_correction
      redaction_review_by_student
      teacher_file_url
      lessons {
        finished
        result
        lesson_type
        subject {
          id
        }
        subject_id
        course_id
        id
        title
        description
        cover
        week
        sep_name
        sep_book_pages
        lesson_content {
          aiconversations {
            id
            title
            publish
            level_cerf
            character_for_role_play
            card_image
            cover_image
            avatar_image
            section_1 {
              question
              order
            }
            section_2 {
              question
              order
            }
            created_at
            updated_at
            deleted_at
          }
          id
          redaction {
            title
            rubrics {
              order
              rubric
            }
            question
            id
          }
          literacy {
            id
            title
            cover
            publish
            content {
              type
              image
              text
              order
            }
          }
          quizzes {
            id
            url
            title
            filename
            order
            questions {
              answers {
                id
                answer
                correct
                order
                image
                url
              }
              question
              id
              image
              url
            }
          }
          videos {
            id
            url
            title
            file_id
            metadata
            imageUrl
            order
            quizzes {
              quiz {
                id
                url
                title
                filename
                order
                questions {
                  answers {
                    id
                    answer
                    correct
                    order
                    image
                    url
                  }
                  question
                  id
                  image
                  url
                }
              }
              time
              time_out
            }
          }
        }
        grade {
          id
          name
          grade
        }

        themes {
          id
          name
        }
        axes {
          id
          name
        }
      }
    }
  }
`;
