import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getCoursesBySubjectIdV2,
  getCoursesBySubjectIdV2Variables,
} from "../__generated__/gql-types/getCoursesBySubjectIdV2";

const UseGetCoursesBySubjectIdV2 = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdV2Variables) => {
      const result: ApolloQueryResult<getCoursesBySubjectIdV2> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID_V2,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySubjectIdV2;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectIdV2;

const GET_COURSES_BY_SUBJECT_ID_V2 = gql`
  query getCoursesBySubjectIdV2(
    $getCoursesBySubjectIdInputV2: GetCoursesBySubjectIdInputV2
  ) {
    getCoursesBySubjectIdV2(
      getCoursesBySubjectIdInputV2: $getCoursesBySubjectIdInputV2
    ) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      program_2017
      description
      initial_evaluation
      sep_book_pages
      sep_book_pages_2017
      threemester
      threemester_2017
      week
      week_2017
      count
    }
  }
`;
