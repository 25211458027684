import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetCoursesInputLiteracy } from "../__generated__/gql-types/globalTypes";
import {
  getCoursesLiteracy,
  getCoursesLiteracyVariables,
} from "../__generated__/gql-types/getCoursesLiteracy";

const useGetCoursesLiteracy = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesLiteracyVariables) => {
      const result: ApolloQueryResult<getCoursesLiteracy> = await client.query({
        query: GET_COURSES_LITERACY,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesLiteracy;
    },
    [client]
  );
};

export default useGetCoursesLiteracy;

const GET_COURSES_LITERACY = gql`
  query getCoursesLiteracy($getCoursesInputLiteracy: GetCoursesInputLiteracy) {
    getCoursesLiteracy(getCoursesInputLiteracy: $getCoursesInputLiteracy) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      english
      reading
      description
      initial_evaluation
      sep_book_pages_english
      sep_book_pages_reading
      threemester_english
      threemester_reading
      week_english
      week_reading
      count
    }
  }
`;
