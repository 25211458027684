import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { buyItemFromStoreVariables } from "../__generated__/gql-types/buyItemFromStore";

const UseBuyItemFromStore = () => {
  const client = useApolloClient();

  return useCallback(
    async (itemId: buyItemFromStoreVariables) => {
      return await client.mutate({
        mutation: BUY_ITEM_FROM_STORE,
        fetchPolicy: "no-cache",
        variables: itemId,
      });
    },
    [client]
  );
};
export default UseBuyItemFromStore;

const BUY_ITEM_FROM_STORE = gql`
  mutation buyItemFromStore($itemId: ID!) {
    buyItemFromStore(item_id: $itemId) {
      name
      id
    }
  }
`;
