import { Drawer } from "antd";
import { Link } from "react-router-dom";
import styles from "./drawerLesson.module.scss";

// Icons
import cross from "../../../../assets/icons/others/close-icon.svg";
import blueDiamond from "../../../../assets/icons/students/diamonds/mathDiamonds/mathDiamond-S.svg";
import book from "../../../../assets/icons/bookDrawerStudent.svg";

// Types
import { getClassroomsByStudentId_getClassroomsByStudentId } from "../../../../__generated__/gql-types/getClassroomsByStudentId";
import { getCoursesBySubjectId_getCoursesBySujectId_lessons } from "../../../../__generated__/gql-types/getCoursesBySubjectId";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { FlagContent } from "./FlagContent";
import useIsMobile from "../../../../hooks/useIsMobile";

interface DrawerProp {
  origin: string;
  estilos?: string;
  onCloseLesson: () => void;
  visibleLesson: boolean;
  data:
    | getCoursesBySubjectId_getCoursesBySujectId_lessons
    | getLessonById_getLessonById
    | null;
  item?: getClassroomsByStudentId_getClassroomsByStudentId;
}
const DrawerLesson = ({
  onCloseLesson,
  origin,
  estilos,
  visibleLesson,
  data,
  item,
}: DrawerProp) => {
  const isMobile = useIsMobile();

  return (
    <Drawer
      closable={false}
      placement={isMobile ? "bottom" : "right"}
      className={estilos ?? ""}
      maskClassName={estilos ?? ""}
      onClose={() => {
        onCloseLesson();
      }}
      open={visibleLesson}
      footer={
        <div className={styles.footerDetail}>
          {estilos !== "estilosIntruc" && (
            <Link
              to={`/task/${data?.id}`}
              state={{ item, origin, onNavigate: true }}
            >
              <button className={styles.taskButtonMision}>COMENZAR</button>
            </Link>
          )}
          {estilos === "estilosIntruc" && (
            <button className={styles.taskButtonMision} onClick={onCloseLesson}>
              CONTINUAR
            </button>
          )}
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.textHeader}>
            <div>Completa esta misión y consigue</div>
            <img src={blueDiamond} alt="blueDiamond" />
            <span className={styles.cantGem}>100</span>
          </div>
          <div className={styles.closeHeader}>
            <img
              src={cross}
              onClick={() => {
                onCloseLesson();
              }}
              alt="cross"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <div className={styles.lessonDetailContainer}>
          <div className={styles.titleContainer}>
            <span className={styles.textTitle}>{data?.title}</span>
            <p>{data?.description}</p>
          </div>
          <FlagContent data={data} />
        </div>
        <div className={styles.textInfo}> INTRUCCIONES</div>

        <div className={styles.descriptionLesson}>
          <p className={styles.description}>
            Completa la misión siguiendo las indicaciones, asegúrate de realizar
            todas las actividades y ¡gana 100 puntos!
          </p>
        </div>

        <div className={styles.textInfo}> APRENDIZAJES</div>

        <div className={styles.descriptionLesson}>
          <div className={styles.containerTitleDescription}>
            <div className={styles.textDescription}>{data?.title}</div>
            <div className={styles.titlePages}>
              Páginas {data?.sep_book_pages} <img src={book} alt="book" />
            </div>
          </div>
          <div className={styles.tagContainer}>
            {data?.themes?.map((theme) => {
              return (
                theme.name && (
                  <div className={styles.tagIndividual}>{theme.name}</div>
                )
              );
            })}
            {data?.axes?.map((axe) => {
              return (
                axe.name && (
                  <div className={styles.tagIndividual}>{axe.name}</div>
                )
              );
            })}
          </div>

          <p className={styles.description}>{data?.description}</p>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerLesson;
