import { FC, useEffect, useState } from "react";
// import { Header } from "../Layout/Header/index";
// import { Banner } from "./Banner";
// import { Spin } from "antd";
import styles from "./help.module.scss";
// import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
// import { useSelector } from "react-redux";
// import { Mobile } from "../../redux/store/store";
// import { Accordion } from "./Accordion";
// import { HelpMobile } from "../Mobile/HelpMobile";

const Help: FC = () => {
	// const isMobile = useIsMobile();
	// const getClassroomsByTeacherId = UseClassroomsByTeacher();
	// const [visibleProfile, setVisibleProfile] = useState(false);
	// const [isLoading, setIsLoading] = useState<boolean>(true);
	// const [classRooms, setClassRooms] = useState<
	//   GetClassroomsByTeacherId_getClassroomsByTeache[] | null
	// >();
	// useEffect(() => {
	//   (async () => {
	//     const classRoomRes = await getClassroomsByTeacherId({
	//       getClassroomsByTeacherIdInput: {
	//         searchArgs: null,
	//       },
	//     });
	//     setIsLoading(false);
	//     setClassRooms(classRoomRes);
	//   })();
	// }, [getClassroomsByTeacherId]);
  // el que encuentra esto es puto
	return (
		<div className={styles.container}>
			{/* <Header
        title="Ayuda"
        visible={visibleProfile}
        setVisible={setVisibleProfile}
      />
      {!classRooms && (
        <div className={styles.spinner}>
          <Spin size="large" />
        </div>
      )}
      {classRooms && (
        <>
          {mobile ? (
            <div
              style={
                mobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }
                  : {}
              }
            >
              <HelpMobile />
            </div>
          ) : (
            <>
              <Banner
                homeworks={classRooms[0]?.homeworks}
                isLoading={isLoading}
              />
              <div className={styles.accordionContainer}>
                <Accordion />
              </div>
            </>
          )}
        </>
      )} */}
		</div>
	);
};

export default Help;
