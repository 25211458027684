// functions for disabling periods of time on date & time pickers

import moment from "moment";
import { createMomentObject } from "../../../utils/functions";
import dayjs from "dayjs";

const timeFormat = "HH:mm";

const disabledDate = (current: dayjs.Dayjs) => {
  return current && current < moment().subtract(1, "days");
};

const disabledStartHours = (startDate: any) => {
  let today = createMomentObject(startDate, true);

  const hours: number[] = [];
  if (moment().date() === today.date()) {
    for (let i = 0; i < moment().hour(); i += 1) hours.push(i);
    return hours;
  }
  return hours;
};

const disabledStartMinutes = (startHour: number, startDate: any) => {
  const minutes: number[] = [];
  if (startHour === -1 && Number(startDate.slice(0, 2)) === moment().date()) {
    for (let i = 0; i < moment().minute() + 1; i += 1) minutes.push(i);

    return minutes;
  }
  if (
    startDate === moment().format("DD/MM/YYYY") &&
    startHour === moment().hour()
  ) {
    for (let i = 0; i < moment().minute() + 1; i += 1) minutes.push(i);
    return minutes;
  }

  return minutes;
};

const disabledEndHours = (startDate: any, endDate: any, startTime: any) => {
  const hours: number[] = [];
  if (startDate === endDate) {
    for (let i = 0; i < Number(startTime.slice(0, 2)); i += 1) hours.push(i);
    return hours;
  }
  return hours;
};

const disabledEndMinutes = (
  endHour: number,
  startDate: any,
  startTime: any,
  endDate: any
) => {
  const minutes: number[] = [];
  if (startDate === startDate && Number(startTime.slice(0, 2)) !== endHour) {
    return minutes;
  }
  if (endDate === moment().format("DD/MM/YYYY")) {
    {
      for (let i = 0; i < Number(startTime.slice(3, 5)) + 1; i += 1)
        minutes.push(i);
      return minutes;
    }
  }
  if (startDate === startDate) {
    for (let i = 0; i < Number(startTime.slice(3, 5)) + 1; i += 1)
      minutes.push(i);
    return minutes;
  }

  return minutes;
};

const disableStartTime = (startDate: any) => {
  return {
    disabledHours: () => disabledStartHours(startDate),
    disabledMinutes: (hour: number) => disabledStartMinutes(hour, startDate),
  };
};
const disableEndTime = (startDate: any, endDate: any, startTime: any) => {
  return {
    disabledHours: () => disabledEndHours(startDate, endDate, startTime),
    disabledMinutes: (hour: number) =>
      disabledEndMinutes(hour, startDate, startTime, endDate),
  };
};

const startTimePickerOnChange = (
  time: moment.Moment | null,
  setStartTime: any,
  startDate: any,
  endDate: any,
  endTime: any,
  setEndTimeMoment: any,
  setEndTime: any
) => {
  setStartTime(time ? time.format(timeFormat) : "");
  if (startDate === endDate) {
    const finalEndTime = createMomentObject(
      startDate,
      false,
      Number(time?.hour()),
      Number(time?.minutes()) + 5
    );
    if (
      Number(endTime.slice(0, 2)) <= Number(time?.hour()) &&
      Number(endTime.slice(0, 2)) !== 0
    ) {
      setEndTimeMoment(finalEndTime);
      setEndTime(`${finalEndTime.hour()}:${finalEndTime.minutes()}`);
    }
  }
};

const endTimePickerOnChange = (
  time: moment.Moment | null,
  endDate: any,
  endTime: any,
  setEndTime: any,
  setEndTimeMoment: any
) => {
  const momentObj = createMomentObject(
    endDate,
    false,
    Number(time?.hour()),
    Number(time?.minutes())
  );
  setEndTime(time ? time.format(timeFormat) : "");
  setEndTimeMoment(momentObj);
  if (endTime !== time?.format(timeFormat)) {
    setEndTime(time ? time.format(timeFormat) : "");
  }
};

const endTimeMomentObject = (
  startDate: any,
  endDate: any,
  endTime: any,
  startTime: any
) => {
  if (startDate === endDate) {
    const result = createMomentObject(
      startDate,
      false,
      Number(endTime.slice(0, 2)),
      Number(endTime.slice(3, 5)) + 1,
      0
    );
    return result;
  }
  const result = createMomentObject(
    startDate,
    false,
    Number(startTime.slice(0, 2)),
    Number(startTime.slice(3, 5)) + 1,
    0
  );
  return result;
};

export const timePickersUtils = {
  startTimePickerOnChange: startTimePickerOnChange,
  endTimePickerOnChange: endTimePickerOnChange,
  disableStartTime: disableStartTime,
  disableEndTime: disableEndTime,
  disabledStartHours: disabledStartHours,
  disabledStartMinutes: disabledStartMinutes,
  disabledEndHours: disabledEndHours,
  disabledEndMinutes: disabledEndMinutes,
  disabledDate: disabledDate,
  endTimeMomentObject: endTimeMomentObject,
};
