import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ActivityIndicator } from "../../components/ActivityIndicator/ActivityIndicator";
import { LoginComponent } from "../../components/Login";
import { notificationNegative } from "../../components/Shared/Notifacation/NotificationLuca";
import useAuth from "../../context/useAuth";

const Login: React.FC = () => {
  const { errorToken, isLoading, isAuthToken, userToken } = useAuth();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (!isAuthToken) {
    return <LoginComponent />;
  }

  if (errorToken && errorToken.length > 0) {
    notificationNegative();
    return <LoginComponent />;
  }

  if (
    isAuthToken &&
    (url?.includes("localhost") ||
      url?.includes("app") ||
      url?.includes("frontend"))
  ) {
    if (userToken?.schools[0].name === "Escuela Demo Prof")
      return (
        <Navigate to="/nem/dosification/2?grade=6&course=&subject=1" replace />
      );
    if (userToken?.role === "teacher")
      return <Navigate to="/myclasses" replace />;
    if (userToken?.role === "student")
      return <Navigate to="/mymissions" replace />;
    if (userToken?.role === "admin")
      return <Navigate to="/myclasses" replace />;
    if (!userToken) return <Navigate to="/noSchool" replace />;
  }

  return <div>LoginComponent</div>;
};

export default Login;
