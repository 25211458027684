import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserScoreInput } from "../__generated__/gql-types/globalTypes";

const UseSubtractClassroomPoints = () => {
  const client = useApolloClient();
  return useCallback(
    async (homeworkInput: UserScoreInput[]) => {
      return await client.mutate({
        mutation: SUBTRACT_CLASSROOM_POINTS,
        fetchPolicy: "no-cache",
        variables: { classroomPointsInput: homeworkInput },
      });
    },
    [client]
  );
};
export default UseSubtractClassroomPoints;

const SUBTRACT_CLASSROOM_POINTS = gql`
  mutation subtractClassroomPoints($classroomPointsInput: [UserScoreInput]) {
    subtractClassroomPoints(classroomPointsInput: $classroomPointsInput) {
      id
    }
  }
`;
