export const functionReturnSubject = (e: string) => {
  if (e === "1") return "matematicas";
  if (e === "2") return "español";
  if (e === "4") return "ciencias_naturales";
  if (e === "16") return "ingles";
  return "matematicas";
};
export const functionReturnIdSubject = (e: string | undefined) => {
  if (e === "matematicas") return "1";
  if (e === "español") return "2";
  if (e === "ciencias_naturales") return "4";
  if (e === "ingles") return "16";
  return "1";
};
