import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherNavigateLessonDosification = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_NAVIGATE_LESSON_DOSIFICATION,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherNavigateLessonDosification;

const TEACHER_NAVIGATE_LESSON_DOSIFICATION = gql`
  mutation teacherNavigateLessonDosification {
    teacherNavigateLessonDosification
  }
`;
