import { FC, useState } from "react";
import styles from "./card.module.scss";
import moment from "moment";
import { Button } from "../../../../../Shared/Button";
import defaultImage from "../../../../../../assets/images/defaultImage.svg";
import gemPositive from "../../../../../../assets/icons/gems/gemPositive.svg";
import gemNegative from "../../../../../../assets/icons/gems/gemNegative.svg";
import video1 from "../../../../../../assets/icons/videoMisionPending.svg";
import lectura1 from "../../../../../../assets/icons/lectura.svg";
import conversation from "../../../../../../assets/icons/conversation.png";
import { getClassroomsByStudentId_getClassroomsByStudentId_homeworks } from "../../../../../../__generated__/gql-types/getClassroomsByStudentId";
import thumbnailCiencias from "../../../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../../../assets/images/thumbnail/thumbnailEspañol.png";

import thumbnailClase from "../../../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import CoverReading from "../../../../../../assets/images/thumbnail/CoverReading.png";
import CoverLectura from "../../../../../../assets/images/thumbnail/CoverLectura.png";
import CoverEvaluation from "../../../../../../assets/images/thumbnail/CoverEvaluation.png";
import CoverWriting from "../../../../../../assets/images/thumbnail/CoverWriting.png";
import ensayoMatematica from "../../../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../../../assets/images/thumbnail/ensayoCiencias.png";

import { toLocalDate } from "../../../../../../utils/functions";
import "moment/locale/es";
import { DrawerHomework } from "../../../DrawerHomeworkStudent";

interface CardProps {
  homework: getClassroomsByStudentId_getClassroomsByStudentId_homeworks | null;
  type?: string;
}

const Card: FC<CardProps> = ({ homework, type }) => {
  moment.locale("es-mx");
  const [visibleLesson, setVisibleLesson] = useState(false);

  function encontrarValorMayor(
    variable1: number | null,
    variable2: number | null
  ) {
    if (variable1 !== null && variable2 !== null) {
      return Math.max(variable1, variable2);
    } else if (variable1 !== null) {
      return variable1;
    } else if (variable2 !== null) {
      return variable2;
    } else {
      return null;
    }
  }
  const maxResult = encontrarValorMayor(
    homework?.result ?? null,
    homework?.post_homework_result ?? null
  );

  const showDrawerLesson = () => {
    setVisibleLesson(true);
  };

  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  const functionReturnSubject = () => {
    if (homework?.lessons && homework?.lessons[0].subject_id === 1) {
      return "Matemáticas";
    }
    if (homework?.lessons && homework?.lessons[0].subject_id === 2) {
      return "Español";
    }
    if (homework?.lessons && homework?.lessons[0].subject_id === 4) {
      return "Ciencias";
    }
    if (homework?.lessons && homework?.lessons[0].subject_id === 16) {
      return "Inglés";
    }
  };

  const functionReturnImg = () => {
    if (
      homework?.lessons &&
      homework?.lessons?.length >= 0 &&
      homework?.lessons[0].lesson_type === "classroomguide"
    ) {
      return thumbnailClase;
    }

    if (
      homework?.lessons &&
      homework?.lessons?.length >= 0 &&
      homework?.lessons[0].lesson_type === "evaluation"
    ) {
      if (homework.lessons[0].subject_id === 1) {
        return thumbnailMate;
      }
      if (homework.lessons[0].subject_id === 2) {
        return thumbnailEspañol;
      }
      if (homework.lessons[0].subject_id === 4) {
        return thumbnailCiencias;
      }
      if (homework.lessons[0].subject_id === 16) {
        return CoverEvaluation;
      }
    }

    if (
      homework?.lessons &&
      homework?.lessons?.length >= 0 &&
      homework?.lessons[0].lesson_type === "redaction"
    ) {
      if (homework.lessons[0].subject_id === 1) {
        return ensayoMatematica;
      }
      if (homework.lessons[0].subject_id === 2) {
        return ensayoEspañol;
      }
      if (homework.lessons[0].subject_id === 4) {
        return ensayoCiencias;
      }
      if (homework.lessons[0].subject_id === 16) {
        return CoverWriting;
      }
    }

    if (
      homework?.lessons &&
      homework.lessons.length &&
      homework?.lessons[0]?.lesson_content?.videos?.length &&
      homework?.lessons[0]?.lesson_content?.videos[0]?.imageUrl &&
      homework?.lessons[0].lesson_type === "videolesson"
    ) {
      return homework?.lessons[0]?.lesson_content?.videos[0].imageUrl;
    }

    if (
      homework?.lessons &&
      homework.lessons.length &&
      homework?.lessons[0]?.lesson_content?.literacy &&
      homework?.lessons[0]?.lesson_content?.literacy?.cover
    ) {
      return homework?.lessons[0]?.lesson_content?.literacy?.cover;
    }
    if (
      homework?.lessons &&
      homework.lessons.length &&
      homework?.lessons[0]?.lesson_content?.aiconversations?.card_image
    ) {
      return homework?.lessons[0]?.lesson_content?.aiconversations?.card_image;
    }

    if (
      homework?.lessons &&
      homework.lessons.length &&
      homework?.lessons[0]?.lesson_type === "literacy"
    ) {
      if (homework?.lessons[0]?.subject_id === 16) {
        return CoverReading;
      }
      return CoverLectura;
    }
    if (
      homework?.lessons &&
      homework.lessons.length &&
      homework?.lessons[0]?.lesson_content?.literacy
    ) {
      console.log(homework?.lessons);
      // return homework?.lessons[0]?.lesson_content?.literacy?.cover;
    }

    return defaultImage;
  };
  return (
    <div className={styles.container}>
      {homework?.lessons &&
        homework?.lessons[0]?.lesson_type === "redaction" &&
        !homework?.redaction_review_by_student &&
        homework?.redaction_correction && (
          <div className={styles.badgeRed}> !</div>
        )}

      <div className={styles.imageContainer}>
        <img
          src={functionReturnImg()}
          alt="defaultImage"
          className={styles.imagenCard}
        />

        <div className={styles.subjectButton}>{functionReturnSubject()}</div>
        <div className={styles.contentButton}>
          <img
            src={
              homework?.lessons &&
              homework?.lessons[0].lesson_type === "literacy"
                ? lectura1
                : homework?.lessons &&
                  homework?.lessons[0].lesson_type === "conversation"
                ? conversation
                : video1
            }
            alt="asd"
          />{" "}
          <span>
            {homework?.lessons &&
            homework?.lessons[0].lesson_type === "videolesson"
              ? "Video Lección"
              : homework?.lessons &&
                homework?.lessons[0].lesson_type === "redaction"
              ? "Redaccion"
              : homework?.lessons &&
                homework?.lessons[0].lesson_type === "literacy"
              ? "Lectura"
              : homework?.lessons &&
                homework?.lessons[0].lesson_type === "conversation"
              ? "Conversation"
              : "Evaluación"}
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h1>{homework?.title}</h1>

          {type !== "approved" ||
          (!homework?.redaction_correction &&
            homework?.finished &&
            homework?.lessons &&
            homework?.lessons[0].lesson_type === "redaction") ? (
            <div className={styles.finishContainer}>
              {`Finaliza   ${moment(
                toLocalDate(homework?.end_date)?.slice(0, 5),
                "DD-MM"
              ).format("D MMM")}`}
            </div>
          ) : (
            <div className={styles.containerGem}>
              {maxResult && maxResult >= 3 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
              {maxResult && maxResult >= 6 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
              {maxResult && maxResult > 9 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
            </div>
          )}
        </div>
        <div className={styles.botonContainer}>
          {homework?.lessons &&
          homework.lessons[0].lesson_type === "redaction" ? (
            <Button
              type={
                homework.cta_button === "NO ENTREGADA"
                  ? "secondary-large"
                  : "primary-large"
              }
              disabled={homework.cta_button === "NO ENTREGADA" ? true : false}
              text={
                homework.cta_button
                  ? homework.cta_button === "TERMINAREVIDENCIA"
                    ? "TERMINAR"
                    : homework.cta_button
                  : ""
              }
              onClick={() => {
                showDrawerLesson();
              }}
            />
          ) : homework?.lessons &&
            homework?.lessons[0].lesson_type === "conversation" ? (
            <Button
              type="primary-large"
              text={maxResult === null ? "start" : "repeat"}
              onClick={() => {
                showDrawerLesson();
              }}
            />
          ) : (
            <Button
              type="primary-large"
              text={
                !homework?.needs_file_evidence
                  ? maxResult === null
                    ? "Comenzar"
                    : "Repetir"
                  : maxResult === null
                  ? homework?.studentEvidenceEvent
                    ? "Continuar"
                    : "Comenzar"
                  : homework?.studentEvidenceEvent
                  ? "Repetir"
                  : "Continuar"
              }
              onClick={() => {
                showDrawerLesson();
              }}
            />
          )}
        </div>
      </div>
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={homework?.id}
        />
      )}
    </div>
  );
};

export default Card;
