import { FC, useState } from "react";
import styles from "./filters.module.scss";

import matematica from "../../../assets/icons/subjects/matematica.svg";
import matematicaSelected from "../../../assets/icons/subjects/matematica-selected.svg";
import ciencias from "../../../assets/icons/subjects/ciencias.svg";
import cienciasSelected from "../../../assets/icons/subjects/ciencias-selected.svg";
import spanish from "../../../assets/icons/subjects/spanish.svg";
import spanishSelected from "../../../assets/icons/subjects/spanish-selected.svg";
import useAuth from "../../../context/useAuth";
import { DosificationDrawer } from "./CompleteDosificationDrawer";
import { useLocation } from "react-router";
import useIsMobile from "../../../hooks/useIsMobile";

interface FiltersProps {
  setLessonViewSelected: React.Dispatch<React.SetStateAction<string>>;
  subjectId: string[];
  setSubjectId: (e: string[]) => void;
  loadingDosification: boolean;
}

const NemFilters: FC<FiltersProps> = ({
  loadingDosification,
  setLessonViewSelected,
  subjectId,
  setSubjectId,
}) => {
  const isMobile = useIsMobile();
  const { userToken } = useAuth();
  const location = useLocation();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const DrawerOnClose = () => {
    setVisibleDrawer(false);
  };
  const showDosificationForSchool = () => {
    let showDosification = true;
    userToken?.schools.map((school) => {
      if (
        school.name === "Escuela Piloto" ||
        school.name === "Escuela Demo" ||
        school.name === "Demo en Casa" ||
        school.name === "Union"
      ) {
        showDosification = false;
      }
    });

    if (!showDosification) {
      setLessonViewSelected("search");
    }

    return showDosification;
  };

  return (
    <div className={styles.container}>
      <div className={styles.programLucaMobile}>
        {userToken?.role === "teacher" ? "Programa LUCA" : "Curso LUCA"}
      </div>
      <div className={styles.containerButton}>
        <div className={styles.containerMobileFilter}>
          <button
            name="1"
            style={isMobile ? {} : { minWidth: "168px" }}
            className={
              subjectId.includes("1") ? styles.itemSelected : styles.items
            }
            disabled={
              userToken?.schools[0].name === "Legionarios" ? true : false
            }
            onClick={() => {
              !loadingDosification && setSubjectId(["1"]);
            }}
          >
            {subjectId.includes("1") ? (
              <img src={matematicaSelected} alt="matematicaSelected" />
            ) : (
              <img src={matematica} alt="matematica" />
            )}
            <div>P. matemático</div>
          </button>
          <button
            name="2"
            className={
              subjectId.includes("2") ? styles.itemSelected : styles.items
            }
            style={isMobile ? {} : { minWidth: "133px" }}
            onClick={() => {
              !loadingDosification && setSubjectId(["2"]);
            }}
          >
            {subjectId.includes("2") ? (
              <img src={spanishSelected} alt="spanishSelected" />
            ) : (
              <img src={spanish} alt="spanish" />
            )}
            <div>Lenguajes</div>
          </button>
        </div>
        <div className={styles.containerMobileFilter}>
          <button
            name="4"
            className={
              subjectId.includes("4") ? styles.itemSelected : styles.items
            }
            style={isMobile ? {} : { minWidth: "140px" }}
            disabled={
              userToken?.schools[0].name === "Legionarios" ? true : false
            }
            onClick={() => {
              !loadingDosification && setSubjectId(["4"]);
            }}
          >
            {subjectId.includes("4") ? (
              <img src={cienciasSelected} alt="cienciasSelected" />
            ) : (
              <img src={ciencias} alt="ciencias" />
            )}
            <div>P. científico</div>
          </button>
          <button
            name="17"
            className={
              subjectId.includes("17") ? styles.itemSelected : styles.items
            }
            style={isMobile ? {} : { minWidth: "133px" }}
            onClick={() => {
              !loadingDosification && setSubjectId(["17"]);
            }}
          >
            {subjectId.includes("17") ? (
              <img src={spanishSelected} alt="spanishSelected" />
            ) : (
              <img src={spanish} alt="spanish" />
            )}
            <div>Gramática</div>
          </button>
        </div>
      </div>

      <div className={styles.leftItems}>
        {userToken?.role === "teacher" && showDosificationForSchool() && (
          <button
            name="Ver dosificaciones completas"
            className={styles.viewAllDosifications}
            onClick={() => {
              setVisibleDrawer(true);
            }}
          >
            Ver dosificaciones completas
          </button>
        )}
      </div>
      <DosificationDrawer visible={visibleDrawer} onClose={DrawerOnClose} />
    </div>
  );
};

export default NemFilters;
