import { FC } from "react";
import styles from "./paginationProgressBar.module.scss";

interface Props {
  limit: number;
  setLimit: (e: number) => void;
  itemsLength: number;
  totalLength: number;
}

const PaginationProgressBar: FC<Props> = ({
  limit,
  setLimit,
  itemsLength,
  totalLength,
}) => {
  return (
    <div className={styles.paginationContainer}>
      <h3>
        {itemsLength} de {totalLength} resultados
      </h3>
      <div className={styles.progressContainer}>
        <div
          className={styles.progressBar}
          style={{
            width: `${(itemsLength * 100) / totalLength}%`,
          }}
        ></div>
      </div>
      {totalLength !== itemsLength && (
        <button
          disabled={totalLength === itemsLength}
          onClick={() => {
            setLimit(limit + 8);
          }}
          style={{
            cursor: totalLength === itemsLength ? "not-allowed" : "pointer",
          }}
        >
          ver más
        </button>
      )}
    </div>
  );
};

export default PaginationProgressBar;
