import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalMissProject.module.scss";

interface PropsModalDelete {
  modalDelete: boolean;
  onCloseModalDelete: () => void;
  text?: string;
}
const ModalMissProject: FC<PropsModalDelete> = ({
  modalDelete,
  text,
  onCloseModalDelete,
}) => {
  return (
    <Modal
      open={modalDelete}
      onCancel={onCloseModalDelete}
      closable={false}
      width={"470px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        <div className={styles.containerText}>
          <div className={styles.containerSubTitle}>
            {text
              ? text
              : "Parece que no has seleccionado todas las opciones que necesitamos para crear tu proyecto. Revisa por favor"}
          </div>
        </div>

        <div className={styles.footerDetail}>
          <button
            className={styles.taskButtonMision}
            onClick={onCloseModalDelete}
          >
            Volver
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMissProject;
