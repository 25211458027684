import React, { FC } from "react";
import { Modal } from "antd";
import styles from "./modalReward.module.scss";
import gem from "../../../assets/pages/store/gem.png";
import medalFirst from "../../../assets/icons/clasification/medalFirst.svg";
import medalThired from "../../../assets/icons/clasification/medalThired.svg";
import medalSecond from "../../../assets/icons/clasification/medalSecond.svg";
import useIsMobile from "../../../hooks/useIsMobile";
import useCloseFinishTorunament from "../../../api/useCloseFinishTournamentModal";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";

export interface TrophiesTournament {
  position: number;
  score: number;
  range_date: string;
}

interface PropsModalReward {
  openModal: boolean;
  userScore: getUserByEmail_getUserByEmail;
  onCloseModalPosition: () => void;
}

const ModalReward: FC<PropsModalReward> = ({
  openModal,
  onCloseModalPosition,
  userScore,
}) => {
  const isMobile = useIsMobile();
  const closeFinish = useCloseFinishTorunament();

  const getReward = (position: number) =>
    position === 1 ? 500 : position === 2 ? 300 : 200;

  return (
    <Modal
      open={openModal}
      closable={false}
      width={isMobile ? "100%" : "800px"}
      footer={""}
      className={`${styles.container} modalReward`}
      centered
    >
      <div className={styles.content}>
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 1 && (
            <img src={medalFirst} alt="medal" className={styles.medalImg} />
          )}
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 2 && (
            <img src={medalSecond} alt="medal" className={styles.medalImg} />
          )}
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 3 && (
            <img src={medalThired} alt="medal" className={styles.medalImg} />
          )}

        <div className={styles.title}>¡ENHORABUENA!</div>
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 1 && (
            <div className={styles.description}>
              Has terminado el torneo en primera posición
            </div>
          )}
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 2 && (
            <div className={styles.description}>
              Has terminado el torneo en segunda posición
            </div>
          )}
        {userScore?.avatar_state?.trophies &&
          userScore?.avatar_state?.trophies?.length > 0 &&
          userScore?.avatar_state?.trophies[
            userScore?.avatar_state?.trophies.length - 1
          ].position === 3 && (
            <div className={styles.description}>
              Has terminado el torneo en tercera posición
            </div>
          )}

        <div
          className={styles.buttonReward}
          onClick={async () => {
            onCloseModalPosition();
            await closeFinish();
          }}
        >
          <div className={styles.reclaim}>Reclama tu recompensa</div>
          <img src={gem} alt="asd"></img>
          {userScore?.avatar_state?.trophies &&
            userScore?.avatar_state?.trophies?.length > 0 &&
            userScore?.avatar_state?.trophies[
              userScore?.avatar_state?.trophies.length - 1
            ].position === 1 && <div>{getReward(1)}</div>}
          {userScore?.avatar_state?.trophies &&
            userScore?.avatar_state?.trophies?.length > 0 &&
            userScore?.avatar_state?.trophies[
              userScore?.avatar_state?.trophies.length - 1
            ].position === 2 && <div>{getReward(2)}</div>}
          {userScore?.avatar_state?.trophies &&
            userScore?.avatar_state?.trophies?.length > 0 &&
            userScore?.avatar_state?.trophies[
              userScore?.avatar_state?.trophies.length - 1
            ].position === 3 && <div>{getReward(3)}</div>}
        </div>
      </div>
    </Modal>
  );
};

export default ModalReward;
