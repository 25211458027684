import { Modal } from "antd";
import React, { FC, useState } from "react";
import styles from "./modalSave.module.scss";
import { Loader } from "../../../../../Commons";

interface PropsModalDelete {
  open: boolean;
  onCloseModalDelete: () => void;
  saveEvidenceModal: () => Promise<void>;
}
const ModalDelete: FC<PropsModalDelete> = ({
  open,
  onCloseModalDelete,
  saveEvidenceModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      open={open}
      onCancel={onCloseModalDelete}
      closable={true}
      width={"444px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.containerTitle}>
              ¿Seguro que quieres guardar la evidencia?
            </div>
            <div className={styles.containerSubTitle}>
              Cuando la guardes, ya no podrás modificarla y se enviará a tu
              maestro.
            </div>

            <div className={styles.footerDetail}>
              <button
                className={`${styles.taskButtonDelete} ${styles.footerButton}`}
                onClick={() => {
                  onCloseModalDelete();
                }}
              >
                Cancelar
              </button>

              <button
                className={`${styles.taskButtonMision} ${styles.footerButton}`}
                onClick={async () => {
                  setIsLoading(true);
                  await saveEvidenceModal();
                  onCloseModalDelete();
                }}
              >
                Guardar
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalDelete;
