import axios from "axios";
import packageJson from "../../package.json";
import { apiLuca } from "./constants";

export const manageVersion = async () => {
  try {
    const version = await axios.get<{ version: string }>(`${apiLuca}/version`);

    caches.keys().then((names) => {
      // Delete all the cache files
      console.log({ names });
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    if (version.data.version !== packageJson.version) {
      if ("caches" in window) {
        console.log('caches in window')
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      return { idem: false, error: "version" };
    } else {
      return { idem: true, error: null };
    }
  } catch (error: any) {
    return { idem: false, error: error.message };
  }
};

export const getActualVersion = async () => {
  try {
    const { data } = await axios.get<{ version: string }>(`${apiLuca}/version`);

    return {
      backend: data.version,
      front: packageJson.version,
    };
  } catch (error) {
    console.log("getActualVersion error: ", error);
    return null;
  }
};
