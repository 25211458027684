import { FC } from "react";
import styles from "./taskBanner.module.scss";
import circleInProgress from "../../../assets/icons/circleInProgress.svg";
import calendar from "../../../assets/icons/calendaryTable.svg";
import warning from "../../../assets/icons/warning.svg";

import { getClassroomsByTeacher_getClassroomsByTeacher_homeworks } from "../../../__generated__/gql-types/getClassroomsByTeacher";
import { useNavigate } from "react-router-dom";
import moment from "moment";
interface BannerProps {
  handleClickCard: (e?: boolean) => void;
  subjectId?: string;
  homeworks:
    | (getClassroomsByTeacher_getClassroomsByTeacher_homeworks | null)[]
    | null;
}

const TaskBanner: FC<BannerProps> = ({
  homeworks,
  subjectId,

  handleClickCard,
}) => {
  const navigate = useNavigate();

  const handleButton = (event: any) => {
    subjectId === "16" ? navigate("/english") : navigate("/nem");
    event.stopPropagation();
  };
  const unchecked:
    | (getClassroomsByTeacher_getClassroomsByTeacher_homeworks | null)[]
    | undefined = homeworks?.filter(
    (homework) => homework && homework.status === "unchecked"
  );

  const inCourse:
    | (getClassroomsByTeacher_getClassroomsByTeacher_homeworks | null)[]
    | undefined = homeworks?.filter(
    (homework) => homework && homework.status === "inCourse"
  );

  const programmed:
    | (getClassroomsByTeacher_getClassroomsByTeacher_homeworks | null)[]
    | undefined = homeworks?.filter(
    (homework) => homework && homework.status === "programed"
  );

  return (
    <>
      {unchecked && unchecked.length > 0 && (
        <div className={styles.boxGray}>
          <div className={styles.descriptionTask}>
            <img src={warning} alt="" className={styles.img} />
            {unchecked.length} Tareas sin revision
          </div>

          <div className={styles.redirect}> Ver resultados</div>
        </div>
      )}

      {inCourse && inCourse.length > 0 && (
        <div className={styles.boxGray}>
          <div className={styles.textBoxGray}>
            <img src={circleInProgress} alt="" />
            {inCourse.length === 1
              ? "1 Tarea en curso"
              : `${inCourse.length} Tareas en curso`}
          </div>
          <div
            className={styles.redirect}
            onClick={() => {
              handleClickCard && handleClickCard();
            }}
          >
            Ver progreso
          </div>
        </div>
      )}

      {programmed && programmed.length > 0 && (
        <div className={styles.boxGray}>
          {programmed.length !== 1 ? (
            <>
              <div className={styles.textBoxGray}>
                <img src={calendar} /> {programmed.length} Tareas programadas
              </div>
              <div
                className={styles.redirect}
                onClick={() => {
                  handleClickCard && handleClickCard();
                }}
              >
                Ver
              </div>
            </>
          ) : (
            <>
              <div className={styles.textBoxGray}>
                <img src={calendar} /> {programmed.length} Tarea programada
              </div>
              <div className={styles.dateBoxGray}>
                {programmed[0] &&
                  moment(programmed[0].start_date, "DD/MM/YYYY HH:mm", false)
                    .format("DD MMM")
                    .replace(/\.$/, "")}{" "}
                -{" "}
                {programmed[0] &&
                  moment(programmed[0].end_date, "DD/MM/YYYY HH:mm", false)
                    .format("DD MMM")
                    .replace(/\.$/, "")}
              </div>
            </>
          )}
        </div>
      )}

      {programmed &&
        programmed.length === 0 &&
        inCourse &&
        inCourse.length === 0 &&
        unchecked &&
        unchecked.length === 0 && (
          <div className={styles.boxGray}>
            <div className={styles.textBoxGrayEmpty}>
              No hay tareas programadas o en curso
            </div>
            <div className={styles.redirect} onClick={handleButton}>
              Asignar tareas
            </div>
          </div>
        )}
    </>
  );
};

export default TaskBanner;
