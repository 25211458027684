import { DropResult } from "react-beautiful-dnd";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../__generated__/gql-types/getLessonById";

export interface PropsColumns {
  id: number;
  title: string;
  taskIds: string[];
}
export interface PropsOnDragon {
  result: DropResult;
  columns: PropsColumns[];
  setColumns: (e: PropsColumns[]) => void;
}

export interface PropsNewFraseDrag {
  alertTrue: boolean;
  subject_id: number | null;
  alertFalse: boolean;
  alertMid: boolean;
  content: getLessonById_getLessonById_lesson_content_quizzes_questions;
  columns: PropsColumns[];
  setColumns: (e: PropsColumns[]) => void;
}

export const onDragEnd = ({ columns, result, setColumns }: PropsOnDragon) => {
  const { destination, source, draggableId } = result;
  if (!destination) return;
  //   draggableId : id de columna donde saca el elemento
  //source : indice de elemento que tomas y id de la columna
  //destination : destino del elemento que pusiste
  //destination.index : indice del array donde llevaste el elemento

  const firstColumn = columns.find((x) => x.id === 1);
  const start = columns.find((x) => x.id.toString() === source.droppableId); //start : columna de donde sacas el elemento
  const finish = columns.find(
    (x) => x.id.toString() === destination.droppableId
  );

  if (
    (destination.droppableId === source.droppableId &&
      destination.index === source.index) ||
    !start ||
    !finish ||
    start === finish
  ) {
    return;
  }

  const startTaskIds = Array.from(start!.taskIds); //array de elementos de la columna donde se retira
  startTaskIds.splice(source.index, 1); // elimina el elemento

  const newStart = {
    //newStart: nuevo columna sin el elemento
    ...start,
    taskIds: startTaskIds,
  };
  const finishTaskIds = Array.from(finish.taskIds);

  if (columns.length === 3) {
    if ((finish.id === 1 || finish.id === 2) && finish.taskIds.length > 0) {
      // Cuando se quieren poner dos elementos en la columna 1
      newStart.taskIds.push(finishTaskIds[0]);
      finishTaskIds.splice(0, finishTaskIds.length, draggableId); //borra el array y pone el elemento de la otra columna en la columna 1
    } else {
      finishTaskIds.splice(destination.index, 0, draggableId);
    }
  } else {
    if (firstColumn?.taskIds.length === 1 && start.id === 2) {
      // Cuando se quieren poner dos elementos en la columna 1
      newStart.taskIds.push(finishTaskIds[0]);
      finishTaskIds.splice(0, finishTaskIds.length, draggableId); //borra el array y pone el elemento de la otra columna en la columna 1
    } else {
      finishTaskIds.splice(destination.index, 0, draggableId);
    }
  }

  const newFinish = {
    //newFinish: nuevo columna con el elemento
    ...finish,
    taskIds: finishTaskIds,
  };
  const newState = columns.map((column) => {
    if (column.id === newStart.id) {
      return newStart; // Reemplazar el objeto con el mismo ID
    }
    if (column.id === newFinish.id) {
      return newFinish; // Reemplazar el objeto con el mismo ID
    }
    return column; // Mantener el objeto original
  });

  newState.sort((a, b) => {
    return a.id - b.id; // Mantén el orden actual
  });
  setColumns(newState);
};

export const getListOptionsStyle = () => ({
  overflow: "none",
  display: "flex",
  gap: "50px",
});

export const getListStyle = () => ({
  display: "inline-block",
  gap: "50px",
});
