import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { changePasswordVariables } from "../__generated__/gql-types/changePassword";

const useUpdatePassword = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: changePasswordVariables) => {
      return await client.mutate({
        mutation: CHANGE_PASSWORD,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useUpdatePassword;

const CHANGE_PASSWORD = gql`
  mutation changePassword($prevPassword: String, $newPassword: String) {
    changePassword(prevPassword: $prevPassword, newPassword: $newPassword) {
      success
    }
  }
`;
