import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getCoursesBySubjectIdLength,
  getCoursesBySubjectIdLengthVariables,
} from "../__generated__/gql-types/getCoursesBySubjectIdLength";

const UseGetCoursesBySubjectIdLength = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdLengthVariables) => {
      const result: ApolloQueryResult<getCoursesBySubjectIdLength> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID_LENGTH,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySujectId;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectIdLength;

const GET_COURSES_BY_SUBJECT_ID_LENGTH = gql`
  query getCoursesBySubjectIdLength(
    $getCoursesBySubjectIdInput: GetCoursesBySubjectIdInput
  ) {
    getCoursesBySujectId(
      getCoursesBySubjectIdInput: $getCoursesBySubjectIdInput
    ) {
      id
    }
  }
`;
