import { Drawer, Tabs } from "antd";
import styles from "./drawerProfileMobile.module.scss";
import { HeaderDrawerMobile } from "../Shared/HeaderDrawerMobile";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";
import candadoVioleta from "../../../assets/images/candadoVioleta.svg";
import candadoGris from "../../../assets/images/candadoGris.svg";
import { resolveAvatar } from "../../../utils/functions";

interface DrawerProp {
  onClose: () => void;
  goBack: () => void;
  visible: boolean;
  userDb: getUserByEmail_getUserByEmail | null;
}
const DrawerProfileMobile = ({
  onClose,
  visible,
  goBack,
  userDb,
}: DrawerProp) => {
  const { TabPane } = Tabs;
  return (
    <>
      <Drawer
        onClose={onClose}
        placement={"bottom"}
        height={"500px"}
        size={"large"}
        visible={visible}
        title={
          <HeaderDrawerMobile
            onClose={onClose}
            goBack={goBack}
            title={"Mi perfil"}
          />
        }
        closable={false}
        style={{ background: "white !important" }}
      >
        <div className={styles.container}>
          <div className={styles.bodyDrawer}>
            <img
              src={resolveAvatar(
                userDb?.avatar ?? "polux",
                userDb?.score?.level?.toString() ?? "1"
              )}
              alt="avatar_image"
            />

            <div className={styles.candadosContainer}>
              <Tabs defaultActiveKey="1" size="small">
                <TabPane tab="Complementos" key="1">
                  <div className={styles.candadosSubContainer}>
                    <img src={candadoVioleta} alt="" />
                    <img src={candadoGris} alt="" />
                    <img src={candadoGris} alt="" />
                    <img src={candadoGris} alt="" />
                  </div>
                  <></>
                  <div className={styles.candadosSubContainer}>
                    <img src={candadoGris} alt="" />
                    <img src={candadoGris} alt="" />
                    <img src={candadoGris} alt="" />
                    <img src={candadoGris} alt="" />
                  </div>
                </TabPane>
                {/* <TabPane tab="Ropa" disabled key="2">
                  Tab 2
                </TabPane> */}
                <TabPane tab="Objetos" disabled key="3">
                  Tab 3
                </TabPane>
                <TabPane tab="Fondos" disabled key="4">
                  Tab 4
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default DrawerProfileMobile;
