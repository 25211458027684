import React, { FC } from "react";
import styles from "./assingDataClassroom.module.scss";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { timePickersUtils } from "./timePickersPanelUtils";

interface PropsAssingData {
  startTime: string | dayjs.Dayjs;
  startDate: string | dayjs.Dayjs;
  setStartDate: (e: string | dayjs.Dayjs) => void;
  setStartTime: (e: string | dayjs.Dayjs) => void;
}
const AssingDataClassroom: FC<PropsAssingData> = ({
  startTime,
  startDate,
  setStartDate,
  setStartTime,
}) => {
  const timeFormat = "HH:mm";
  const dateFormat = "DD-MM-YYYY";

  const disableStartTime = () => timePickersUtils.disableStartTime(startDate);

  return (
    <div className={styles.programPanel}>
      <h1 className={styles.typography}>Programar la fecha y hora</h1>
      <div className={styles.programPanelTitles}>
        <p style={{ marginTop: "3%" }}>Fecha</p>{" "}
        <p style={{ marginTop: "3%" }}>Hora</p>{" "}
      </div>

      <div className={styles.programInputs}>
        <DatePicker
          onChange={(date) => {
            setStartDate(date ? date.format(dateFormat) : startDate);
          }}
          style={{ width: "48%" }}
          value={dayjs(startDate, dateFormat)}
          showNow={false}
          format={dateFormat}
          className={styles.dataPickerStyles}
          changeOnBlur={true}
          disabledDate={timePickersUtils.disabledDate}
        />
        <TimePicker
          onChange={(time) => {
            setStartTime(time ? time.format(timeFormat) : startTime);
          }}
          changeOnBlur={true}
          style={{ width: "48%" }}
          showNow={false}
          format={timeFormat}
          value={dayjs(startTime, timeFormat)}
          disabled={startDate ? false : true}
          disabledTime={disableStartTime}
        />
      </div>
    </div>
  );
};

export default AssingDataClassroom;
