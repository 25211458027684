import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useCreateStudentHomeworkEvidenceEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (homeworkId: number, studentEvidenceUrl: string) => {
      return await client.mutate({
        mutation: CREATE_HOMEWORK_EVIDENCE,
        fetchPolicy: "no-cache",
        variables: { homeworkId, studentEvidenceUrl },
      });
    },
    [client]
  );
};
export default useCreateStudentHomeworkEvidenceEvent;

const CREATE_HOMEWORK_EVIDENCE = gql`
mutation createStudentHomeworkEvidenceEvent($homeworkId: Int, $studentEvidenceUrl: String) {
  createStudentHomeworkEvidenceEvent(homeworkId: $homeworkId, studentEvidenceUrl: $studentEvidenceUrl) {
    id
    title
    item_type
    item_id
    user_id
    student_homework_evidence_url
  }
}
`;
