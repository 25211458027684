import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getResponseByUserAndRedactionHomework,
  getResponseByUserAndRedactionHomeworkVariables,
} from "../__generated__/gql-types/getResponseByUserAndRedactionHomework";

const UseGetResponseByUserAndRedactionHomework = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getResponseByUserAndRedactionHomeworkVariables) => {
      const result: ApolloQueryResult<getResponseByUserAndRedactionHomework> =
        await client.query({
          query: GET_LESSONS_BY_SUBJECT_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getResponseByUserAndRedactionHomework;
    },
    [client]
  );
};

export default UseGetResponseByUserAndRedactionHomework;

const GET_LESSONS_BY_SUBJECT_ID = gql`
  query getResponseByUserAndRedactionHomework(
    $userId: Int
    $homeworkId: Int
    $redactionId: Int
  ) {
    getResponseByUserAndRedactionHomework(
      user_id: $userId
      homework_id: $homeworkId
      redaction_id: $redactionId
    ) {
      id
      redaction_response
    }
  }
`;
