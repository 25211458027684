import React, { FC, useState } from "react";
import styles from "./bannerList.module.scss";
import question from "../../../../assets/pages/classes/question.png";
import {
  getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard as PropsClassroom,
  getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks,
} from "../../../../__generated__/gql-types/getClassroomWithHomeworksByDashboard";
import { Checkbox, ConfigProvider, Tooltip } from "antd";
import { lessonTypeIconResolve } from "../../../SingleClass/TaskTable/utils";
import { functionReturnImg } from "./utils";
import useUpdateDashboardVisibleHomework from "../../../../api/useupdateDashboardVisibleHomework";
import { DetailsHomeworkDrawer } from "../../../SingleClass/DetailsHomeworkDrawer";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { DependencyRefresh } from "../../../../redux/store/store";
import { setDependencyRefresh } from "../../../../redux/slices/dependencyRefresh";

interface PropsBannerList {
  homeworks:
    | (getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks | null)[]
    | null;
}
const BannerList: FC<PropsBannerList> = ({ homeworks }) => {
  const updateDashboard = useUpdateDashboardVisibleHomework();
  const [homeworkCheck, setHomeworkCheck] = useState<string[]>();
  const [visible, setVisible] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);

  const onClose = () => {
    setVisible(false);
  };

  const handleUpdate = async (homeworkId: number) => {
    try {
      await updateDashboard({
        homeworkId: homeworkId,
        visible: false,
      });
      dispatch(setDependencyRefresh(!dependencyRefresh)); // Hacer Refresh
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.containerPanel}>
        <div className={styles.headerList}>
          <div className={styles.firstColumn}>
            <div className={styles.subject}> {""}</div>
            <div className={styles.grade}>Grado</div>
            <div className={styles.class}>Clase</div>
            <div className={styles.type}>Tipo</div>
            <div className={styles.task}>Tarea</div>
          </div>
          <div className={styles.secondColumn}>
            <div className={styles.completed}>Completada</div>
            <div className={styles.averege}>Promedio</div>
            <div className={styles.finished}>
              <div>Archivar</div>
              <Tooltip
                title="Marca como completada la tarea para confirmar que la has revisado. 
Al hacerlo, desaparecerá de la lista de tareas pendientes."
                color={"#cc7700"}
              >
                <img src={question} alt="Help" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerPanel}>
        {homeworks?.map((homework, index) => (
          <div
            className={styles.bodyList}
            key={index}
            onClick={() => {
              homework?.id && setTaskId(homework?.id);
              setVisible(true);
            }}
          >
            <div className={styles.firstColumn}>
              <div className={styles.subject}>
                <img
                  src={functionReturnImg(homework?.subject_id ?? null)}
                  alt="default"
                />
              </div>
              <div className={styles.grade}>{homework?.grade}°</div>
              <div className={styles.class}>{homework?.label}</div>
              <div className={styles.type}>
                <img
                  src={
                    homework?.lesson_type
                      ? lessonTypeIconResolve(homework.lesson_type)
                      : ""
                  }
                  className={styles.typeImage}
                  alt="type"
                />
              </div>
              <div className={styles.task}>{homework?.title}</div>
            </div>
            <div className={styles.secondColumn}>
              <div className={styles.completed}>{homework?.participation}%</div>
              <div className={styles.averege}>
                {homework?.average && homework?.average.toFixed(1)}
              </div>
              <div className={styles.finished}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#3843D0",
                    },
                  }}
                >
                  <Checkbox
                    style={{ zIndex: 99 }}
                    autoFocus={true}
                    checked={
                      homework?.id && homeworkCheck?.includes(homework?.id)
                        ? true
                        : false
                    }
                    onClick={async (e) => {
                      e.isPropagationStopped();
                      e.preventDefault();
                      e.stopPropagation();
                      homework?.id && homeworkCheck
                        ? setHomeworkCheck([...homeworkCheck, homework?.id])
                        : homework?.id && setHomeworkCheck([homework?.id]);
                      homework?.id &&
                        (await handleUpdate(Number(homework?.id)));
                    }}
                  ></Checkbox>
                </ConfigProvider>
              </div>
            </div>
          </div>
        ))}
      </div>
      {visible && (
        <DetailsHomeworkDrawer
          back="calendar"
          taskId={taskId}
          singleTask={null}
          onCloseTask={onClose}
          visibleTask={visible}
        />
      )}
    </div>
  );
};

export default BannerList;
