import React, { ChangeEvent, FC } from "react";
import styles from "./mainInteligentTeacher.module.scss";
import leftArrow from "../../../../../../assets/icons/arrows/leftArrow.png";
import rightArrow from "../../../../../../assets/icons/arrows/rightArrow.png";
import downArrow from "../../../../../../assets/icons/arrows/downArrow.png";
import englishLucaBot from "../../../../../../assets/icons/englishLucaBot.png";
import { Select, Input, Tabs } from "antd";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../../__generated__/gql-types/getRedactionCorrection";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../../../__generated__/gql-types/getHomeworkById";
import { environment } from "../../../../../../configuration/environment";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import { useFlags } from "launchdarkly-react-client-sdk";
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

interface PropsMain {
  handlePrev: () => void;
  handleNext: () => void;
  currentIndex: number;
  setEditorGtpState: (e: EditorState) => void;
  editorGtpState: EditorState | undefined;
  editorState: EditorState | undefined;
  teacherComments: string;
  alumnsDelivered:
    | getHomeworkById_getHomeworkById_students_grades[]
    | undefined;
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
  setEvaluateStudent: React.Dispatch<
    React.SetStateAction<getRedactionCorrection_getRedactionCorrection | null>
  >;
  task: getHomeworkById_getHomeworkById | null;
  userId: number | null;
  setTeacherComments: React.Dispatch<React.SetStateAction<string>>;
}

const MainInteligentTeacher: FC<PropsMain> = ({
  teacherComments,
  setEditorGtpState,
  editorGtpState,
  handlePrev,
  editorState,
  handleNext,
  setTeacherComments,
  setEvaluateStudent,
  evaluateStudent,
  currentIndex,
  task,
  userId,
  alumnsDelivered,
}) => {
  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(e.target.value) || 0;
    if (
      evaluateStudent &&
      Number.isInteger(Number(newValue)) &&
      newValue >= 0 &&
      newValue <= 10
    ) {
      setEvaluateStudent({ ...evaluateStudent, note: Number(newValue) });
      if (e.target.value === undefined) {
        setEvaluateStudent({ ...evaluateStudent, note: null });
      }
    }
  };

  const studentEvidence = task?.students_grades?.find(
    (student) => student.student_id === userId
  );
  const url = `${environment.awsBucketVideos}${studentEvidence?.studentEvidenceUrl}`;
  const flags = useFlags();
  const isEnglish =
    task?.lessons &&
    task?.lessons?.length > 0 &&
    task?.lessons[0].subject?.id === "16";
  return (
    <div className={styles.container}>
      <div className={styles.containerUsers}>
        <div
          className={styles.arrow}
          onClick={() => {
            handlePrev();
          }}
        >
          <img src={leftArrow} alt="left" />
        </div>
        <div className={styles.nameUser} key={currentIndex}>
          {alumnsDelivered &&
            alumnsDelivered[currentIndex] &&
            alumnsDelivered[currentIndex].student_name}
        </div>
        <div
          className={styles.arrow}
          onClick={() => {
            handleNext();
          }}
        >
          <img src={rightArrow} alt="right" />
        </div>
      </div>
      <div className={styles.containerInfo}>
        <div className={styles.responseStudent}>
          <Tabs defaultActiveKey="1" centered className={styles.tabStyles}>
            {flags?.correctedExtend && (
              <TabPane tab={isEnglish ? "Corrected" : "Corregido"} key="1">
                {evaluateStudent?.redaction_gpt_response_correction && (
                  <Editor
                    editorState={editorGtpState}
                    readOnly={false}
                    placeholder="Escribe aquí tu respuesta"
                    editorStyle={{
                      fontSize: "16px",
                      backgroundColor: "white",
                      borderRadius: 12,
                      padding: 10,
                      overflow: "hidden",
                      minHeight: "50vh",
                    }}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarHidden={false}
                    localization={{
                      locale: "es",
                    }}
                    onEditorStateChange={setEditorGtpState}
                  />
                )}
              </TabPane>
            )}
            <TabPane tab={isEnglish ? "Original" : "Original"} key="2">
              {evaluateStudent?.redaction_response && (
                <Editor
                  editorState={editorState}
                  readOnly={true}
                  placeholder="Escribe aquí tu respuesta"
                  editorStyle={{
                    backgroundColor: "white",
                    height: "60vh",
                    borderRadius: 12,
                    padding: 10,
                  }}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbarHidden={true}
                  localization={{
                    locale: "es",
                  }}
                />
              )}
            </TabPane>

            {url && (
              <TabPane tab={isEnglish ? "Evidence" : "Evidencia"} key="3">
                <div className={styles.containerImg}>
                  {task?.needs_file_evidence ? (
                    <img src={url} alt={"img"} />
                  ) : (
                    <div>No se solicitó evidencia para esta tarea.</div>
                  )}
                  {task?.needs_file_evidence ? (
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.downloadEvidence}
                    >
                      <span>Descargar evidencia</span>
                    </a>
                  ) : null}
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
        <div className={styles.qualificationStudent}>
          <div className={styles.containerQualificationStudent}>
            <div>{isEnglish ? "Mark (out of 10)" : "Calificación (de 10)"}</div>
            <input
              className={styles.boxQualification}
              type="number"
              min={0}
              max={10}
              step={1}
              value={evaluateStudent?.note ?? undefined}
              onChange={handleNoteChange}
            />
          </div>
          <div className={styles.containerRubricas}>
            <div className={styles.containerColumns}>
              <div className={styles.firstColumn}>
                <div className={styles.titleRubricas}>
                  {isEnglish ? "Rubrics" : "Rúbricas"}
                </div>
                {evaluateStudent?.rubrics?.map((rubric, index) => {
                  return (
                    <div key={index}>
                      <div className={styles.subtitleRubricas}>
                        {isEnglish ? "Rubric" : "Rúbrica"} {index + 1}:{" "}
                        {rubric.rubric}
                      </div>
                      <Select
                        suffixIcon={<img src={downArrow} alt="arrow" />} // Puedes cambiar este icono a uno personalizado
                        style={{ width: 300, fontSize: "14px" }}
                        placeholder="Selecciona una opción"
                        value={rubric.calification}
                        onChange={(e) => {
                          let newObj = evaluateStudent.rubrics;
                          if (newObj) {
                            newObj[index].calification = e;
                          }
                          evaluateStudent &&
                            setEvaluateStudent({
                              ...evaluateStudent,
                              rubrics: newObj,
                            });
                        }}
                      >
                        <Option value={1} style={{ fontSize: "14px" }}>
                          {isEnglish ? "Excellent" : "Excelente"}
                        </Option>
                        <Option value={2} style={{ fontSize: "14px" }}>
                          {isEnglish ? "Good" : "Bien"}
                        </Option>
                        <Option value={3} style={{ fontSize: "14px" }}>
                          {isEnglish ? "OK" : "Regular"}
                        </Option>
                        <Option value={4} style={{ fontSize: "14px" }}>
                          {isEnglish ? "Needs Improvement" : "Por mejorar"}
                        </Option>
                      </Select>
                    </div>
                  );
                })}
              </div>
              <div className={styles.secondColumn}>
                <img
                  src={englishLucaBot}
                  alt="bot"
                  className={styles.columnBotImg}
                />
              </div>
            </div>
            <div className={styles.commentsRubricas}>
              {isEnglish ? "Comments" : "Comentarios"}
            </div>
            <TextArea
              value={teacherComments}
              onChange={(e) => {
                setTeacherComments(e.target.value);
              }}
              style={{
                width: "100%",
                height: 120,
                resize: "none",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInteligentTeacher;
