import { useEffect, useState } from "react";
import UseGetUserByEmail from "../api/useGetUserByEmail";
import useAuth from "../context/useAuth";
import { getUserByEmail_getUserByEmail } from "../__generated__/gql-types/getUserByEmail";

export const useGetUser = () => {
  const { userToken } = useAuth();
  const getUserByEmail = UseGetUserByEmail();
  const [loading, setLoading] = useState(true);
  const [userDb, setUserDb] = useState<getUserByEmail_getUserByEmail | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        const userRes = await getUserByEmail({
          userEmail: userToken?.email,
        });
        setUserDb(userRes);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [getUserByEmail, userToken?.email]);

  return {
    loading,
    userDb,
  };
};
