import { FC } from "react";
import styles from "./searchbar.module.scss";
import searchIcon from "../../../assets/icons/searchIcon2.svg";
import lupa from "../../../assets/icons/lupa.png";

interface SearchbarProps {
  setSearchInput?: (e: string) => void;
  searchInput?: string;
  personalStyles?: string;
}

const Searchbar: FC<SearchbarProps> = ({
  setSearchInput,
  searchInput,
  personalStyles,
}) => {
  const handleSearchInput = (e: { target: { value: string } }) => {
    setSearchInput && setSearchInput(e.target.value);
  };

  return (
    <div
      className={
        personalStyles === "reading"
          ? styles.containerReading
          : styles.container
      }
    >
      <img src={lupa} alt="" />
      <input
        type="text"
        name="searchbar"
        placeholder="Buscar"
        onChange={handleSearchInput}
        value={searchInput}
      />
    </div>
  );
};

export default Searchbar;
