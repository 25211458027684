import React, { FC } from "react";
import styles from "./fill.module.scss";
import { getResponsesByHomework_getResponsesByHomework } from "../../../../../__generated__/gql-types/getResponsesByHomework";

interface PropsFill {
  index: number;
  subject_id?: number | null;
  question: getResponsesByHomework_getResponsesByHomework;
}

const Fill: FC<PropsFill> = ({ index, question, subject_id }) => {
  const partes = question.question?.split("__");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {subject_id === 16
            ? "Write the missing word to complete the sentence."
            : "Escribe la palabra que falta para completar la frase."}
        </div>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.textFill}>
          <p>
            <span>{partes![0]}</span>
            <span
              style={{
                background: "#8DCE4F",
                textAlign: "center",
                margin: "0px 8px",
                padding: "6px 24px",
                borderRadius: "6px",
              }}
            >
              {question.answers![0].answer}
            </span>
            <span>{partes![1]}</span>
          </p>
        </div>

        <div className={styles.containerBox}>
          {question.student_input_responses?.map((answer) => (
            <div className={answer.correct ? styles.boxGreen : styles.boxRed}>
              {answer.response}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fill;
