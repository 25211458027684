import { useCallback } from "react";
import {
  getStudentsAnswersAverageStatus,
  getStudentsAnswersAverageStatusVariables,
} from "../__generated__/gql-types/getStudentsAnswersAverageStatus";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

const useGetStudentsAnswersAverageStatus = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getStudentsAnswersAverageStatusVariables) => {
      const result: ApolloQueryResult<getStudentsAnswersAverageStatus> =
        await client.query({
          query: GET_STUDENT_ANSWERS_AVERAGE_STATUS,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getStudentsAnswersAverageStatus;
    },
    [client]
  );
};

export default useGetStudentsAnswersAverageStatus;

const GET_STUDENT_ANSWERS_AVERAGE_STATUS = gql`
  query getStudentsAnswersAverageStatus($homeworkId: Int!) {
    getStudentsAnswersAverageStatus(homeworkId: $homeworkId) {
      questionId
      totalResponses
      answer_types_status {
        total
        type
      }
    }
  }
`;
