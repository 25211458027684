import { FC } from "react";
import { Tabs } from "antd";
import { Loader } from "../../../Commons";
import { Collapsible } from "../Collapsible";
import styles from "./tabscomponent.module.css";
import { Searchbar } from "../../../Commons/Searchbar";
import { getCoursesBySubjectId_getCoursesBySujectId } from "../../../../__generated__/gql-types/getCoursesBySubjectId";
import { ActivityIndicator } from "../../../ActivityIndicator/ActivityIndicator";
import useIsMobile from "../../../../hooks/useIsMobile";

const { TabPane } = Tabs;

interface TabsProps {
	subjectsObjectives: {
		id: string;
		subjectName: string;
	}[];
	isLoading: boolean;
	isLoaded: boolean;
	searchInput?: string;
	setSearchInput?: (e: string) => void;
	setSubjectIdKey: (subject: string) => void;
	setSubjectId: (subject: string) => void;
	coursesData: getCoursesBySubjectId_getCoursesBySujectId[];
	subjectIdKey: string | null;
}

const TabsComponent: FC<TabsProps> = ({
	subjectsObjectives,
	setSubjectIdKey,
	coursesData,
	searchInput,
	setSearchInput,
	isLoading,
	isLoaded,
	setSubjectId,
	subjectIdKey,
}) => {
	const isMobile = useIsMobile();
	if (!subjectIdKey) {
		return <ActivityIndicator />;
	}

	const handleChangeSubject = (key: string) => {
		setSubjectIdKey(key);
		setSubjectId(key);
	};

	const functionValidationFilterCourseData = () => {
		return coursesData?.length >= 1 ? (
			coursesData?.map((item, indexItem) => {
				if (item?.lessons && item?.lessons?.length > 0) {
					return <Collapsible indexItem={indexItem} item={item} />;
				}
			})
		) : (
			<h2 style={{ margin: "1% 0 0 1%" }}>No se encontraron coincidencias.</h2>
		);
	};

	const loading = () => {
		return (
			<div
				className={
					isLoaded ? styles.isLoadedContainer : styles.isLoadingContainer
				}
			>
				<Loader />
			</div>
		);
	};

	const functionValidationSearchInput = () => {
		return searchInput
			? functionValidationFilterCourseData()
			: coursesData?.map((item) => {
					if (item?.lessons && item?.lessons?.length > 0) {
						return (
							<>
								{loading()}

								<Collapsible
									indexItem={Number(item.id)}
									item={item}
									key={item.id}
								/>
							</>
						);
					}
			  });
	};

	return (
		<Tabs
			defaultActiveKey={subjectIdKey}
			onChange={handleChangeSubject}
			className={isMobile ? styles.containerMobile : styles.container}
		>
			{subjectsObjectives?.map((subject) => {
				return (
					<TabPane
						tab={subject?.subjectName}
						key={subject?.id}
						className={isMobile ? styles.subContainerMobile : ""}
					>
						<div className={isMobile ? styles.searchMobile : ""}>
							<Searchbar
								setSearchInput={setSearchInput}
								searchInput={searchInput}
							/>
						</div>
						<div className={isMobile ? styles.backgroundMobile : ""}>
							{isLoading ? (
								<div className={styles.loaderContainer}>
									<Loader />
								</div>
							) : (
								functionValidationSearchInput()
							)}
						</div>
					</TabPane>
				);
			})}
		</Tabs>
	);
};
export default TabsComponent;
