import { FC } from "react";
import { AsteroidsChallenge } from "../../../components";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";

interface AsteroidsChallengePageProps {
  userDb?: getUserByEmail_getUserByEmail
}

const AsteroidsChallengePage: FC<AsteroidsChallengePageProps> = () => {
  return <AsteroidsChallenge />;
};

export default AsteroidsChallengePage;
