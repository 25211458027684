import { Progress } from "antd";
import styles from "./studentsAnswersAverageStatus.module.scss";
import { getResponsesByHomework_getResponsesByHomework } from "../../../../../__generated__/gql-types/getResponsesByHomework";
import {
  getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus,
  getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus_answer_types_status,
} from "../../../../../__generated__/gql-types/getStudentsAnswersAverageStatus";
import redMark from "../../../../../assets/images/red_alert_mark.svg";

interface StudentsAnswersAverageStatusProps {
  question: getResponsesByHomework_getResponsesByHomework;
  averageStatus:
    | getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus[]
    | null;
}

const StudentsAnwersAverageStatus = ({
  question,
  averageStatus,
}: StudentsAnswersAverageStatusProps) => {
  const isTwoSideQuestion =
    question.question_type === 1 ||
    question.question_type === 4 ||
    question.question_type === 5
      ? true
      : false;

  const showImageBad = (
    averageStatus: getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus[],
    questionId: number
  ) => {
    const answer = averageStatus.find((ans) => ans.questionId === questionId);
    if (!answer) return false;

    const totalResponses = answer.totalResponses;
    const badResponsesTotal = answer.answer_types_status?.find(
      (ans) => ans.type === "bad"
    )?.total;

    if (!totalResponses || !badResponsesTotal) return false;
    const percent = (badResponsesTotal / totalResponses) * 100;

    if (percent >= 50) return true;
    return false;
  };

  return averageStatus && averageStatus.length > 0 ? (
    <div className={styles.container}>
      {showImageBad(averageStatus, question?.question_id) && (
        <img src={redMark} alt="info"></img>
      )}
      {averageStatus?.map(
        (
          classroomAverageAnswerStatus: getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus
        ) => {
          const finalAnswerSnap = isTwoSideQuestion
            ? classroomAverageAnswerStatus.answer_types_status?.filter(
                (item) => item.type !== "ugly"
              )
            : classroomAverageAnswerStatus.answer_types_status;

          const finalAnswer:
            | getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus_answer_types_status[]
            | null
            | undefined = [];

          const goodElem = finalAnswerSnap?.find(
            (elem) => elem.type === "good"
          );
          const uglyElem = finalAnswerSnap?.find(
            (elem) => elem.type === "ugly"
          );
          const badElem = finalAnswerSnap?.find((elem) => elem.type === "bad");

          if (goodElem) finalAnswer.push(goodElem);
          if (uglyElem && uglyElem.total !== 0) finalAnswer.push(uglyElem);
          if (badElem) finalAnswer.push(badElem);

          if (
            Number(classroomAverageAnswerStatus.questionId) ===
            Number(question.question_id)
          ) {
            return finalAnswer?.map(
              (
                answerTypeStatus: getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus_answer_types_status
              ) => {
                return (
                  <div
                    key={answerTypeStatus?.type + `${new Date().getTime()}`}
                    className={styles.innerContainer}
                  >
                    <Progress
                      strokeLinecap="square"
                      percent={
                        (answerTypeStatus?.total! /
                          classroomAverageAnswerStatus.totalResponses!) *
                        100
                      }
                      showInfo={false}
                      className={"homeworkProgress"}
                      trailColor="#F3F3F6"
                      strokeColor={
                        answerTypeStatus?.type === "good"
                          ? "#8DCE4F"
                          : answerTypeStatus?.type === "bad"
                          ? "#E64C66"
                          : "#ffba31"
                      }
                    />

                    <div className={styles.numbersContainer}>
                      {" "}
                      {answerTypeStatus?.total}/
                      {classroomAverageAnswerStatus.totalResponses}{" "}
                    </div>
                  </div>
                );
              }
            );
          } else {
            return null;
          }
        }
      )}
    </div>
  ) : null;
};

export default StudentsAnwersAverageStatus;
