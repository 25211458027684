import { FC, useEffect, useState } from "react";
import spanishIcon from "../../../assets/icons/españolIcon.svg";
import scienceIcon from "../../../assets/icons/cienciaIcon.svg";
import mathIcon from "../../../assets/icons/mathIcon.svg";
import englishIcon from "../../../assets/icons/englishAmerican.svg";
import arrowIcon from "../../../assets/icons/arrowIcon.svg";
import check from "../../../assets/images/check.png";
import { TaskBanner } from "../TaskBanner";
import useAuth from "../../../context/useAuth";
import styles from "./card.module.scss";
import { useNavigate } from "react-router-dom";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import UseClassroomWithHomeworks from "../../../api/useGetClassroomWithHomeworks";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import { getUserByEmail_getUserByEmail_classrooms } from "../../../__generated__/gql-types/getUserByEmail";

interface CardProps {
  item: getUserByEmail_getUserByEmail_classrooms;
}
const Card: FC<CardProps> = ({ item }) => {
  const getClassroomWithHomeworks = UseClassroomWithHomeworks();
  const [ClassroomWithHomework, setClassroomWithHomework] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks | null>(null);
  const [loading, setLoading] = useState(false);

  const { userToken } = useAuth();
  const navigate = useNavigate();

  const showGradesOnBase100 = userToken?.schools[0].show_grades_on_base_100;

  useEffect(() => {
    const fetchHomework = async () => {
      const studentsClassroomHomeworks = await getClassroomWithHomeworks({
        classroomId: item.id,
      });
      setClassroomWithHomework(studentsClassroomHomeworks);
    };
    setLoading(true);
    fetchHomework();
    setLoading(false);
  }, [getClassroomWithHomeworks, item.id]);

  const handleClickCard = () => {
    navigate(`/myclasses/${item.id}`);
  };

  const functionReturnImg = (subjectId: string | undefined) => {
    if (subjectId === "1") {
      return mathIcon;
    }
    if (subjectId === "2") {
      return spanishIcon;
    }
    if (subjectId === "4") {
      return scienceIcon;
    }
    if (subjectId === "16") {
      return englishIcon;
    }
    return mathIcon;
  };

  return (
    <div className={styles.cardContainer} onClick={handleClickCard}>
      <div className={styles.containerHeader}>
        <div className={styles.headerContent}>
          <img
            src={functionReturnImg(item.program?.subject_id?.toString())}
            alt=""
          />

          <div className={styles.contentTitle}>
            <div className={styles.title}>
              {item?.program?.subject?.name} {item.program?.grade?.grade}°{" "}
              {item.group?.name}
            </div>
          </div>
        </div>
        <div className={styles.headerBack}>
          <img src={arrowIcon} alt="" />
        </div>
      </div>

      <div className={styles.secondContainer}>
        <div className={styles.squareContainer}>
          {!loading ? (
            <>
              <div className={styles.titleSquare}>Calificación promedio</div>
              <div className={styles.numberContainer}>
                <img src={check} alt="" />
                <div className={styles.numberContainer}>
                  {ClassroomWithHomework?.total_average && showGradesOnBase100
                    ? Number(ClassroomWithHomework?.total_average) * 10
                    : Number(ClassroomWithHomework?.total_average)
                    ? Number(ClassroomWithHomework?.total_average)
                    : "--"}
                </div>
              </div>
            </>
          ) : (
            <ActivityIndicator />
          )}
        </div>

        <div className={styles.squareContainer}>
          {!loading ? (
            <>
              <div className={styles.titleSquare}>Participación tareas</div>
              <div className={styles.logoNumberContainer}>
                <div className={styles.numberContainer}>
                  {ClassroomWithHomework?.total_participation
                    ? `${ClassroomWithHomework?.total_participation}%`
                    : "0%"}
                </div>
              </div>
            </>
          ) : (
            <ActivityIndicator />
          )}
        </div>
      </div>

      <div className={styles.thirdContainer}>
        <TaskBanner
          subjectId={item.program?.subject_id?.toString()}
          homeworks={
            ClassroomWithHomework?.homeworks?.filter(
              (a) => a && a.deleted_at === null
            ) ?? []
          }
          handleClickCard={handleClickCard}
        />
      </div>
    </div>
  );
};

export default Card;
