import { FC } from "react";
import XLSX from "xlsx";
import styles from "./downloadTableAsExcel.module.scss";
import downloader from "../../../assets/icons/teacher/dowloader.svg";
import { toLocalDate } from "../../../utils/functions";
import { Button } from "antd";

interface DownloadTableAsExcelProps {
  dataToRender: any[];
  classRoom: any;
  format: string;
}

export const DownloadTableAsExcel: FC<DownloadTableAsExcelProps> = ({
  dataToRender,
  classRoom,
  format,
}) => {
  const finalData = () => {
    if (
      dataToRender === undefined ||
      (dataToRender.length && dataToRender[0].__typename === "Homework")
    ) {
      const homeworkStatusName = (status: string) => {
        return status === "inCourse"
          ? "En Curso"
          : status === "finished"
          ? "Finalizada"
          : status === "programed"
          ? "Programada"
          : "Sin Estado";
      };

      const finalHomeworks = dataToRender.map((homework) => {
        return {
          Estado: homeworkStatusName(homework.status),
          Tarea: homework.title,
          Completada:
            homework.status === "inCourse"
              ? "--"
              : homework.classroom_participation,
          Promedio:
            homework.status === "inCourse"
              ? "--"
              : homework.classroom_average_grades,
          Inicio: toLocalDate(homework.start_date),
          Fin: toLocalDate(homework.end_date),
        };
      });
      return finalHomeworks;
    } else if (dataToRender !== undefined && dataToRender.length > 0) {
      const filteredData = dataToRender.filter((el) => {
        return el.student_id !== null && typeof el.student_id === "number";
      });
      let result: any[] = [];
      filteredData.forEach((student) => {
        let columnsObject = {
          Alumno: `${student.student_name}`,
          Completas: student.completed_homeworks,
          Promedio: student.total_avg,
        };

        student.homeworks.forEach((homework: any) => {
          const title: any = homework.title + " " + homework.end_date;
          const homeResult = homework.result;

          Object.assign(columnsObject, {
            [title]:
              homeResult === undefined || homeResult === null
                ? "NO HECHO"
                : homeResult,
          });
        });
        result.push(columnsObject);
      });

      return result;
    }
    return [{}];
  };

  const ws = XLSX.utils.json_to_sheet(finalData());
  const myCsv = XLSX.utils.sheet_to_csv(ws);

  const download_csv = (content: any, fileName: any, mimeType: any) => {
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href =
        "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
    }
  };

  const exportFile = () => {
    const wscols = [
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
    ];

    XLSX.utils.sheet_add_aoa(ws, [], { origin: "D2" });
    ws["!cols"] = wscols;
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Data");

    XLSX.writeFileXLSX(
      wb,
      dataToRender[0].__typename === "Homework"
        ? `Reporte Tareas ${classRoom.name}.xlsx`
        : `Reporte Alumnos ${classRoom.name}.xlsx`
    );
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(255, 188, 65)",
          borderRadius: "11px",
          width: "160px",
          textAlign: "center",
        }}
        onClick={
          format === "EXCEL"
            ? exportFile
            : () =>
                download_csv(
                  myCsv,
                  `Reporte Tareas ${classRoom.name}.csv`,
                  "text/csv;encoding:utf-8"
                )
        }
      >
        Descargar
        {/* <img src={downloader} alt="downloader" /> */}
      </Button>
    </>
  );
};
