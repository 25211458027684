import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getCoursesBySubjectIdV2NEM,
  getCoursesBySubjectIdV2NEMVariables,
} from "../__generated__/gql-types/getCoursesBySubjectIdV2NEM";

const UseGetCoursesBySubjectIdV2NEM = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getCoursesBySubjectIdV2NEMVariables) => {
      const result: ApolloQueryResult<getCoursesBySubjectIdV2NEM> =
        await client.query({
          query: GET_COURSES_BY_SUBJECT_ID_V2_NEM,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getCoursesBySubjectIdV2NEM;
    },
    [client]
  );
};

export default UseGetCoursesBySubjectIdV2NEM;

const GET_COURSES_BY_SUBJECT_ID_V2_NEM = gql`
  query getCoursesBySubjectIdV2NEM(
    $getCoursesBySubjectIdInputV2Nem: GetCoursesBySubjectIdInputV2NEM
  ) {
    getCoursesBySubjectIdV2NEM(
      getCoursesBySubjectIdInputV2NEM: $getCoursesBySubjectIdInputV2Nem
    ) {
      id
      order_by
      block_id
      subject_id
      grade_id
      featured
      publish
      type
      title
      description
      initial_evaluation
      sep_book_pages
      week_reading
      sep_book_pages_reading
      nem
      month
      week
      count
    }
  }
`;
