import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getHomeworkById,
  getHomeworkByIdVariables,
} from "../__generated__/gql-types/getHomeworkById";

const UseGetHomeworkById = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getHomeworkByIdVariables) => {
      const result: ApolloQueryResult<getHomeworkById> = await client.query({
        query: GET_HOMEWORK_BY_ID,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getHomeworkById;
    },
    [client]
  );
};

export default UseGetHomeworkById;

const GET_HOMEWORK_BY_ID = gql`
  query getHomeworkById($homeworkId: ID!) {
    getHomeworkById(homeworkId: $homeworkId) {
      id
      status
      studentTries
      tries
      start_date
      end_date
      needs_file_evidence
      teacher_file_url
      classroom_participation
      classroom_average_grades
      description
      individualHomework
      title
      classroom {
        id
        name
        program {
          title
          description
        }
        group {
          users {
            id
            last_name
            first_name
          }
        }
      }
      users {
        first_name
        last_name
        id
      }
      lessons {
        id
        title
        sep_name
        description
        lesson_type
        subject {
          code
          id
        }
        lesson_content {
          redaction {
            id
            question
            rubrics {
              rubric
              order
            }
          }
          quizzes {
            id
            url
            title
            filename
            order
            created_at
            deleted_at
            questions {
              id
              question
              image
              image_aspect
              type_id
              order
              answers {
                id
                answer
                image
                image_aspect
                correct
                order
                question_id
                url
                created_at
                updated_at
                deleted_at
              }
              relationQuestion {
                id
                block1 {
                  image
                  text
                  coincidence
                  url
                }
                block2 {
                  image
                  text
                  coincidence
                  url
                }
                question_id
                created_at
                updated_at
                deleted_at
              }
              matchQuestion {
                id
              }
              dAndDQuestion {
                id
                blockItems {
                  image
                  text
                  coincidence
                  url
                }
                blockContainer {
                  image
                  text
                  coincidence
                  totalItems
                  url
                }
                question_id
                created_at
                updated_at
                deleted_at
              }
              url
              created_at
              updated_at
              deleted_at
            }
          }
          videos {
            metadata
            key_learnings
            microcontent
          }
        }
      }

      students_grades {
        student_id
        student_avatar
        student_name
        student_last_name
        delivered
        tries
        high_score
        studentEvidenceUrl
        status
      }
    }
  }
`;
