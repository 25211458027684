import React, { FC, useState } from "react";
import styles from "./listProject.module.scss";
import { ActiveFilterSearcher } from "../../Searcher/ActiveFilterSearcher";
import { Searchbar } from "../../Commons";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import deleteIcon from "../../../assets/icons/delete_trash.svg";
import { Progress, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { getTeacherProjectsByTeacherId_getTeacherProjectsByTeacherId } from "../../../__generated__/gql-types/getTeacherProjectsByTeacherId";
import { WithoutProject } from "./WithoutProject";
import dayjs from "dayjs";
import UseDeleteTeacherProjects from "../../../api/useDeleteTeacherProjects";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { setDependencyRefresh } from "../../../redux/slices/dependencyRefresh";
import ModalDeleteProject from "../../Shared/ModalDeleteProject/ModalDelete";

interface PropsListProject {
  setIsCreating: (e: boolean) => void;
  totalProject:
    | getTeacherProjectsByTeacherId_getTeacherProjectsByTeacherId[]
    | null;
  setGrade: (e: number[]) => void;
  grade: number[];
  searchInput: string;
  firstProject: boolean;
  setSearchInput: (e: string) => void;
}
const ListProject: FC<PropsListProject> = ({
  totalProject,
  setIsCreating,
  grade,
  firstProject,
  searchInput,
  setGrade,
  setSearchInput,
}) => {
  const deleteProject = UseDeleteTeacherProjects();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDrawerDelete, setOpenDrawerDelete] = useState(false);
  const [idProject, setIdProject] = useState<null | number>(null);
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);

  const columns: ColumnsType<getTeacherProjectsByTeacherId_getTeacherProjectsByTeacherId> =
    [
      {
        title: "GRADO",
        dataIndex: "grade",
        key: "grade",
        width: 60,
        align: "center",
        render: (grade) => {
          return <div className={styles.textGrade}>{grade}º</div>;
        },
      },
      {
        title: "TITULO DEL PROYECTO",
        dataIndex: "title",
        key: "title",
        render: (title) => {
          return <div className={styles.textTable}>{title}</div>;
        },
      },
      {
        title: "FECHA DE CREACION",
        dataIndex: "date",
        key: "date",
        width: 180,
        align: "center",
        render: (date, _) => {
          return (
            <div className={styles.textTable}>
              {dayjs(_.created_at).format("DD-MM-YYYY")}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "url",
        width: 180,
        align: "center",
        render: (url, _) => {
          return (
            <div className={styles.actionsContainer}>
              <div className={styles.seeProject}>
                {_.percent === 100 ? (
                  url ? (
                    <a href={url} target="_blank" rel="noreferrer">
                      Ver proyecto
                    </a>
                  ) : (
                    <div className={styles.containerCreating}>
                      <Spin />
                      <div>Creando</div>
                    </div>
                  )
                ) : (
                  <div className={styles.containerCreating}>
                    <Progress
                      strokeColor="#3843D0"
                      format={(number) => ""}
                      type="circle"
                      percent={_.percent && _.percent !== 0 ? _.percent : 10}
                      size={21}
                      strokeWidth={23}
                    />
                    <div>Creando</div>
                  </div>
                )}
              </div>
              <img
                src={deleteIcon}
                alt="crash"
                className={styles.imgDelete}
                onClick={() => {
                  _?.id && setIdProject(_.id);
                  setOpenDrawerDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ];

  const deleteProjectModal = async () => {
    await deleteProject({ teacherProjectId: idProject });
    dispatch(setDependencyRefresh(!dependencyRefresh));
    setOpenDrawerDelete(false);
  };
  const onCloseModalDelete = () => {
    setOpenDrawerDelete(false);
  };

  return !firstProject ? (
    <WithoutProject setIsCreating={setIsCreating} />
  ) : (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {openFilter && (
          <ActiveFilterSearcher
            setOpenFilter={setOpenFilter}
            setGrade={setGrade}
            grade={grade}
          />
        )}
        <div className={styles.listContainer}>
          <div className={styles.filtersContainer}>
            {!openFilter && (
              <div
                className={styles.items}
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                <img src={settingsIcon} alt="" className={styles.filterImg} />
                <div>Filtros</div>
              </div>
            )}
            <Searchbar
              setSearchInput={setSearchInput}
              searchInput={searchInput}
            />
            <div className={styles.button} onClick={() => setIsCreating(true)}>
              Crear proyecto
            </div>
          </div>
          <Table
            dataSource={totalProject?.reverse() ?? []}
            pagination={false}
            columns={columns}
            loading={false}
          />
        </div>
      </div>
      <ModalDeleteProject
        onCloseModalDelete={onCloseModalDelete}
        modalDelete={openDrawerDelete}
        deleteProjectModal={deleteProjectModal}
      ></ModalDeleteProject>
    </div>
  );
};

export default ListProject;
