import React from "react";
import { DraggableToken } from "./DraggableToken";
import { Droppable } from "react-beautiful-dnd";
import styles from "./fraseDrag.module.scss";
interface RowProps {
  rowItems: { id: string; title: string; content?: string; answers?: any[] };
}

const getListStyle = () => ({
  display: "flex",
  gap: "70px",
  marginTop: "13px",
  marginLeft: "15px",
  overflow: "none",
  width: "100%",
  height: "100%",
});

export const Row: React.FC<RowProps> = ({ rowItems }) => {
  return (
    <>
      <div className={styles.rowTitle}>{rowItems?.content}</div>
      <div className={styles.spaceDrag}>
        <Droppable droppableId={rowItems.id} type="TASK" direction="horizontal">
          {(provided: any, snapshot: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}
            >
              {rowItems.answers?.map((task, index) => (
                <DraggableToken
                  key={task.id}
                  id={task.id}
                  content={task.answerContent}
                  index={index}
                />
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
};
