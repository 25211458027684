import React, { FC } from "react";
import styles from "./progressHomework.module.scss";
import gemNegative from "../../../../../assets/icons/gems/gemNegative.svg";
import gemPositve from "../../../../../assets/icons/gems/gemPositive.svg";

interface PropsProgressHomework {
  resultHomework: number;
}
const ProgressHomework: FC<PropsProgressHomework> = ({ resultHomework }) => {
  const result =
    resultHomework === 15 ? 10 : resultHomework ? resultHomework : 0;

  const getItemStyle = (): React.CSSProperties => ({
    width: `${result * 10}%`,
  });

  return (
    <div className={styles.containerProgress}>
      <div className={styles.textPoints}> PUNTOS CONSEGUIDOS</div>

      <div className={styles.container}>
        {result !== 10 && (
          <div className={styles.totalgem}>
            <img src={gemNegative} alt="" />
            <div> 100</div>
          </div>
        )}
        <div style={getItemStyle()} className={styles.containerFull}>
          {result !== 1 && (
            <div className={styles.cantGemAlumn}>
              <img src={gemPositve} alt="" />
              <div> {result ? result * 10 : 0} </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressHomework;
