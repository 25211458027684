import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getClassroomLessonById,
  getClassroomLessonByIdVariables,
} from "../__generated__/gql-types/getClassroomLessonById";

const UseClassroomLessonById = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getClassroomLessonByIdVariables) => {
      const result: ApolloQueryResult<getClassroomLessonById> =
        await client.query({
          query: GET_CLASSROOMSLESSON_BY_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClassroomLessonById;
    },
    [client]
  );
};

export default UseClassroomLessonById;

const GET_CLASSROOMSLESSON_BY_ID = gql`
  query getClassroomLessonById($classroomLessonId: ID!) {
    getClassroomLessonById(classroomLessonId: $classroomLessonId) {
      start_date
      title
      classroom_id
      lesson {
        id
      }
    }
  }
`;
