import arrowBack from "../../../../assets/icons/arrow_back.svg";
import styles from "./mobilePasswordChanger.module.scss";
import { Input } from "antd";
import { Button } from "../../../Shared/Button";
import useUpdatePassword from "../../../../api/useUpdatePassword";
import { useEffect, useState } from "react";
import useAuth from "../../../../context/useAuth";
import {
  notificationNegative,
  NotificationPositive,
} from "../../../Shared/Notifacation/NotificationLuca";
import { useNavigate } from "react-router-dom";

const MobilePasswordChanger = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [prueba, setPrueba] = useState("");
  const [password, setPassword] = useState({
    passwordOld: "",
    passwordNew1: "",
    passwordNew2: "",
    error: true,
  });
  const [validError, setValidError] = useState({
    error: false,
    errorText: "",
  });

  const changePassword = useUpdatePassword();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  }

  function handleOffFocus(event: React.ChangeEvent<HTMLInputElement>) {
    if (password.passwordNew1 !== password.passwordNew2) {
      setValidError((prevState) => ({
        ...prevState,
        error: true,
      }));
    } else setValidError((prevState) => ({ ...prevState, error: false }));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const updateResponse = await changePassword({
        prevPassword: password.passwordOld,
        newPassword: password.passwordNew1,
      });

      if (updateResponse.data) {
        logout();
        NotificationPositive("¡Estupendo! La contraseña ha sido modificada.");
      }
    } catch (error) {
      notificationNegative();
    }
  };

  useEffect(() => {
    if (password.passwordNew1 === password.passwordNew2) {
      setPassword((prevState) => ({ ...prevState, error: false }));
    } else {
      setPassword((prevState) => ({ ...prevState, error: true }));
    }
  }, [password.passwordNew1, password.passwordNew2, password.error]);

  useEffect(() => {
    if (password.passwordNew1 === password.passwordNew2) {
      setValidError((prevState) => ({ ...prevState, errorText: "" }));
      setPrueba("");
    } else if (
      password.passwordNew1.length < 6 ||
      password.passwordNew2.length < 6
    ) {
      setValidError((prevState) => ({
        ...prevState,
        errorText:
          "La longitud de las contraseñas debe ser de al menos 6 caracteres.",
      }));
    } else {
      setValidError((prevState) => ({
        ...prevState,
        errorText: "Las contraseñas deben coincidir.",
      }));
    }
  }, [validError.error, password.passwordNew1, password.passwordNew2]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.arrowContainer} onClick={() => navigate(-1)}>
          <img src={arrowBack} alt="arrow" />
        </div>
        <div className={styles.titleHeaderContainer}>
          <h1 className={styles.titleHeader}>Cambiar contraseña</h1>
        </div>
      </div>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.bodyDrawer}>
            <h1> Establece tu contraseña</h1>
            <h4>
              Asegúrate que sea diferente a la anterior y puedas recordarla
              facilmente.
            </h4>

            <label className={styles.passwordLabel}>Contraseña actual</label>
            <Input.Password
              name="passwordOld"
              value={password.passwordOld}
              onChange={handleChange}
              placeholder="Por seguridad, introduce tu contraseña actual."
              className={styles.passwordInput}
              style={{ borderRadius: "11px" }}
            />
            <></>
            <label className={styles.passwordLabel}> Nueva contraseña</label>
            <Input.Password
              name="passwordNew1"
              value={password.passwordNew1}
              onChange={handleChange}
              placeholder="Escribe tu nueva contraseña."
              className={styles.passwordInput}
              style={{ borderRadius: "11px" }}
              onBlur={handleOffFocus}
            />

            <label className={styles.passwordLabel}>
              {" "}
              Confirmar contraseña
            </label>
            <Input.Password
              name="passwordNew2"
              value={password.passwordNew2}
              onChange={handleChange}
              placeholder="Escribe tu nueva contraseña"
              className={styles.passwordInput}
              style={{ borderRadius: "11px" }}
              onBlur={handleOffFocus}
            />
            <p style={{ color: "red" }}>{validError.errorText}</p>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type={"primary"}
              text={"guardar"}
              onClick={handleSubmit}
              disabled={password.error ?? false}
            />
            <Button
              type={"stroke"}
              text={"cancelar"}
              onClick={() => navigate(-1)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobilePasswordChanger;
