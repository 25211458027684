import React, { useEffect, useState } from "react";
import styles from "./flagContent.module.scss";
import whiteFlag from "../../../../../assets/icons/students/whiteFlag.svg";
import defaultImage from "../../../../../assets/images/defaultImage.svg";
import { getCoursesBySubjectId_getCoursesBySujectId_lessons } from "../../../../../__generated__/gql-types/getCoursesBySubjectId";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";

interface PropsFlagContent {
  data:
    | getCoursesBySubjectId_getCoursesBySujectId_lessons
    | getLessonById_getLessonById
    | null;
}
const FlagContent = ({ data }: PropsFlagContent) => {
  const [cont, setCont] = useState<number>(0);
  const [contVideos, setContVideos] = useState<number>(0);

  useEffect(() => {
    let contador = 0;
    data?.lesson_content?.videos?.forEach((videoCont) => {
      setContVideos(contador + videoCont.metadata?.duration);
    });
  }, [data]);

  useEffect(() => {
    let contador = 0;
    data?.lesson_content?.quizzes?.forEach((quizz) => {
      setCont(contador + quizz.questions!.length);
    });
  }, [data]);
  return (
    <div className={styles.video}>
      <div className={styles.foto}>
        <img
          src={
            data?.lesson_content?.videos &&
            data.lesson_content.videos.length &&
            data?.lesson_content?.videos[0].imageUrl
              ? data?.lesson_content?.videos[0].imageUrl
              : defaultImage
          }
          alt="default"
        />
      </div>

      <div className={styles.bandera}>
        {contVideos ? <div className={styles.line}></div> : <></>}
        <div className={styles.numerado}>
          <div className={styles.circleNumeradosFlag}>
            <img src={whiteFlag} alt="" />
          </div>

          <div className={styles.objective}>
            Objetivo {data?.subject?.name}
            <div className={styles.objectiveText}> {data?.title} </div>
          </div>
        </div>
        {contVideos ? (
          <>
            <div className={styles.numerado}>
              <div className={styles.circleNumerados}>1</div>
              <div className={styles.textNumerados}>Video</div>
              <div className={styles.contNumerados}>
                {`${Math.floor(contVideos / 60)
                  .toString()
                  .padStart(2, "0")} min ${Math.floor(contVideos % 60)
                  .toString()
                  .padStart(2, "0")} sec`}
              </div>
            </div>
            <div className={styles.line2}></div>

            <div className={styles.numerado}>
              <div className={styles.circleNumerados}>2</div>
              <div className={styles.textNumerados}> Quiz </div>
              <div className={styles.contNumerados}>
                {cont.toString().padStart(1, "0")} preguntas
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FlagContent;
