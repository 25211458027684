import React, { FC } from "react";
import styles from "./fraseDrag.module.scss";
import {
  getResponsesByHomework_getResponsesByHomework,
  getResponsesByHomework_getResponsesByHomework_student_ftbdad_responses,
} from "../../../../../__generated__/gql-types/getResponsesByHomework";
import Latex from "react-latex";

interface PropsFrase {
  index: number;
  question: getResponsesByHomework_getResponsesByHomework;
  subject_id?: number | null;
}
const FraseDrag: FC<PropsFrase> = ({ index, question, subject_id }) => {
  const orderedAnswers = question.answers?.sort((a, b) => a.order! - b.order!);
  const partes = question.question!.split("__");
  const answerCorrect = orderedAnswers!.filter((x) => x.correct === 1);
  const getColor = (
    answer: getResponsesByHomework_getResponsesByHomework_student_ftbdad_responses
  ) => {
    if (answer?.correct) {
      return answer.percent < 30
        ? "#D2EFB5"
        : answer.percent < 60
        ? "#B6DE8F"
        : "#8DCE4F";
    } else {
      return answer.percent < 30
        ? "#FFDADA"
        : answer.percent < 60
        ? "#FFADAD"
        : "#FF8283";
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {subject_id === 16
            ? "Fill in the spaces with the right word(s)"
            : "Coloca las fichas en su lugar correspondiente."}
        </div>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.textFill}>
          {partes![0]}&nbsp;
          <span
            style={{
              minWidth: "68px",
              minHeight: "38px",
              display: "inline-block",
              background: "#8DCE4F",
              textAlign: "center",
              lineHeight: "30px",
              padding: "2px 3px",
              borderRadius: "12px",
            }}
          >
            {answerCorrect[0] && answerCorrect[0]?.answer}
          </span>
          &nbsp;
          {partes[1]}
          &nbsp;
          {answerCorrect[1] && (
            <span
              style={{
                minWidth: "68px",
                minHeight: "38px",
                display: "inline-block",
                background: "#8DCE4F",
                textAlign: "center",
                lineHeight: "30px",
                padding: "2px 8px",
                borderRadius: "12px",
              }}
            >
              {answerCorrect[1] && answerCorrect[1].answer}
            </span>
          )}
          &nbsp;
          {partes[2]}
        </div>
        <div className={styles.textFill}>
          <div className={styles.responseContainer}>
            {question.student_ftbdad_responses?.map((response_container) => {
              return (
                <div className={styles.responseContent}>
                  {response_container.map((e) => {
                    return (
                      <div className={styles.containerAnswer}>
                        <div
                          className={
                            e.correct
                              ? styles.answerSelected
                              : styles.answerOutSelected
                          }
                          style={{ background: getColor(e) }}
                        >
                          {e.answer && <Latex>{e.answer!}</Latex>}
                        </div>
                        <div className={styles.containerGrey}>
                          {Math.round(e.percent)}%
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FraseDrag;
