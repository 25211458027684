import { Drawer } from "antd";
import styles from "./drawerFilter.module.scss";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import { ActiveFilters } from "../../ActiveFilters";

interface PropsFilter {
  setGrade: (value: number) => void;
  grade?: number;
  setBlock: (e: number) => void;
  block?: number;
  setSepBookPage: (value: string) => void;
  sepBookPagesList?: string[];
  sepBookPage?: string;
  setSelectedWeek: (value: string) => void;
  weekList?: string[];
  selectedWeek?: string;
  onClose: () => void;
  visible: boolean;
}
const DrawerFilter = ({
  onClose,
  visible,

  setSepBookPage,
  sepBookPagesList,
  sepBookPage,
  weekList,
  selectedWeek,
  setSelectedWeek,
  setGrade,
  grade,
  setBlock,
  block,
}: PropsFilter) => {
  const handleCleanAll = () => {
    setSepBookPage("");
    setSelectedWeek("");
    setGrade(0);
    setBlock(0);
  };
  return (
    <Drawer
      placement={"bottom"}
      width={500}
      onClose={onClose}
      open={visible}
      height={"500px"}
      closable={false}
      style={{
        background: "white !important",
      }}
      title={
        <div className={styles.header}>
          <div className={styles.titleHeader}>
            <h1>Filtros</h1>
          </div>

          <img src={closeIcon} alt="close icon" onClick={onClose} />
        </div>
      }
      footer={
        <div className={styles.footer}>
          <div className={styles.alumnoButton} onClick={handleCleanAll}>
            Restaurar Filtros
          </div>
          <div className={styles.taskButton} onClick={onClose}>
            Aplicar filtros
          </div>
        </div>
      }
    >
      <ActiveFilters
        sepBookPage={sepBookPage}
        sepBookPagesList={sepBookPagesList}
        setSepBookPage={setSepBookPage}
        setGrade={setGrade}
        grade={grade}
        setBlock={setBlock}
        block={block}
        weekList={weekList}
        setSelectedWeek={setSelectedWeek}
        selectedWeek={selectedWeek}
      />
    </Drawer>
  );
};

export default DrawerFilter;
