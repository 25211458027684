import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getHomeworkTriesForFinishedHomeworkAction,
  getHomeworkTriesForFinishedHomeworkActionVariables,
} from "../__generated__/gql-types/getHomeworkTriesForFinishedHomeworkAction";

const useGetHomeworkTriesForFinishedHomeworkAction = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getHomeworkTriesForFinishedHomeworkActionVariables) => {
      const result: ApolloQueryResult<getHomeworkTriesForFinishedHomeworkAction> =
        await client.query({
          query: GET_HOMEWORK_BY_ID_TRY_AGAIN,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getHomeworkTriesForFinishedHomeworkAction
        ?.studentTries;
    },
    [client]
  );
};

export default useGetHomeworkTriesForFinishedHomeworkAction;

const GET_HOMEWORK_BY_ID_TRY_AGAIN = gql`
  query getHomeworkTriesForFinishedHomeworkAction($homeworkId: ID!) {
    getHomeworkTriesForFinishedHomeworkAction(homeworkId: $homeworkId) {
      studentTries
    }
  }
`;
