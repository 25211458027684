import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getGradeLeaderBoards } from "../__generated__/gql-types/getGradeLeaderBoards";

const UseGetLeaderBoard = () => {
  const client = useApolloClient();

  return useCallback(
    async () => {
      const result: ApolloQueryResult<getGradeLeaderBoards> = await client.query({
        query: GET_LEADER_BOARD,
        fetchPolicy: "no-cache",
       
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getGradeLeaderBoards;
    },
    [client]
  );
};

export default UseGetLeaderBoard;

const GET_LEADER_BOARD = gql`
query getGradeLeaderBoards {
  getGradeLeaderBoards {
    scores
    grade
    group_id
    updated_at
    id
    deleted_at
    created_at
    last_reset_at
  }
}

`;
