import { FC } from "react";
import arrowIcon from "../../../assets/icons/arrowIcon.svg";
import styles from "./cardPoints.module.scss";
import { getClassroomsByTeacher_getClassroomsByTeacher } from "../../../__generated__/gql-types/getClassroomsByTeacher";
import { useNavigate } from "react-router-dom";

interface CardProps {
  classRoomsIndividual: getClassroomsByTeacher_getClassroomsByTeacher;
  key: string;
}
const CardPoints: FC<CardProps> = ({ classRoomsIndividual, key }) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    navigate(`/points/${classRoomsIndividual.id}`, {
      state: { classRoomsIndividual },
    });
  };
  console.log({ classRoomsIndividual });
  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        handleClickCard();
      }}
    >
      <div className={styles.firstContainer}>
        <div className={styles.containerQuad}>
          <div className={styles.quads}>
            {classRoomsIndividual.program?.grade?.grade}°
            {classRoomsIndividual.group?.name}
          </div>
        </div>
        <div className={styles.cardHeaderContainer}>
          <div className={styles.cardTitleContainer}>
            <p>
              {classRoomsIndividual?.program?.grade?.grade}° {classRoomsIndividual?.group?.name} {classRoomsIndividual?.school?.name}
            </p>
          </div>
          <div className={styles.cardSubtitleContainer}>
            <p>{classRoomsIndividual?.group?.users?.length ?? 0} Alumnos</p>
          </div>
        </div>
        <div className={styles.arrowContainer}>
          <img src={arrowIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CardPoints;
