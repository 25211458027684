import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useUserOpenLuca = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: USER_OPEN_LUCA,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useUserOpenLuca;

const USER_OPEN_LUCA = gql`
  mutation userOpenLucaActionEvent {
    userOpenLucaActionEvent
  }
`;