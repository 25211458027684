import { useEffect, useState } from "react";
import styles from "./card.module.scss";
import { Button } from "../../../Shared/Button";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import video1 from "../../../../assets/icons/videoMisionPending.svg";
import blueDiamond from "../../../../assets/icons/students/blueDiamond.svg";
import grayDiamond from "../../../../assets/icons/students/grayDiamond.svg";
import {
  getCoursesBySubjectId_getCoursesBySujectId_lessons,
  getCoursesBySubjectId_getCoursesBySujectId_lessons_lesson_content_videos,
} from "../../../../__generated__/gql-types/getCoursesBySubjectId";
import useIsMobile from "../../../../hooks/useIsMobile";

interface CardProps {
  element: getCoursesBySubjectId_getCoursesBySujectId_lessons;
  showDrawerLesson: (
    e: getCoursesBySubjectId_getCoursesBySujectId_lessons
  ) => void;
}

const Card = ({ element, showDrawerLesson }: CardProps) => {
  const [itemImage, setItemImage] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const functionReturnSubject = () => {
    if (element.subject?.id && element?.subject?.id === "1") {
      return "Matemáticas";
    }
    if (element.subject?.id === "2") {
      return "Español";
    }
    if (element.subject?.id === "4") {
      return "Ciencias";
    }
  };
  useEffect(() => {
    let image: getCoursesBySubjectId_getCoursesBySujectId_lessons_lesson_content_videos[] =
      [];
    element?.lesson_content?.videos?.forEach((e) => {
      image.push(e);
    });
    image.length && setItemImage(image[0]?.imageUrl ?? null);
  }, [element.lesson_content?.videos]);

  const handleDrawerLesson = () => {
    showDrawerLesson(element);
  };

  return !isMobile ? (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img
          src={
            element.lesson_content?.videos?.length &&
            element.lesson_content?.videos[0]?.imageUrl
              ? element.lesson_content?.videos[0].imageUrl
              : defaultImage
          }
          alt="default"
          className={styles.imagenCard}
          style={{ opacity: element.finished ? "0.4" : "1" }}
        />
        <div className={styles.subjectButton}>{functionReturnSubject()}</div>

        <div className={styles.contentButton}>
          <img src={video1} alt="asd" /> <span>Video Lección</span>
        </div>
      </div>
      <div className={styles.content}>
        <h1>{element?.title}</h1>
        <div className={styles.buttonsContainer}>
          {!element?.finished ? (
            <Button
              type="primary-large"
              text="VER MISIÓN"
              onClick={handleDrawerLesson}
            />
          ) : (
            <Button
              type="stroke-large"
              text="REPETIR"
              onClick={handleDrawerLesson}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.mobileContainer}>
      <div className={styles.imageContainer}>
        <div className={styles.imageSubContainer}>
          <img
            src={itemImage ?? defaultImage}
            style={{ opacity: element.finished ? "0.4" : "1" }}
            alt=""
          />
        </div>
      </div>
      <div className={styles.content}>
        <h1>{element?.title}</h1>
        <div className={styles.buttonsContainer}>
          {!element?.finished ? (
            <>
              <div className={styles.pointsButton}>
                <img src={grayDiamond} alt="gray-diamond" /> -
              </div>
              <Button
                type="primary"
                text="VER MISIÓN"
                onClick={handleDrawerLesson}
              />
              <></>
            </>
          ) : (
            <>
              <div className={styles.pointsButton}>
                <img src={blueDiamond} alt="blue-diamond" />
                {element.result}
              </div>
              <Button
                type="stroke"
                text="REPETIR"
                onClick={handleDrawerLesson}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
