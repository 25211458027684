export interface QuestionProps {
  id: string;
  content?: string;
  title: string;
  answers?: { answerContent: string; id: string; order: number }[];
}

interface TaskLayout {
  rows: QuestionProps[];
}

export const initialData: TaskLayout = {
  rows: [
    {
      id: "column-1",
      title: "Source",
      content: "Ordena de mayor a menor la tabla del 7",
      answers: [
        { answerContent: "7x4", id: "42", order: 4 },
        { answerContent: "7x2", id: "25", order: 2 },
        { answerContent: "7x3", id: "39", order: 3 },
        { answerContent: "7X1", id: "18", order: 1 },
        
      ],
    },
    {
      id: "column-2",
      title: "Destination",
      answers: [],
    },
  ],
};
