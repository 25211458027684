import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getRedactionCorrection,
  getRedactionCorrectionVariables,
} from "../__generated__/gql-types/getRedactionCorrection";

const UseGetRedactionCorrection = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getRedactionCorrectionVariables) => {
      const result: ApolloQueryResult<getRedactionCorrection> =
        await client.query({
          query: GET_REDACTION_FOR_TEACHER_CORRECTION,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getRedactionCorrection;
    },
    [client]
  );
};

export default UseGetRedactionCorrection;

const GET_REDACTION_FOR_TEACHER_CORRECTION = gql`
  query getRedactionCorrection($teacherModalInfoInput: teacherModalInfoInput) {
    getRedactionCorrection(teacherModalInfoInput: $teacherModalInfoInput) {
      student_name
      response_id
      rubrics {
        rubric
        order
        calification
      }
      redaction_response
      note
      redaction_comments
      redaction_gpt_comments
      redaction_teacher_response_correction
      redaction_gpt_response_correction
    }
  }
`;
