import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getClassroomWithHomeworks,
  getClassroomWithHomeworksVariables,
} from "../__generated__/gql-types/getClassroomWithHomeworks";

const UseClassroomWithHomeworks = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getClassroomWithHomeworksVariables) => {
      const result: ApolloQueryResult<getClassroomWithHomeworks> =
        await client.query({
          query: GET_CLASSES,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClassroomWithHomeworks;
    },
    [client]
  );
};

export default UseClassroomWithHomeworks;

const GET_CLASSES = gql`
  query getClassroomWithHomeworks($classroomId: ID!) {
    getClassroomWithHomeworks(classroomId: $classroomId) {
      classroom {
        name
        id
        school {
          id
          name
        }
        group {
          id
          users {
            id
            first_name
            last_name
            avatar
            email
            score {
              id
              main_score
              level
            }
          }
        }
      }
      homeworks {
        id
        title
        description
        status
        start_date
        tries
        end_date
        deleted_at
        created_at
        utc_start_date
        utc_end_date
        utc_filter_date
        lessons {
          id
          sep_name
          lesson_type
        }
        average
        participation
      }
      total_participation
      total_average
    }
  }
`;
