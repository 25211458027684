import React, { useMemo } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { apiGraphQLUrl } from "../../utils/constants";
import useAuth from "../../context/useAuth";

interface GraphqlProviderProp {
  children: React.ReactNode;
}

const GraphQLProvider: React.FC<GraphqlProviderProp> = ({ children }) => {
  const { token } = useAuth();

  const client = useMemo(() => {
    return new ApolloClient({
      uri: `${apiGraphQLUrl}`,
      cache: new InMemoryCache(),
      headers: {
        token: token,
      },
    });
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
