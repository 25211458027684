import styles from "./bannerNoHomework.module.scss";
import robotLuca from "../../../../assets/pages/classes/robotLuca.png";

const BannerNoHomeworkInCourse = () => {
  return (
    <div className={styles.container}>
      <div className={styles.firstColumn}>
        <img src={robotLuca} alt="" />
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.title}>¡Qué triste! </div>
        <div className={styles.description}>
          Ninguna de tus clases tiene tareas o cursos planeados. ¡Ayuda a tus
          estudiantes a aprender y divertirse asignando tareas!
        </div>
      </div>
    </div>
  );
};

export default BannerNoHomeworkInCourse;
