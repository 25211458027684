import { useEffect, useState } from "react";
import styles from "./banner.module.scss";
import useAuth from "../../../context/useAuth";
import { BannerList } from "./BannerList";
import { BannerNoHomework } from "./BannerNoHomework";
import UseClassroomWithHomeworksByDashboard from "../../../api/useGetClassroomWithHomeworksByDashboard";
import {
  getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard as PropsClassroom,
  getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks,
} from "../../../__generated__/gql-types/getClassroomWithHomeworksByDashboard";
import { ConfigProvider, Skeleton, Switch } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { BannerListInCourse } from "./BannerListInCourse";
import { BannerNoHomeworkInCourse } from "./BannerNoHomeworkInCourse";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const getClassroomWithHomeworksByDashboard =
    UseClassroomWithHomeworksByDashboard();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const { userToken } = useAuth();
  const [statusTask, setStatusTask] = useState<"finished" | "inCourse">(
    "finished"
  );
  const [classRoom, setClassRoom] = useState<PropsClassroom | null>(null);
  const [homeworkFinish, setHomeworkFinish] = useState<
    | (getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks | null)[]
    | null
  >(null);
  const [homeworkInCourse, setHomeworkInCourse] = useState<
    | (getClassroomWithHomeworksByDashboard_getClassroomWithHomeworksByDashboard_homeworks | null)[]
    | null
  >(null);

  const handleChange = (e: boolean) => {
    e ? setStatusTask("finished") : setStatusTask("inCourse");
  };

  let orderHomeworks = (homeworks: any) => {
    homeworks.forEach((hom: any) => {
      if (typeof hom.end_date === "string") {
        let day = hom?.end_date?.slice(0, 2);
        let month = hom?.end_date?.slice(3, 5);
        let year = hom?.end_date?.slice(6, 10);
        let hour = hom?.end_date?.slice(10, 16);

        let newEndDate = new Date(`${month}-${day}-${year} ${hour}`);

        hom["new_end_date"] = newEndDate;
      }
    });
    return homeworks.sort(
      (a: { new_end_date: number }, b: { new_end_date: number }) =>
        a.new_end_date > b.new_end_date
          ? 1
          : a.new_end_date < b.new_end_date
          ? -1
          : 0
    );
  };

  useEffect(() => {
    (async () => {
      const classRoomLessons = await getClassroomWithHomeworksByDashboard();
      setClassRoom(classRoomLessons);
      let homeworkInCourse = classRoomLessons?.homeworks?.filter(
        (hw) => hw?.status !== "finished"
      );
      let homeworkFinished = classRoomLessons?.homeworks?.filter(
        (hw) => hw?.status === "finished"
      );
      homeworkInCourse && setHomeworkInCourse(homeworkInCourse);
      homeworkFinished && setHomeworkFinish(homeworkFinished);
    })();
  }, [getClassroomWithHomeworksByDashboard, dependencyRefresh]);

  return (
    <div className={styles.container}>
      {!classRoom ? (
        <div className={styles.containerSkeleton}>
          <Skeleton active paragraph={{ rows: 7 }} />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <h1 className={styles.title}>¡Hola {userToken?.first_name}!</h1>
            {classRoom?.homeworks?.length &&
            classRoom?.homeworks?.length > 0 ? (
              <h3 className={styles.subTitle}>
                {classRoom?.homeworks?.length &&
                classRoom?.homeworks?.length > 0 &&
                statusTask === "finished"
                  ? homeworkFinish?.length === 1
                    ? `Tienes 1 tarea finalizada sin revisar.`
                    : `Tienes ${homeworkFinish?.length} tareas finalizadas sin revisar.`
                  : homeworkInCourse?.length === 1
                  ? `Tienes 1 tarea en curso o planeada.`
                  : `Tienes ${homeworkInCourse?.length} tareas en curso o planeadas.`}
              </h3>
            ) : (
              <></>
            )}

            <div className={styles.finishedTask}>
              <div className={styles.textFinishedTask}>
                {statusTask === "inCourse"
                  ? " Tareas no finalizadas"
                  : "Tareas finalizadas"}
              </div>
              <ConfigProvider
                theme={{
                  components: {
                    Switch: {
                      handleSize: 16,
                      trackHeight: 20,
                      trackMinWidth: 38,
                      colorPrimary: "#3843D0",
                    },
                  },
                }}
              >
                <Switch
                  value={statusTask === "finished" ? true : false}
                  size="default"
                  onChange={handleChange}
                />
              </ConfigProvider>
            </div>
          </div>
          {statusTask === "finished" ? (
            homeworkFinish && homeworkFinish?.length > 0 ? (
              <BannerList homeworks={homeworkFinish} />
            ) : (
              <BannerNoHomework />
            )
          ) : homeworkInCourse && homeworkInCourse?.length > 0 ? (
            <BannerListInCourse homeworks={orderHomeworks(homeworkInCourse)} />
          ) : (
            <BannerNoHomeworkInCourse />
          )}
        </>
      )}
      {statusTask === "inCourse" && (
        <div className={styles.containerRedirect}>
          <div
            className={styles.redirectCalendar}
            onClick={() => navigate("/calendar")}
          >
            Ver calendario{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
