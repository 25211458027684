import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/icons/logo_blue.svg";

import styles from "./backgroundLogin.module.scss";

interface PropsBackground {
  children: React.ReactNode;
}
const BackgroundLogin = ({ children }: PropsBackground) => {
  const navigate = useNavigate();
  return (
    <div className={styles.backgroundLuca}>
      <img
        src={logo}
        alt=""
        className={styles.logo}
        onClick={() => {
          return navigate("/");
        }}
      />
      <div className={styles.container}>{children}</div>
      <div className={styles.footer}>
        <strong>¿Necesitas ayuda?</strong>Mándanos un correo a
        <a href="mailto:soporte@lucaedu.com">
          <span>soporte@lucaedu.com </span>
        </a>
        o escríbenos por Whatsapp al
        <a
          className={styles.helpSubTitleBlue}
          href="https://wa.me/525541691994"
        >
          +52 55 4169 1994
        </a>
      </div>
    </div>
  );
};

export default BackgroundLogin;
