import styles from "./bannerClasification.module.scss";
import moon from "../../../../assets/images/students/banner/moonBanner.svg";
import medalFirst from "../../../../assets/icons/clasification/medalFirst.svg";
import medalThired from "../../../../assets/icons/clasification/medalThired.svg";
import medalSecond from "../../../../assets/icons/clasification/medalSecond.svg";
import rocket from "../../../../assets/images/students/banner/rocketBanner.svg";
import { PropsBanner } from "../utils";
import { useEffect, useState } from "react";
import { getMonthName, resolveAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";

const BannerClasification = ({
  tablaScore,
  dateLeader,
  daysLeft,
}: PropsBanner) => {
  const [dia, setDia] = useState(0);
  const [diaMore, setDiaMore] = useState(0);
  const [finishMonth, setFinishMonth] = useState<any>();
  const firstAlumn =
    tablaScore && tablaScore?.scores?.find((e) => e.position === 1);
  const secondAlumn =
    tablaScore && tablaScore?.scores?.find((e) => e.position === 2);
  const thirdAlumn =
    tablaScore && tablaScore?.scores?.find((e) => e.position === 3);

  useEffect(() => {
    if (dateLeader) {
      setDia(new Date(Number(dateLeader)).getDate());
      const dateLeaderBecome = new Date(Number(dateLeader));
      dateLeaderBecome.setDate(dateLeaderBecome.getDate() + 14);
      setDiaMore(dateLeaderBecome.getDate());
      setFinishMonth(dateLeaderBecome);
    }
  }, [dateLeader]);

  return (
    <div className={styles.container}>
      {tablaScore && (
        <>
          <div className={styles.containerDate}>
            <div className={styles.badgeDate}>
              Concurso quincenal | {dia} al {diaMore} de{" "}
              {getMonthName(
                new Date(Number(finishMonth ?? "")).getMonth()
              ).toLowerCase()}{" "}
              | quedan {daysLeft} días
            </div>
          </div>
          <div className={styles.containerCard}>
            <div className={styles.cardAvatar}>
              <div className={styles.medalContainer}>
                <img src={medalFirst} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {firstAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    firstAlumn?.student_level && firstAlumn?.student_level < 6
                      ? resolveAvatar(
                          firstAlumn?.student_avatar ?? "polux",
                          firstAlumn?.student_level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(
                          firstAlumn?.student_avatar ?? ""
                        )
                  }
                  alt="avatar"
                  className={
                    firstAlumn?.student_level && firstAlumn?.student_level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {firstAlumn && firstAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {firstAlumn && firstAlumn.score} puntos
                </div>
              </div>
            </div>
            <div className={styles.cardAvatar}>
              <div className={styles.medalContainer}>
                <img src={medalSecond} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {secondAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    secondAlumn?.student_level && secondAlumn?.student_level < 6
                      ? resolveAvatar(
                          secondAlumn?.student_avatar ?? "polux",
                          secondAlumn?.student_level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(
                          secondAlumn?.student_avatar ?? ""
                        )
                  }
                  alt="avatar"
                  className={
                    secondAlumn?.student_level && secondAlumn?.student_level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {secondAlumn && secondAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {secondAlumn && secondAlumn.score} puntos
                </div>
              </div>
            </div>
            <div className={styles.cardAvatar}>
              <div className={styles.medalContainer}>
                <img src={medalThired} alt="medal" />
              </div>
              <div className={styles.avatarContainer}>
                {thirdAlumn?.shopActions?.map((clothes: any) => (
                  <img
                    src={clothes.item_img ?? ""}
                    alt="avatar"
                    style={{
                      zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                    }}
                    className={styles.clothes}
                  />
                ))}
                <img
                  src={
                    thirdAlumn?.student_level && thirdAlumn?.student_level < 6
                      ? resolveAvatar(
                          thirdAlumn?.student_avatar ?? "polux",
                          thirdAlumn?.student_level.toString() ?? "1"
                        )
                      : functionReturnImgAvatar(
                          thirdAlumn?.student_avatar ?? ""
                        )
                  }
                  alt="avatar"
                  className={
                    thirdAlumn?.student_level && thirdAlumn?.student_level < 6
                      ? styles.avatarImgwithOutLevel // revisar
                      : styles.avatarImg
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textCard}>
                  {thirdAlumn && thirdAlumn.name}
                </div>
                <div className={styles.textPoints}>
                  {thirdAlumn && thirdAlumn.score} puntos
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <img
        alt="space"
        src={rocket}
        className={styles.imageRocket}
        width={"90%"}
      />
      <img alt="space" src={moon} className={styles.imageMoon} />
    </div>
  );
};

export default BannerClasification;
