import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./inputChat.module.scss";
import sendImg from "../../../../../../../assets/pages/quiz/sendImg.png";
import sendImgGrey from "../../../../../../../assets/pages/quiz/sendImgGrey.png";
interface InputChat {
  isTyping: boolean;
  isFinished: boolean;
  handleSendAlumn: (userMessage: string) => Promise<void>;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
const InputChat: FC<InputChat> = ({
  isTyping,
  isFinished,
  handleSendAlumn,
}) => {
  const [answerInput, setAnswerInput] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("46px");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const manejarKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && answerInput) {
      setAnswerInput("");
      setTextareaHeight(`46px`);
      await handleSendAlumn(answerInput);
      setAnswerInput("");
    }
  };

  useEffect(() => {
    setTextareaHeight("auto");
    if (textareaRef.current) {
      setTextareaHeight(
        `${
          textareaRef.current.scrollHeight < 56
            ? 46
            : textareaRef.current.scrollHeight
        }px`
      );
    }
    if (!answerInput) {
      setTextareaHeight(`46px`);
    }
  }, [answerInput]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isTyping]);
  return (
    <div className={styles.containerInput}>
      <textarea
        ref={textareaRef}
        style={{ height: textareaHeight }}
        value={answerInput}
        disabled={isTyping}
        className={isFinished ? styles.inputAIGrey : styles.inputAI}
        onKeyDown={(event) => {
          !isFinished && manejarKeyDown(event);
        }}
        onChange={(e) => {
          !isFinished && setAnswerInput(e.target.value);
        }}
      />
      {isFinished ? (
        <img
          src={sendImgGrey}
          alt="send"
          className={styles.imgAI}
          onClick={async () => {
            setAnswerInput("");
            !isFinished && (await handleSendAlumn(answerInput));
          }}
        />
      ) : (
        <img
          src={sendImg}
          alt="send"
          className={styles.imgAI}
          onClick={async () => {
            setAnswerInput("");
            !isFinished && (await handleSendAlumn(answerInput));
          }}
        />
      )}
    </div>
  );
};

export default InputChat;
