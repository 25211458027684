import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./changePassword.module.scss";
import candado from "../../../assets/icons/candado.svg";
import checkPassword from "../../../assets/icons/checkPassword.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiLuca } from "../../../utils/constants";
import { notificationNegative } from "../../Shared/Notifacation/NotificationLuca";
interface Values {
  password: String;
  changepassword: String;
}

const Schema = Yup.object().shape({
  password: Yup.string()
    .required("Este campo es requerido")
    .min(6, "La contraseña debe tener minimo 6 caracteres"),
  changepassword: Yup.string()
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Ambas contraseña deben ser iguales"
      ),
    })
    .required("Este campo es requerido")
    .min(6, "La contraseña debe tener minimo 6 caracteres"),
});

const ChangePassword = () => {
  let { id, token } = useParams();
  const navigate = useNavigate();
  const [resultCall, setResultCall] = useState<boolean>();

  const handleSubmit = async (values: Values) => {
    try {
      await axios.post(`${apiLuca}/resetPassword/${id}/${token}`, {
        password: values.password,
      });
      setResultCall(true);
    } catch (error) {
      notificationNegative(
        "¡Ups! el token a expirado. Por favor, vuelve a intentarlo."
      );
    }
  };
  return resultCall ? (
    <>
      <img src={checkPassword} alt="" />
      <div className={styles.titleHeader}>¡Nueva contraseña guardada!</div>

      <div
        className={styles.botonReturnLogin}
        onClick={() => {
          return navigate("/");
        }}
      >
        Iniciar sesión en Luca
      </div>
    </>
  ) : (
    <>
      <img src={candado} alt="" className={styles.imgContainer} />
      <div className={styles.titleHeader}>Introduce tu nueva contraseña</div>
      <div className={styles.subTitleHeader}>
        Asegúrate que sea diferente a la anterior.
      </div>
      <div className={styles.containerTitleInput}>
        <Formik
          initialValues={{
            password: "",
            changepassword: "",
          }}
          validationSchema={Schema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
            <Form
              className={styles.formSpaces}
              onSubmit={handleSubmit}
              placeholder=""
            >
              <div className={styles.containerLabel}>
                <div className={styles.titleInput}>Contraseña</div>
              </div>
              <input
                className={styles.formFields}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
              />

              <span style={{ color: "red" }}>{errors.password}</span>
              <div className={styles.containerLabel}>
                <div className={styles.titleInput}>Confirmar contraseña</div>
              </div>
              <input
                name="changepassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.changepassword}
                type="password"
                className={styles.formFields}
              />

              <span style={{ color: "red" }}>{errors.changepassword}</span>

              <button type="submit" className={styles.botonForm}>
                Guardar contraseña
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;
