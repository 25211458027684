import { FC} from "react"
import styles from "./feedback.module.scss"
import { useNavigate } from "react-router-dom"
import diamond from "../../../../assets/icons/students/diamonds/mathDiamonds/mathDiamond-S.svg"
import feedback_failed from "../../../../assets/icons/students/challenges/asteroids/feedback_failed.svg"
import feedback_approved from "../../../../assets/icons/students/challenges/asteroids/feedback_approved.svg"
import feedback_approved_all from "../../../../assets/icons/students/challenges/asteroids/feedback_approved_all.svg"
import close from "../../../../assets/icons/students/closeMobile.svg";
interface Props {
  points: number
}

const Feedback: FC<Props> = ({ points }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <img src={close} alt="exit" className={styles.close} onClick={()=>{navigate(-1)}}/>
      <div className={styles.content}>


        {points <= 5 && (
          <>
            <img src={feedback_failed} alt="feedback_failed" />
            <h1>Desafío finalizado</h1>
            <h2>Un asteroide ha chocado contra tu nave... Deberás completar <span> 5 misiones </span>  para repararla antes probar un nuevo desafío</h2>
          </>
        )}
        {points >= 6 && points <= 9 && <>
          <img src={feedback_approved} alt="feedback_failed" />
          <h1>¡Desafío superado!</h1>
          <h2>Tu nave ha resultado ligeramente dañada, necesitarás completar<span> 3 misiones </span>  para repararla antes de comenzar otro desafío</h2>
        </>}
        {points >= 10 && <>
          <img src={feedback_approved_all} alt="feedback_best" />
          <h1>¡Máxima puntuación!</h1>
          <h2>Enhorabuena, tu nave está intacta. <br /> Reune fuerzas durante <span> 1 misión </span>  antes de comenzar un nuevo desafío</h2>
        </>}


        <div className={styles.reward}> <p>Conseguiste </p> <img src={diamond} alt="diamond" />  <h3> +{points} </h3></div>
      </div>
      <div className={styles.buttons} onClick={() => navigate("/extrapoints")}>
        COMENZAR NUEVA MISIÓN
      </div>
    </div>
  )
}

export default Feedback;