import React, { FC } from "react";
import styles from "./notCourse.module.scss";
import lucaBot from "../../../../../assets/pages/newProject/bot.svg";
interface PropsNotCourse {
  searchInput: string;
}
const NotCourse: FC<PropsNotCourse> = ({ searchInput }) => {
  return searchInput.length < 1 ? (
    <div className={styles.container}>
      <img src={lucaBot} alt="bot" />
      <div className={styles.text}>
        Utiliza el buscador para encontrar los temas que quieres incluir en tu
        proyecto.
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <img src={lucaBot} alt="bot" />
      <div className={styles.text}>
        Tú búsqueda no arroja resultados en nuestro programa. ¡Inténtalo de
        nuevo con una búsqueda diferente!
      </div>
    </div>
  );
};

export default NotCourse;
