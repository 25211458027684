import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { ClassroomLessonInput } from "../__generated__/gql-types/globalTypes";

const UseCreateClassroomLesson = () => {
  const client = useApolloClient();

  return useCallback(
    async (classroomLessonInput: ClassroomLessonInput) => {
      return await client.mutate({
        mutation: CREATE_HOMEWORK,
        fetchPolicy: "no-cache",
        variables: { classroomLessonInput },
      });
    },
    [client]
  );
};
export default UseCreateClassroomLesson;

const CREATE_HOMEWORK = gql`
  mutation createClassroomLesson($classroomLessonInput: ClassroomLessonInput) {
    createClassroomLesson(classroomLessonInput: $classroomLessonInput) {
      id
      start_date
      classroom_id
      created_at
      type
      title
    }
  }
`;
