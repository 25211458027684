import React, { FC } from "react";
import styles from "./questions.module.scss";
import Latex from "react-latex";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsQuestions {
  index: number;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
}
const Questions: FC<PropsQuestions> = ({ index, question }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {question.question && <Latex>{question.question}</Latex>}
        </div>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="quiz"
            />
          </div>
        )}
        {question.paragraph && (
          <div className={styles.containerParagraph}>{question.paragraph}</div>
        )}
        <div className={styles.textFill}>
          {question.answers?.map((e) => {
            return (
              <div className={styles.containerAnswer}>
                <div
                  className={
                    e.selected && e.correct
                      ? styles.answerSelected
                      : e.selected && !e.correct
                      ? styles.answerSelectedWrong
                      : styles.answerOutSelected
                  }
                >
                  {e.answer ? (
                    <Latex>{e.answer!}</Latex>
                  ) : (
                    <img src={e.image ?? ""} alt="" width={"90px"} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Questions;
