import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { ResponsesInput } from "../__generated__/gql-types/globalTypes";

const UseCreateResponse = () => {
  const client = useApolloClient();

  return useCallback(
    async (responsesInput: ResponsesInput) => {
      return await client.mutate({
        mutation: CREATE_RESPONSE,
        fetchPolicy: "no-cache",
        variables: { responsesInput },
      });
    },
    [client]
  );
};
export default UseCreateResponse;

const CREATE_RESPONSE = gql`
  mutation createResponse($responsesInput: ResponsesInput) {
    createResponse(responsesInput: $responsesInput) {
      id
      homework_id
      lesson_id
      user_id
      quiz_id
      question_id
      question_type
      note
      answers_responses
    }
  }
`;
