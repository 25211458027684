import React, { useEffect, useState } from "react";
import styles from "./mainLesson.module.scss";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { Checkbox, Tabs } from "antd";
import ReactPlayer from "react-player";
import { PDFViewer } from "react-view-pdf/lib";
import { environment } from "../../../../configuration/environment";
import { ActivityIndicator } from "../../../ActivityIndicator/ActivityIndicator";
import { useLocation, useSearchParams } from "react-router-dom";
import useTeacherClickSeeVideoLesson from "../../../../api/useTeacherClickSeeVideoLesson";
import useTeacherClickSeeVideoLessonNotNEM from "../../../../api/useTeacherClickSeeVideoLessonNotNEM";
import useTeacherInteractWithContent from "../../../../api/useTeacherInteractWithContent";
import useAuth from "../../../../context/useAuth";
import useStudentActionLibrary from "../../../../api/useStudentActionsLibrary";
import { TabDetails } from "./TabDetails";
import { TabQuiz } from "./TabQuiz";
import { TabConversation } from "./TabConversation";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TabLiteracy } from "./TabLiteracy";

interface LessonProps {
  individualLesson: getLessonById_getLessonById | null;
  back: string;
  selectPanel: string;
  playerRef: React.MutableRefObject<ReactPlayer | null>;
  setSelectPanel: (p: string) => void;
}

const MainLesson = ({
  individualLesson,
  back,
  playerRef,
  selectPanel,
  setSelectPanel,
}: LessonProps) => {
  const location = useLocation();
  const { userToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState<string | null>(null);
  const [showKeyLearnings, setShowKeyLearnings] = useState(false);
  const [slide, setSlide] = useState<string | null>(null);
  const [responseSlide, setResponseSlide] = useState<string | null>(null);

  const [videoNew, setVideoNew] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  // const [selectPanel, setSelectPanel] = useState<string>("1");
  const [isNem, setIsNem] = useState<boolean>(false);

  const [qualitySelected, setQualitySelected] = useState<number>(1080);
  const [seconds, setSeconds] = useState(0);
  const [play, setPlay] = useState(false);
  const [isSendTeacherPlay, setIsSendTeacherPlay] = useState(false);
  const flags = useFlags();
  const qualityFlag = flags.quality;

  const clickPlayVideoNEMRecordEvent = useTeacherClickSeeVideoLesson();
  const clickPlayVideoNotNEMRecordEvent = useTeacherClickSeeVideoLessonNotNEM();
  const teacherInteractWithContent = useTeacherInteractWithContent();
  const studentActionsLibrary = useStudentActionLibrary();

  const handleSeek = (seconds: number) => {
    playerRef &&
      playerRef?.current &&
      playerRef.current.seekTo(seconds, "seconds");
  };

  const [urlVideo, setUrlVideo] = useState("");

  const generateUrlVideo = (url: string, quality: number) => {
    if (quality !== 1080) return `${url.split(".mp")[0]}_${quality}.mp4`;
    return url;
  };

  useEffect(() => {
    // Actualizar los parámetros de búsqueda
    if (back === "lessons") {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("key", selectPanel); // Agrega el parámetro deseado
        return params.toString();
      });
    }
  }, [selectPanel]);

  useEffect(() => {
    // chequear si es NEM
    setIsNem(location.pathname.includes("nem"));
  }, [setIsNem, location]);

  useEffect(() => {
    if (
      individualLesson &&
      individualLesson?.lesson_content &&
      individualLesson?.lesson_content.videos &&
      individualLesson?.lesson_content.videos[0] &&
      individualLesson?.lesson_content.videos[0].url
    ) {
      const updatedUrl = generateUrlVideo(
        individualLesson.lesson_content.videos[0].url,
        qualitySelected
      );
      setUrlVideo(updatedUrl);
      setTimeout(() => {
        handleSeek(seconds);
      }, 10);
    }
  }, [qualitySelected, individualLesson]);

  useEffect(() => {
    setLoading(true);
    individualLesson?.lesson_content?.videos?.map((videos) => {
      videos.url && setVideoNew(videos.url);
    });
    individualLesson?.lesson_content?.pdf &&
      setPdf(
        `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`
      );
    individualLesson?.lesson_content?.slide &&
      setSlide(
        `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`
      );
    individualLesson?.lesson_content?.responses_pdf &&
      setResponseSlide(
        `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.responses_pdf}`
      );

    individualLesson?.lesson_content &&
      individualLesson?.lesson_content?.videos &&
      individualLesson?.lesson_content?.videos[0] &&
      individualLesson?.lesson_content?.videos[0].key_learnings &&
      individualLesson?.lesson_content?.videos[0].key_learnings?.split("#\n")
        .length > 7 &&
      setShowKeyLearnings(false);

    const panelSelected =
      individualLesson?.lesson_type === "videolesson" ||
      individualLesson?.lesson_type === "audio"
        ? "4"
        : individualLesson?.lesson_type === "classroomguide" ||
          individualLesson?.lesson_type === "audio_exam" ||
          individualLesson?.lesson_type === "writing_exam" ||
          individualLesson?.lesson_type === "manipulative_dynamics"
        ? "6"
        : individualLesson?.lesson_type === "book_pages"
        ? "8"
        : individualLesson?.lesson_type === "slides"
        ? "7"
        : individualLesson?.lesson_type === "evaluation"
        ? "5"
        : individualLesson?.lesson_type === "redaction"
        ? "10"
        : individualLesson?.lesson_type === "literacy"
        ? "11"
        : individualLesson?.lesson_content?.responses_pdf
        ? "9"
        : "1";
    setSelectPanel(panelSelected);

    let settime = setTimeout(() => {
      setLoading(false);
    }, 150);

    return () => {
      clearTimeout(settime);
    };
  }, [individualLesson]);

  const onChange = (key: string | string[]) => {
    typeof key === "string" && setSelectPanel(key);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <div className={styles.tabsContainer}>
          <Tabs
            defaultActiveKey={selectPanel}
            activeKey={selectPanel}
            tabBarStyle={{ background: "white" }}
            onChange={onChange}
          >
            {/* tab Detalles  */}
            {(individualLesson?.lesson_type === "evaluation" ||
              userToken?.role === "teacher") && (
              <Tabs.TabPane tab="Detalles" key="1">
                <TabDetails
                  showKeyLearnings={showKeyLearnings}
                  individualLesson={individualLesson}
                  isNem={isNem}
                  setShowKeyLearnings={setShowKeyLearnings}
                />
              </Tabs.TabPane>
            )}

            {videoNew &&
              (individualLesson?.lesson_type === "videolesson" ||
                individualLesson?.lesson_type === "audio" ||
                individualLesson?.lesson_type === "audio_exam") && (
                <Tabs.TabPane
                  tab={
                    individualLesson?.lesson_type === "videolesson"
                      ? "Video"
                      : "Audio"
                  }
                  style={{ marginTop: -15 }}
                  key="4"
                >
                  <ReactPlayer
                    ref={playerRef}
                    controls
                    width="100%"
                    height="100%"
                    playing={play}
                    url={urlVideo ?? ""}
                    onProgress={(e) => setSeconds(e.playedSeconds)}
                    progressInterval={1000}
                    playbackRate={1}
                    onPlay={() => {
                      if (!isSendTeacherPlay && !play) {
                        if (isNem) {
                          clickPlayVideoNEMRecordEvent({
                            contentId: Number(individualLesson?.id),
                          });
                          setIsSendTeacherPlay(true);
                        } else {
                          clickPlayVideoNotNEMRecordEvent({
                            contentId: Number(individualLesson?.id),
                          });
                          setIsSendTeacherPlay(true);
                        }
                      }
                    }}
                    config={{
                      file: {
                        attributes: {
                          playsInline: true,
                          controlsList: "nodownload",
                          disableRemotePlayback: true,
                          disablePictureInPicture: true,
                        },
                      },
                    }}
                  />
                  {qualityFlag && (
                    <div className={styles.qualityMobileContainer}>
                      <p className={styles.qualityTitle}>Calidad video</p>
                      <Checkbox
                        className={styles.styleCheck}
                        checked={qualitySelected === 1080}
                        onChange={() => {
                          setQualitySelected && setQualitySelected(1080);
                          setPlay(true);
                        }}
                      >
                        1080p
                      </Checkbox>
                      <Checkbox
                        className={styles.styleCheck}
                        checked={qualitySelected === 720}
                        onChange={() => {
                          setQualitySelected && setQualitySelected(720);
                          setPlay(true);
                        }}
                      >
                        720p
                      </Checkbox>
                      <Checkbox
                        className={styles.styleCheck}
                        checked={qualitySelected === 360}
                        onChange={() => {
                          setQualitySelected && setQualitySelected(360);
                          setPlay(true);
                        }}
                      >
                        360p
                      </Checkbox>
                    </div>
                  )}
                </Tabs.TabPane>
              )}

            {userToken?.role === "teacher" &&
              individualLesson?.lesson_type === "literacy" && (
                <Tabs.TabPane tab="Lectura" key="11">
                  <div className={styles.literacyContainer}>
                    {individualLesson?.lesson_content?.literacy?.content?.map(
                      (content, index) => (
                        <TabLiteracy content={content} />
                      )
                    )}
                  </div>
                </Tabs.TabPane>
              )}

            {userToken?.role === "teacher" &&
              (individualLesson?.lesson_type === "videolesson" ||
                individualLesson?.lesson_type === "evaluation" ||
                individualLesson?.lesson_type === "literacy") && (
                <Tabs.TabPane tab="Quiz" key="5">
                  <TabQuiz individualLesson={individualLesson} />
                </Tabs.TabPane>
              )}

            {userToken?.role === "teacher" &&
              pdf &&
              (individualLesson?.lesson_type === "classroomguide" ||
                individualLesson?.lesson_type === "manipulative_dynamics" ||
                individualLesson?.lesson_type === "audio_exam" ||
                individualLesson?.lesson_type === "writing_exam" ||
                individualLesson?.lesson_type === "evaluation" ||
                individualLesson?.lesson_type === "literacy") && (
                <Tabs.TabPane
                  tab={
                    individualLesson?.lesson_type === "classroomguide" ||
                    individualLesson?.lesson_type === "manipulative_dynamics"
                      ? "Orientación Didáctica"
                      : individualLesson?.lesson_type === "literacy"
                      ? "Actividad"
                      : "Examen"
                  }
                  key="6"
                >
                  <div className={styles.paddingContainerClassroomGuide}>
                    <div className={styles.pdfContainerClassroomGuide}>
                      <PDFViewer
                        url={pdf}
                        onPageChanged={() => {
                          teacherInteractWithContent({
                            contentId: Number(individualLesson?.id)
                              ? Number(individualLesson?.id)
                              : null,
                            contentType: individualLesson?.lesson_type
                              ? individualLesson?.lesson_type
                              : null,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Tabs.TabPane>
              )}

            {slide && individualLesson?.lesson_type === "book_pages" && (
              <Tabs.TabPane tab="Páginas alumno" key="8">
                <div className={styles.paddingContainerClassroomGuide}>
                  <div className={styles.pdfContainerClassroomGuide}>
                    <PDFViewer
                      url={slide}
                      onPageChanged={() => {
                        userToken?.role === "teacher" &&
                          teacherInteractWithContent({
                            contentId: Number(individualLesson?.id)
                              ? Number(individualLesson?.id)
                              : null,
                            contentType: individualLesson?.lesson_type
                              ? individualLesson?.lesson_type
                              : null,
                          });
                        userToken?.role === "student" &&
                          studentActionsLibrary({
                            action: "ver",
                            lessonId: individualLesson?.id
                              ? individualLesson?.id
                              : null,
                            section: back === "nem" ? "NEM" : "2017",
                            typeLesson: "libro",
                          });
                      }}
                    />
                  </div>
                </div>
              </Tabs.TabPane>
            )}

            {individualLesson?.lesson_type === "redaction" && (
              <Tabs.TabPane tab="Pregunta" key="10">
                <div className={styles.paddingContainer}>
                  <div className={styles.detailsLeccionSepContainer}>
                    <h3 className={styles.descriptionTask}>Enunciado</h3>
                  </div>

                  <div className={styles.detailsContentContainer}>
                    <div className={styles.detailsTitleContent}>
                      {individualLesson.lesson_content.redaction?.question.includes(
                        "Evidencia"
                      ) ? (
                        <>
                          <div className={styles.descriptionLesson}>
                            {
                              individualLesson.lesson_content.redaction?.question.split(
                                "Evidencia:"
                              )[0]
                            }
                          </div>
                          <br />
                          <div className={styles.descriptionLesson}>
                            Evidencia:
                            {
                              individualLesson.lesson_content.redaction?.question.split(
                                "Evidencia:"
                              )[1]
                            }
                          </div>
                        </>
                      ) : (
                        <div className={styles.descriptionLesson}>
                          {individualLesson.lesson_content.redaction?.question}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.detailsLeccionSepContainer}>
                    <h3 className={styles.descriptionTask}>Rúbricas</h3>
                  </div>

                  <div className={styles.detailsContentContainer}>
                    <div className={styles.detailsTitleContent}>
                      {individualLesson.lesson_content.redaction?.rubrics.map(
                        (e, index) => {
                          return (
                            <>
                              <div className={styles.descriptionLesson}>
                                Rúbrica {index + 1}: {e.rubric}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            )}
            {individualLesson?.lesson_type === "conversation" && (
              <Tabs.TabPane tab="Conversación" key="11">
                <TabConversation individualLesson={individualLesson} />
              </Tabs.TabPane>
            )}

            {/* pagePDF */}
            {userToken?.role === "teacher" &&
              responseSlide &&
              individualLesson?.lesson_content?.responses_pdf && (
                <Tabs.TabPane tab="Páginas solucionario" key="9">
                  <div className={styles.paddingContainerClassroomGuide}>
                    <div className={styles.pdfContainerClassroomGuide}>
                      <PDFViewer url={responseSlide} />
                    </div>
                  </div>
                </Tabs.TabPane>
              )}

            {slide && individualLesson?.lesson_type === "slides" && (
              <Tabs.TabPane tab="Diapositivas" key="7">
                <div className={styles.paddingContainerClassroomGuide}>
                  <div className={styles.pdfContainerClassroomGuide}>
                    <PDFViewer
                      url={slide}
                      onPageChanged={() => {
                        userToken?.role === "teacher" &&
                          teacherInteractWithContent({
                            contentId: Number(individualLesson?.id)
                              ? Number(individualLesson?.id)
                              : null,
                            contentType: individualLesson?.lesson_type
                              ? individualLesson?.lesson_type
                              : null,
                          });
                        userToken?.role === "student" &&
                          studentActionsLibrary({
                            action: "ver",
                            lessonId: individualLesson?.id
                              ? individualLesson?.id
                              : null,
                            section: back === "nem" ? "NEM" : "2017",
                            typeLesson: "diapos",
                          });
                      }}
                    />
                  </div>
                </div>
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default MainLesson;
