import React, { useEffect, useState } from "react";
import styles from "./bannerRachas.module.scss";
import starGold from "../../../assets/pages/classes/startGolden.png";
import starGrey from "../../../assets/pages/classes/startGrey.png";
import fire from "../../../assets/pages/classes/fire.png";
import UseGetStartRankByTeacher from "../../../api/useGetTeacherStarRankByTeacherId";
import { GetTeacherStarRankByTeacherId_getTeacherStarRankByTeacherId } from "../../../__generated__/gql-types/GetTeacherStarRankByTeacherId";
import useAuth from "../../../context/useAuth";

const BannerRachas = () => {
  const getStartRank = UseGetStartRankByTeacher();
  const { userToken } = useAuth();
  const [startRank, setStartRank] =
    useState<GetTeacherStarRankByTeacherId_getTeacherStarRankByTeacherId | null>(
      null
    );

  useEffect(() => {
    (async () => {
      const response = await getStartRank({
        getTeacherStarRankByTeacherIdId: userToken?.id,
      });
      setStartRank(response);
    })();
  }, [getStartRank, userToken?.id, setStartRank]);

  return (
    startRank && (
      <div className={styles.container}>
        {startRank.total_stars === startRank.stars ? (
          <div className={styles.containerDescription}>
            Ya has alcanzado tu meta, ¡asigna aún más tareas para ser el mejor
            profesor de Luca!
          </div>
        ) : (
          <div className={styles.containerDescription}>
            Asigna tareas que inicien esta semana a al menos{" "}
            {startRank.total_stars} clases para mantener tu racha.
          </div>
        )}
        <div className={styles.containerContent}>
          <div className={styles.containerWeekObject}>
            <div className={styles.objectWeekText}>Objetivo semanal</div>
            <div className={styles.contianerStar}>
              {Array(startRank?.total_stars)
                .fill(0)
                .map((e, index) =>
                  startRank?.stars && index <= startRank?.stars - 1 ? (
                    <img src={starGold} alt="start" />
                  ) : (
                    <img src={starGrey} alt="start" />
                  )
                )}
            </div>
          </div>
          <div className={styles.containerRachas}>
            <div className={styles.rachas}> Racha </div>
            <img src={fire} alt="" />
            <div className={styles.week}>
              {typeof startRank?.streak_of_weeks === "number" &&
              startRank?.streak_of_weeks !== 1
                ? `${startRank?.streak_of_weeks} semanas`
                : `${startRank?.streak_of_weeks} semana`}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default BannerRachas;
