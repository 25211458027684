import React, { FC } from "react";
import {
  getLessonById_getLessonById,
  getLessonById_getLessonById_axes,
  getLessonById_getLessonById_themes,
} from "../../../../../__generated__/gql-types/getLessonById";
import { Tag } from "antd";
import evaluacion_alumno from "../../../../../assets/images/evaluacion_alumno.png";
import styles from "./tabDetails.module.scss";
import book from "../../../../../assets/icons/teacher/book/book-selected.svg";
import useAuth from "../../../../../context/useAuth";
import useIsMobile from "../../../../../hooks/useIsMobile";

interface PropsDetails {
  individualLesson: getLessonById_getLessonById | null;
  isNem: boolean;
  showKeyLearnings: boolean;
  setShowKeyLearnings: (e: boolean) => void;
}

const TabDetails: FC<PropsDetails> = ({
  individualLesson,
  isNem,
  setShowKeyLearnings,
  showKeyLearnings,
}) => {
  const { userToken } = useAuth();
  const isMobile = useIsMobile();

  return userToken?.role === "student" ? (
    <div className={styles.detailsSuggestedContentContainerStudent}>
      <img src={evaluacion_alumno} className={styles.imgSuggested} alt="img" />
    </div>
  ) : (
    <div className={styles.paddingContainer}>
      {(individualLesson?.lesson_type === "videolesson" ||
        individualLesson?.lesson_type === "audio" ||
        individualLesson?.lesson_type === "audio_exam") &&
        individualLesson?.course?.sep_book_pages && (
          <>
            <div className={styles.detailsLeccionSepContainer}>
              <h3 className={styles.descriptionTask}>
                {isNem ? "SECUENCIA" : "LECCIÓN SEP"}
              </h3>
            </div>
            <div className={styles.detailsContentContainer}>
              <div className={styles.detailsTitleContent}>
                <h3 className={styles.titleTask}>
                  {individualLesson?.course?.title}
                </h3>
              </div>
              <div>
                <h3 className={styles.pageTask}>
                  Páginas {individualLesson?.course?.sep_book_pages}
                  <img src={book} alt="" className={styles.bookImg} />
                </h3>
              </div>
            </div>
          </>
        )}

      {individualLesson?.lesson_content?.videos &&
        individualLesson?.lesson_content?.videos[0] &&
        individualLesson?.lesson_content?.videos[0].microcontent && (
          <>
            <div className={styles.detailsLeccionSepContainer}>
              <h3 className={styles.descriptionTask}>MICROCONTENIDOS</h3>
            </div>

            <div className={styles.detailsSuggestedContentContainer}>
              <div className={styles.descriptionLesson}>
                {individualLesson?.lesson_content?.videos[0].microcontent}
              </div>
            </div>
          </>
        )}

      {individualLesson?.lesson_type === "videolesson" &&
        individualLesson?.lesson_content &&
        individualLesson?.lesson_content?.videos &&
        individualLesson?.lesson_content?.videos[0].key_learnings && (
          <>
            <div className={styles.detailsLeccionSepContainer}>
              <h3 className={styles.descriptionTask}>APRENDIZAJES CLAVES</h3>
            </div>

            <div className={styles.detailsSuggestedContentContainer}>
              {showKeyLearnings ? (
                <>
                  <ul className={styles.descriptionLesson}>
                    {individualLesson?.lesson_content?.videos[0].key_learnings
                      ?.split("\n")
                      .filter((item) => item.length > 0)
                      .map((learning) => {
                        return <li>{learning}</li>;
                      })}
                  </ul>
                  <div
                    onClick={() => setShowKeyLearnings(false)}
                    className={styles.showKeyLearnings}
                  >
                    ver menos
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ul className={styles.descriptionLesson}>
                    {individualLesson?.lesson_content?.videos[0].key_learnings
                      ?.split("\n")
                      .filter((item) => item.length > 0)
                      .slice(0, 3)
                      .map((learning, index) => {
                        return <li>{learning}</li>;
                      })}
                  </ul>
                  <div
                    onClick={() => setShowKeyLearnings(true)}
                    className={styles.showKeyLearnings}
                  >
                    ver más
                  </div>
                </div>
              )}
            </div>
          </>
        )}

      {!isNem &&
        individualLesson?.axes &&
        individualLesson?.themes &&
        (individualLesson?.themes?.length > 0 ||
          individualLesson?.axes?.length > 0) && (
          <>
            <div className={styles.detailsLeccionSepContainer}>
              <h3 className={styles.descriptionTask}>
                EJE, TEMA Y APRENDIZAJE ESPERADO
              </h3>
            </div>
            {!isMobile ? (
              <div className={styles.detailsContentContainer}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "initial",
                    flexWrap: "wrap",
                  }}
                >
                  {individualLesson?.axes?.map(
                    (axe: getLessonById_getLessonById_axes, index: number) => {
                      return (
                        <Tag
                          color="#EFFBFA"
                          className={styles.tags}
                          key={index}
                        >
                          EJE: {axe.name}
                        </Tag>
                      );
                    }
                  )}
                  {individualLesson?.themes?.map(
                    (
                      theme: getLessonById_getLessonById_themes,
                      index: number
                    ) => {
                      return (
                        <Tag
                          color="#EFFBFA"
                          className={styles.tags}
                          key={index}
                        >
                          TEMA: {theme.name}
                        </Tag>
                      );
                    }
                  )}
                </div>
                <div style={{ paddingLeft: 7, marginTop: 10 }}>
                  <h3 className={styles.descriptionTask}>
                    {individualLesson.learnings}
                  </h3>
                </div>
              </div>
            ) : (
              <div className={styles.detailsContentContainerMobile}>
                {individualLesson?.axes?.map(
                  (axe: getLessonById_getLessonById_axes, index: number) => {
                    return (
                      <Tag
                        color="#EFFBFA"
                        className={styles.tagsMobile}
                        key={index}
                      >
                        EJE: {axe.name}
                      </Tag>
                    );
                  }
                )}
                {individualLesson?.themes?.map(
                  (
                    theme: getLessonById_getLessonById_themes,
                    index: number
                  ) => {
                    return (
                      <Tag color="#EFFBFA" className={styles.tags} key={index}>
                        TEMA: {theme.name}
                      </Tag>
                    );
                  }
                )}
                <div style={{ paddingLeft: 7, marginTop: 10 }}>
                  <h3 className={styles.descriptionTask}>
                    {individualLesson.learnings}
                  </h3>
                </div>
              </div>
            )}
          </>
        )}

      <div className={styles.detailsLeccionSepContainer}>
        <h3 className={styles.descriptionTask}>USO SUGERIDO</h3>
      </div>

      <div className={styles.detailsSuggestedContentContainer}>
        {individualLesson?.lesson_type === "videolesson" && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Introducir nuevos conceptos: Usa los videos como una forma
              divertida de presentar ideas y despertar el interés de los
              estudiantes.
            </div>
            <div className={styles.descriptionLesson}>
              2. Repasar y consolidar conocimientos: Usa los videos como una
              herramienta de repaso en tareas y para fortalecer los
              conocimientos previamente enseñados.
            </div>
            <div className={styles.descriptionLesson}>
              3. Apoyo a la enseñanza individualizada: Personaliza la
              experiencia de aprendizaje asignando videos específicos según las
              necesidades de cada estudiante.
            </div>
            <div className={styles.descriptionLesson}>
              4. Estimular la discusión en grupo: Después de ver un video,
              fomenta la participación y el debate en grupo con preguntas
              relacionadas.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "redaction" && (
          <div>
            <div className={styles.descriptionLesson}>
              Usa las preguntas abiertas para que los alumnos construyan textos
              para desplegar sus habilidades superiores de pensamiento para la
              comparación, análisis y teorización.
            </div>
          </div>
        )}

        {(individualLesson?.lesson_type === "classroomguide" ||
          individualLesson?.lesson_type === "slides" ||
          individualLesson?.lesson_type === "manipulative_dynamics" ||
          individualLesson?.lesson_type === "book_pages") && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Planificación de lecciones: Usa actividades y juegos de cada
              guión para aprender nuevos aprendizajes en grupos.
            </div>
            <div className={styles.descriptionLesson}>
              2. Reforzamiento visual: Usa diapositivas atractivas para
              visualizar conceptos clave.
            </div>
            <div className={styles.descriptionLesson}>
              3. Interacción y participación: Incorpora elementos visuales en
              las diapositivas e interactivos en guiones de clase para mantener
              a los estudiantes comprometidos mientras aprenden.
            </div>
            <div className={styles.descriptionLesson}>
              4. Repaso y consolidación: Usa los recursos para repasar y
              consolidar el aprendizaje.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "evaluation" && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Medir el aprendizaje: Usa las evaluaciones para comprobar el
              nivel de dominio de los aprendizajes esperados al finalizar una
              unidad o tema.
            </div>
            <div className={styles.descriptionLesson}>
              2. Retroalimentación formativa: Proporciona retroalimentación
              individualizada utilizando los resultados de las evaluaciones.
            </div>
            <div className={styles.descriptionLesson}>
              3. Identificar necesidades de aprendizaje: Usa los datos de las
              evaluaciones para identificar áreas en las que los estudiantes
              necesitan más apoyo.
            </div>
            <div className={styles.descriptionLesson}>
              4. Informes y comunicación con los padres: Genera informes claros
              sobre el rendimiento de los estudiantes y compártelos con los
              padres.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "audio" && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Mejora la comprensión oral mediante grabaciones y preguntas.
            </div>
            <div className={styles.descriptionLesson}>
              2. Refuerza vocabulario y pronunciación con repeticiones y
              transcripciones.
            </div>
            <div className={styles.descriptionLesson}>
              3. Fomenta la interacción usando grabaciones en debates y
              proyectos.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "conversation" && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Asigne esta tarea para que sus alumnos practiquen sus
              habilidades de escritura con el sistema de inteligencia
              artificial.
            </div>
            <div className={styles.descriptionLesson}>
              2. Los alumnos podrán comunicarse sin temor a equivocarse, lo cual
              promueve el desarrollo de sus habilidades comunicativas y la
              estructuración de las reglas gramaticales que lo posibilitan.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "literacy" && (
          <div>
            <div className={styles.descriptionLesson}>
              1. Asigne la lectura a los estudiantes para promover el interés
              por la secuencia.
            </div>
            <div className={styles.descriptionLesson}>
              2. La lectura incluye en su narrativa el uso contextualizado del
              vocabulario y de los conceptos gramaticales de la lección en su
              totalidad. Esto expone a los estudiantes a lenguaje novedoso y
              prepara el paso a la integración y formalización.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "audio_exam" && (
          <div>
            <div className={styles.descriptionLesson}>
              Evalúa el conocimiento de tus estudiantes sobre temas estudiados
              con anterioridad, usando nuestro formato similar a las pruebas
              formales de Cambridge English.
            </div>
            <div className={styles.descriptionLesson}>
              1. Descarga e imprime el examen en PDF.
            </div>
            <div className={styles.descriptionLesson}>
              2. Reproduce el audio en clase.
            </div>
            <div className={styles.descriptionLesson}>
              3. Pide a tus alumnos que respondan a las preguntas del examen con
              la información obtenida del audio reproducido.
            </div>
          </div>
        )}

        {individualLesson?.lesson_type === "writing_exam" && (
          <div>
            <div className={styles.descriptionLesson}>
              Evalúa el conocimiento de tus estudiantes sobre temas estudiados
              con anterioridad, usando nuestro formato similar a las pruebas
              formales de Cambridge English.
            </div>
            <div className={styles.descriptionLesson}>
              1. Descarga e imprime el examen en PDF.
            </div>
            <div className={styles.descriptionLesson}>
              2. Pide a tus alumnos que respondan a las preguntas del examen con
              la información obtenida del audio reproducido.
            </div>
          </div>
        )}
      </div>
      {individualLesson?.lesson_type === "literacy" &&
        individualLesson.lesson_content.literacy?.copyright && (
          <div>
            {/* perdon por esto pancholi pero es viernes a las 17:41 y lo resolvi como pude jajkjaja */}
            <br />
            <div className={styles.detailsSuggestedContentContainer}>
              <div>
                <div className={styles.descriptionLesson}>
                  {individualLesson.lesson_content.literacy?.copyright}
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default TabDetails;
