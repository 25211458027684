import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { LayoutLuca } from "./components/Layout";
import useAuth from "./context/useAuth";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/reset.css";
import UseGetUserByEmail from "./api/useGetUserByEmail";
import useUserOpenLuca from "./api/useUserOpenLuca";
import { useEffect, useState } from "react";
import useUserDb from "./UserProvider/UserProvider";
import { notification } from "antd";
import { getUserByEmail_getUserByEmail } from "./__generated__/gql-types/getUserByEmail";

const App = () => {
  const { userToken, logout } = useAuth();
  const [userDb, setUserDb] = useState<getUserByEmail_getUserByEmail | null>(
    null
  );
  const getUserByEmail = UseGetUserByEmail();
  const { setAuthUserDb } = useUserDb();
  const OpenLuca = useUserOpenLuca();

  useEffect(() => {
    const fetchUserInfoByEmail = async () => {
      try {
        if (userToken) {
          const userRes = await getUserByEmail({
            userEmail: userToken?.email,
          });
          setAuthUserDb(userRes);
          setUserDb(userRes);
        }
        OpenLuca();
      } catch (error) {
        logout();
        notification.error({
          message:
            "Ocurrió un error o tu sesión expiró, por favor ingresa nuevamente.",
        });
      }
    };
    fetchUserInfoByEmail();
  }, [getUserByEmail, userToken, OpenLuca, logout, setUserDb]);
  return (
    <BrowserRouter>
      {userToken ? (
        <LayoutLuca userDb={userDb} setUserDb={setUserDb}>
          <Routes userDb={userDb} />
        </LayoutLuca>
      ) : (
        <Routes />
      )}
    </BrowserRouter>
  );
};

export default withLDProvider({
  clientSideID: "65b254628d3d1d0f66cf5ae2",
})(App);
