import moment from "moment";
import { getLessonById_getLessonById_lesson_content_videos } from "../__generated__/gql-types/getLessonById";
import { getLessonsBySubjectIdLength_getLessonsBySubject } from "../__generated__/gql-types/getLessonsBySubjectIdLength";

// polux
import polux1 from "../assets/images/avatars/polux/polux_level_1.svg";
import polux2 from "../assets/images/avatars/polux/polux_level_2.svg";
import polux3 from "../assets/images/avatars/polux/polux_level_3.svg";
import polux4 from "../assets/images/avatars/polux/polux_level_4.svg";
import polux5 from "../assets/images/avatars/polux/polux_level_5.svg";
import polux6 from "../assets/images/avatars/polux/polux_level_6.svg";
import polux7 from "../assets/images/avatars/polux/polux_level_7.svg";
import polux8 from "../assets/images/avatars/polux/polux_level_8.svg";
import polux9 from "../assets/images/avatars/polux/polux_level_9.svg";
import polux10 from "../assets/images/avatars/polux/polux_level_10.svg";
import polux11 from "../assets/images/avatars/polux/polux_level_11.svg";
import polux12 from "../assets/images/avatars/polux/polux_level_12.svg";
import polux13 from "../assets/images/avatars/polux/polux_level_13.svg";
// rigel
import rigel1 from "../assets/images/avatars/rigel/rigel_level_1.svg";
import rigel2 from "../assets/images/avatars/rigel/rigel_level_2.svg";
import rigel3 from "../assets/images/avatars/rigel/rigel_level_3.svg";
import rigel4 from "../assets/images/avatars/rigel/rigel_level_4.svg";
import rigel5 from "../assets/images/avatars/rigel/rigel_level_5.svg";
import rigel6 from "../assets/images/avatars/rigel/rigel_level_6.svg";
import rigel7 from "../assets/images/avatars/rigel/rigel_level_7.svg";
import rigel8 from "../assets/images/avatars/rigel/rigel_level_8.svg";
import rigel9 from "../assets/images/avatars/rigel/rigel_level_9.svg";
import rigel10 from "../assets/images/avatars/rigel/rigel_level_10.svg";
import rigel11 from "../assets/images/avatars/rigel/rigel_level_11.svg";
import rigel12 from "../assets/images/avatars/rigel/rigel_level_12.svg";
import rigel13 from "../assets/images/avatars/rigel/rigel_level_13.svg";
// spica
import spica1 from "../assets/images/avatars/spica/spica_level_1.svg";
import spica2 from "../assets/images/avatars/spica/spica_level_2.svg";
import spica3 from "../assets/images/avatars/spica/spica_level_3.svg";
import spica4 from "../assets/images/avatars/spica/spica_level_4.svg";
import spica5 from "../assets/images/avatars/spica/spica_level_5.svg";
import spica6 from "../assets/images/avatars/spica/spica_level_6.svg";
import spica7 from "../assets/images/avatars/spica/spica_level_7.svg";
import spica8 from "../assets/images/avatars/spica/spica_level_8.svg";
import spica9 from "../assets/images/avatars/spica/spica_level_9.svg";
import spica10 from "../assets/images/avatars/spica/spica_level_10.svg";
import spica11 from "../assets/images/avatars/spica/spica_level_11.svg";
import spica12 from "../assets/images/avatars/spica/spica_level_12.svg";
import spica13 from "../assets/images/avatars/spica/spica_level_13.svg";
// shaula
import shaula1 from "../assets/images/avatars/shaula/shaula_level_1.svg";
import shaula2 from "../assets/images/avatars/shaula/shaula_level_2.svg";
import shaula3 from "../assets/images/avatars/shaula/shaula_level_3.svg";
import shaula4 from "../assets/images/avatars/shaula/shaula_level_4.svg";
import shaula5 from "../assets/images/avatars/shaula/shaula_level_5.svg";
import shaula6 from "../assets/images/avatars/shaula/shaula_level_6.svg";
import shaula7 from "../assets/images/avatars/shaula/shaula_level_7.svg";
import shaula8 from "../assets/images/avatars/shaula/shaula_level_8.svg";
import shaula9 from "../assets/images/avatars/shaula/shaula_level_9.svg";
import shaula10 from "../assets/images/avatars/shaula/shaula_level_10.svg";
import shaula11 from "../assets/images/avatars/shaula/shaula_level_11.svg";
import shaula12 from "../assets/images/avatars/shaula/shaula_level_12.svg";
import shaula13 from "../assets/images/avatars/shaula/shaula_level_13.svg";
// tatooine
import tatooine1 from "../assets/images/avatars/tatooine/tatooine_level_1.svg";
import tatooine2 from "../assets/images/avatars/tatooine/tatooine_level_2.svg";
import tatooine3 from "../assets/images/avatars/tatooine/tatooine_level_3.svg";
import tatooine4 from "../assets/images/avatars/tatooine/tatooine_level_4.svg";
import tatooine5 from "../assets/images/avatars/tatooine/tatooine_level_5.svg";
import tatooine6 from "../assets/images/avatars/tatooine/tatooine_level_6.svg";
import tatooine7 from "../assets/images/avatars/tatooine/tatooine_level_7.svg";
import tatooine8 from "../assets/images/avatars/tatooine/tatooine_level_8.svg";
import tatooine9 from "../assets/images/avatars/tatooine/tatooine_level_9.svg";
import tatooine10 from "../assets/images/avatars/tatooine/tatooine_level_10.svg";
import tatooine11 from "../assets/images/avatars/tatooine/tatooine_level_11.svg";
import tatooine12 from "../assets/images/avatars/tatooine/tatooine_level_12.svg";
import tatooine13 from "../assets/images/avatars/tatooine/tatooine_level_13.svg";
// colmillu
import colmillu1 from "../assets/images/avatars/colmillu/colmillu_level_1.svg";
import colmillu2 from "../assets/images/avatars/colmillu/colmillu_level_2.svg";
import colmillu3 from "../assets/images/avatars/colmillu/colmillu_level_3.svg";
import colmillu4 from "../assets/images/avatars/colmillu/colmillu_level_4.svg";
import colmillu5 from "../assets/images/avatars/colmillu/colmillu_level_5.svg";
import colmillu6 from "../assets/images/avatars/colmillu/colmillu_level_6.svg";
import colmillu7 from "../assets/images/avatars/colmillu/colmillu_level_7.svg";
import colmillu8 from "../assets/images/avatars/colmillu/colmillu_level_8.svg";
import colmillu9 from "../assets/images/avatars/colmillu/colmillu_level_9.svg";
import colmillu10 from "../assets/images/avatars/colmillu/colmillu_level_10.svg";
import colmillu11 from "../assets/images/avatars/colmillu/colmillu_level_11.svg";
import colmillu12 from "../assets/images/avatars/colmillu/colmillu_level_12.svg";
import colmillu13 from "../assets/images/avatars/colmillu/colmillu_level_13.svg";

type UtcDateProps = string | undefined | null;
// Para convertir el tiempo del video
const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const videoDuration = (
  video: getLessonById_getLessonById_lesson_content_videos
) => {
  let dur = video.metadata.duration;
  const minutes = Math.floor(dur / 60);
  const seconds = Math.floor(dur % 60);
  const result = `${padTo2Digits(minutes)} min ${padTo2Digits(seconds)} sec`;
  return result;
};

export const toUtcDate = (date: UtcDateProps) => {
  const dateUTCLocal = moment(date, "DD-MM-YYYY HH:mm").format();
  const dateUTCUniversal = moment
    .utc(dateUTCLocal)
    .format("DD-MM-YYYY HH:mm")
    .toString();
  return dateUTCUniversal;
};

export const toLocalDate = (date: string | undefined | null) => {
  if (date) {
    const splittedDate: string[] = date.split("/");
    if (splittedDate.length === 3) {
      const hour = splittedDate[2].slice(5, 7);
      const minute = splittedDate[2].slice(8, 10);
      const day = splittedDate[0];
      const month = splittedDate[1];
      const year = splittedDate[2].slice(0, 4);
      const finalStartDate = `${year}-${month}-${day}T${hour}:${minute}:00Z`;
      return moment
        .utc(finalStartDate)
        .local()
        .format("DD-MM-YYYY HH:mm")
        .toString();
    }
  }
};
export const hasDatePassed = (endDate: string | null | undefined) => {
  // Parse the date string into a Date object
  const parts = endDate?.split(" ");
  const dateParts = parts?.length ? parts[0].split("-") : ["01", "01", "23"];
  const timeParts = parts?.length ? parts[1].split(":") : ["00", "00"];
  const year = parseInt(dateParts[2], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed
  const day = parseInt(dateParts[0], 10);
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  const targetDate = new Date(year, month, day, hours, minutes);

  // Get the current date and time
  const currentDate = new Date();

  // Compare the target date with the current date
  return currentDate > targetDate;
};

export const createMomentObject = (
  date: any,
  nowDate: boolean,
  hour?: number,
  minutes?: number,
  seconds?: number
) => {
  let momentDate = moment({
    year: Number(date.slice(6, 10)),
    month: Number(date.slice(3, 5)) - 1,
    day: Number(date.slice(0, 2)),
  });

  momentDate.set("hour", nowDate ? moment().hour() : hour ? hour : 0);
  momentDate.set(
    "minutes",
    nowDate ? moment().minutes() : minutes ? minutes : 0
  );
  momentDate.set(
    "seconds",
    nowDate ? moment().seconds() : seconds ? seconds : 0
  );
  return momentDate;
};

export const filterLessonGrades = (lessons: any) => {
  return (
    lessons?.filter(
      (lesson: { grade: { grade: string } }) =>
        lesson?.grade?.grade === "4" ||
        lesson?.grade?.grade === "5" ||
        lesson?.grade?.grade === "6"
    ) ?? []
  );
};

export const filterWeeks = (
  lessonsFiltered: any[] | null,
  setList: (e: string[]) => void
) => {
  let weeks: string[] = [];
  let weekArray = lessonsFiltered?.map((e: any) => e?.week?.toString());
  weekArray?.forEach((element: string | null | undefined) => {
    if (element && !weeks?.includes(element)) return weeks.push(element);
  });
  let weeksSort = weeks.sort((a, b) => Number(a) - Number(b));
  setList(weeksSort);
};

export const filterPages = (
  lessonsFiltered: getLessonsBySubjectIdLength_getLessonsBySubject[] | null,
  setList: (e: string[]) => void
) => {
  let pages: string[] = [];
  let pagesArray = lessonsFiltered?.map((e) => e?.sep_book_pages);

  pagesArray?.forEach((element) => {
    if (
      element &&
      !pages.includes(element.replace(/\s/g, "")) &&
      !pages.includes(element)
    ) {
      if (element.includes("-")) {
        return pages.push(element.replace(/\s/g, ""));
      } else {
        return pages.push(element);
      }
    }
  });

  const sortedStrings = pages.sort((a, b) => {
    // Convierte los strings en arrays de números separados por guiones
    const aNums = a.split("-").map(Number);
    const bNums = b.split("-").map(Number);

    // Si hay un valor vacío, ponlo al final
    if (aNums.length === 0) {
      return 1;
    }
    if (bNums.length === 0) {
      return -1;
    }

    // Compara los números
    if (aNums[0] !== bNums[0]) {
      return aNums[0] - bNums[0];
    }
    if (aNums[1] !== bNums[1]) {
      return aNums[1] - bNums[1];
    }
    return 0;
  });

  setList(sortedStrings);
};

export const avatars = [
  {
    name: "polux",
    image1: polux1,
    image2: polux2,
    image3: polux3,
    image4: polux4,
    image5: polux5,
    image6: polux6,
    image7: polux7,
    image8: polux8,
    image9: polux9,
    image10: polux10,
    image11: polux11,
    image12: polux12,
    image13: polux13,
  },
  {
    name: "rigel",
    image1: rigel1,
    image2: rigel2,
    image3: rigel3,
    image4: rigel4,
    image5: rigel5,
    image6: rigel6,
    image7: rigel7,
    image8: rigel8,
    image9: rigel9,
    image10: rigel10,
    image11: rigel11,
    image12: rigel12,
    image13: rigel13,
  },
  {
    name: "shaula",
    image1: shaula1,
    image2: shaula2,
    image3: shaula3,
    image4: shaula4,
    image5: shaula5,
    image6: shaula6,
    image7: shaula7,
    image8: shaula8,
    image9: shaula9,
    image10: shaula10,
    image11: shaula11,
    image12: shaula12,
    image13: shaula13,
  },
  {
    name: "spica",
    image1: spica1,
    image2: spica2,
    image3: spica3,
    image4: spica4,
    image5: spica5,
    image6: spica6,
    image7: spica7,
    image8: spica8,
    image9: spica9,
    image10: spica10,
    image11: spica11,
    image12: spica12,
    image13: spica13,
  },
  {
    name: "tatooine",
    image1: tatooine1,
    image2: tatooine2,
    image3: tatooine3,
    image4: tatooine4,
    image5: tatooine5,
    image6: tatooine6,
    image7: tatooine7,
    image8: tatooine8,
    image9: tatooine9,
    image10: tatooine10,
    image11: tatooine11,
    image12: tatooine12,
    image13: tatooine13,
  },
  {
    name: "colmillu",
    image1: colmillu1,
    image2: colmillu2,
    image3: colmillu3,
    image4: colmillu4,
    image5: colmillu5,
    image6: colmillu6,
    image7: colmillu7,
    image8: colmillu8,
    image9: colmillu9,
    image10: colmillu10,
    image11: colmillu11,
    image12: colmillu12,
    image13: colmillu13,
  },
];

export const resolveAvatar = (avatarName: string, avatarLevel: string) => {
  const avatarSelected = avatars.find((value) => value.name === avatarName);
  if (avatarLevel === "1") return avatarSelected?.image1;
  if (avatarLevel === "2") return avatarSelected?.image2;
  if (avatarLevel === "3") return avatarSelected?.image3;
  if (avatarLevel === "4") return avatarSelected?.image4;
  if (avatarLevel === "5") return avatarSelected?.image5;
  if (avatarLevel === "6") return avatarSelected?.image6;
  if (avatarLevel === "7") return avatarSelected?.image7;
  if (avatarLevel === "8") return avatarSelected?.image8;
  if (avatarLevel === "9") return avatarSelected?.image9;
  if (avatarLevel === "10") return avatarSelected?.image10;
  if (avatarLevel === "11") return avatarSelected?.image11;
  if (avatarLevel === "12") return avatarSelected?.image12;
  if (avatarLevel === "13") return avatarSelected?.image13;
  return avatars[0].image13;
};

export const resolveColorAvatar = (avatarName: string) => {
  if (avatarName === "polux") return "#F2C051";
  if (avatarName === "rigel") return "#6545A3";
  if (avatarName === "spica") return "#ABCE6F";
  if (avatarName === "tatooine") return "#87C8E5";
  if (avatarName === "shaula") return "#4072CC";
  if (avatarName === "colmillu") return "#D86D5B";
  return "#F2C051";
};

export const getDifferenceInDays = (startDate: Date) => {
  startDate.setUTCHours(0, 0, 0, 0);
  const startDateToReset = startDate.getTime();
  const todaySnap = new Date();
  todaySnap.setUTCHours(0, 0, 0, 0);
  const today = todaySnap.getTime();
  const differenceInDays = Math.round(
    (today - startDateToReset) / (1000 * 3600 * 24)
  );

  return differenceInDays;
};

export const calculateLevel = (Score: number) => {
  if (Score >= 0 && Score <= 100) {
    return (Score / 100) * 100;
  }
  if (Score >= 101 && Score <= 300) {
    return (Score / 300) * 100;
  }
  if (Score >= 301 && Score <= 600) {
    return (Score / 600) * 100;
  }
  if (Score >= 601 && Score <= 1000) {
    return (Score / 1000) * 100;
  }
  if (Score >= 1001 && Score <= 1500) {
    return (Score / 1500) * 100;
  }
  if (Score >= 1501 && Score <= 2100) {
    return (Score / 2100) * 100;
  }
  if (Score >= 2101 && Score <= 2800) {
    return (Score / 2800) * 100;
  }
  if (Score >= 2801 && Score <= 3600) {
    return (Score / 3600) * 100;
  }
  if (Score >= 3601 && Score <= 4600) {
    return (Score / 4600) * 100;
  }
  if (Score >= 4601 && Score <= 5800) {
    return (Score / 5800) * 100;
  }
  if (Score >= 5801 && Score <= 7200) {
    return (Score / 7200) * 100;
  }
  if (Score >= 7201 && Score <= 9000) {
    return (Score / 9000) * 100;
  }
  if (Score >= 9001 && Score <= 11200) {
    return (Score / 11200) * 100;
  }
  if (Score >= 11201 && Score <= 14200) {
    return (Score / 14200) * 100;
  }
  if (Score >= 14201 && Score <= 18000) {
    return (Score / 18000) * 100;
  }

  return 100;
};

export const getMonthName = (month: number) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio ",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[month];
};
export const functionReturnSubject = (subject_id: number) => {
  if (subject_id === 1) {
    return "Matemáticas";
  }
  if (subject_id === 2) {
    return "Español";
  }
  if (subject_id === 4) {
    return "Ciencias";
  }
  if (subject_id === 16) {
    return "Inglés";
  }
};
