import React, { FC } from "react";
import { HomeworkCard } from "./StudentCard";
import styles from "./studentsTable.module.scss"


interface HomeworksProps {
  dataSource: any;
  showAssignHomeworkDrawer: () => void;
  sortedInfoStudent: any,
  selectedStatusStudent: string
}

const MobileStudentsTable: FC<HomeworksProps> = ({ dataSource, showAssignHomeworkDrawer, sortedInfoStudent, selectedStatusStudent }) => {

  return (
    <div className={styles.container}>
      {dataSource?.group?.users?.sort((a: any, b: any) => {

        if (sortedInfoStudent?.order === "ascend") {
          let n = a.first_name.localeCompare(b.first_name);
          return n === 0 && a.first_name !== n.first_name ? b.first_name.localeCompare(a) : n
        };
        if (sortedInfoStudent?.order === "descend") {
          let n = b.first_name.localeCompare(a.first_name);
          return n === 0 && b.first_name !== n.first_name ? a.first_name.localeCompare(b) : n
        };
        return a.first_name - b.first_name
      }).map(
        (user: any, index: React.Key | null | undefined) => {
          return (
            <HomeworkCard user={user} key={index} />
          )
        }
      )
      }
      {/* <button onClick={showAssignHomeworkDrawer} ><img src={plusIcon} alt="" /></button> */}
    </div>
  )
}

export default MobileStudentsTable