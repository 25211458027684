import { FC, useState } from "react";
import { HomeworkCard } from "./HomeworkCard";
import styles from "./homeworksTable.module.scss";
import moment from "moment";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../../../__generated__/gql-types/getClassroomWithHomeworks";
import { DetailsHomeworkDrawer } from "../../../../SingleClass/DetailsHomeworkDrawer";
interface HomeworksProps {
  dataSource: getClassroomWithHomeworks_getClassroomWithHomeworks;
  sortedInfo: any;
  selectedStatusTask: string;
}

const MobileHomeworksTable: FC<HomeworksProps> = ({
  dataSource,
  sortedInfo,
  selectedStatusTask,
}) => {
  const [visible, setVisible] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [singleTask, setSingleTask] = useState({});
  const showDrawer = (id: string) => {
    setTaskId(id);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={styles.container}>
      {dataSource?.homeworks
        ?.filter((homework) => {
          return selectedStatusTask === "all"
            ? homework
            : homework?.status === selectedStatusTask;
        })
        .sort((a, b) => {
          if (sortedInfo?.order === "ascend") {
            return (
              moment(a?.utc_start_date).unix() -
              moment(b?.utc_start_date).unix()
            );
          } else if (sortedInfo?.order === "descend") {
            return (
              moment(b?.utc_start_date).unix() -
              moment(a?.utc_start_date).unix()
            );
          }
          return 0;
        })
        .map((homework, index: number) => {
          return (
            <div
              onClick={() => {
                homework && showDrawer(homework.id);
                setSingleTask(homework);
              }}
            >
              <HomeworkCard homework={homework} key={index} />
            </div>
          );
        })}

      {visible && (
        <DetailsHomeworkDrawer
          back="myclasses"
          taskId={taskId}
          singleTask={singleTask}
          onCloseTask={onClose}
          visibleTask={visible}
        />
      )}
    </div>
  );
};

export default MobileHomeworksTable;
