import { FC, useState, useEffect } from "react"
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styles from "./countdown.module.scss"
interface Props {
  setStart?: (e: boolean) => void
}

const Countdown: FC<Props> = ({ setStart }) => {

  const [remaining, setRemaining] = useState<any>()

  const textChange = () => {
    if (remaining === 3) return "Calentando motores..."
    if (remaining === 2) return "Acercandonos al campo de asteroides..."
    if (remaining === 1) return "Inicializando cañones."
  }

  useEffect(() => {
    (remaining === 0 && setStart) && setStart(true)
  }, [remaining])


  return (
    <div className={styles.container}>
      <h2 >{textChange()}</h2>
      <CountdownCircleTimer
        isPlaying
        duration={3}
        colors={['#3843D0', '#3843D00', '#3843D0', '#3843D0']}
        colorsTime={[7, 5, 2, 0]}
      >

        {({ remainingTime }) => {
          setRemaining(remainingTime)
          return <div className={styles.countdownContainer}>
            <h1 >{remainingTime}</h1>
          </div>
        }}

      </CountdownCircleTimer>
    </div>
  )
}

export default Countdown;