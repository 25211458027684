import { FC, useEffect, useState } from "react";
import styles from "./mainProfile.module.scss";

// Icons
import arrow from "../../../../assets/icons/arrows/arrow-next.svg";
import avatar from "../../../../assets/icons/teacher/avatar/teacherAvatar-L.svg";
import letter from "../../../../assets/icons/letterBlack.svg";
import padlock from "../../../../assets/icons/teacher/privacy-icon.svg";
import terminos from "../../../../assets/icons/teacher/terms-icon.svg";
import schoolIcon from "../../../../assets/icons/school_Drawer.svg";
import logoutButton from "../../../../assets/images/logoutButton.svg";

import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";
import { getActualVersion } from "../../../../utils/version";

interface MainProfileProps {
  logout: () => void;
  showDrawerCondiciones: () => void;
  showDrawerPrivacidad: () => void;
  showDrawerPassword: () => void;
  userToken: getUserByEmail_getUserByEmail | null;
}

const MainProfile: FC<MainProfileProps> = ({
  userToken,
  showDrawerCondiciones,
  showDrawerPrivacidad,
  showDrawerPassword,
  logout,
}) => {
  const [versions, setversions] = useState({
    backend: "",
    front: "",
  });

  const detectDeviceType = () => {
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)
    ) {
      return "Tablet";
    } else if (/Tablet|iPad/i.test(ua)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };

  const redirectSchoolBrisas = () => {
    logout();
    window.location.href = `https://www.colegiobrisas.com/aulavirtual`;
  };

  useEffect(() => {
    const verifyVersion = async () => {
      try {
        const actualVersion = await getActualVersion();
        actualVersion && setversions(actualVersion);
      } catch (error) {
        console.log("verify version: ", error);
      }
    };
    verifyVersion();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.bodyDrawer}>
        <div className={styles.containerImageTitle}>
          <img src={avatar} alt="avatar" />
          <div className={styles.nickProfile}>
            <h1> {userToken?.first_name} </h1>
            {userToken?.schools?.map((school, index) => {
              return (
                <div className={styles.row} key={index}>
                  <img src={schoolIcon} alt="school" />
                  <h2> {school.name} </h2>
                </div>
              );
            })}
            <div className={styles.row} style={{ marginTop: "4px" }}>
              <img src={letter} alt="email" />
              <h2 style={{ color: "#888DA6" }}> {userToken?.email} </h2>
            </div>
          </div>
        </div>
        <div className={styles.version}>
          <strong>Versión</strong>
          <div>Local: {versions.front}</div>
          <div>Global: {versions.backend}</div>
        </div>
      </div>

      <div className={styles.subContainer}>
        <div className={styles.accountPassword}>
          <h1>Mi cuenta</h1>
          {userToken?.schools[0].name !== "Colegio Brisas" && (
            <button
              onClick={() => {
                showDrawerPassword();
              }}
              style={{ background: "#feb522", borderRadius: 10 }}
            >
              Cambiar Contraseña
            </button>
          )}
        </div>

        <div className={styles.accountData}>
          <h2>{userToken?.email}</h2>

          <h3>********</h3>
        </div>
      </div>
      <div className={styles.subContainer}>
        <div className={styles.spaceProfile} onClick={showDrawerCondiciones}>
          <div style={{ display: "flex" }}>
            <img src={terminos} width="24px" alt="terms" />
            <h3>Términos y Condiciones</h3>
          </div>
          <img src={arrow} alt="arrow" />
        </div>

        <hr style={{ border: "1px solid #F3F3F6", margin: "8px 0" }} />

        <div className={styles.spaceProfile} onClick={showDrawerPrivacidad}>
          <div style={{ display: "flex" }}>
            <img src={padlock} width="24px" alt="padlock" />
            <h3>Privacidad</h3>
          </div>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className={styles.containerDownload}>
        <div>
          Descarga nuestra aplicación de escritorio para ver videos incluso
          cuando tengas mala conexión.
        </div>

        <a
          target="_blank"
          href="https://drive.google.com/drive/folders/1Q9b54mlA4lrDOKUbX2dHurbVUQFFTlyl?usp=drive_link"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFBA31",
            color: "#000000",
            width: "160px",
            height: "40px",
            borderRadius: 11,
            border: "0px 0px 3px 0px",
            fontWeight: "bold",
          }}
        >
          Descargar LUCA
        </a>
      </div>
      <div
        className={styles.logoutButtonContainer}
        onClick={() => {
          userToken?.schools[0].name === "Colegio Brisas"
            ? redirectSchoolBrisas()
            : logout();
        }}
      >
        <img src={logoutButton} alt="" />
      </div>
    </div>
  );
};

export default MainProfile;
