import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useTeacherSubstractPoints = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    return await client.mutate({
      mutation: TEACHER_SUBSTRACT_POINTS,
      fetchPolicy: "no-cache",
      variables: {},
    });
  }, [client]);
};
export default useTeacherSubstractPoints;

const TEACHER_SUBSTRACT_POINTS = gql`
  mutation teacherSubstractPoints {
    teacherSubstractPoints
  }
`;
