import { getLessonsBySubjectId_getLessonsBySubject } from "../../../__generated__/gql-types/getLessonsBySubjectId";
import styles from "./tags.module.scss";

interface TagsProps {
  item: getLessonsBySubjectId_getLessonsBySubject;
}

const Tags = ({ item }: TagsProps) => {
  return (
    <>
      <div className={styles.tagsContainer}>
        <div className={styles.tagContentWhite}>
          {item?.grade?.name?.split(" ")[0]} Grado
        </div>
        <div className={styles.tagContentWhite}>Semana {item?.week}</div>
      </div>
      <div className={styles.tagsContainer}>
        {item?.themes?.map((theme, indexTheme) => {
          return (
            <div className={styles.tagContent} key={indexTheme}>
              {theme.name}
            </div>
          );
        })}
        {item?.axes?.map((axe, indexAxe) => {
          return (
            <div className={styles.tagContent} key={indexAxe}>
              {axe.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Tags;
