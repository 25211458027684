import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_literacy_content } from "../../../../../__generated__/gql-types/getLessonById";

import styles from "./tabLiteracy.module.scss";
interface PropsLiteracy {
  content: getLessonById_getLessonById_lesson_content_literacy_content | null;
}
const TabLiteracy: FC<PropsLiteracy> = ({ content }) => {
  if (content?.type === "image") {
    return (
      <div className={styles.imageLiteracy} style={{ marginTop: 40 }}>
        <img
          src={content.image ? content.image : ""}
          style={{ borderRadius: 20 }}
          alt="ad"
        />
      </div>
    );
  }
  // old
  if (content?.type === "text") {
    return (
      <div className={styles.textLiteracy} style={{ marginTop: 40 }}>
        {content?.text && (
          <div>
            {content?.text.split("\n").map((line) => {
              return (
                <div>
                  <p
                    className={styles.fontText}
                    // style={{ fontSize: stylesToGrade() }}
                  >
                    {line}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  // new
  if (content?.contentType === 0) {
    return (
      <div className={styles.textLiteracy} style={{ marginTop: 40 }}>
        {content?.text && (
          <div>
            {content?.text.split("\n").map((line) => {
              return (
                <div>
                  <p className={styles.fontText}>{line}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  if (content?.contentType === 2) {
    return (
      <div className={styles.imageLiteracy} style={{ marginTop: 40 }}>
        <img
          src={content.image ? content.image : ""}
          style={{ borderRadius: 20 }}
          alt="ad"
        />
      </div>
    );
  }

  if (content?.contentType === 3) {
    return (
      <div className={styles.imageLiteracy} style={{ marginTop: 40 }}>
        <img
          src={content.image ? content.image : ""}
          style={{ borderRadius: 20 }}
          alt="ad"
        />
      </div>
    );
  }

  if (content?.contentType === 1 && content?.contentLeft === "image") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 40,
        }}
      >
        <div className={styles.imageLiteracy}>
          <img
            src={content.image ? content.image : ""}
            style={{ borderRadius: 20 }}
            alt="ad"
          />
        </div>
        <div className={styles.textLiteracy}>
          {content?.text && (
            <div>
              {content?.text.split("\n").map((line) => {
                return (
                  <div>
                    <p className={styles.fontText}>{line}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (content?.contentType === 1 && content?.contentLeft === "text") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 40,
        }}
      >
        <div className={styles.textLiteracy}>
          {content?.text && (
            <div>
              {content?.text.split("\n").map((line) => {
                return (
                  <div>
                    <p className={styles.fontText}>{line}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.imageLiteracy}>
          <img
            src={content.image ? content.image : ""}
            style={{ borderRadius: 20 }}
            alt="ad"
          />
        </div>
      </div>
    );
  }
  return <></>;
};

export default TabLiteracy;
