import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState = false;

export const dependencyRefreshSlice = createSlice({
  name: "dependencyRefreshParams",
  initialState,
  reducers: {
    setDependencyRefresh: (state, action: PayloadAction<boolean>) =>
      action.payload,

    getDependencyRefresh: () => initialState,
  },
});

export const { setDependencyRefresh, getDependencyRefresh } =
  dependencyRefreshSlice.actions;
export default dependencyRefreshSlice.reducer;

export const dependencyRefreshParams = (state: RootState) =>
  state.dependencyRefresh;
