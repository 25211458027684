import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getUserScore } from "../__generated__/gql-types/getUserScore";

const UseUserScore = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getUserScore> = await client.query({
      query: GET_USER_SCORE,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getUserScore;
  }, [client]);
};

export default UseUserScore;

const GET_USER_SCORE = gql`
  query getUserScore {
    getUserScore {
      id
      math_score
      spanish_score
      science_score
      main_score
      user_id
      math_medal
      spanish_medal
      science_medal
      level
      main_medal
      math_average
      science_average
      spanish_average
      user {
        avatar_state {
          show_image_close_store
          id
          show_finish_tournament
          name
          trophies {
            score
            range_date
            position
            created_at
          }
          available_balance
          user_id
        }
      }
    }
  }
`;
