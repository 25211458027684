import React, { FC, useEffect, useState } from "react";
import styles from "./conversation.module.scss";
import { PropsConversation, PropsMessage, apiKeyChatGpt } from "./utils";
import axios from "axios";
import { QuizHeader } from "../Header";
import { ChatConversation } from "./ChatConversation";
import { handleAudioNegativeFinish, handleAudioPositiveFinish } from "../utils";
import useAuth from "../../../../../context/useAuth";
import { environment } from "../../../../../configuration/environment";
import { useLocation, useNavigate } from "react-router-dom";
import ResultConversation from "./ResultConversation/ResultConversation";
import UseCreateChat from "../../../../../api/useCreateChat";

const Conversation: FC<PropsConversation> = ({
  individualLesson,
  handleInstructions,
  statusHomework,
}) => {
  let questions =
    individualLesson.lesson_content.aiconversations?.section_1 &&
    individualLesson.lesson_content.aiconversations?.section_2
      ? individualLesson.lesson_content.aiconversations?.section_1.concat(
          individualLesson.lesson_content.aiconversations?.section_2
        )
      : [];
  const { userToken, token } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkParams = queryParams.get("homework");
  const [contQuestion, setContQuestion] = useState(1);
  const [messages, setMessages] = useState<PropsMessage[]>([]);
  const [isFinished, setIssFinished] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [result, setResult] = useState<any | null>(null);
  const [contResult, setContResult] = useState<number>(0);
  const createChatLog = UseCreateChat();

  const functionUnderstoodIsFalse = async (userMessage: string) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You asked this: ${questions[contQuestion - 1].question}.
        Student responded this: ${userMessage}
        Explain to the student, en B2 español, qué quería decir la pregunta y darles una sugerencia de una respuesta ejemplar en inglés (write this suggestion in english, but the rest of your comment in Spanish). Por ejemplo “Te pregunté sobre tu edad, ejemplo de respuesta “I am 6 years old.”” Solo 25 palabras maximo.`,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
        messageUser: response.data.choices[0].message.content,
      },
      {
        message: response.data.choices[0].message.content,
        notUnderstood: true,
        sender: "LUCA",
        messageUser: response.data.choices[0].message.content,
      },
    ]);
  };

  const functionGoodBye = async () => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You are in the middle of english practice with a spanish child  in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. 
        You previously asked this question: "${questions[contQuestion - 1]}"
        Now say goodbye to the student, in your role, max 20 words`,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const functionFixedError = async (userMessage: string) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You are revising chat texts written a non-native English child. I want you to look for grammar, spelling, tense, and agreement errors in this text that I'm going to show you next, and as a result, I want you to transform the text into an HTML where the errors are marked in red text and next to the errors, I want you to write the corrections in green enclosed in [] (and these brackets should also be in green). Also, correct words written in Spanish with their English translations, if thre are any.
        I only want you to return "Errors:" and then just the html with the corrections and not plain text and conditions that you marked before, also I need you to strictly respect the following structure when marking the errors in the text in the Errors: <span style="color: red;">example</span><span style="color: green;">[example]</span> (This is an example of how to do it correctly). For this text: ${userMessage}.
        
        Respond with the following, with nothing else. No introduction or explanation.
        Errors:"your structure html"
        `,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };
  const functionNextQuestions = async (userMessage: string) => {
    const propmtsAlumn =
      contQuestion === questions.length
        ? [
            {
              role: "user",
              content: `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
                individualLesson.grade?.grade
              } grade at ${
                individualLesson.lesson_content.aiconversations?.level_cerf
              } CEFR level in English:  You asked this: ${
                questions[contQuestion - 1].question
              }. Student responded this: ${userMessage}Respond to their answer without make a questions (response must be in english), staying in character and remembering the CEFR level and age. Remember, you are pretending to be ${
                individualLesson.lesson_content.aiconversations
                  ?.character_for_role_play
              }.`,
            },
          ]
        : [
            {
              role: "user",
              content: `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
                individualLesson.grade?.grade
              } grade at ${
                individualLesson.lesson_content.aiconversations?.level_cerf
              } CEFR level in English:  You asked this: ${
                questions[contQuestion - 1].question
              }. Student responded this: ${userMessage}. Respond to their answer with 10 words max (response must be in english), staying in character. Do not ask any questions within your response. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. And then ask the following new question (do not add or change this question or ask any other question in addition to it):${
                questions[contQuestion].question
              }. Remember, you are pretending to be ${
                individualLesson.lesson_content.aiconversations
                  ?.character_for_role_play
              }.`,
            },
          ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const handleSendAlumn = async (userMessage: string) => {
    setIsTyping(true);
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
      },
    ]);

    const propmtsAlumn = [
      {
        role: "user",
        content: `You are in the middle of english practice (you must only speak in english) with a Spanish child in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. You previously asked this question: ${
          questions[contQuestion - 1].question
        } Their response was ${userMessage} Execute step by step the following:

          1. Determine whether the student understood the question from their answer, if they did, put Understood[1]. If they didn’t, put Understood[0]. If they answer in Spanish, give a score of 0 and Understood[0] and Errors[NO].

          2:Check the piece for any errors in grammar or vocab, if there are, return: Errors[YES] or if there are not Errors[NO]. Don’t count not capitalizing the first word or using a full stop at the end of the message as an error. 

          3. Give the student’s answer a score out of 10. If they have answered with complete sentences and longer answers (4+ words), and if they spelled and used grammar correctly. Be generous and kind with your scorin:  an answer more than 4 words and correct should definitely receive a 10. these are primary school students with a low ability in English! Put your response as Pregunta[score]. 

          Respond with the following, with nothing else. No introduction or explanation.
          Pregunta[X]
          Understood[X]
          Errors[X]
`,
      },
    ];

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKeyChatGpt}`,
          },
        }
      );

      if (
        response.data.choices[0].message.content.includes("Understood[0]") ||
        response.data.choices[0].message.content.includes("understood[0]") ||
        response.data.choices[0].message.content.includes("Understood [0]") ||
        response.data.choices[0].message.content.includes("Understood: [0]") ||
        response.data.choices[0].message.content.includes("Understood:[0]")
      ) {
        await functionUnderstoodIsFalse(userMessage);
      } else {
        const resultado =
          response.data.choices[0].message.content.match(/\[(.*?)\]/);
        const valorEncerrado = resultado ? resultado[1] : null;
        if (Number(valorEncerrado)) {
          setContResult(
            messages[messages.length - 1].notUnderstood === true
              ? 0
              : contResult + Number(valorEncerrado) / questions.length
          );
        }

        let errorUser =
          response.data.choices[0].message.content.includes("[YES]") ||
          response.data.choices[0].message.content.includes("YES")
            ? await functionFixedError(userMessage)
            : null;

        let nextQuestions = await functionNextQuestions(userMessage);

        if (contQuestion === questions.length) {
          const responseGoodBye = await functionGoodBye();
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              position: "second_to_last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
            {
              message: responseGoodBye,
              sender: "LUCA",
              position: "last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setIssFinished(true);
          setMessages(localMessages);
        } else {
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setMessages(localMessages);
        }
        setContQuestion(contQuestion + 1);
      }
    } catch (error) {
      setMessages([
        {
          message: "Tenemos un error, por favor reintentá más tarde.",
          sender: "LUCA",
        },
      ]);
    }
    setIsTyping(false);
  };

  const handleResult = async () => {
    Math.ceil(contResult) > 5
      ? handleAudioPositiveFinish()
      : handleAudioNegativeFinish();

    if (userToken?.role === "teacher" || userToken?.role === "admin") {
      setResult({
        __typename: "EvaluateLessonResponse",
        firstTryWith10: null,
        isCourseApproved: Math.ceil(contResult) > 6 ?? false,
        result: Math.ceil(contResult),
        userWonMedal: null,
        homework_tries: null,
      });
    } else {
      try {
        const newMessages = messages.map(({ position, ...rest }) => rest);
        await createChatLog({
          aiChatLogInput: {
            homework_id: homeworkParams ? Number(homeworkParams) : null,
            user_id: Number(userToken?.id),
            chat_log: newMessages,
          },
        });

        const response = await axios.post(
          `${environment.backendUrl}/evaluateLessonV3`,
          {
            evaluateLessonInputV3: {
              quiz_result: statusHomework?.post_homework
                ? null
                : Math.ceil(contResult),
              homework_id: homeworkParams ? Number(homeworkParams) : null,
              lesson_id: Number(individualLesson?.id),
              post_homework_result: statusHomework?.post_homework
                ? Math.ceil(contResult)
                : null,
            },
          },
          {
            headers: {
              token,
            },
          }
        );
        setResult(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsTyping(true);
      const payload = {
        model: "gpt-4o", // Asegúrate de usar el modelo correcto disponible en ese momento
        messages: [
          {
            role: "assistant",
            content: `I want to give speaking English practice (i.e. in English) to a spanish child in ${individualLesson.grade?.grade} grade at ${individualLesson.lesson_content.aiconversations?.level_cerf} CEFR level in English. You need to pretend to be ${individualLesson.lesson_content.aiconversations?.character_for_role_play}. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. Introduce yourself in less than 10 words, create a segue to the following question, and ask the following question: ${questions[0].question}`,
          },
        ],
      };

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKeyChatGpt}`,
            },
          }
        );
        setMessages([
          {
            message: response.data.choices[0].message.content,
            sender: "LUCA",
          },
        ]);
        setContQuestion(1);
      } catch (error) {
        setMessages([
          {
            message: "Tenemos un error, por favor reintentá más tarde.",
            sender: "LUCA",
          },
        ]);
      }
      setIsTyping(false);
    })();
  }, [individualLesson]);

  return (
    <div className={styles.container}>
      {result ? (
        <ResultConversation
          result={result}
          individualLesson={individualLesson}
          messages={messages}
        />
      ) : (
        <>
          <QuizHeader
            individualLesson={individualLesson}
            selectedKeyLiteracy={"1"}
            total={questions.length}
            contProgress={contQuestion}
            handleInstructions={handleInstructions}
          />

          <ChatConversation
            isFinished={isFinished}
            messages={messages}
            isTyping={isTyping}
            individualLesson={individualLesson}
            handleSendAlumn={handleSendAlumn}
          />
        </>
      )}

      <div
        className={isFinished ? styles.botonNormal : styles.botonBloqued}
        onClick={() => {
          if (isFinished) {
            result ? navigate(-1) : handleResult();
          }
        }}
      >
        {result ? "CONTINUE" : "FINISH "}
      </div>
    </div>
  );
};

export default Conversation;
