import { FC, useState } from "react";
import { Card } from "../Card";
import styles from "./tabsComponent.module.scss";
import { getClassroomsByStudentId_getClassroomsByStudentId_homeworks } from "../../../../../../__generated__/gql-types/getClassroomsByStudentId";

interface TabsComponentProps {
  completedTasks:
    | getClassroomsByStudentId_getClassroomsByStudentId_homeworks[]
    | null;
  needReviewTasks:
    | getClassroomsByStudentId_getClassroomsByStudentId_homeworks[]
    | null;
  redactionTasks:
    | getClassroomsByStudentId_getClassroomsByStudentId_homeworks[]
    | null;
  contReviewStudent: number;
}

const TabsComponent: FC<TabsComponentProps> = ({
  completedTasks,
  needReviewTasks,
  redactionTasks,
  contReviewStudent,
}) => {
  const [tabsKey, setTabsKey] = useState("completedTasks");

  return (
    <div className={styles.container}>
      <div className={styles.containerTabs}>
        <div
          className={
            tabsKey === "completedTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsKey("completedTasks");
          }}
        >
          Misiones superadas
        </div>
        <div
          className={
            tabsKey === "needReviewTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsKey("needReviewTasks");
          }}
        >
          Necesita repasar
          {needReviewTasks && needReviewTasks?.length > 0 && (
            <div className={styles.badgeRed}> {needReviewTasks?.length}</div>
          )}
        </div>
        <div
          className={
            tabsKey === "redactionTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsKey("redactionTasks");
          }}
        >
          Redacciones
          {contReviewStudent >= 1 && (
            <div className={styles.badgeRed}>{contReviewStudent}</div>
          )}
        </div>
      </div>
      <div className={styles.cardContainer}>
        {tabsKey === "completedTasks" &&
          completedTasks?.map((element, key) => {
            return <Card homework={element} type="approved" key={key} />;
          })}
        {tabsKey === "needReviewTasks" &&
          needReviewTasks?.map((element, index) => {
            return <Card homework={element} type="approved" key={index} />;
          })}
        {tabsKey === "redactionTasks" &&
          redactionTasks?.map((element, index) => {
            return <Card homework={element} type="approved" key={index} />;
          })}
      </div>
    </div>
  );
};
export default TabsComponent;
