import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLessonsBySubjectIdLength,
  getLessonsBySubjectIdLengthVariables,
} from "../__generated__/gql-types/getLessonsBySubjectIdLength";

const UseGetLessonsBySubjectIdLength = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getLessonsBySubjectIdLengthVariables) => {
      const result: ApolloQueryResult<getLessonsBySubjectIdLength> =
        await client.query({
          query: GET_LESSONS_BY_SUBJECT_ID_LENGTH,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonsBySubject;
    },
    [client]
  );
};

export default UseGetLessonsBySubjectIdLength;

const GET_LESSONS_BY_SUBJECT_ID_LENGTH = gql`
  query getLessonsBySubjectIdLength(
    $getLessonsBySubjectInput: GetLessonsBySubjectInput
  ) {
    getLessonsBySubject(getLessonsBySubjectInput: $getLessonsBySubjectInput) {
      id
      grade {
        grade
      }
      week
      sep_book_pages
    }
  }
`;
