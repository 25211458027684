import { FC } from "react";
import styles from "./footer.module.scss"
import deleteButton from "../../../../assets/icons/students/challenges/asteroids/delete.png"

interface Props {
  handleInput: (e: string) => void;
  handleResult: () => void;
  handleDelete: () => void;
}

const AsteroidsFooter: FC<Props> = ({ handleInput, handleResult, handleDelete }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.keyboard}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div className={styles.rowContainer}>
            {[1, 2, 3, 4, 5, "delete"].map((num) => {
              const margenes = () => {
                if (num === 1) return "10px 5px 5px 10px"
                if (num === "delete") return "10px 0px 5px 5px"
                return "10px 5px 5px 5px"
              }
              return (
                <div className={styles.box} style={{ margin: margenes() }} key={num} onClick={num !== "delete" ? () => handleInput(`${num}`) : handleDelete}>
                  {num === "delete" ? <img src={deleteButton} alt="deleteButton" /> : <h1> {num}   </h1>}

                </div>
              )
            })}
          </div>

          <div className={styles.rowContainer} >
            {[6, 7, 8, 9, 0, "."].map((num) => {
              const margenes2 = () => {
                if (num === 6) return "5px 5px 10px 10px"
                if (num === ".") return "5px 0px 10px 5px"
                else return "5px 5px 10px 5px"
              }
              return (
                <div className={styles.box} style={{ margin: margenes2() }} key={num} onClick={() => handleInput(`${num}`)} >
                  <h1> {num} </h1>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.resultButtonContainer}>
          <div className={styles.resultButton} onClick={handleResult} >
            <h1> = </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AsteroidsFooter;